import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_sor1 = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        BITUNG1A: {
            "1":"1U_Phi-tWoI8puP0wmJ4fRgyegrAFOAMH",
            "2":"15xS2CREx-7zxkCxtbU98svLrlJzQtdUF",
            "3":"1tRDqRmXimODoskSGigL53Lb4xx0yWFEM",
            "4":"1hbH0PsF1vrvJudrgCgc0Y7yyJ_5da6qM",
            "5":"1Plp4ak_K7j_8ezZQzGEW0bC38cjRtf7t",
            "6":"1uIzclyjkjReCAMUPNAtEFN0COJOCRBl8",
            "7":"1NZ-WqNOGq7e-B1R94hNNgV1-19_yM9mO",
            "8":"1qAzJ2XIKWL8sZ0YfVEwrclvggGDDF8Z1",
            "9":"1loEeIN_SPXEPIuHMHUQubSSrEqXy1t_A",
            "10":"1vO9KkblxB33iIXSOzSaEOLPCFP-TVgRw",
            "11":"11h9TRYGyGwKDUhBTti4P3eE0TcFhiE2J",
            "12":"1Ffum3V_rm1K8EdIfP2xfz6UrCuUirzhK",
            "13":"1diqREGtx2bdE7-YOgtW1cE7CTKsyjgRQ",
            "14":"191rQp1VKspChgPwA3hveHkor7zzc23rj",
            "15":"1hbLxpvM4rixk8VPhGyeWPLBKj_Kx6Qdi",
            "16":"1M7vwZDb_ddfGmB1TPHmxZXElx2G7o3vq",
            "17":"1FTVvGIug6wyUK3H2pTPYeke8TMOB8Qnl",
            "18":"13IskbRP5UX8UjYOIB3xT5NwE5feNAgN2",
            "19":"1KUM8WbCS9BhDzlflksYNfCVgMY5PrgSg",
            "20":"1Tej1JcGzUC5sAft0ByzrRmIZHQnlhI3d",
            "21":"1HlD6nH22Zk9yBr5rxD_IwoMZMKGg2xQM",
            "22":"1WAbXh6-GZbbfpoJ_AvASye0fSS7lkqdE",
            "23":"1HqdQvaJ4kUqKhArpX3SNUZUWEAzWpB5v",
            "24":"1Zne6Bqf-2963FuYwipoL7bclnbe2h75P",
            "25":"1aSWzJAOVlhyd5P3u_dWNdU5p4nZiLnBf",
            "26":"16Ejqwk2lc4XtYKG7bMjlYiPo8BYrtu-Z",
            "27":"1ZIicjoWUzsRBcHFXf_NJdmZuKQqiHCSQ",
            "28":"1cTfAyeyoXuEjf7-jIXGiw7Qvrkqs944C",
            "29":"1fwxLFoPt5M9xnp1Rht4SCLpDmPd1F2jb",
            "30":"1IMQHeL8pcn-Cc9tzsdkyxkJRd3cvs088",
            "31":"156nfV9aGvP5vXNX0v_nyH2iqUmK0zhAS",
            "32":"1IkADSLmPbzfA-j8S6BEzD7QPwn_e2lf4",
            "33":"1VDemyenwAdGn5Qa1uAFevwtvO-Q5H0_5",
            "34":"1Q7mLSzRHp1Qb40C3EFr1CF2kVdI5zzUn",
            "35":"1VahMg5kgGSfKSuxIeNU8dK6teccte2Tu",
            "36":"1NOKd4z0qKo_NwcV1sKbLR5gGLFlbtCvE",
            "37":"1a7DVqVxd7lL-Wlv_c_X53BNIoWE7SeHH",
            "38":"1glNq5QI1Ifq0KMJDBsqtF_UwyKjQv-m2",
            "39":"1uynvVuuadd44WiryPQy1RltWXaKmseLN",
            "40":"1g533X8IF_RwSETM-p1p89eWzahuDV3RL",
            "41":"12PmK6pIy73bozCSG8uZZMqBhTo1Y4tYF",
            "42":"1qlbJXT7U6_R4U2f02wT8AOB9zH8NW08r",
            "43":"1vr9Gt6vcNfgyYDfzvEBJ7uew5mH4PR7y",
            "44":"17UJhgQXHwY9VyNDEdK0Bvrlh0VIDvxQB",
            "45":"1ay3lftGs9fTmZfVMaN0-JPBrBZkYyqRn",
            "46":"1JfXIzw1-UJGXB_XqxdFL9Eym5zD3AikX",
            "47":"1nJvBTZmCGugmMQW6jTbAhs0ioNtCLxUK",
            "48":"1OVtS9bpsk5YjU0jsC5nkZcx0YHF3LJxs",
            "49":"1z22ZvlruMjY-GOTzFEMT21ifueAyi6s6",
            "50":"1Z_oFDqalTzmLqsDd0fdmKGX-EXS9gwQA",
            "51":"1AF3amJejxVM-rS8DaQXTRRLykoK-WbrE",
            "52":"1OdRnCMdgjhTtPqE_YYDF6JHkNx8CLsCF",
            "53":"1dMqptcQQR3PmvGaWrW8KSwppBiFwHr-m",
            "54":"1wWPjS3wpRwOOGF54rTw3gQummyi11Mb9",
            "55":"1gdvzNc0fR8ZQ0R3Li3duK7lgXKCzvhWG",
            "56":"1aVP43AmETyTQdWzpq9kAu7EsWsvDY6XC",
            "57":"1Xv9v1F7wuXFfWnIfn09spLe_OHHwRLRO",
            "58":"10n5D8JwZ4wIrDyhh5-WBnvnN_zu7kIRL",
            "59":"1HOmUTo_Lgz51aIhLVD-jw_3x9BweBOoT",
            "60":"16OWpzSUvR8N7Y-j6myfoSR97q-5dzeSl",
            "61":"1nYvN2bW45fd8MGrEDyyUPnxyHSYgbtOG",
            "62":"1pFzCgIe39k-XACB5LameaStSusRzjv9B",
            "63":"1ibWTsarA3eIg6h9GkqlrL_xg6tjfocHu",
        },
        
        BITUNG1B:{
            "1":"1-8zRwnQB1S1LDGdQgyK6YaIKqRCBCtre",
            "2":"1qLe3cgBAPTvNYxAmQhBjwSuOOjs4HkK0",
            "3":"1AwA09LkUP8i8zHVrb55d_9ZJNi1o4Yvr",
            "4":"1PsKd4IHXG38O0kBWKoAsV7ZhsErIo6j6",
            "5":"1M89oqIv1kTfcGckJUgdO71gHB0pLc-6-",
            "6":"1ogoCuNr0Q_GwnRTClnhGk4MLhdNCboYv",
            "7":"1XG1AKv_WlaktqfEeKVfhK5xdeb1WNI7F",
            "8":"1_I9mrhJha-mva7e7JwmezOeD34jnHRoy",
            "9":"1h1HfG2DGuHjocqMWCfZmI7gSzTMBQXr7",
            "10":"1YjcEyVkoYic4vs5ewTnFsa7x12BHGmXK",
            "11":"1eV6m2OZGmL_Tb_ljQATtp8JWlu8FPS3Z",
            "12":"1ZPvVU_Q0A5xMDx4T7y8L0DqX8w3xk0Br",
            "13":"1_5t3oIhS7FZ9i5Gr03roGI24D5tCRjth",
            "14":"103dMBfE1s1T2XZLDoJPut-fYsKUjm2-u",
            "15":"1HhE39u5J2TaXmeQNpVeqzc5ccmyTqFTc",
            "16":"1_6SGgZpTmbDeeaqK0OO8pbAn97NJ-p65",
            "17":"1nj2AStyw4rVuMz3AQhQyUvHrBjtZbsPn",
            "18":"1Xby3ekGZ4BStvfuT4qsb2dPHOoUx7Gui",
            "19":"1EUKWKDz1nVpxlkAGRrklvt_ePnnCUw8D",
            "20":"1d5xcUv8_QkDW3OmXfjrE7Ej-_wtirZM6",
            "21":"1gbXeHO22bXgDU0DDPwQ9CKYTJR-kR2WB",
            "22":"1LV__i6JqyET2QYfUlNlT79xaBlPH2CrA",
            "23":"1_zKEy_yp90kRFZcf4V81PtNH67nxZIzr",
            "24":"153TNMJyVZx-d3k5pogGMb5Pc-dwSAyms",
            "25":"1Lt5x2q0uJwpgBBmCW0bzi7OBPTWdI60l",
            "26":"1Q5FkBjs_X_w7kltjPvseZ3lDbcTRvA2r",
            "27":"1ZiDMJEEtNLuNW2i5uBL-XUlZDB6E--lk",
            "28":"13zbHu3s_mZm7hI1zkuDUcW1CRJDG4RNE",
            "29":"100Hmxy66qia3uaPCBPqr25unkdbicRmR",
            "30":"1mt1vgQ3sV1Ajkhavh6zJXRGXbThllf0U",
            "31":"1EaiR7RqKR1mThgUYjsXQu1guu_4NELB6",
            "32":"1Nd2cYEPH1TukKQsemRseuRw5LpXzq93i",
            "33":"1PZBl1TbI10kLt1EBIdTRfkw3D5K3idDj",
            "34":"1pvMJCKi3Wd5ioKLe1AmMbC-ZEf9-MbtV",
            "35":"1jlyBn9_5g5ZndQy_D-h1O_x8zXZyAAe1",
            "36":"1QJwXJQlbcY40Md23sn58zf_E88rxzQ1v",
            "37":"114zvkcxMBoK8Fm8wk9SFsx6S8mUNqJjA",
            "38":"107kbbQdygdU9qZ5yLw_CpnYJOFxnYjHe",
            "39":"1uyWNMLshAMM7sRhG8yQ03FcPvuVSpzZ8",
            "40":"1vf32KhzyQ-W1FnNbuEG6mxzORtm2SSiU",
            "41":"1jsYhJIFJOAHTbXZDC1IeiuRuy-ZOsFaX",
            "42":"1s0BtpfFfz9RsBZZyqiz4QOO4IZztE47L",
            "43":"10wDOUO2b7NJCLa52wATrpSIJuAjEGGuL",
            "44":"1WjwQnJmXWrBFgNc_KUmytcHr5VWvRt4F",
            "45":"1eH9OYGXBddo9Qt6l21NTnTYxpbYn4UNp",
            "46":"1z-uwLkRH1RwJuD5alw5m-sTkIxDhsQ5L",
            "47":"1hFKG3h0y3RqONf7-1oV-QvzeB-YvXKwY",
            "48":"1BJSeC8IQh-y3ELdRSdyAwkG0bLxUmhbz",
            "49":"1vrJlz2dZTgmvdhSnM8CYrrfybACCHxPn",
            "50":"1W1VSTElYEM0DL2SWgubUWf-X44NeL72T",
            "51":"1CAFxfP6-i0GD_SZH1USXk0_LZKhYHdj9",
            "52":"1eL_I4SU1NoJaqgSnU5zGGfofH4fp5FV2",
            "53":"1uM4DsvBMxWu9a2_0G7mIJByJ8zT-wJ2V",
            "54":"1gpb-PlUcuDQP8_0v0rYSlFjamiHAtSyS",
            "55":"1oYJrXrNt9N6gcOkzMe3g7R-TOKm2DGJV",
            "56":"118eCetS56HuI2LQImtbvkel-MdkEFVxX",
            "57":"1BCBSt3bsYpox5-XnvISah1XVI5zlGuhx",
            "58":"1USnhfNuNdQYAic1jr6isQjumANbh0Hq4",
        },
        
        BITUNG2A:{
            
            "1":"1WaSZBvVkpwIlT7SU8xuyYDvvkPpe6MUe",
            "2":"1-5LJ-O7zAuBpQLQ8q1u6AhxgzY60aF0I",
            "3":"1GUCAyCzOZNwX5le79B2_heGcpZu6GYeD",
            "4":"1evg3qM_hdzjrx68RAqGrlPSnWbTfbqmK",
            "5":"1gdr0pTbyDgLAGkJrBMk-HvlOGB7UlNEB",
            "6":"1UFKQkVSVPtj92QKzBgp-RqYhcQV4FBLg",
            "7":"1-NFEgolc4I6BMlI_mNnbbavdc5CiojSZ",
            "8":"1MqgGifN6Ojq_53ol5Ugt-9uKCsdqN-Gq",
            "9":"173O7o6BKsGe6AEmAA7rdsKT0I1MjySM3",
            "10":"1PaSsMcl3jsylpesY3oGQecGCbMIQPjHg",
            "11":"1JJUK3taKd6rU3WLzixaM_lBBmj9mz9Ac",
            "12":"10BQKAB04sJcbtoDZ9Q8iDo_PsulN-Jz0",
            "13":"1Kaufhp1teY-uheu3b4ghbIQqEGQnUyFg",
            "14":"1xXzTWrQFCsOLTtek2N2w5-gdtKmkh_rA",
            "15":"1EtkwJQqeQ9gZJCq7pW9RLVwo0r1skoDF",
            "16":"1GZNdzZCzKcHBOkLogIcPt-C0C4Du4ShA",
            "17":"1dQthLGg0dniXyPSyvQQSw53fl_ncD0yy",
            "18":"1SEqFCAY2_75yOTNVD35vlFcCjSelao9q",
            "19":"11qUQtbPq5c9g3j69K_qrnSiZI00Oj3Jj",
            "20":"1LW00nUll5ssBjcJQ99To58xoDISEuBnh",
            "21":"1hrjoHe6LybdJ0qs1VFaSTW-SIvE7ffBu",
            "22":"17FuCp4WfyIXVlUUgP6sFfn_wonlEiEmS",
            "23":"1MtLXTwk1JP6cXprPVBDUEYpjWnZAPoQu",
            "24":"1mX1EIo2aVU_dgFAOeTcRqrRg-KkKu_hx",
            "25":"1S_M-uUAHwXEXwMwQGPGfbA6awoUExbsp",
            "26":"1Xt5Bw3eRLCZhqLWKM0IuSEnwUoGYfHhD",
            "27":"1HXvuuYnZdH8vMxX4XAL4oz0gjp9hxJyU",
            "28":"1XqQQSnDs147uhnBPge8GV1SkKxaQgtOv",
            "29":"1Ib-Hx7JhG2lqP1gIQ8F_O2xqDYmpxIQy",
            "30":"1xTn_kMEFp_V9qMzgS9RvaPgS868gWCWx",
            "31":"1afWjQM_xci4HW7nqbHA0Bl4EnTUJdve3",
            "32":"1ps2SSbbm5jeLsObaLZ6T5i93yq7Q8YCS",
            "33":"1H10BbE83ePOOz7VRlVyelIAvAqqbxRTj",
            "34":"1w9Q_sY_1-XF3-OnEibqLlrlDl0tSXvhS",
            "35":"1gqEjUTNnKjH-1L_tU1M4_HpFkY-QtKIk",
            "36":"1T2Xsw0Q_UAMbD90pkycdp-AyyI7GG5Pr",
            "37":"1nURM8w2Mv7G0jLFIS7hPiNGHx8JMO14l",
            "38":"12VfN0Bd-vS_cAfp3lWMPp__gN07exqyE",
            "39":"1YG4rJRONM8ILZBsBXKq6Svg0c82e84W7",
            "40":"1z6NmJDbjfKMYY2CX9EvIx-s8M1XeN8RF",
            "41":"1rWmXPrnZK5gMp8JBPSjvdWcci8nViTtA",
            "42":"1SUtkMpvILETF6hJjiih5iyY4WLhd3IMX",
            "43":"17F21Y81PI8TF15fR2Wqcd42SAuUZyvD-",
            "44":"1JelzM3usTHyqet8h0FoAVLDoPbtz07XW",
            "45":"15IGA7XEWpKLeZ7gF_efXlx6qBckC33_G",
            "46":"1SUGz9XlF29HbjhQtKCZIRB-zZUPKnhBH",
            "47":"1rdtJrzM2diWzwVoM5gY-GtXR-_WV_OIn",
            "48":"1z-fq99rHO5Ze5XYGXx_bXEmCLA7fDXtb",
            "49":"1J0-k_bvtl8Yc90VkgGd5d1f1aiSE64Nn",
            
        },
        
        BITUNG2B:{
            
            "1":"1CU8on_iNRUxL1MKcOiOtUeugjoJgnGZD",
            "2":"1egnSB8HEpbwX_6UUQlBswxfZ5_hP43ep",
            "3":"1UkqNuC2LZt7EfNuZqdBItbQ6h7YbFOw2",
            "4":"1jJzp8UEGm_X3CRTTvfPaGHdxxdkcMy1D",
            "5":"1l_xA0-crJRyEfCUzHwQsyKLpO_Ya4Tyu",
            "6":"1yIlyV2NEXTAkrb9MtcJx32AB0dYeME1L",
            "7":"1AwvNY9R_X9KX7jBpE2zzy9AxJa0WvOAL",
            "8":"1jjvGU0e3CF3lhlLRAVsSu-_Ln6H2Ex-q",
            "9":"1AvpxNTUrI0IMQRnFm-O1zxIxIItBW6h8",
            "10":"1yefWmYuInvpMsbYKFR3gQw6ZVugVxsrD",
            "11":"1JOPFD7gst7NKQ6kG87JkMFNr8im1LhaS",
            "12":"1gonJ7fkSzgrsZIGO9JFfkTucGXYfA6h2",
            "13":"1soYHwwAX0IoEKde6YSCR2xM9ePM6Hyjw",
            "14":"1q_tSQfJMEAsoR7w0uDY2niJ8HuihOySj",
            "15":"15WOKT7E1T5zUNpmc-SfS1Hbv2ofpUpvn",
            "16":"12YGfTjgAnCcaOAQpypdM5CNPL4jyXLZC",
            "17":"12DUGSRNuFY3WSu5X1NEy3DgPZyvmV_qM",
            "18":"1-KYJT9pIsDxgHu4WSF6E2isHsrle2qh_",
            "19":"1ykBRWHbXLc3NV0vqTi1fTZYU0hg0VkUU",
            "20":"1DhKBeLHWrnMTTL1bt__i-rwUnL_N59gc",
            "21":"1j7um49ZWGCRNzXnCdo-lyEtmfiXpIv67",
            "22":"1_s3ZOoZH6Wjmbjb2AwmMNJCAIYU5FS6u",
            "23":"1ztnuZ5l1vsGxxZjDdMyzeR9OIuBgBfJ5",
            "24":"1kbiX-xw7lbneF7rGxeh9n7kS0rsCcUFF",
            "25":"1XSDFAgWxkYacbN01_MSAMRRFzNN52H1p",
            "26":"14c5LODlCHqWFNb9QWgbethDUc_STQByQ",
            "27":"14Z6bIBlDpGjDUHgABOEmBro3f3-1N6WM",
            "28":"1NdAQXCkJufwy9d3YdpJT504DH04k5y2j",
            "29":"1LQCD5awoKoZf29wu4PXIiFSW8mZ6BBU6",
            "30":"1f94zDxikO3-LwPwelFLWeQnnSleY4Jdp",
            "31":"1Rl4df1ALgXwAUGB3UYXWG-OXGY2JXRzR",
            "32":"1t9TGVkYmmGqQSAgZmkB__3Bt4u0OZWl5",
            "33":"10ncs-3xfGLFW6KdlPtcH2kJqrBf6avwa",
            "34":"1XGBwJdsIJtfKTDXhF94ezYuOhW5Mfwv4",
            
        },
        
        BITUNG2OUTLET:{
            
            "1":"18CFpz1Tvm1NF_17m2MMZvkRCKCnrB4zt",
            "2":"1A7dTkoC44yjJLEhIf-1qoLeRAz3YufeN",
            "3":"1VLlWwNU4Xw6GGHtqcs3PtMX0nl_ZgCC7",
            "4":"1D4UB_2y4VR_KV76EPjcA43kVWBjaSnbk",
            "5":"1vVr0_boLDliN86ZWK3xkyQr733ssaeJ4",
            "6":"11H9uHAgqmlTZWtU11Uc9CicsiYpsc_zG",
            "7":"10XWgcfCggCg5YyO0FSC7Q0ZTaskLyYlE",
            "8":"1cWBglxGNYTFp8XuIg3rZvA9MFSW4hFSv",
            "9":"1uVkgv6HwegQTrQU7O7wRKQ_EGgqkeQmV",
            
        },
        
        BITUNG3A:{
            
            "1":"1w8Oc6JjOobv6H8PuZ_8jR9aZ4ZG3MSyO",
            "2":"135wYLzxxRBaCywXIsfeVt2aYvIReV42x",
            "3":"1Z5ZFNoDs5sQbmBbgp-RtfV4g0ne-2MSV",
            "4":"16QP7fa4sFigvLS1ox-oOfMkNPlfRahHb",
            "5":"1vFad1bya2Ytlfxwyofv4arfgDFLpdeAa",
            "6":"1ll-pTFiDHSw5FeZabJDVvVAbLzI30mWX",
            "7":"1mBOwdIzNQ1352vNHaNQesHt_csl8Eyjp",
            "8":"1YriiqJScPpRHuEVx_x_ZJruoc0PSVulJ",
            "9":"1D37BKm-n96_OgZKAR_J_Yj4_voKlDRoW",
            
        },
        
        BITUNG3B:{
            "1":"1-7ayCM4MbcUc_xcXYoRtT5iAfrup6oK9",
            "2":"1AN_QyCW3k3yQ-XNdsYuCwbCJpgOQtL9b",
            "3":"1hAYWGOxxMiqzhCJMLNcU8dLocUKwokCN",
            "4":"136h-7jIgFhj1cRwYhujAUAdtC6Z2jkPN",
            "5":"1ww1_dxpGR-928i3UrMuWpcmi_ZaM87vU",
            "6":"1P8uADHSLxwUEA8h9dDshcD8CyIj7QC3O",
            "7":"1u4GDyT_6bLkWO4Eb2Y8CckeB__-fuAWA",
            "8":"1rPxnKGkntXnN4ECx9EtZ6EXSNHni2w4G",
            "9":"1tznSbPRtl1XEtZ6nzDv6jRFqrQlM1GBz",  
        },
        
        BITUNG3OUTLET:{
            "1":"1hGHXKHfLIdq_90BBUtZEV3HHBoR-Lipu",
            "2":"1jKcP1JrrLcs2XptYiSL5vVo1xhbuP9AS",
            "3":"1tBFuVvkiivTTKAXma36O-4za7C4a0Wna",
            "4":"1Ornn6w7Ukbz1NPi3C9kgom4wjlOMMWwP",
            "5":"11yxDIhmy1hxuT1bvcAdxgNbbgH6GAfRy",
        },
        
        BITUNG4_OUTPUT_SKG:{
            
            "1":"1rz1gSfvOwtbgrxQXNEW5tlUvSJKrMQ_V",
            "2":"1ROLKMgmhMLX0va6-oULLOKrpQUywqac9",
            "3":"1a4FWajMGIAf2gJZySuEyv7JG0Pg29F0a",
            "4":"1VO92lw1zNUaMIObUvg5I6OOjwTJvwKO6",
            "5":"1vigaoL_lHzSVGUsG6ugS3xQ-lNmPePyj",
            "6":"1Cec9qBuTZgdDvC-01yHJI3y2XWGyeYTr",
            "7":"1AgCj6945vdCieZtGDrS0jwuXXpGPsmyt",
            "8":"19KaZZfNnZak0a7UN6LtjVkZ3Zkq4ZiKa",
            "9":"1vfGcyDT2-ENmP9YMBU1NqwkAs-JB_tmx",
            "10":"1PB1aiLtaW5pOtUEgYRJcf_KTdUdergYa",
            "11":"1orap4M6NqLA3d_74NiGUUwSUVHsGaKCf",
            "12":"1MjyGeaEe6ACRgAyPcBEyw-V28LcSZrCX",
            "13":"1d15dUrDlWo5YXStpLNWV0aWU-7-OesoK",
            "14":"1pZVRKtySIumPunHR5Mz86qlOsxreHS6h",
            "15":"19iVS4FDuCV80dG33JPwklWKmWJNtUzr3",
            "16":"1UJEBTKfX8g9m6WaB9sODfBRDXFiIMwjn",
            "17":"1acQ2CMhpmLYO3QKUHYBor_K5jOT03pW7",
            "18":"1d2uAFUFBTJKjLi6vxxZ91hsbKkUngR1j",
            
        },
        
        BITUNG4_STREAM_A:{
            "1":"1pmbynOsozX_o01MpXNaMp1ohR0pTsHON",
            "2":"1tesRRdWXbcdTi7RkbYzX_ASiFVp7o-Ig",
            "3":"1-kzOJEHyKFtgYfCkgBIXYBIoba7GIwss",
            "4":"1JTrWuuPLM6fiDdRpswGf5yURl52tWBPc",
            "5":"1IHnn7pjuTWrAhWaNoAXrmPffkUpfnQXa",
            "6":"1zx7FAmk_mAwdFLTmwKFK58FJ1eGEj45t",
            "7":"1RfgE-MGWgEJo8Q4zp_Mv8ODKcUu3zHd7",
            "8":"1IJdeGUmvIzuHcrj9bRIxWmbdy4vemORC",
            "9":"1ZirermBWl3XAGciJ_84MhnmMufJLs7EO",
            "10":"1CKju2UGa7QI4ruuQpJH1zl3TgcQKjRqR",
            "11":"1dHUYivDNu9Z0DuiE2SrQH8K5KbbTQXGw",
            "12":"1W9kx7YDdKHH_EEXJN1vNTTeRPpkCVRZN",
            "13":"142kdrGTDwfBh-rDY9tK7KScIbOACxdul",
            "14":"1b2t152rb5RFsOHrx4tYkJs9clZXp8001",
            "15":"1HfzaYRG1n7cAZ_nJu7IIesctT33cYQEr",
            "16":"1b27CEucavwwlIaMz25YIDZKnpL0xI0-O",
            "17":"1eGAeeCLIRlfKJK6Ur7bXvkErZIb5PnUn",
            "18":"1e4Ca6JE2NHv-gmViEuID5v-haLy_j4hy",
            "19":"1mzh4fOmUvxggnRLtxArAT0gFxRg64K-x",
            "20":"1s49e3hg9C5KWQzvswCbdrpYMsCQ4e36-",
            "21":"1qCD5X8E7yYXWalpsNdTayPTS5ugSGdv3",
            "22":"1FIlIHUKzWypvvlRZwKT9goqc2_he9c7q",
            "23":"1hnYIx7X-q83YELqigVI95xS0EfL6oCMM",
            "24":"1XitbCD0jdA9Sv3FfOsT6jBjl7L0YFF85",
            "25":"17UsUnLtreA0zVNCADoHjjqVdSMibO46d",
            "26":"1DAiN9Nc6Oaucm1Daw4_PqYczWYxNsS6_",
            "27":"1j0jAqsJwFGwR02csOvJuuQLT0x7-Psh5",
            "28":"17sXpVCRrFkNWrrhGvYYaOohWh0roBriR",
            "29":"1za2pKgeqEL5lGjgj9bqJ7GGUf7FDl8RD",
            "30":"1bvkrILuGSsrs9iMGLXoSL8FD2jx6u2qX",
        },
        
        BITUNG4_STREAM_B:{
            "1":"1_hU72PSJFCyP-YjGZgX3j4cO-7ciMlTx",
            "2":"13bSxextcL7BJk_OudG33qWNW0y-Tiax-",
            "3":"1wnvr0HdkljyOAHofT-jYlYlOlGWmjMrN",
            "4":"1stePkcEas-MGAJTmJPU63iw-bJInhsp9",
            "5":"1ge19r3Ox75s6mkFA72TcGaVWG8ZKBekm",
            "6":"1Dj4huVU8ts46eyNXr2e0jqKskT-xmgln",
            "7":"1I9kE31f5a0oUUKhx9WQ8XGx8FRApKp3C",
            "8":"1vzUHfZ0MK8SiBWlooio8JsrPsHJMIkGe",
            "9":"15DbVuzMb2gp3T9ox2jKd-C0ZHsrk94gf",
            "10":"1cYcKyl51fkP9qH9L_1OLa_mwCb_RIwBi",
            "11":"1CxaYwBIRh91xn1tx6fgzOcywImgU7J6P",
            "12":"1A4_h1eacuaSswB3FW2e4W6_GaYJJvXK9",
            "13":"1CUn4kKi92c-M7GkcNYph0MquXlDS7qdV",
            "14":"1JpZsgIkRzwjrYBivbup_1ACM55oOc87V",
            "15":"10Eyh4r5_qbpyip8R27MhTBMQunKOl3CU",
            "16":"1hcoxk3HR1I8y3OTpyE_CIRKiIGLjh32u",
            "17":"19ay3Rl8gNXWYjlpjqtT7A6DRI_Wq64LG",
            "18":"1OFaqDniL2Fgagu-1dheSeE6gSynBjHTx",
            "19":"1vYcBMkfZ0HEgz67X-jHKcxRvN7a_6zl6",
            "20":"18qh70FNq32H_JWw4oYQHBH2xS2yuROc0",
            "21":"1_z6Lbb7B3WyL0Lv4Za64kcZS6JlJAo9t",
            "22":"1Z6mrhzfQ0cKLA379DXGip93-CZ6uW-FN",
            "23":"142nuW9LOIYXJkTZv7m6fje4uvk5O8a38",
            "24":"103nWng5R9rrXuUrZOwIkwO7snXJ6_bIs",
            "25":"1FLaFw8132JrxUOg3r0rZUWDy59r_9Jmd",
            "26":"1jWpafM8w1heCEtA79O9YpprlHvlOmVuF",
            "27":"1Ue5qgZfQME-XTuXq7qhv0NnS3GlknGo2",
            "28":"1YZM2u32_fWWCTuN1ICWBNgrx-sWJM_NL",
            "29":"1zLCq2pCqouA_V825CpmbiLS6nBvtznaw",
            "30":"1j3HAMCfdPZwT2I0MMcCol8IKJK03lWDA",
            "31":"1RRraTnJw3rI44smn3J5FYIY5gsXmOws2",
            "32":"1b374tRMjKMEPvJuDG6OfIVhcEI8It_yK",
            "33":"1lorhooRUlWbsW9QNAVOOQH0X5dpUDt7_",
            "34":"",
        },
        
        BITUNG_KATALOG:{
            "1":"1aj7H1wRaiYGrLQN3OvNLlWtQm96bZrYD",
            "2":"1NDfp9fV3M2AVA0_yFJG9Leezub2yUUpg",
            "3":"1McnF3-7XaiSn75C8cAa-e846RifNq5o9",
        },
        
        CIKANDE:{
            "1":"1-8NRqM9vneor7cmzIdr5T1AZAXpoVfGM",
            "2":"137VJ70DVZVpvxwuNDJfh4ncSpVvmLgHP",
            "3":"16UwUcL5p3tABd1Msf5QMQi7xLF1umVtq",
            "4":"1KprMrcu1Vr7LXReHnHgQ-BOhn3642rMy",
            "5":"1S6B2u4gnP1C0O2MmC_mLYC7EkakdQLTa",
            "6":"1_9OxC0MroeutM9beCWacoefwtcZDhVY1",
            "7":"1iRH5c7I_iRF_TbvpRPr_QxUCFBKBVEFZ",
            "8":"1ovOllxFayjtVfyuqzo1h3BC-ZQRkNtMw",
            "9":"11lGnXx7oQ-g1mmysxt8YrZvA2EBP6cgk",
            "10":"11s14ncB1WLQb_1_pNFYwcYY9LPfpGbSB",
            "11":"13vrkkjTqY0WZpChq9sp-WwExRxUgMuw_",
            "12":"14-uVYBZUXHz4jxvqeVdlFyh49AdXhZGw",
            "13":"19YH017EK9C_9MEL0IRQ4nd68KJQQsuiA",
            "14":"1BCvEXBbnwLmrfcTDl35U2H4mg8895a9t",
            "15":"1BiQfxhil2ino3rU5dfYaoueZWaHd2jWk",
            "16":"1L44RQIB0LTz_PphOHGO0hPt4DsLb8gTU",
            "17":"1U_ckZkSL_J9xFLeycgThJmkrEzHi4y2b",
            "18":"1UlhsjkdhGB75TKcKuytGH0xkgqli0tSi",
            "19":"1gHzETrI3mSGwZZz8uZcbtERpWljVumvg",
            "20":"1m8cTQul_Wajiq1ZbVX9hA5tJtBbrZDeC",
            "21":"1ntSmrWX6fJ0QSZApuZqFsc12YB7Jbp7I",
            "22":"12MxhKNNXKQMiBmswvovJ7SqUqRyUpJyt",
            "23":"13gf2oZXTHjTRT2vH59uiXGtnecGRgmuz",
            "24":"1JiqCY71eHUSIJrksjlnJbXWyaVDA-MKl",
            "25":"136vHjdvKxBZWZux0I4m7G9IswGO97wku",
        },
        
        SERPONG:{
            "1":"11lmlfkOS1qRMSTLMciHCmv2HWqy-4MBT",
            "2":"16CpoNYTM3-_6Dc8Vfk0UzAiiwfgb_TLp",
            "3":"18Km_pyNJYZfzZIzTwonhjCQL1grhRKjC",
            "4":"1E8b8V4wYLtzBRbMSSvdfdx_eb0bCtesz",
            "5":"1IOSc0Op1dH8vzC-IiBJkLe_r81SWouor",
            "6":"1K7kFwBrGRTZuS1-XGwlUFa5_3R5WDGIn",
            "7":"1KKmRSxrCdeZspw3vrc-Us9hnOXwL1vpn",
            "8":"1O5vQEtda3o55Vkz2s80wTcWJzjgfecWx",
            "9":"1OMBKmFDVrBoXEFCfwvjVzqmGsX1T2DSV",
            "10":"1SsP5mEyfhd40OjJg4VXiir9a3P8N2bGx",
            "11":"1VIoYU9-YHkA6Kr3qkZyn4mwiJV_Gj64J",
            "12":"1Vg1LYqviFh6mV6Jr2JdKQQYzCwOIFVw6",
            "13":"1Z0HB80UToGRRThRMe8RHjYs8Vf-nLuls",
            "14":"1ZckSmlxdaIEP-Y34HnGX1mqJCDhAgEoD",
            "15":"1gT6yUMNtw_duytxhpd4eDhyFnULOTJD7",
            "16":"1hfAJwM-YjqCNHOCoYhztCVnLJ_AYwVfQ",
            "17":"1iuLP9Yr2DjnUss_hsfElqvFKi9V97lxS",
            "18":"1nJhiF8JOApDOCOuTEVs0sE7xLaUyjlAW",
            "19":"1nQAFBmXruLHSO8JbgBpLXpDmnhAJd27m",
            "20":"1olK6Hfo2BvBWCbGL2L8LJYiCe6Z4MWnv",
            "21":"1ozRInrT73_KtPY8dpE977ulZGIqmInWW",
            "22":"1ue2M44j2qZ4384vhjNn8oa4p2XcaHenO",
            "23":"1xFsiu4pl0vnfbcY1UH2eZaMcMcQAnl1P",
            "24":"1z3N32W_ueIzo6q4oNyQYwtBAjOxU-6FK",
            "25":"1-gNJmZUJr-JerLDkQlVq2wbbv9PmsYdZ",
            "26":"106Jca4IkdU0zc24cJ6i8F9HLYDbMn_kA",
            "27":"112kGuBIud0bf4H0TpqfvULJVNeHlzmfV",
            "28":"11nhuijbcgvVajwfHf9gNv7xaMFMJam6H",
            "29":"12_tRNgcyztO9Z2DyS14ve_c2ZJtPiv6f",
            "30":"140HYKRVXW2KpwVcBkLzgR92LUimN7y-Q",
            "31":"16eSxS7_qPjsFMAqUYXslw2djxXPvfXtS",
            "32":"1796v1CfrU0HvgsEBkWtGc6Ki52z-50cx",
            "33":"1A10BCZVAykmXfrQOYVxeiC9Cie2-wM_P",
            "34":"1A2cD-LjQ3KyQq8W0zl1gvpJcyISeRJzY",
            "35":"1B_FVSofQT6wYfmitBR250pV7zwZxB9_R",
            "36":"1CsP9Il0rFuFtFBCPnzBFGuy8V6AUq59w",
            "37":"1DfaHBE03Gc3Ur4ys_sN7ySMklBWsxEgj",
            "38":"1E765_38alcK8YNHqWox_0CCp2oj8gWr1",
            "39":"1F0nvbS4R6WwJlZFGPGz6gcJRj-QHBwhE",
            "40":"1FhOwRoJvg6HwRKCWo5fcnFlKOA5xSkaU",
            "41":"1I35jKRGALKgnBcvL24dW4xydrwA-la64",
            "42":"1I6eF17B93yJbXB-uiAbOasW9JUaiII2P",
            "43":"1I7uwQ1oclTux5VS4O_49rlBJKGfrZTTa",
            "44":"1LBNWK2P6jlfZJOhTXuae1FOlDSOs3Rsi",
            "45":"1MmNnNB67KBmQ05AYiP2QgcH0TTTS0gj4",
            "46":"1MqejXr4_FwzSxxZFrlduh2ZyZNWg5r1t",
            "47":"1N46d-SNjhUcduTv2pThSJ_SqMMXGmoNS",
            "48":"1OHI9t2JRL_Eo8cBmjYc6Y9W-mgulHiht",
            "49":"1OWPknHTjm04O2kkFLsJhPs3gAXKtZ4M3",
            "50":"1So6a8opECOPLx1Q64HhmitHVd4w2KtdB",
            "51":"1Tk7uxTd_0tBlaLlbEao26LKdDoCbRcf1",
            "52":"1WHf-LLlIgcH18BGpr6EmW1-3-6YUSUYz",
            "53":"1WePfTj-IPONtxeRwDOSVHSPh17JgocpJ",
            "54":"1Wt4J7YVdoEAtW_T24ICCeAzsG10okVMf",
            "55":"1XdaqDBggk1Up-m3oSPgve04UrZ9lPmms",
            "56":"1Xf8Jy6RE171g4DaYHiD6snt-Rw_k-_2q",
            "57":"1XjDC86z6764bOysZS002N7Xszw_rLoF0",
            "58":"1YXnXA_S_VK7oH6FyvlZ6ZWAN3HdcyeXW",
            "59":"1ZG1QVBcmkAbsAa3SIr3CaO3GEaNhxZ50",
            "60":"1bqGtu7u-jSACf2jc--OT3n15uQsrPejJ",
            "61":"1dVBafre0Q9NgDiXHoYprRMudYHCfDtxs",
            "62":"1flQj3-xwQEkffMEtLPCA2u2RGzqRkWTp",
            "63":"1fwQATnBJRXmIT2klZPzEY5rJGgn4jaCU",
            "64":"1herHlNVewDVLjJNTATcTou8KcRCnQy6L",
            "65":"1hk-u-OOp3zsChLBPmmXtfUPrz-vD8Iuf",
            "66":"1iuDGwDEyl0HhEg93UwwcFVHmjo_cLOI5",
            "67":"1jNeCPUDv2U3p2bxHSGIL33PsZI1A7NXl",
            "68":"1j_gOSso5iymDEGObqMvkZOZPGdcHb5EQ",
            "69":"1jbbszKl0S13q6yTPMjSdjPF3Bf1bEKc0",
            "70":"1kqlQvfh7n-pjikbfjnh2b0Jn6aB2D27r",
            "71":"1lH0PSWXRLeWmRROfe1XDPHBhY53SRggb",
            "72":"1oEFo8VRjCLIP-XhEM_gMpzyJhea5K0LT",
            "73":"1oc8fjtie0He0elX7ML76mtkQPPztv9cG",
            "74":"1p0deMSKGcpv_cJQ7xxSHWY9utH6ptnPL",
            "75":"1pHLTSo7xo5PVRqcsZCu33NWlxs14wrVx",
            "76":"1px27ZSoLog1Nra-Ll0CiBxMWX_tf5gBF",
            "77":"1qk8RrKK1yca7QjMbGucH3G0vmQEMvQ13",
            "78":"1qnb__XKH2TY7ANFDM7npO_yhn3aLjitL",
            "79":"1rSncAzTXOkChBrT35YbWaGUtBRdO3gJu",
            "80":"1uUHXN0pnjXfdTDgvHbgwSmBFsQvTfWBD",
            "81":"1vQYmhmhmaqnBwJHKZlGnsrEwoD7-fz_N",
            "82":"1x40onpGZR8zC7yIZJvJopFxniezYvA6P",
            "83":"1xd3mJRYsX-WNSlMg7Ux7fT3fqMgWmLIS",
            "84":"1xf6zvMI9hKomhrDMyWqtf6OB4OKXkxNN",
            "85":"1ySe99KzjKLYeGkiPNty7N80b-KrLcVy3",
            "86":"1z25tzWEnzS3f17Cd65cKj85l1m63Qfwr",
            "87":"1zUuL4ZKH4P4Z7PgncLhua00f4S1RQCEc",
            "88":"1znuLzia1WN4ABpO6QJG85dFZrApQ3eem",
            "89":"15484bqx-af4ivoEuvQ5LspgtPj0mhzdz",
            "90":"17i0IXB9Z7cLsQ-kGY9616GXpYr6TukAu",
            "91":"1EPcmNxX5bL4vVyrVNIFy2081otVHo8DT",
            "92":"1FAWoARBNW5iA_fs-B3DvCZy7TYMWZ7ag",
            "93":"1KX60PiBgoOYd69UDywvXOucrehk5su2P",
            "94":"1L2lQBxhzIfmwaMW1KRqPqAi7sPwwJy2J",
            "95":"1e0arNql5k__sOUj3O1Uy8mW_FLecLaHy",
            "96":"1e7zujXIh3oyPCIW8_Hx_3C7IG1bj1iYh",
            "97":"1ivUqnqcAw2t0Upzu8x-qmXzmUoPED3Ve",
            "98":"1ju-ZB_IYajYTGRSITsh54DqMTwwU37D-",
            "99":"1pTvZd9F2a2CfAcbQX_kKSXRhc-_iWRS4",
            "100":"1tUXJyEmZlqZDkBS2Ya_OqH6PuWzxKM8C",
            "101":"1-LxsTv84P0vxE2JnIr7qKmZqFkHFGiU5",
            "102":"10jNxrGw6kij1mQgz1ZIXLN8O8S0i_OwQ",
            "103":"11B3C3LdkSayKvU1NszmpGvVlBPladpRJ",
            "104":"11XToy-UtLNVTAlnpxtmIQsVOSwYscbsv",
            "105":"11icEercrWKOwRysQNuLZe24Cw3FUenGS",
            "106":"15h60WeU1Ugk6E64uBEPDysFpcMGTUb3m",
            "107":"179I5Ee6cLRRkzC4lXmHX2suZRKm1AWRT",
            "108":"17yi0UE-I5x282JXiStEuyPs8iDBdw4SO",
            "109":"1AQuUxpaBYq2GvVndedy46stGF_U752n4",
            "110":"1AntmXoZmSEE8Bmc98MHyNPb8PsoPdWKr",
            "111":"1EGlvS3FVsPhPoTNwMVALo9CfNly3jtYp",
            "112":"1F6uFF3hAxjVEyBXnPz56Pf2EkubkNQXt",
            "113":"1Ge5FsRjB1Nss3eZVDncKIBErnWD5Rqc3",
            "114":"1GkTtoqdRMaJNp8H9VRj33tBhXanwAaJb",
            "115":"1HFJ63qMT6e5JiSCOozixXTBuD6iajw8I",
            "116":"1HY6dKVZGj07r-7H_ns51XeEU_xrT7lEc",
            "117":"1LXFyn-j6GNU8qcqqgrPwwCk3tJXc5VBO",
            "118":"1MrrM7QjbqE3a78lxZPQwgCYZc7l_iNjq",
            "119":"1OZ2mvMsi1Zx4pnSP6GNq9VGw-inKIV1C",
            "120":"1TIhu-l6ktQRBLqn8FyR296dVssm5rfI0",
            "121":"1UQi8a--Vk2cqWSCzMYHnRIm5LnM22mMT",
            "122":"1USCwpeuAbACddSbeJMc6iywWuRb0blDD",
            "123":"1UnuCHHhRdmSKCTgM5tu65jzT802NCml_",
            "124":"1VJwkBqocfqCukTyoqKBPI4h2F_-oabq_",
            "125":"1VePWcTULeCNUhSNIQkBAdX8vXMjea3_x",
            "126":"1Vs0azDH8aUXkA3SgnzhZxGZwhGFIiu4N",
            "127":"1Vw2izwMMKahBbAZfeyYrW055PR3vbLZE",
            "128":"1WG7bPqBY-kUUWau9vWtRFWgXNbPqRQsz",
            "129":"1WGiXC3sjiZK_8xxXWZ8ix23Fc9C7pvg1",
            "130":"1XShW54hgTC-oHPbUUMMFGxalIpqAh2_w",
            "131":"1YkNmRAq7Pg0S6pGc0A-0KM2pDyzQzkop",
            "132":"1ZGQltJG-SfvYdrPNwJWrocoMCIGSQKQ3",
            "133":"1bdsllLY7M3lnzKNfEAHvHtHP7bTpWDmU",
            "134":"1da_FYL_7HE1bB9aTBlsjRNmTub2JwcwY",
            "135":"1ePUD9Mlhdbwd1eJ-dqS1MRu8nUuNotM_",
            "136":"1hf8Vdr5FO_5qnPAO67WHsmlJr6JPvxOt",
            "137":"1lmJY7JJ8a0PKP9LUsH0G9KIKxThVoxs5",
            "138":"1mBjUXB2mzNhbbzV1UmqksaPpXyUMqPjd",
            "139":"1mJ8Iusr1ha8MIp5pFAKLiMog94j_vZvl",
            "140":"1n7h73G-WXRbg2LZ5WIEt9cfAYxshz5-4",
            "141":"1nHNtlDY4Y583s98IvjMjWPCQsg1HcOxc",
            "142":"1oI93VT99sD1oHXoZDjMkZLMHZAew5aQ4",
            "143":"1pf8WZ66onPJ7TJC4a6zYm8f35Fd0-mro",
            "144":"1qRJLXtv8ykR79sbgl_puaSKsGHaftuYy",
            "145":"1qqJgvPDqAMhar_EaRXrnm3SLiifM4oO8",
            "146":"1qwQNp_xLGGVrctiifkUWndjsH8lyeJwJ",
            "147":"1rJwWaOD0ujI_cQVRmNk4-eEbBesOKKVl",
            "148":"1v3Fo_ds14MCrgt6tSTlxJgxEm3vsSK6l",
            "149":"1vT6aaDCsyjOgOKBpzbS77RnUJhNd54wJ",
            "150":"1yp_br-bkiZmykPkXhsAz9Api8jO9NmiC",
            "151":"1272j7RoobxosXL8vaVzUaHLB8sb_Gw1T",
            "152":"12dRC9UFj3HHvr3krOchL1ZDtQRzaZ8Cx",
            "153":"16jNeKm8R83gFrzURs-ApC6ug07phMvhr",
            "154":"17qUOBLjYJA3PZzG_nW2vslV_s3I9ubrq",
            "155":"1EGd-xYNnPK0GAX9NTfzvG2R0eU3-PP08",
            "156":"1KtC-kBRICk7s6Azan5w8mhh2Qlq4PiRK",
            "157":"1OHsw3wrabY5xfHlf6W2u0-hY438wbumJ",
            "158":"1OZ2cg9yux1AafMnZutGHYtbH-2Qn9qqi",
            "159":"1bgt1mw3WlCXrMRwZVD2rFwC09ycuchLl",
            "160":"1dBzC81v6-6ByujhpCHuwyadEP7nCFKGN",
            "161":"1gqO8u68_-tqO8VuqFGiGcM69DZeNzvWA",
            "162":"1gvuubddgFVyiA6aXT-d72nzQ7whSFpY_",
            "163":"1z7eCsZkNaYckh7MqtmKlrt1z9G8HXxLC",
            "164":"1PmxD8QBavZrcWWswzfQVksTR6xznBAzT",
            "165":"1CBvYcbTTvWdfZa9snwlBYFk_3ta-hvNs",
            "166":"1HgJZN6ZQxop-uhjN30hXoJXjCothbZxu",
            "167":"1b-xiM_3OUGtHTYrVRoLZ43WLiYmrUtNn",
            "168":"1tTKy3XEKuNIrnZBMAv5c0A5GVI1oq86-",
        },
        
        CIMANGGIS:{
            "1":"1-XPpHqcjmmREG0jfreT55wO1GitjC-6a",
            "2":"1-hIt5o9-UmQ9JfsRe8ozZkPF4U6BIkBz",
            "3":"1-vtCTiiKchfN9FM6RzaEOGVql9c9m5qU",
            "4":"111gJVB9bJTo02uln2yprAizHSwfpRPmE",
            "5":"12KgDauia3VQqXoYCc6WGW-PUIKsBZNA3",
            "6":"12scmc9wkomxMPg9vxROKJcFHVbMP2wtg",
            "7":"14iYttJvdcyma5itnHkt9580SDbbCxN38",
            "8":"15whFpuocugoJqmbBPa9LSiYU2URJJH_2",
            "9":"16tzp1fvn8IyPw_gUgVMZy_4C3NIdsyyp",
            "10":"19NeRk_jcMTTRUxcfM2tAZSDdBtr7PZ5q",
            "11":"1AFF0iYkN3SEt0XDf_puYjhkw0mG_gWfW",
            "12":"1D6sI03E3Kn3zIOKSkpMKCKXJcP9qxDcm",
            "13":"1DFIY6YNlYNPiqOjV1603eLxrwYkORGU9",
            "14":"1DR0xk2urraD-hzuaiTkxsZhZzALstBdd",
            "15":"1DlYyftju-EzRiO7Ahu0oVyqgpRqTPcsC",
            "16":"1DrwZDc2cYP0bNpM0V0TLXS6FtiMrqys2",
            "17":"1G0nEb7veQEN1IAK0ZeIzxIa_uFymzMPE",
            "18":"1He8iZGp89jb1FqcMjRdx6ZSEVxwqoqT9",
            "19":"1Hs_sFwY-CQmCHSO-5M6s30UH5WOHxG5y",
            "20":"1K-P6-ZWxH-FBenBTmYoNSgP3-Q-mKNNE",
            "21":"1L6_Fg-Rc9q5yvQHENdh53xfphR4ysVa3",
            "22":"1OGF4Wtx6as2a6H0QWxi9B4gGhfjeZnCy",
            "23":"1QQv4MRWc-C_JAcdhztM5lbSvZMw2ygh0",
            "24":"1Qc0MO0WybBtjm_wgT8W_rjcAWKlQhX8C",
            "25":"1QgMh6D-QXRecIqh6FlcBh6kN1JqCqmM3",
            "26":"1SeJujn1lISnHLM6RaEWLW7hfnn4AF0I-",
            "27":"1T9BMvDZOEJ75C7cc902ZSwRkLEs2NYhI",
            "28":"1U4n3aK1zZ_sRb_fDAwLYH1Xk_ZbzAn9L",
            "29":"1VCAiiZhs2Mdr8uFGMBr07R6xC86dP_PM",
            "30":"1Xs276CrOCzPan0E3kswilPeYBpmynS1-",
            "31":"1Yxac7g8bAIVsT2WEgk9NdQamC9njBPwn",
            "32":"1azIJJEqNg-auH9u3clssgyqhRRwEd1Vz",
            "33":"1bD7veu6CGHH2QsGDnTyMeFHLRH7mi80E",
            "34":"1bYZQpGhUP2Y-WR35YT97Vf_SJ_JMmMtT",
            "35":"1ceVtRYGOWOkfZe_LqxJYg5kDYBG8Unng",
            "36":"1dD1-hnyi3ZzO6sW6yPjOR74lZLPfgxw1",
            "37":"1dI6QPH3wE_4fTSJ9v_9Q5kfI_l7bl5TB",
            "38":"1dMFwL1zNQydBMebCdS2Pw9MbypcjOoju",
            "39":"1e_uAAT-yMNY-KpatfY4c-fEwu6q4k39Z",
            "40":"1gmI4omXR4M8mcU6YkE82DSEpBTr43hmt",
            "41":"1iYoOKori-dm5SCQ64YMFxGG7dQ-MbnFt",
            "42":"1jZjB2JGJSiajIRuh8KDyE-AXX8MG5MaZ",
            "43":"1nGpUT6IHKT1WlRtZOYeFmfRDDB32hW63",
            "44":"1nIX0046kqun1ljuAUX7uJOToCizTA2Du",
            "45":"1oIulOAfR2ofKFZ1V5h8hNs6G2QNJCQvA",
            "46":"1pR43JRkdw_Ye0gCBBM8egTpYR2nShJTZ",
            "47":"1qAOVDYf1poobTNAZKV5d2rdzu_xk57cH",
            "48":"1sNz00w2dzQdrAg08aQr-_0csr0Z6H8Mt",
            "49":"1tOFrbFTUw7CccgRvc6QWImu7QOVf0HOi",
            "50":"1tblWf9syg7unQXnZxSnXKM-LZpaGK5Iu",
            "51":"1vrI1k0cGqWdQNjOmNwBg1CNCzlmzvAbS",
            "52":"1wJ-oIC-C1ppkGlIwZT7mk6u8t5QewlFA",
            "53":"1xBr0MiquRakz8SZ7zoouCQtEND-pcSj6",
            "54":"1xZEtpM6O9BMVehpwIU1wgQpb5kPSrsY-",
            "55":"1y-vna2PomSrIYwkZRiDl3dbVicfxvEPR",
            "56":"1y4jDQe2VXRqfTVcV3OWBqaq3COX-yaZE",
            "57":"1yS3owz2NukekP58OLTw1U-BRKHSl3Hqi",
            "58":"12Hu_LP0-tHUmNmq50lSJeTvzFqfJotv9",
            "59":"14HJbwQy3Snlls5tKqhW-h573S17HbmaX",
            "60":"15FyY-ROoFszIxvMGfTee06a9uuwsRktv",
            "61":"17SGkWrKhWHAy7j0Yj8PQn47e_gnPPmrc",
            "62":"1A7koMcBQQ9Kw6Ei2kyzlQY1t_aQwHFL6",
            "63":"1CHlQo55ivCwZZleMtaivjsy3kpzlJtpj",
            "64":"1Cn7xE4HvalsrTkOnVNn64skn7Pd1t5CS",
            "65":"1Ek_fOwvQXqzrYACFBrmOMBAfcNu7SL8S",
            "66":"1EyIEVlOYiEpHR0sS918-eW7IS66li3Wm",
            "67":"1K2koz0bL64pMWnsbEodPPoTb58fMKNsG",
            "68":"1KuG8NLcFm2rwzDEjBHgZQQ3lDVgQZTyL",
            "69":"1NZKfGGaKA9OqvxXRbfyJ6GkEist17Xx2",
            "70":"1OJAA1uPjtcfKwlSQEQ_RfC4HvkBGTHfb",
            "71":"1RF-y05gpCbRXLQDHl0-dwWpPLyf6PcEX",
            "72":"1RfvSY-ppH3eYPDYyMG13uqq4qLqalC7o",
            "73":"1Rizctgywr2vFDRFgEHy6K4hkSwZA6S-5",
            "74":"1ShQPnyHtPzORFAe3coR2_Duc0sB4v2jn",
            "75":"1SqOEBp8UFBCvOBj6Y9tz7nxZPK2cTK4S",
            "76":"1TlhkDV6EQYtq0x7eKdK6cxaBWllfo3vb",
            "77":"1WPHL2axPSfREDNVCyFutPOxcUWPmtWw6",
            "78":"1WR9E2nGwJ7dXYyZRgLuK_M8sFl4mrccf",
            "79":"1YtJYeIm5PtGjKtWgTDU593E07Si3WGkw",
            "80":"1aT7LQsB0xyMju5PZqGpNtU-SmnnCbLok",
            "81":"1dLT4t3hKv4htwNvVO9IJcIlRaGA5QX8y",
            "82":"1ek8NZKh4Qvp3BxcEu32nIdS4cPC1Oudo",
            "83":"1fjbXZmQXTSnldLexuZI81WJOikyQnRte",
            "84":"1gX0ytoIJLqV51IY_SfeNVkoYerznAZ4Y",
            "85":"1gaduASGaOFrlLega3s7xxqCiUtRCzwTs",
            "86":"1gzl5w-iD0KCdgu613EEb8GDdpgWm8bg-",
            "87":"1hKNNoyd1ShxUUQF5k_qcNBolTOryslHF",
            "88":"1ipqpI5lKr8APa2KXDGkIuRaGFk5uvciY",
            "89":"1jA4FUNyX0gBCuOUh_L8LBapMJH65ergV",
            "90":"1jRlkMHtxruUAeR0hXxLxMw3TUm-nI261",
            "91":"1lAXhfOnX-RzSuKXdTvC4OHUzsOrSP6Us",
            "92":"1lq8RtXKsd5KqwQYEOskDfoAxRPl9NGkC",
            "93":"1pPDbHDxDAWjo1GpI5RXkiZ2MdB5_htQD",
            "94":"1piSCUFjO4DP1MYfeGggcrV1DRfUz15ts",
            "95":"1sQrA9AwhO3J88H8JGYHn16TemoFMRXh3",
            "96":"1tBCd3QSdw-4DQvhn6oe9tDfPdSV3dTiX",
            "97":"1uis04hFquxBGvn5I_IQUXsdoB6rSaimI",
            "98":"1waqU2MeY7bW9QEMMvFIWPUCyb8CnrpwK",
            "99":"1yPTwGI9MvAy1DFstYI0i821ku6ipKGcD",
            "100":"1yv31vAQPAORdkMKTlgZ6qYf70OkFjkmE",
            "101":"10uUvk4-hweQyr9F96npAz0qYop8bW_Da",
            "102":"14dinI_RTeCrHk7WOlDadTrHlQwq_NDQ_",
            "103":"17PDCuY7AY3DU7Z9Qqw5JTQX-roWNQ0Q4",
            "104":"1ARJt9htzAKNGVztR6Fd9qveVIVTHrtgC",
            "105":"1E7tkOKi6toxn5NJHy3ID7A0q3F_TThBf",
            "106":"1G5Z31EKDSIc8Bieq-1V9OB3fEJdc8Nzj",
            "107":"1GUnLKmM8DekBK0vMCytej2az77F-w51U",
            "108":"1Ivr_uhp0c75roh8v71zyDbSwOYZfPcIB",
            "109":"1KjRwPbZy6iPIDD97jnGYwOy6BtZTjBsS",
            "110":"1MejnCil0xrU27NS3h4YqewKjl105Jwn-",
            "111":"1Ppnlhb2tjlWNuZdmMU5JDks9Gq5G0jiT",
            "112":"1QeHgqwgiCjjKZIga_-sQDwiKhnGX93LN",
            "113":"1StCP0W4td8Z3WfXvbxpC4AVbnAgCEHe_",
            "114":"1VO38cvzwBElTJlhS_YHwm9Dc2qiyJUYT",
            "115":"1agX9U4cM2A1DiEpUAIvy53lxL6povOnH",
            "116":"1c1eDdAr8jUUJGHUDeADlgALoffLuUJg-",
            "117":"1eP4snqNS4tZwazLfZBVS1lcbreoFZPcB",
            "118":"1hxnSA5ARm-EltTXzZWbHfDXrYAG_ABPV",
            "119":"1k83sEKAQwYSeoMPcig5GWJNm6qDhE7WM",
            "120":"1nO6dwwTlp8nXiZhHnRsFB6QAlXXGZWd6",
            "121":"1w34p2BH2WKiQhLOxtDvb49JEa1Yt0Cl5",
            "122":"1zboY5PtCModNS_p43ogBIcwyyGF0eVk2",
            "123":"1-IhLEuMCGtEJRK-NfZRJ8RZ0LpDA3iOx",
            "124":"10gcv6Nq2AMrAT55NqG26NP4H8mGxmELB",
            "125":"11GuFMvG3Nsoqaimjj6L8Teu7eijbN7hN",
            "126":"1287DPe1yRn_UQhfpadMYqLl4kUpASAcx",
            "127":"13jDf-nxpDERNvy0c5Omtdk1f7GBAAv_L",
            "128":"14zCX4qn0g1ayT4TFFzYVuCETQ25Ii7PF",
            "129":"17qclAKSjPT8j2NDjfCkaOTLktIUCEnCL",
            "130":"184X7a7vz1IHyiYisZdQY1Bj0s7NxaFb3",
            "131":"18O6aDfe5r1g0FCGwPx8klrEiJKtqa5x6",
            "132":"1BzlkDsqt7ZgFmfK4S9mz_XFfT10JM2mu",
            "133":"1DKVSYle5zI_a4JyRRQhsZQJL1ZiRlKoL",
            "134":"1Epm7vNQH2cia1uz3fNMCjDhPfEke9bpB",
            "135":"1F7j5NWZRx6ILYRL1kbvq5RmOMclWlALi",
            "136":"1Fe4ngaubrlCl0b7By8IrHk9NnLZGYmst",
            "137":"1FuelUgZs1z0eK9Kwd10cgjxi0OfiAI1K",
            "138":"1G8awAdr7DHdF9-xbQbMzQjWflFxH1t0H",
            "139":"1GByPiXdP_IVsq9scp7R41RqMJq0kmEBH",
            "140":"1GR03DH6R3ox2EoFgVGU0g-QCb4NQjagr",
            "141":"1HANUZmrtf8iz4Ozii2g3zl_60f3KK96n",
            "142":"1J34Evj_2lE6XhQTANEn1JGoz5-adyceZ",
            "143":"1JA39A6896UoqqnlMGPRy29d8MgeV_RYh",
            "144":"1JfhPI6qrx6j79IrH8wY_vWg-vn5V0se7",
            "145":"1KXOqiA54LIboA_FYPMidksw4XU1PsrJ-",
            "146":"1LgrDXZeqaTPkV6uD0-b1H9G3MY9mGZBe",
            "147":"1MKfPd8JKWg8viIRqvXt8Ob6Qd_aYO0Bd",
            "148":"1MKzIABrXUZENIdiwEGc25p-cG1I90irr",
            "149":"1PH3G4bEnMqRdYiuDhSgIpw-lHAqs7Ev5",
            "150":"1PO1_Kgdrsi5e7W-uk2kUrlgjh_yf9QEa",
            "151":"1PpMjU34GOEQbZgvWIjCCGS3pGccsUvgQ",
            "152":"1Q9xLJlANR5HOrIZMllcYNTR1wrpTWB6S",
            "153":"1R72xZDJrGP9KMZh8vcx_A3P6nMPhpEuq",
            "154":"1S5Pd7b5n6OoAlVSA08ROi0Rzu13d8-a0",
            "155":"1T1mOPM24WLE6ufS1VgqrGKnXmRw-4Mi0",
            "156":"1UBiUMvX2E_U9jF40Qs1maqy3XgYy4zdA",
            "157":"1Vgz1JGZcqhVnjR4qViSTAltkME1i9yHv",
            "158":"1W0L9HK95I0mGFVUp0aGlkPsQ-Q-ruppt",
            "159":"1WqqM2hQX4jeoMLeA0PXDexRyeP_B9rbg",
            "160":"1Xb0jzufWin_Ghbosg6oZ0FKHy8RSh_Rg",
            "161":"1YuUZzQuNZHsOVpIbUWEf_ireED5TT4Tj",
            "162":"1_INlpA12Z0q2EBJH1BNOmu5TTLpfSovA",
            "163":"1_L_5v8Ydxc2b8pMayXXVVI1hWSDOZhWK",
            "164":"1_cb0qWMoO-a2BtVujfBKS1KiPjJ9MsYe",
            "165":"1bMw8FMwK37UVKu1XIZ8LfqWYrye5LPt5",
            "166":"1bmue_DaBO54tktFoSiyT8CjxdiiPJ0l0",
            "167":"1dsZ_6ohQK3jNnTlV9tCTQtBjVtwMJQOg",
            "168":"1g1jpzCkEslUB-8JVmUdqblUelOoHHcqB",
            "169":"1gxYVMVpBCbDc6ZaklbbJYVVIGoAU3zZZ",
            "170":"1gy_DXxTZu-RMydDs8LpNoCXAnXdVz4B-",
            "171":"1iNE-db8cCwUgwHFMrMZevMLmMpm0CD60",
            "172":"1lf3u1LIzF4WAt9pOSAR77jK77a-WUWc5",
            "173":"1r2VWDebilDfC8gfFKrnzXMCIzDDpj023",
            "174":"1rOAWpu5ReBEULDTMGmEJLL4mWtdqJWBn",
            "175":"1rUX_YC9n0yuw0H4apOTW4yDJRg6XDeAU",
            "176":"1s46g6E3nJ53eeY_tNX_IhnGKAzTabM7R",
            "177":"1sl0BlveOAgyizsPO6nRwAYkS_QisN0B7",
            "178":"1tFGnegianCVgg-9kiXR2D-3MQiSBPXk_",
            "179":"1tRCQfc8HOtauJgfGLod3mjXuw7A85Utq",
            "180":"1tSxWcn5rzujqJSKyy13C5f9N_VPLqRUN",
            "181":"1wSS5DcRTYonon2EpwBNKg4pmmqOPaWjC",
            "182":"1wYWVGv8htG-1GpmHKT_LZjPSGAvMEW-l",
            "183":"1xO0xt-2Wfi6iRUahYy5S-qUK1QXRmEpT",
            "184":"1xoNQHNBedaOW5Q6Fb9PrewZJR62mzhuD",
            "185":"1yoOkJyIJ6isWKJ9-sn7euss5uXvG-Xdh",
            "186":"1zHRrRWAFOMImUpQJgEMDBPgbNfyrWYhy",
            "187":"1zSAcxZYafjQo1oq2wwimxqXy9xOkrSxm",
            "188":"11g1fL5fgQZlk-Nvjfwl4qAVUKA4llegW",
            "189":"1FO4Qrk-D8ysbIMdYZJ-fV1dqofdVFY_T",
            "190":"1QCRjipVfO1BNGviGTtC6k2XSz6LecYhY",
            "191":"1_EPYc8jQiElEK0apiIyO55W1WpbgLChp",
            "192":"1_RXt8foxvZtjTsM-3qDslMKQD_AKbgqQ",
            "193":"1mchxYh7UmZmehkyL5S9hz4OYQt1kz4eb",
            "194":"1ojszwTnKkUocnkSjbBj2ysJUcj6s_IMK",
            "195":"1qCCfzCHYQ3fQo7P9Lg-TCFrRqa4NJIQV",
            "196":"1rLxlJqqap8Pd8NUCdYApMDO-t7vyF9av",
            "197":"1Q0Gs7mMz9_JSyGWePP7CaUk6KZkL1SWK",
            "198":"1pBP9LRi5boZiyByK6wN2k-_0jnvWWaY1",
            "199":"17dVK2AxFClgBAc1UrZUBCbimW945PAuZ",
            "200":"1MPWoj3Eakatce2kUPgbgVVwQPut1Fv4T",
            "201":"1X_DgHtiVHHHMZtQt-DBBtk9DpTdDCEDm",
        },
        
        NAROGONG:{
            "1":"181gu09xjFMFJfnDo-X7L_wAZcYXkC1Qp",
            "2":"1ECBCAoTzBn3ftsiyzoqxEsN3H4-5Naxd",
            "3":"1F0IRGEbIi-YCSUJCfBaFkaxibvC_MUQA",
            "4":"1GGRRy-o_oj9uimr8kKZwXAwLZi88WyTC",
            "5":"1HV6uUJyeIfyBSxRMURJb_tH7PjZ39nh4",
            "6":"1IO3noz8xcBaqdoeCeN-zMTyS2lj7DG-i",
            "7":"1KA6jwdjTmSdT_duUjzMzmW4x07c-Yqqi",
            "8":"1NOxCwK3FA46_XnGcyE08VR9mMdtSKNJH",
            "9":"1SZp-JLwrlKSn-FgiDbi54RG5HAQy2SQ8",
            "10":"1SfdvEuG0tUeu--fBA5j27G2d194E7olI",
            "11":"1U5m4rzDo1TEyIZs6MciYioy5NCVBwWRw",
            "12":"1V7US-w9ZkFuj8ApoGuJwDlgx2Pb8cfcM",
            "13":"1VqXQQmVIXupmKzN5dnvDOxArQMZiRSwm",
            "14":"1Ze7TCFT5qyzG55ylnXScy8uDLxbLbuEg",
            "15":"1bwxa6FfWcGL1n3Fqb2Zbzu3xsPtVdJsU",
            "16":"1fim31QJ4JVYuEg7aHa_CgnXNKjLYtk2i",
            "17":"1nm0YYvJ0-m9PSmFlZJmfVTxi4w_NepWn",
            "18":"1xtTOAaVcqN_456VuIiAPm6-9Wzlj7196",
            "19":"1-ab1gIlk_NZnTJ7Z46cd5IZQwxx0cdND",
            "20":"1-nPdFE-1VBiZ6SBdCepaNDZsr_fufaId",
            "21":"10620yfFdB4DwvY8hUkJUmLFOZ25raDgK",
            "22":"10YGpTBJ49PiFqsO5VhZ0q6YR4g7SnbEY",
            "23":"11SX_DvBmvfHpB4USJ7fJTbaFCCzxWUKB",
            "24":"17ieLMqcwRrjg8ptmgd79_IuxL03gr7Rq",
            "25":"1A_w-yDXDmkUcik8gdRmjpniMOQDyiDYN",
            "26":"1AhNS9f20Cb-1Cm8-jqFuVlYEewlnSBbX",
            "27":"1CNdY3FM0W42q_sJYOhbHg3rddJkx0ns0",
            "28":"1Dc6Rx2HH4moojKAUG3rsivMXwQCC0fw8",
            "29":"1DcFv-95hnouGKjAfYVc19RVESWJC2zrH",
            "30":"1EnwJ1ekCxp-jnd6ptUWN6lNY95LLm2-V",
            "31":"1GhuX9pHPU3MvyDQP5OI6aKokm0sHtLtL",
            "32":"1HAK27QVS513hYTmU6kn4Hujn1ew5F3j3",
            "33":"1HWMdLIMfrOyvQgeGbIHEn2TK00R_GhlW",
            "34":"1HYuyhGdgmfFAEs29wSlZwG-j0AT1RVTb",
            "35":"1ITIowL0TzjGG0C_kO4UwivbAMAO7BOh6",
            "36":"1Ivcjqc1YVxY9URyIT_v425QozT9eYqh8",
            "37":"1J7Ko-4XJ4VaSb6aeTNAVJK_FnmEJocUq",
            "38":"1Mcac5VcyGusNhvxe1DHpykoJdxdphp8w",
            "39":"1Mnw_eIr32FSdOPZs8J5R95dHDdC8cKOb",
            "40":"1NONX374ARVFkAP2xv_ToWtsTlqCPHAwc",
            "41":"1NnEpbtA5sHPmOUoC7tq-0kD_1rdFjUVn",
            "42":"1OcJZI_thN-7FKw1iIq6yHviRR1l2cdKO",
            "43":"1OqYkDn-3rZbUMHVEnFWBHD84lxm5-pLk",
            "44":"1R51UZkDRoKWACBqpmHd5Cri_pGIzxByg",
            "45":"1RB5_Bvft-6X35Zv_R0pFJbh8xjO-qCzN",
            "46":"1RF7YlNvu3kGQR-kLkumSRjGrgDeugYFz",
            "47":"1RW8vYsAXcLCDFNmLvCuqiko-3SWChBzK",
            "48":"1SOfDv5-84mL9poA73OWETfjyUbHurxlL",
            "49":"1Ssw_vu1Qvxeaz3iA_6Kfxi2ZyM9qYQbB",
            "50":"1TzcDvxE2PteCOuMWo1yElSx7eRSxYlVy",
            "51":"1WhxYP_AAAiKunbXrKCaLV6lrxu7n547L",
            "52":"1Y5R8gGfKTBymgREfmILTzkAg9XyFtRYv",
            "53":"1YFQVJig6tN0p0mTeyZpWXvJufDVdRcqY",
            "54":"1_OwUpa9qEZb3ssuz-8Cq-AvxbSP5rnvY",
            "55":"1aJB2fIeMAg7aJSYr3-T_Gv9oKetZOpBy",
            "56":"1atNZGTaIbHyGoyWQ8-0yQCCdFF3xVncE",
            "57":"1eyO85N6-7wxtHbcBIoOWibD-FCLt8_G0",
            "58":"1foy5VEQOJH_qBDXqjU0_RysxLPv0s0dL",
            "59":"1gJcHBAlxL-UvmAkl7ERJhWI6LFAQQafo",
            "60":"1gY7A0n962kq0UN0m76xpv4NiwNT866gA",
            "61":"1hSTg--Hn4-dnZmL9UK1SOu1GsndIveOZ",
            "62":"1hViGRgnoZ8UniHcI4YyHW09FSRVeezbD",
            "63":"1hjrQYFNAk6-aOAT2HQQzyqaXOx_GA1DS",
            "64":"1ir5_tyCX3O3-L5fxUuPAcDDhEm1bkozt",
            "65":"1jCJLb_mGCx3LOqrtNx8dOSwyEwt9Hx_d",
            "66":"1jsj5O1_yGjZCGgN6YaLQvmHBFD-Ub8A9",
            "67":"1laekM7mQim0es4BKc6iF9fyFvd8KRazH",
            "68":"1mPhsb88fJptqGrpsXGeoKYqWxNPfeWLF",
            "69":"1oVLeB4THasfWcntlkyiP4Qnj9hfyb9qQ",
            "70":"1oum5zOOKXE4uGpjcVoqjUf_gaWkOH3BJ",
            "71":"1pRXrqyX7L8NoPCBhIIszmy8NApA_AtP5",
            "72":"1poqY7L_jrXbE4flpO3Hgkd9wtN4nLPsU",
            "73":"1qRyHY_wf0b2xfRmqTCIdHZto0Put7XEy",
            "74":"1qjA_1iX2gq-2vdsJglwV7FsNai1q8qTY",
            "75":"1rMoLbY0Z7M6JKjH9trTwwnTyIuSil4bD",
            "76":"1shTPMUPN0EVK0HZou9cOjGJaw75X5ZdC",
            "77":"1siBh6JB8RFOZ3waWNv9ngAJnd4ZH2vpz",
            "78":"1t88V1pe11KkUnCNrbi1D01C04i0Ysspj",
            "79":"1uREmYNqmkgX7nbP9Z_W2qNdMTZlFk4yS",
            "80":"1u_Q0JeWRty5Lzji8yuDrkAt8XJeenqp1",
            "81":"1ucDqkR_qH5L7IoHnbqKNW1pDz4pu8bN5",
            "82":"1uhVBo_oGea6E3kAJcCYv3HTo3KcQFfZ3",
            "83":"1ze0QW5wROxYiqSfh1joG046j_SVwXeaR",
            "84":"1zegw0DWGZ6977fyma0NnSNssu-he6nKM",
            "85":"12kPergKlF_BNRf0c00j8apnTiuMo53aq",
            "86":"14dLwr2g1phFO5ytQRIYpema5OFpIQG6x",
            "87":"17FrrHxGI70F3O5_OaBrDPnhcxrnk4bKU",
            "88":"19AHTSeEaCinmxHBAxjd_okK-rWaQhlm5",
            "89":"1CT7ZHCTFNPL2OGRY9IDiczPMVh62gl9B",
            "90":"1KYIpjdmCTW7yS6VaBNcz_-uAoFs-Q7vL",
            "91":"1OM7lUFRmW7QkWgRs6fTxoDObNNRTraWI",
            "92":"1QEXOtA674avpbs39C_AnbSpBCeWL3Bb2",
            "93":"1WdpQktVpoO6yAe1NqVp-FYNEO25VUaWl",
            "94":"1ZJraqlFh0GnGNWWoZkXrM6LExTeznRrV",
            "95":"1ZNnbaCXS5aJFiFyO7QOcgAvIaPtXMrrn",
            "96":"1gSlXO-Ha91VwkSvBJxJy6Y_q55SnCoP8",
            "97":"1mvNkhojDjbyzvpu06tmB5ajViBDzeXhr",
            "98":"1oE0-_3vYcJm5_0nEQOVy4PNqInouvpjs",
            "99":"1tcxSS42qzga2Nt575xhBSL5yuC7G_GNZ",
            "100":"1wUKb-jcz9LasRmRvzK6dOfodtHz8Rwyp",
            "101":"1-tUziuRTIJATU_rs0zES30wXhixPNMMT",
            "102":"118WwlBKvI1hV1IxY-qIpux4U8kdWxjHA",
            "103":"140BoiSQtPssPlz-54Yq2bhbTQx7gESHd",
            "104":"14ecy-N_cN-3BRIwSmoUnnDvf6JN2hfhp",
            "105":"14phNBizEJlYjYflT-rryNhM3M9zZN3YG",
            "106":"14yLN73hxMifMza52wLSJ5U8CX6_M-CWc",
            "107":"16Es3vRzNXQ68sRTcITEySQ84vGQQ0UBC",
            "108":"17f-TnOwLjgUJB4Lvs0UYmKnCjIWm-PnQ",
            "109":"19rocimYzifrLhz6Q1kCJd0TvE3_cvimL",
            "110":"1AB0AbGHHNIGZDC4cDhbUyCXkDG3jtkJE",
            "111":"1AFl1oMlEkZf3eL8vqhgLGQWZ0uOVn5lu",
            "112":"1AsyelRFk0e0jW10JI3pbVgDExAhvL0jL",
            "113":"1BcHmVLA0Rl0l-LU59JzuUnbJ51bkF-ZO",
            "114":"1DEeH1ehNVfQHm3oqzb740lws1RZWZOkO",
            "115":"1EeDXAAjEK84VzwMa-AopqNhxprivPCMP",
            "116":"1G9UJjh-Q3AMbWdy5spFXRlpMHWp6HGXb",
            "117":"1GgOR16gJoU6Y-A9N1Z7JHcT5ErYdScC1",
            "118":"1Hau0rF-HD2ZFXf_ivO9Bb_YjCmwJBRsx",
            "119":"1Kl2x7-DJD20LA8qQg3WQoth5Q0cAhN-q",
            "120":"1OfcHUFrhQqkDv5Y83TIMX3iP1EcFw6fd",
            "121":"1Q7pi3t1SYZWnzXSuFIAlFfP1mAzuWlMD",
            "122":"1S9x3rEGcXq1gWttCwSiodjftJo6EDoTn",
            "123":"1Ss83GOAfqoy70VdNf_xTsJ4i5CtUSWfO",
            "124":"1X9OF5xotrgh8PChtraLbQzKEpJtK-3Eh",
            "125":"1Y4ESjFINHmVn3xMJ-bwOKMPz6fpquijv",
            "126":"1YbTDblvBxPtDhR8zK5sa20M4mnSlDNd3",
            "127":"1aS6tZE7c4ZhFwYEy_z2gWQsokYdop5IT",
            "128":"1cRh9_njGyrLRXZDo6xI7jHsLFPZwZAs2",
            "129":"1csMQjYSRMXtQ3fwsnNhhT9Y0ck7RjbQ8",
            "130":"1dLOT3uUK8ewG8RAWQ8K3FOVU2dHjAB4e",
            "131":"1dV5_6R05t7DYXAEibZI5mv2bBGYU6S8c",
            "132":"1eETgbdDBo3lXu01ccs35LI-7gu2EwSbH",
            "133":"1eEyahNFvZfx8Ra5sZ_Qi7IGW2pH2r5Ec",
            "134":"1faL5yETiGdiSfvNpp7mPPfswLCvxMfvs",
            "135":"1gDSXWBzDfN7qZ-4W5BUBqDZ4oSTQCLgl",
            "136":"1gOF2mfE84aPkx_ztNH1_3z1z1W9ml2hu",
            "137":"1hDRz-AZo6OXHNzZgJhsgbBhvRl-sDS3F",
            "138":"1lgI_cz5MD_XdFxv_UVEsfvazLLI0TX5V",
            "139":"1mnexemx5a9tUsPxpc4U07a4-BnWrgiXs",
            "140":"1n4tZXVYq2GRcpJ2exPNJgSfWZwbqBAzc",
            "141":"1nFSw6wpgGSYEPUs5cGlWtRedfygCsCKn",
            "142":"1pcfUwOfINkiwMleqhBeubRb-5oEB4liO",
            "143":"1r267MSA9zUpmlfIemJVvc72SzdOJuM_d",
            "144":"1reAFhiHVFbi-UYd8coxlle1LQJAxxUxm",
            "145":"1tTClMLLEks4DrdGKabKsbsG8UGpLb6Q3",
            "146":"1t_SLDpQpGB1VcHNHkbCYpj7NFhx5BOIL",
            "147":"1uHzi3xiGClBE_0MLwZRsxd4boe9fRllO",
            "148":"1v6VHJ_yWvICbJXEbz57FBIH6OJs3YYos",
            "149":"1vl7D36HFC_Yxzhh74md7v2Cc5TO_POSB",
            "150":"1zLqIeM1tXiHtsWRGhdc57iXFDmDV6OAU",
            "151":"1-3WJmrxETYnvsK-j-oeXN8k-kWaDhZ9_",
            "152":"11-lryBHKBwm-LyT7Bf_cVILhbP6XJEVp",
            "153":"127rs0xRjD-STU1oVTrO2zlif1NSYYh6M",
            "154":"15IXMcusPMH4gsNQZrJdizEWWm4lJ7TzR",
            "155":"15oid4oHreXAARTKjCiVrEGKsO4ucboZG",
            "156":"15telnroFdTS439bzAFJN0Gd0xmRhdv67",
            "157":"177CSgKeLTN1FeTKXitmaueJENgpMrcZC",
            "158":"18Gep3zRcw9X6gGQyK3JO4SfTBhQXvKiq",
            "159":"1AHh4NeriX4VI50PiqG6MI_pbqFQTm8nU",
            "160":"1Al4-0QIcgEXnuhDf8NxcgjokO7JT4D4T",
            "161":"1BvCrR3wb_6FFSrRS1koltInTZ5czXaX7",
            "162":"1D_ShXz0TgH_GppejDCTggMhc2_JGjaKT",
            "163":"1E31Q2oEbMsdNPZDLOMl8qZN5hpvKej7P",
            "164":"1IleEh1FWrBzMCvfU6CnKeEwS5flTySkn",
            "165":"1Jgjp-TF43aqcHukCBAjxoErSoG98gbfr",
            "166":"1Kr--n0f9dlO4GKA-Ylmx1yhQr6b-hbPT",
            "167":"1LtmnVAAgcfkZnx0-OKYwoM0HBl138FdK",
            "168":"1MIjnxP1w7DNDH1FKisgffSBppp45Yi9P",
            "169":"1R4J_FdzJfkqy3_jsITxfxL4nymzx-cGM",
            "170":"1SG16hEPiQTMsNAhOR-rDN-1Yc9oa0Ilc",
            "171":"1Ut8TmHkpf5cq5qukDmlep1I8rf_x4a92",
            "172":"1YB3bmJx1aivLQDKNGCvTzeLLpqcFneCW",
            "173":"1YICi2WPCKdZbELxuuVq-76upKhknwY-g",
            "174":"1YxrEIIxXKrGX-WZlyM4IywAizdxF74MO",
            "175":"1cIkNTDGMnaZHJLqZEkvwdGA9H06Fubo3",
            "176":"1cQPJWG_G5WP-x8m2POR4pILJIEB6Q_lZ",
            "177":"1d3vN05EO7hTN0bsDLvLSFUF2H9QwDBnK",
            "178":"1e5kEg9klp1FG1DACiVIclXvrOSPdoUmA",
            "179":"1eRN_6UnPDqwbTvJvEmu0vMo498esswvz",
            "180":"1eW4cp2VkIW1fO8EvaFrBi8u3IFMexTjt",
            "181":"1fKD7RA75mLvzLjOOCj9i298JphVBGm4O",
            "182":"1g639Bxuy1P0pngJgU1ayGZzh8SZF--oM",
            "183":"1izcfjMHoVGtSKINRtGaAJlTf5krl-TSF",
            "184":"1jubRMODlwjOtdhZlR3YsG2lVpmAGMkn5",
            "185":"1lXR0yn30Vcshch_Q9tYjLFw6wc27L4y_",
            "186":"1m-4VMUZSRbzqYDUAEhNvam688k7DLBdb",
            "187":"1mds0ZI2twuKTpyQ1A3Q3sWxXTFPJvuac",
            "188":"1nbWvONAKK9g_P62T8ocU1G1KG7SSxLu6",
            "189":"1pT6m7YxvM0HfVKCzNt9Fsf0requCmAT1",
            "190":"1pdx1raJuMIc7UXDnpO7IxH4f_0oPdcii",
            "191":"1qE_NuCl8mWMNOTcOHUatvsy3ubioKyIv",
            "192":"1qQKAOupm8oyb8YQqncDyPwuXfCk1dfFj",
            "193":"1r7J3kUZKhvq1zYfR73LGRi4jUSDW78rF",
            "194":"1tdICxplxGAn9RQliKZaQEpo7D4dkozcV",
            "195":"1to1luh0gBS-KHZu_3ko00j5MZ45Lu-Nl",
            "196":"1w5U_KxX-8pAqi0VdEXWKofIl3CfMl1jg",
            "197":"1yd6kblh6aipceDo_GTJQBSuc_ZnTdRes",
            "198":"1ytLn1exayX4a9DJE4fsJT_5qkktSktn3",
            "199":"1z-szS2_ns78xn6sY5VUQsIM9zxGcMEYl",
            "200":"1zraYohqnLKlHyZlCladCcIyx0NX8Pfcu",
            "201":"10lTKMAUOBL1FZyg4051U4T0StDvC_rWG",
            "202":"1CDILNNWYxfUrQmGhdRk8hHJOXrPyGbPM",
            "203":"1IGpG8VryS2Xju3krPmEOU3S2J7Hd0yOX",
            "204":"1OkleaBZjcMFz5XSthPpaxhJITmD_i40Q",
            "205":"1TNsyXH7YRvb8fo3D2SumsFcb8OBUSpW3",
            "206":"1WrNaUIfbG8JVPs3gKTsEvKquj6nr8Btx",
            "207":"1_rY6Rj5nv0tnA3mAWXtbHrYsDjCdby6k",
            "208":"1nrl6tVeBmfuWOnBBwgwedAX6L2n9aQ5c",
            "209":"1q_Wxa44l0l1sAniI1t2KPwEectkhzbZr",
            "210":"1rpXf2BOSTYQyo_eag-DQPmYAF1MOpbBa",
            "211":"1vQ3ajDe5F7B8aRLU4oXRZ40SWY-OI8LD",
            "212":"1xolzr4QXLNc9F2E4oPMljrUfXGt3cTjr",
            "213":"11HnepKIWfwxpkqz929IxP0HVPB4haGNl",
            "214":"16CThxiUBnq1vwXM_HdYHBhqLY7KhTJjf",
            "215":"16HyeLrrB8cZRR-nt3ocPz9zguVw9Pg1s",
            "216":"19Fd87DLlQIYQOv5T_TciH2hFetdKtdaB",
            "217":"1MJyu_ssOkG60pYAudTuGMScL8Z2wr5Sw",
            "218":"1Pu4e5iTCnNE5SBD8JbX0hC_mPmWka7oL",
            "219":"1VLFKr49EFqUlKd4P1753zOYopq5lqkR1",
            "220":"1aZXqYFuznwbqT2g2jBibsaHona4Rl57O",
            "221":"1dsEbdIqWhWTXRwwKL-gLKLdzS-E8uWHi",
            "222":"1fSs1wJyqhiNi0hQrtu-FYxxgg2-NyvEC",
            "223":"1kIurKHp4R0TYy6KiTyfWL70B6uoqLfyB",
            "224":"1lBCEJzaJNtQE6NyNFYx6YOpz88fPsloc",
            "225":"1nQDB14yxBjigoglm6CbU7r8M8RjtyLii",
            "226":"1xPFaaoHl6oiGNcaPsbq_z4ZyX1doKDJA",
            "227":"19mkvu_qbinvuturpNb_OGOmPIsL4DLap",
            "228":"15JvnUGLqiLTP4s4nQqlnQK9TTk0uI1Cf",
            "229":"1Dk6ffhYnqtmirb6oyTkBY4t4jaHwft-i",
            "230":"1yPcE03z6oYKdAhONDp_ErnbzjBCfroc1",
        },
        
        PONDOK_UNGU:{
            "1":"15Zxmzs71-4Pbwb_rc0IDywduBKI-0X9w",
            "2":"1LEkqrzSaM-BV_HSjqUvYdQcVmiU1H2xm",
            "3":"13vyJGW58pZEZd-pg96p8Im9BaKFtGq2L",
            "4":"15qvsIlkw8fkS0SW5FYODAOGWQpYdKleJ",
            "5":"1AXpXD8pXGsZafrJpL0YisRYrxl2F5NUy",
            "6":"1CAbnZFO4Em9olHGsEX2TdEJiuBN5hKyk",
            "7":"1HFX6c3Zjk61ekGHmizkSZgCk68UHpEpZ",
            "8":"1MqvSh0_BrIF4jpK9HFWjdWm9cY6kOEi2",
            "9":"1NiSVCvGh_3FJ-Dk4c41E2iNn1q5F5yu0",
            "10":"1UYuvE3NRdOlYqirbtIKmlgNida73hRmc",
            "11":"1cWX575m4DB2aZUjw13OzfqMUHyHsK9e7",
            "12":"1clAJcxJEHTnv3TuIv99DRhgLZM1G5TUj",
            "13":"1gsLMTJoXei8iqkmfgd_yYSeGYYSN6m3a",
            "14":"1jNaU1vEKO2jIeGnAUEQBhAM2DV6jdYaB",
            "15":"1jpk_xoOsIM_H-3NEVaGmM8DyEJEPdOTQ",
            "16":"1l4jVGu_DRtdoROskRFfa0L0Onaf4JhbL",
            "17":"1llBFcQScLLfYRNMw_owjQbfh2P3likPb",
            "18":"109G-wj8AZoHV0En_oyjWB_n6beUU-_hF",
            "19":"131iNuUOqA9OcdwwcJCw5MiWJfSM4OAcw",
            "20":"14N11K9GCPTSnY6r4Jn9m_nPkT-c3C8zD",
            "21":"14uPIel2vo8Kzdil4OqSW7LBU4SztFJ2o",
            "22":"15zaQg3Or0l0hDVHXwDGVNH8j__9OuCDF",
            "23":"16_gnzi1c3oA5BWJBftSPtJsUA0rZl8KM",
            "24":"17KeD_HbiTKTgbK01Kmvg8FSM9j7-dLPT",
            "25":"1BpmSJvXGgP9lK5OwP6UgC4eoegtvvehz",
            "26":"1CcySpU9VSR4KnJUviD402fIp2dN6bU1I",
            "27":"1CvD1aCFQm55irhU86kAQLyiibC62gP9A",
            "28":"1D65H9AqL-otQhX57GGfBiXnLBLhY4p1T",
            "29":"1DU1PKi9N-3ENYgbdTrH9Vb9tFQW1lFzS",
            "30":"1F7sxlM_NtdQgRuABw386YO9xNeE7S-x1",
            "31":"1FaCVZzreHbfqcP4S5FLt_msJZjT_Rdsd",
            "32":"1G8KITq2TmEQ2w1XkeU69BDwRWyZLAxxK",
            "33":"1GpWvyvVH1rznRI_gRHSs_JxZeCzPnsPL",
            "34":"1IK7Y4yqLvX0g3FniRPGI5q6W0EyfZpUE",
            "35":"1Ie6vcXb-tAIJkKAnvW9_maPjjkCSUZhr",
            "36":"1JlIl8pR8PtGtCsW0S4JXbqzDumn2Yw58",
            "37":"1K14kcFHH2MZZsHRp3-8xjeTyfaP9YOgh",
            "38":"1LkCGXLgZc_s_RrNfYSkzOLP7EoJlL5Yb",
            "39":"1MLBChGdRKKhWlrh9P2jLxBVRhmhcn91I",
            "40":"1Mr9ARiCzY7rYoeIzU6FrMmKtVxRbfECY",
            "41":"1N25k7Oyh7dn0W67j786HA4B-sdKwrb3D",
            "42":"1OseeYTbW84V6GfTekbFrITCph3yLdsdr",
            "43":"1PZNNWIoJCPCBDpTKZwCfwYIpKMDAb9Cy",
            "44":"1Pkw5hUxp8Q6c7vrR4yLODQihHlK73dft",
            "45":"1SX_aOs0bzJTdya_wydOv8vBoGdHZtWWu",
            "46":"1UQ6xDWFAyUgHFUg32kswvboaTDfXa3vU",
            "47":"1WoYTUEl9Ye-cLpbxGzRkb4mvfQ8GNhtI",
            "48":"1YLha04ZA7VBFRcaYF1rD8PzSDWZm3heU",
            "49":"1YgP64LVAV0VDYM5rZ82ZBRth_4S4Z9B6",
            "50":"1_0jruVwoTPn18-gFOJWP2GB-49PL9r-Q",
            "51":"1gVHk1nBIMcu7i6oGnTommk8Pe0NXgorB",
            "52":"1hG0w32iELHXixBg8qRral9MCPVsnHusU",
            "53":"1hVrFUV9tQoUSJpKDGJpuE3QvISpOqf1H",
            "54":"1j1t3gzTdOsPAvJ2TppwM4dzOUHYkaVWw",
            "55":"1j9My9HaPK-MtN7KwDQcCNMxV08NTSrZh",
            "56":"1qolTDOuJVoZHv2rf9sxM5D8MG7O32xcf",
            "57":"1r-Oikq2PhtX6JeCH3WbdB0WVxgGx-ggq",
            "58":"1r4UPCDKZKtHAuDaL-uIIsVGfN_p2YBof",
            "59":"1vfHbqMhLFo3OqAShp8mHM5e-tjAIlfPV",
            "60":"1w_DgvJ_Cnu4Rtx5HDiz5EE5-ss_HuUBy",
            "61":"1xN-LPMilSVUQOeAoNqWlckRCBhUj23zr",
            "62":"1x_s_bVx0FhUSrKJwjcOmnHXZSPXqoSqv",
            "63":"1xtMba_nZqc7JV8IzR6YyVTQgWV-tDbOG",
            "64":"1zCJgNqsXpPN-LQJ6J9BV55S3_Q_aJpUI",
            "65":"1zf5rnJ5WR4h37SvypzU03C0m5CQrn1rN",
        },
        
        TANGSI:{
            "1":"129H1E30U9NYlJ2sa3apXmUHcuC3KlXVU",
            "2":"19uV2ft8-G-LAP5MrrQto2YgTQ5RzExu_",
            "3":"1N99MluUGYiaj71T5cJduCUPGjZrHgC9H",
            "4":"1SFjiy1zy4ptwXPm6lU7HWzPyc7YrnsXf",
            "5":"1UDGMIZOnZdjxDQ_ZZZjvNyZ12SlWq1JJ",
            "6":"1WPs8WTywQ4MjTs_OZJVCd2Fa7cOolRJl",
            "7":"1ZcPKv5MyjCcCt0EJ_crkr_5OQnudDOFe",
            "8":"1hLFBoDABD-3gnsbeTy35JLAqepCOKmJ5",
            "9":"1nVgeBtodEVYF8soBkLrgtQX93N3T8WUZ",
            "10":"1ygniUhpD5Em2_IKPBuYzOSJU5r7S8-fH",
            "11":"1-7zm_iim8gnQiGew-kX49KVNIUK96YXm",
            "12":"1-mokUmp4_-DJL1f0wr8sqN9pWYoEEW6q",
            "13":"13MBWwz8z5nSNdza_qcao1UOS5_pFPo6x",
            "14":"17VKAnt2X9UlrTYAux4iF9wNXN-im734b",
            "15":"19D6ebywav8n9gHjiUUOYO3d2GkuR358r",
            "16":"1AdRAzOdr_S40S-E9-KRilCNrt-CwXQ3z",
            "17":"1Bslo4Bu0dMK39MPimIko9jo8qfgdKayn",
            "18":"1CcblEgl9wS4an__O1436ya0p67ZQSada",
            "19":"1DjuPQ_esYHSeawveWPm7HA3hUrRnI0BG",
            "20":"1EtMC9tNfVU-Hbh0C8eDgSbNPs5O8Pwo_",
            "21":"1Fq6Ac_3yS7o9JviTYUq9FxmKL8pfL6vx",
            "22":"1G2v5NS5V_KQgmJRirbdI_sxIUi7Eoa3s",
            "23":"1JIIq8qwBt-HnxwbqOiznavPW7RdbPkns",
            "24":"1JIx7UJsgA3lfncBAQCJP9jOg3NDY-epU",
            "25":"1JPVad7NMJJWP_teL4XK99PiGFoSWvWf2",
            "26":"1Otwv95epl-VhFKQswe9WlMJeR4JEq5A0",
            "27":"1U384DIen-m31q6fUqc1hei7fWQXhhHe9",
            "28":"1VJg02NRrX4RbFTkFcGgEczon_7unV-R2",
            "29":"1VsA53UVzFkx5_9yompseuV1CIHQK2J_l",
            "30":"1ZAgjND6imDeaiexHCmgEbBHPsTm6anX1",
            "31":"1ZzGXPsOmjvs60i088m3SvXO0tlM-2wh_",
            "32":"1ac6H3TgpCUrF-_EHaHjkjaAOahE1uPt3",
            "33":"1aidmMycVb9JmROS01p3lAIXNjir1dQ0K",
            "34":"1at8sVPRdU2IgZEGda53q0Lymv0zh0Rb1",
            "35":"1bG0kdakMR3GtoYGfMn_MXu9MZs4w1QuG",
            "36":"1dCoXiqTyTqrs5feeWJLCTBZGd0uaCfMi",
            "37":"1ep9gx9_iIFv2_P54zU66R3GvsXpCGoxw",
            "38":"1gBh_56SyrKaOG3IH4Zd3KpDnxyOoymam",
            "39":"1gBz9KODloyh5L615x_BdpwIE3NO26E5M",
            "40":"1hY9II0Nb0-scZyMBvAG0K16HrjfVfVMZ",
            "41":"1iy3HvZ3Y8NugApn8bzaWjqM8VVZUdwvZ",
            "42":"1j8MXXjitU5cl9djkLbZ3QvVdPw6PENpN",
            "43":"1jv9oshduNRG-7NIWd9FoG_c2fL6Cnx4Q",
            "44":"1pcFujpbYhJKtEx-7uXj60_77EJNoR41L",
            "45":"1qv4i02VVJYohEVJERMjDN76pwyRpRT3Y",
            "46":"1r-8WFsYIjugyQxFBEAqr--lXCczKXjcv",
            "47":"1rhB0F49SEzUZfXYe1ii36Szo7Owtt99W",
            "48":"1vk7GR9lqzj7UTOpNYqTsKmU5LQJr3ezI",
            "49":"1xrXk7O103takJjUyfWzZ-DTe8EC0joW5",
            "50":"1yR5dUBLPjnoa7hw4H0MKF-8E4xl2KGCY",
            "51":"1zALE3j_C2w14uJ6B332IKpwX0v8xBmHt",
            "52":"1zESfWH9luo1moCoMVYLpyluVe0XIcDPR",
            "53":"1zzN2N5PDUeFzfwivg1t7OSsYwuyVGw1H",
            "54":"1-icrSUhy5nnssjt6-uiWJiEgGifvynaJ",
            "55":"11EGRd_Pc3FXolU-wGY6iFSBP2AcpRENh",
            "56":"11UxrbSD60UrMdEFyJG4sZegly-1uJ_y5",
            "57":"11szyqiXCOQt1Ax5J566NfHrT6hXg9IKN",
            "58":"13LBhReiMVwae_io_VuaG4n-H87A8P6ue",
            "59":"14aEkEdygz2_cLCmkorDlkLy_YRUefrFi",
            "60":"14iDMc0mMI5xMxX7WqjxVFhnSVQYXx4HL",
            "61":"17kseq82L3L8VxzgzVwrCZhtQ9KGW3s2X",
            "62":"1CIeWLzb71ixBkmEpHC8LpfrzivMoZAsX",
            "63":"1DVI_TfOavxvbbDwDw8Dv3thlLNqs8ijT",
            "64":"1DhvJ_0uVeakgxQDTMl1TnsPGtNYf1agp",
            "65":"1EovkExrKMdsXl0UkUnDiWnO8eknXtbgl",
            "66":"1GEdJjxiX6Jn8dHziRAdorlY6JfSuBqo_",
            "67":"1Gyp215P3-cmcB7R9F_ksCkhln1gHMc5A",
            "68":"1HA_0W37gBL2ToaellPhsUdUAjAB9rq47",
            "69":"1JHcwQehWAuem8cyPTZGyP42-etvFXjwd",
            "70":"1JpWgarzWasSVI0Ge4-EKSo5b2rUdJaMW",
            "71":"1JwdIyYX-6OLBV5HGgmJweFF031jY6bGV",
            "72":"1LAAQgldSiP6aaQzF5mOYAINeQ6aFcHuu",
            "73":"1LVwgtGAHMuUyRqwCN3-QvVfDFiFuhUAV",
            "74":"1QYP59XeOF1PhxSgOtrz2NeY-XuyPFG8n",
            "75":"1RlSeUy2ZwpK8blBY1sMFKO2gyHp6FU9i",
            "76":"1TJ1pJnc-xCPanIFhKssdAjbm5rcE6fsq",
            "77":"1TpxWTdGs9gCHzq8S8E2bBuEaXIYyGS2p",
            "78":"1TvDH5NtT4lDsN1gdc9TIex7lRauvWPm-",
            "79":"1W0l_H3rlqEI9vOiOZ87uHYPj3BGIrvPu",
            "80":"1WRDbmjjuKHGgzKiobPXUD2dMT7PuVrar",
            "81":"1WawNOb9p0IGG5pPxwZqKzZFdvZxnAG6n",
            "82":"1X2u62_pYOTm_7WFcETein0Vcxg3UNREo",
            "83":"1XhDNqFxb37L16zr1yw-de9ykmdVupDTT",
            "84":"1_Pd9q-6yw_s9p0-XwmhBR_cKUtwl5JEY",
            "85":"1_Zfg7hN2zSKmB7iyJnHCVlfkUyoCLNIG",
            "86":"1agqTvZa9fvY3-i35xYnGqNamoT8qyCM7",
            "87":"1ahPBLKHib0MHJedukG1C-D5oaKoPBIxo",
            "88":"1fmkvXkXNE-MJYll1lKXob7UP3_EnmEnj",
            "89":"1gXds8iAwQstBcTdjzBwnVpxg0c3445rl",
            "90":"1h5w2C9VSygMEj3wu8PJL4SAOAzTl8cDZ",
            "91":"1jNdI20W_u8y1lxOvKhrlxgXNlNvH0J1i",
            "92":"1jVT-bEQPfGCp2YBn6f_ndsdXmBszAGG1",
            "93":"1jt5ZDxh9fqmor-IrwAHBK1tZUtQsMP29",
            "94":"1oR4u5fA2vImfLmCv8UAKookkiv2cFjjT",
            "95":"1outsf2NlOMBWGcfW4NzYbkVjgw0Znt9g",
            "96":"1phT76iR_anPwkd8NZuHX55fJCKPavzU_",
            "97":"1q6bwp6Ywfli-nUvZwGw82T1QXTBLV-3m",
            "98":"1qqLqVA9GAvLyO4sjwvrDOoQhCTXkyEcr",
            "99":"1u-Qb665Kd_n0OM9rPWTxwYl88re0MiVU",
            "100":"1vMRW_4lHYaFUSpgNcj7GCemZP1UOW0Dn",
            "101":"1vqOONXKAvpu7otAL3atTG49Svkj1Gp5f",
            "102":"1xUHUYyzXSTZ4FNwUis5kuXY9OszFMqb8",
            "103":"1yG_EZxTRXc_Hv6Bv2nPbya4i17wqkb6b",
            "104":"1z8oRzn_gVFvrVFOPodN2YRTPWW0PzRtV",
            "105":"1zuYv_keuHt0RfbWy7UConMMTT4zt_-4z",
            "106":"10e5y3l8q3eebeWs_W1CuV-IbPgcviqb-",
            "107":"11NfPwZebEIuHo-O3tMzvGLY33-738p67",
            "108":"12btNkWjXkmd0mZ4SbalpNAA27X0YC3DW",
            "109":"12nDHjJ8WL2BMS2b6FTMtrTD35yRgtBFW",
            "110":"14R79wM2V9Q528HNF5NByHt-h-7OHNQ3r",
            "111":"17VXpgZ69GWNakUZ9WII8ZmZsf7Haa1go",
            "112":"196rVakArAgqQiF4kfZ2k7XyT5SvehTlJ",
            "113":"1AbYe2vM3DcakDGDeXEKSPpSPhAKiC72O",
            "114":"1CaFUYjEW5Stgy_J9_4JCRDjbWU0z9gUD",
            "115":"1DPlm7McgDFlT0J2VfxlX63EKHqek4N57",
            "116":"1E2aEftSs6sjL2S31DPFXx4hYqOxAQkPx",
            "117":"1FUAqaKk20i088frUhqTKNAe0ezGTXuXV",
            "118":"1HUiXT1xm2w4EWc65kaOyq9kkjfN0bTRX",
            "119":"1Hn34O_tXDKWJSiIYip6I3S5avQJtB4F1",
            "120":"1J088WunC6J8itXRqsB3Uqvm-h_CNj1oi",
            "121":"1Jf0OIYsaFbCGrKiWfqeALfbzb_MATtme",
            "122":"1Jylu8QTtHcqyYSXWyUzZJyoaic6jno6a",
            "123":"1K_zf--rw13tppFxcCrwg5dE6BCRbHMyj",
            "124":"1LWGbMajjnMGbNMMsZmPV7XTwyARHhVRB",
            "125":"1LlkDIXt-gkAIKDU3bYNl7IW8SkOXSsCB",
            "126":"1M73hGmNUeMwMkGpReV50RUOi1hHupU_4",
            "127":"1R9-CGchlSZvypKiu7xyuTOVE0Of81yYo",
            "128":"1VXfDgmiA1LNkGgj5VW2u6uO7VruN1WN4",
            "129":"1Y2AG87wQ14fPj2UvGg4N_FN8LLr5BCAY",
            "130":"1Y3jnG_oLCaQhVyex5ZrIL5yl11HKdWoD",
            "131":"1Z4AD1UQYr2yRqEeUyKrSlfqeHOrDL18X",
            "132":"1Z4EvAUCnY9BUXMmSym_HfRhMFtoitpPN",
            "133":"1ZSKqPaIy_cOjOE9sAUusbVgwmNinMLUu",
            "134":"1ZoBymvuoXloMoyhlMhjr2W2mOxGeAGWV",
            "135":"1_0vrXEUOfl_hrcnU8JdxMRqU0KoYjXZZ",
            "136":"1_jiU7HsbT2O9ulbo8m0rYzA_MPlkmKvn",
            "137":"1a7Fc3tvaajfHHlE2NEmRrhwVIF3NuhYD",
            "138":"1acZtcQ2Cp_u5cTtWuBBhyrU08ao7GniB",
            "139":"1al-gFjMWtAbpfKjCSF9NSUo3XBtukXaR",
            "140":"1cASopnejwv-OuHFD8OR2nV15DlXXM-tT",
            "141":"1cAW9etTRYzqzDY7Z-pd3IijaFcL2pxoO",
            "142":"1h1Sf1bCdT7Ut3L-LZyCgUuAWvvUKHcaN",
            "143":"1lGLqDxks-bqblW3wmLF5cWDMS5kDDEHM",
            "144":"1mMRvF8qR8KU2CHkgdX5ymYLhM271pCip",
            "145":"1mmnoAmVf9Isv5IN46YzFIQItp3dKoCPp",
            "146":"1mpx6tyYfoczHJ_KGBK92uoacfUXn0idU",
            "147":"1ntoy9wRfT83kRxzSj5rqdv2gLopnaw8z",
            "148":"1nvmD2UiaxDIrMRQ3hzsBOamTgbIWfuJU",
            "149":"1oUtgGmht_ScTWHnOnlS0eRJneCYZ7y3Y",
            "150":"1pOUDjYXhbNc9ME9utyg4bGloXj6Kl5S-",
            "151":"1pinT2Wb6nMvJUTXar144YylRLCulJ6V6",
            "152":"1rWLh-IoMTX81PvQ-K30_0xABRp_6EsMt",
            "153":"1ra0MRS5_ImRiHEF5vFGJA4f4uPGRJxo6",
            "154":"1rlotkUZt0hJSUrJXNBR-8yr-E9Xsv0Qo",
            "155":"1sgxTlpX-CFVggqd8-T16VJP97U-G4uj5",
            "156":"1uPPYf0K4tV4vgKwzmUFQfzlVEXE6xP8J",
            "157":"1uUZkHxXFe8pMWvfzKIQWJV-V0PhLQFrk",
            "158":"1vKv3kJi9ugSIpjI839uwt5Fpr6Bsl27X",
            "159":"1xQeF3qq6d0EMCH0o7gFQh2_LVk2JFpMA",
            "160":"1yzBHXX709tDw3JWXoIzcZUxeBZX5CX9G",
            "161":"1z7EfkxoF6tqELI0BDl8l6ygnM-Gb2rsN",
            "162":"1zBo2H_dTNkVbcI8KOp-2pWoW6tp28rM2",
            "163":"1zRhbHmmIQjWLAe7BNL5ZsWjxZTaWw_Ym",
            "164":"1znAqO9vt60h1b35J3BZdSmZA1xooxYGd",
            "165":"1zp-hqfhT8NwSxgFtmT1X6PtXT7TUsdvz",
            "166":"11fjqCfzhkWY4A0NJ8T6djo4br_DOeuFA",
            "167":"1D-hGC2mg0qoE3DiPazTfIAQUuXdthZWt",
            "168":"1TrmNQQEi2nNBBkb7MemUT2-pHQmQS0EB",
            "169":"10RUh9KV4PZT0MPvEa9vFurpXo_zw-90j",
            "170":"12aIm3Qf4csa9A32Yfs02YjNsPQeeGspX",
            "171":"12q6KozNnkScK65GjHTPccRCdKLfr6zTe",
            "172":"14oHHJ7TBidQeG-hRGMDY9EefrS_gQCJz",
            "173":"16lw6nVvr2Zkp0TI3j_AAqq-DgbmkBfDX",
            "174":"1C4djVprzw79kc9268S3tBCLAR03XOJ0U",
            "175":"1DdL1zUG4g00avapxcSewtqdflLmuE71h",
            "176":"1DeNFi-DGZI4wL9nD838wdtxcJpGdr-EZ",
            "177":"1EHmD6DtrM0K5CwVHu2MXdmnrlxvXFjkj",
            "178":"1Ie0zVYO6Z5ujCrCS7hHg6rCk7VM4ERvm",
            "179":"1KtJoPrXhH2dv34v_tMbZ3sN1uzAo2Dsw",
            "180":"1NVMNU1C-vhH5yaSYNZg0cTsECRUBqHxJ",
            "181":"1QHcICZ4dzCpPmJVmay51frJrzr9xl4V5",
            "182":"1RtCEWMgL__Y2QbWHfgndAv4VKPhmUksC",
            "183":"1S06qSDSvNJM3u7TR5hjFnq7mTAO2Ez_g",
            "184":"1SkT0iEX_wLEZoF8CO7w2jQvvhABwvoVu",
            "185":"1TIZgsyRUy9R1DnBppCMB6hitezC04-vn",
            "186":"1Tt8PQs_14xvp1RlkKQQcB604nevgJ3hC",
            "187":"1WUrwqL7LJIeeThOOG9DfIlwpl0W6125o",
            "188":"1X5RUzq_RtFenZyR79o4uPf_jU720RKRg",
            "189":"1X_An07Ob_TYFhx2YY1TVnjK_6cDquQu6",
            "190":"1Yu3MQCmvC78bXQZGO7Zk_8NGELTKj_89",
            "191":"1YxJEt1uKFzvA3wAt3n3kl_K4i172x56i",
            "192":"1cCO0Y0itWvrX9rQ85khAbtl5j9kkTMn8",
            "193":"1dUNleUFm5jKOKr4FzLTL1n1otUDXWW8d",
            "194":"1dtuJ-AQi1YPJb9nrj1CbyqQ8LyJrCHPj",
            "195":"1e6JL4p7Y-UQ8wA2UDUjRuamIghRM-xMs",
            "196":"1eh4vnxzQS2Ph6raPqqeN255sa2VsA-h5",
            "197":"1gG9c099npj95PPqVA2Jqu6CjUeJIhBjw",
            "198":"1hTHuOPFU4Ugxk-HKtGYDqkuZXlMt3xOy",
            "199":"1itooZZsJRwqWy9oSNn_TI1LZMstYdmfm",
            "200":"1lDxQU7NiIqJJYtfjxAOrND7vX0fmn3dz",
            "201":"1mQGoqxkziXRcbPOp2m6veWOh-xrZWgmj",
            "202":"1nyXPU2atHF6xPuzF6IeVCwmAHcCWE4LU",
            "203":"1ovMBE4jc6i8_wUC3AFaFOGcB3zT7JPG4",
            "204":"1pw2u07l6jeRF5xPMGFkVkq4bj0IFGuD_",
            "205":"1qjzpagcQ5wcuk6oMJV7yCeGjlUkj71az",
            "206":"1s-UKYYXEkeBQp8LgOgv1cjjD2v5Fz0fA",
            "207":"1sLuUkSKOPpqufOA1stQYjj4BnJNQr0kv",
            "208":"1vHYjYSzqHwqr-F9TLMZ4qDbnjVBBT-VV",
            "209":"1vWKKXJc3C4DV_35SqCxjELrzq2ZJgRaL",
            "210":"1vlIUZywyasR4MUQrQr4IEboW6XRORzSF",
            "211":"1y9krYc4vuGPsRwXq5vrQlZSolqVPtzZF",
            "212":"1zP4lXOePlQT9VsuY5p9VRd4kQvjWJKTP",
        },
        
        TEGAL_GEDE:{
            "1":"13xCs7r0rv1aPX8vUQnar5zzDHye81huL",
            "2":"14R8WaNheVeaQBkY8-rHywA1OQSZO-8aN",
            "3":"184oRjuTKaYmLegJyQilK_rsUC_G7LgL1",
            "4":"18tXtIG6fOoovWg0KdL6my8OvwKVlZYsf",
            "5":"1BMPNqKQ0f5ioJr5d18E7AtOk1ZuJtq6A",
            "6":"1BsgDVGjw7K2ic9MufuvEVirHI7KT5m0G",
            "7":"1H_uFfg-GaHsMtjF4rhspNgbUcrSGrP4-",
            "8":"1Msynjq0bcOJB0FkShm1ybTHlF3aLZ3eY",
            "9":"1QZu8TO10ktKUD4it3HSYPb5mDbN5P959",
            "10":"1SC95Qy_ZOXZHNbi8_Q4aGksFKNnWxaS8",
            "11":"1SmtYKHa173n3rRvXv6ZaFiXLAYTAi0LE",
            "12":"1Tp7scqpGlf63YBbzslh2meW2qV4Wv7K0",
            "13":"1X_MJUKtz0_gvNP-x3FRilELK-dag8NAz",
            "14":"1Zw4dlweSAciowDYKzLGScUvLSlOgjk5v",
            "15":"1abxjyhJlyo3xrnyrpjQ1M4nQXddCsdpl",
            "16":"1amxbmrrKbv8ZeBDCmUbDyTYR9kENaL12",
            "17":"1bSsQcQGthtFshyOgEzSp1VaUc6ZsRJxs",
            "18":"1c5ztzmlepb2LE7Se5zSdQwXbC4XWwUEd",
            "19":"1fw2kFmPgesWBaYMEwQCS_N6tXv1Cv5Pr",
            "20":"1hMdm9mGVFmITY1YPNSmJrNE_6V9tSwWF",
            "21":"1k8CA0By_wPl6s0IgkrHUUla4oWSobti6",
            "22":"1k_SLa7A7gdoj25SREWP8Oo-vi21gq3XZ",
            "23":"1m2db9UPjrD-kl2Fi8Udja9lx2tye7gg3",
            "24":"1ngShiF6pn3Y8yoHz6jeMR-VJ3DTwHSRP",
            "25":"1sA0AHTteBRhd0wEar8V87lxFTKDTQQxI",
            "26":"1vBynk3v0jx-i0E2iO6oa1vYj7y-UaowM",
            "27":"1vbCGhtiM1mkKljqVAFT_2VlHh97OIfUv",
            "28":"10BeAdFwyy2m9FtuHuvV__yvB4f7T0Ha4",
            "29":"18vwuHz-Kw2UGfqQM09hrVcaBhf3BSAQw",
            "30":"1AMB6cMO_Nlx1nef_SST4f2ZUFK5ViDt4",
            "31":"1A_IWPNNizpbHwcFiRRU0RlviRoVQ8PGR",
            "32":"1Ao2f6CmXRA40HiDXRdOVXbh4ciV9VqCi",
            "33":"1B9YzSwIe9yq53lgxe9kdOCs3giuwGZdf",
            "34":"1CcMcCmC_t8VLL9Dr9hrgQSTUNEUpPsRK",
            "35":"1E9cqQWPrYfIkOqP2OdW0Zga1Q3hV07QZ",
            "36":"1EmsxLlSPiJSArt8cQJDUlTsDcIOkuf-m",
            "37":"1Gmfno3nwMQc6o5Zzv_b6pBIqypFWUeNy",
            "38":"1GvL_FuJgI7HjOBnAmKYRe30vKnknGlzi",
            "39":"1Jc5sDcnoWWXAangSQp0ucThXOQxxGvfC",
            "40":"1JzYcRdyuaNHAz94MZh98sVEr_aObkJeV",
            "41":"1Ov9jSqix3pZ7SpHOUMlfRS9cmJ_P0Zej",
            "42":"1PKl9LOi5P2dUBnS1g4XBDD3RHP1iwdDN",
            "43":"1QNrNPpLCIW81aEwNLJXTLJGIF-DIcVKI",
            "44":"1QOip13rCx8Le7XAddc381PZ23UrPVA5Q",
            "45":"1SjG4SPKv4q9I-K90pFiJh8sHMhrJjbzv",
            "46":"1UYqzG4MezcUWEcLAf3-PHijzJj7HVLME",
            "47":"1UrEVd-0IdeopmrA8kvCmmn9g74ZeOyWh",
            "48":"1VmfMxVmXNSMfvjSllZZ6NP8Th-6xCD5X",
            "49":"1WZDs1bDL6Q4eLgkUOh02RQFsLlP9A0gC",
            "50":"1cnQMXEDghScKfCU_ul7W53_AdWQncatn",
            "51":"1ftmcP2BtYlF2itcYfuyklPxhdzDYBiMv",
            "52":"1h4C0lhs8fnSRvTf2Na9PUmBWPSihZ-4g",
            "53":"1hx1bBD5e7wn60GgR7Jq7RtjOkqitORId",
            "54":"1jp9Eo0fgAs8HgY0SLZfm-d7eY2M0BN9o",
            "55":"1m5fpsEc6slYvG9IMHY969gv3Q_Hs8Jco",
            "56":"1mMW-iyqzW7_o-kElTxHnOqk-IWHQr7eI",
            "57":"1oJCUBqHZzw7_PI1YKrn1XB8-VipDCH8F",
            "58":"1pduU9PsXxqh3uKxCu064nU9S35aBovse",
            "59":"1pwWYvGF_vQSsVW1j_-YJpPaNm-K1WtyJ",
            "60":"1q2-8EqHL9879fL6rT1CNiBKY6LWUeW1b",
            "61":"1qAgW7Ke2eqP63WwlkTwfW3o4Xtpy0B46",
            "62":"1rR-66oxOhzEeCCFDmox_jS3ihgng1pUg",
            "63":"1s0Vwj_IO1Zvw9cPv7YlgVYmI1kDYNnjj",
            "64":"1tsBBFGHtw5dyMu9AymAtuv-ADqc4Ct_x",
            "65":"1uA0LYFwboIhTBp1jpL0kAJ7jqVmQPtIp",
            "66":"1v5SLgpxyTvZVjKXV8ddGU8dq7---f8sW",
        },
        
        DELTAMAS:{
            "1":"1xibOm5z8XvyJq4uDeFqUrUcDUNKsYPO0",
            "2":"11QFkBBSpTT5q8RtZRMIVn7-Myd1Ya2fI",
            "3":"16-QaHg1ai7tb13jVy3kKh-f9I-f0Lo7Y",
            "4":"17VjnnEA7N_GdUQ8UYRzAbe1ttjR0-tlV",
            "5":"17gYzxRaNZtlwoRRGdXS2iZmmhZvBarcA",
            "6":"1AGNv0GT2qKVsHNXL4Gp3W1Z64lhiNj9Q",
            "7":"1C-96UGEZtb7H1Adu_q1CRc7q1o667PiD",
            "8":"1EgV1Nk4H30JRZaduLv3tQIVOZt_TFMPh",
            "9":"1Gp2VvzGOA7mq6cvXdekpz402nJv8BAGe",
            "10":"1M4x0dHnOmqFsBq51Jx3ElmBcq_itcZD8",
            "11":"1PjXY9jRitJEcyPoJb_9kt1TTahupRtbJ",
            "12":"1RwmCOaLAPiFQW7bNC-b_2KhKWP5_CNlK",
            "13":"1SoTy0zNqw55Qu1mRYHlvclJEyvxQ15Ek",
            "14":"1TcFUvNAz7wxjRwwsaBExccpTlHkU3wQy",
            "15":"1UqeNJf--tQV5-tMOF0Ozv_6dAMadMC3A",
            "16":"1WE1g_rTw3f_BIUEYxrEdOV1HHjgkmRqd",
            "17":"1WRIMCeD3nibfMWENk3jdYDhbGoz7CVzi",
            "18":"1_E9oXQHiSOuIAXh8ISSeTsjrCkkbTAB8",
            "19":"1_b4Vp8aGBLzOETqVPhNnAn_3-e1DTkp-",
            "20":"1aHsN3Bz0d1OW2_g06AymmNk5IDx3tFOu",
            "21":"1fzbgtjex1UjM7s94GlX51Buuz0uMUlhh",
            "22":"1o7oEP8ZH0LzPZejPkk0XiqwaIuEZxq4N",
            "23":"1snwLqnJzzrpLYgPixwiW1zXeyUWq2vwD",
            "24":"1urCGRZ7U-FL6DEQ44L_fsactx_t9W08H",
            "25":"1xVd3dkDUYYXOcg4rSWKWzeNB_SPZzbNZ",
            "26":"1-6XazOZifX5xa2L1_OcKOyqApD4_H0Pu",
            "27":"104tj-hUoCSsExyHvKslmSJT8TwY8OuYz",
            "28":"13vwimRKVxUGxXU4AZjISPdwqYhZw61AB",
            "29":"18H4LWtxr7U_LrpxrpMGIZl6L6LI--LWa",
            "30":"1BOcnR2HLexPnkTqhvNNLFTr9ttTzm1iD",
            "31":"1CqLzBCY6FvBLjo9Qxrbm7PXCqhHZ1c9a",
            "32":"1HWqzQQK_D4KRD8n75f66OY7jOjQPs6Ry",
            "33":"1I-3rLcQ_bbIHglmDCHiZynEaLWfWNIll",
            "34":"1I59BQ1UxJK_4A_luFQyJltis_uZcDJYk",
            "35":"1Og7jDxwUsRxYbnl0NBmCUXC0IhxcLyBY",
            "36":"1RZ_SV30RzwH1pWEjA4W05YoIsuUiyM30",
            "37":"1TFrnBkfIyQzZmvG-L8VCeMDExGhF88gl",
            "38":"1VS0qHhisIc4QW8qDoekBrhB41VytGfIa",
            "39":"1Varziq-UPy8jM4ALD6kFHJVyN_WSJawy",
            "40":"1bT9W4BfTSfnzAnwAO7PDtSQldCwd0_dC",
            "41":"1d2bW-tGkNIAqMe0aYf9r89a-Vx4kJoMJ",
            "42":"1eEwcMMhL-BVANJMf1lcpi3mOP7r2CeS5",
            "43":"1f_lg1t3P3W_oeR3Rd7FeNRLCiDspTNZl",
            "44":"1usRgPauggZziM5FN7oqVqPPpxUzi2Lk-",
            "45":"1wgAIZCcTl__Lh3GWoUiHUkDT-8QUgT_k",
            "46":"1yVz4LKbUeWqmHfkSVBWss3NPTwy4cIS8",
            "47":"1yohkEe7PyuoAdav4qFZRqrXQrv-LFKTE",
            "48":"1z0kf8hFghIek9G1RAoFMw9jJmftruSa6",
            "49":"1-ffA323Yc3iX6vdHaQ0NkAvXZF9eH9pH",
            "50":"117kYFxkxTGRj6j1N7_aQF-Z9ywmNyQ3c",
            "51":"11v4ax0wcH3dGUSjBesLH5-L_j-T0EU7S",
            "52":"13zCi7VfFJ1XwZRdczNn6PDdhjFLn5d1e",
            "53":"15wtD6iKJ0vfKlejOU5qbrh7IPUc3RWjX",
            "54":"1619qrQRx52cGgZbtf9HOzqWzJBlZVTeG",
            "55":"17rne3Fo0xPjLL5t6WFs05z5OX2MWx6DZ",
            "56":"18mienoYfLFR9E-KU9JH3u_O-fGhCQ0p9",
            "57":"1Ba5bU5UXdhoMZ8bEF_pJnhms4M7ifYX7",
            "58":"1GeAEEhS6Mzpqeav8UtzzzvGk2Jf8I_jH",
            "59":"1GyD1rxMzOkZtJHcu7XxSMZvXC9qT4z5M",
            "60":"1PbeyUZfSSGr0ivWBGVU_Oq-4PZdCGrEn",
            "61":"1Qn03AbzmIIIYHxPHTRAO0g0W3dhsfWdB",
            "62":"1RPNOuxmjpqZzwUoKnf-O4gd3Q4uszWeB",
            "63":"1SKgNEduJQNrffmHbcOfKi_5Bmxt_TUi5",
            "64":"1UHjPRHFcbLPFbmxDc-EY0E6-T-nTTOb4",
            "65":"1YdZ6nHzClZIWf8wipuo44BfX79bsQcqH",
            "66":"1Z-_sgN1n0k0ByXzJKXJcqy1YIkR6W3ql",
            "67":"1ZjFg7fbdV-UN-PBgfCVmpn-cxYqLsQeQ",
            "68":"1bu9VXfL7jlyOhLNlOHUFcrXr0T_x_Ahh",
            "69":"1cKN6NRBrv3lQq8fLGVMTlHVX44IVm7yw",
            "70":"1esZYpDmrCzsQTwbGDRwenlehf_VG3Dp3",
            "71":"1hrA81f9Lh9hVbxWWGjaQARg8zX9Omm-W",
            "72":"1i1jWfXxrEYlMRgdbVQlHO2c1uvHnfiqJ",
            "73":"1msj3GUqp2MpoqyWMQ2SSUmuL18RXs4JV",
            "74":"1pP6-bb6yzqVY_frSywBLz2TZRXTW_q_s",
            "75":"1rHwYmL5HC82SaAnnX4WJXin6y1HHXYWs",
            "76":"1sdyNulac74gNBbSr9VfgqRiP3_WMAA0A",
            "77":"1uzhBcW5KcghS5xBqZt6GIiyHIk2vnnAp",
            "78":"1xuv6Lq0ZReB1590mY87VtiD_2Tiu-GOp",
            "79":"1y9qEVeQbnyB3Xyt-dgQWP1csJw-RvNmi",
            "80":"10AEq9EluS9q0j8vINOq_Dgj9aPXoHv70",
            "81":"11YNBj_0-KzBdmJQIt2uWto8YmWWJsh03",
            "82":"15goWUAc7cEYOrUpXod4_gnL6urg1JDVj",
            "83":"17fxjTaV71zZDA3z8DnbAfYdlMMeESw8m",
            "84":"19jUOuafnsGGKux50gxwLqSzbXq-kZsT1",
            "85":"1IjDK4tBhLbZCR1BipgVg-99JijRPKnzF",
            "86":"1IrYGSZoVNRYvSzyPjOmZpaTkBMdgYdu6",
            "87":"1KE4QSV6NYlyTS-ZNxpsbonmbK8Wm65R7",
            "88":"1LSUBYr6mdCQMqG_auHzeZ6U_pc7DDwk1",
            "89":"1Njz1UDEahAkaED4joxCb17m8DQwsEEhc",
            "90":"1TKvDGp-cNx84Dxcgpsk46bOxaMll-7a6",
            "91":"1UAwYa_zulUMivQEkkMF2Dcnbkp-hQOIL",
            "92":"1Vt6Yw4c9psW4tvxu_J-iwWTJWC_nJJvB",
            "93":"1W8FtORPRBz3JGfN2mx0DRbFOL7H1flGN",
            "94":"1W8aG-l9bEW-5Z_47NxBoxP487OiWB6kC",
            "95":"1XEN8BRClywjP0dz58UQNCXwn9IS8HERu",
            "96":"1YC2yQ7fdWbHgLTdCxYbmkRD_yty-J0NC",
            "97":"1_yMIvWWkq4GqWItJn9WRWE1agfaVnSEJ",
            "98":"1cXIu8UguBiRcFT1A4MZNXmpPx2gild3F",
            "99":"1dKsCvBQfsvgW3CEpq8CInuG_XMrhgJlq",
            "100":"1fgnj_nWPIvEq87fzlJz8MPs63eVzi0v_",
            "101":"1hNMl5hk8toKjEupxuXyLUT-bdk2DCpZ6",
            "102":"1hb96x-fOwphbo5CK4NP-L-lWEyPPrSud",
            "103":"1k8O8vQDBWht1MLikx6UcmtUM-qedSC76",
            "104":"1r39qinLtCFZc5oWY32yRvRuHLy66qNwo",
            "105":"1scM9vo5LdmD7KMKNmEfXjP4RU_Je0fO9",
            "106":"1txaVNyfMGdYx5k_i67WOVhK4mKNg_PEB",
            "107":"1w51NaqPnRerOx1HOGJsnj6eyFyZpogPj",
            "108":"1wiE6nRYMofZJ-ADggBPADmNT16i1KJil",
            "109":"1ynUbewTCt4fImO6GyRn1IkTvuKPABuFR",
            "110":"1zCu5NVaiF3LV40n1AQj-pai2UQV3Oa0E",
            "111":"116DaypOltBJHtAqSx2tcpMB-kxrmBM5D",
            "112":"11abudggtTOFEJvtUaLXgI31iDhF4EkWq",
            "113":"12TflcI8Nw4-WoiNjEKFCd3sHdGq2UVW-",
            "114":"13SEAV1RjKxeAqBOtxh05PVwkyKzXzA9M",
            "115":"13iPKhXuuUY6JIIVvxo5sSlv13kLyz1lY",
            "116":"14QZzq1_w0tod61ssGvge9FKKTqV_ydEE",
            "117":"15RFzM23g9se8hClxNdc_QxMHOAaeZEBM",
            "118":"1ANWlPgq20EuL-QYujvq1PoG9dSZlBZkP",
            "119":"1HI-EdhqPyAnZne1U20Uiw0C4cO8NA49n",
            "120":"1JJOnSiVvvvmyfJOSVz5NxfZLgIv84ENF",
            "121":"1M0ySUAEPSpY1INz5ryIy_jXc40bV71CQ",
            "122":"1MjkX7-kwCP4XpHSEcrkeJj9veSFFg8sk",
            "123":"1RXapOgWelADt8qXR0nDMy2-DR2LGNSYJ",
            "124":"1SjquJ3dVJPG5jOVGkrUOCUO0G9YZNAYe",
            "125":"1TJgxsJYVhXZEBXZmnLLiXyOIIgam5jFl",
            "126":"1TZCDEqvThxVi2p8Qa2kstHwQxdMElZWe",
            "127":"1Yo2ssghRaTF_9tjmgxiVvFVjvD1lwM-e",
            "128":"1Z8WDjdkZhcNUEiqpe3TeB_d6vIOdrjD1",
            "129":"1ZEjH8Ell_X4j82XSrtZumGE7-fID-P8v",
            "130":"1_inRO9Xr3zvp1avhIE74wGb6jdWddUd_",
            "131":"1ad76EX6wDdl44p4OjxsFsq5ymk-s8eKg",
            "132":"1crADFTg8IU2gQMZQHmPV1LjggqDVXDRk",
            "133":"1gjpeXUl2Yb1dSmNXEhLQUFa21ffksxjM",
            "134":"1is-x6W0GWsja_fx6phAKcBZi6rHkU4XL",
            "135":"1jJlJFrcrPmoYiVIiJLql5dFqqAwdS3i7",
            "136":"1lwAfgigw_cu0ljb2VnsCojSIs2JCapMh",
            "137":"1mtb11uXEvQvQri2grIFvanJ0ggmSotv6",
            "138":"1p-r8vppV6J9sBum7FMhr97CZrYh36GFZ",
            "139":"1r2Ho9AWyu187ew04SxNzFHnCpopNc2rM",
            "140":"1sxknM8mNUcf5W5aLdrujxiuYnjZSEU1a",
            "141":"1u4I3aLj13aEAs8d-NnszNWlJOe6chhAD",
            "142":"1uOjNzQJoThYyUdt-pk0DVZV1AXHW54_n",
            "143":"1yU7w8zj552Me3oHA7h96J16wpwLn-_8y",
            "144":"13RYg7-ZLbYgIAeKHN9cRxwLDIylhzbRG",
            "145":"13dib6QbnKzSY8gAZ_PzZt0vDIiGU_jTC",
            "146":"14t-rNy49aF4hxxxfleIXLXW4L5eLRabM",
            "147":"15dOx0KP5jhZts-WvY3zjJKYL1q8ulbjb",
            "148":"16hNiI8MfFXhQBJ7ZYMYV6lFPJTm6niCU",
            "149":"17VYcKMCgu_QyIYtp_ke2LfXslNUHTVid",
            "150":"18_M4A06DxIMQlPLQNbWc4bK-TB06qhyg",
            "151":"1AM8cMmXeHeXLUYUyig5pXRc2_jdFBt2v",
            "152":"1BXY6COZhJzcjaZgKV2ovavFngeXWfI1e",
            "153":"1C7BcIy8Mho1xdjEgk0EIOa61eKWgT6zr",
            "154":"1CO_mZHNZNlzc735z0HWSHMQL7sQROYJV",
            "155":"1DaqSFJP6miJ-yFWPts_lE61oSWnmtz46",
            "156":"1DhmJGn9xvtAwblmknjZXPGlJaYYxO2eC",
            "157":"1HLZYSwKlsO2sbocw-f5A5IkXG1H1IACw",
            "158":"1IZzkHDta4VnT1lDfzfCp0zN9-28m5h7z",
            "159":"1J-tp-bO3azs-PtRHCN3168BaByLHWrh7",
            "160":"1Me2kmOlfR6ggkwjmGcAtPeKPL8zYZSNy",
            "161":"1NrmS4J91XIpRYgJfpuqUYi6GMDWuq2Sa",
            "162":"1Nv-8eeGp752xKAL4Zi8kX61qGmBNV-fg",
            "163":"1RXeop1O452j6wUKHvkE-wKplHCA9ZemI",
            "164":"1V8MoqjPT11RyEvq1judD5jSHQDlKdK6Y",
            "165":"1VtbDY_u2e4J0GBVYcMlGlOZqxO0P8hNG",
            "166":"1Vx3HC5UVGy3sthaljBzSeNb7Xw4DnFO2",
            "167":"1Wtg_yGuuSy-8QAmkYoO5Qz8TazqNAQTB",
            "168":"1Zd0mDJDnn3qsJXwBCoPw8zSq28RpFK_q",
            "169":"1Zzn-Azuis4hHZxuGWygufPTS7vjAi3bj",
            "170":"1bexayiYOnppdZ41ddtMGOEdg_0YtUxQx",
            "171":"1cYdScfO92totnbM0rjv3oDbAV8sPr0kn",
            "172":"1iVuK_vY-brEt8_NWA4-Q-l4GM911-Z3n",
            "173":"1jwa_AX8c4SfIZuf1QkiQp_pYJN41KmL5",
            "174":"1q5mFdscKJ6LMC1nXpAc8vOIKZNov8MhN",
            "175":"1tpV_bwfr2LHbqRoAD8BRZp4EZHYHkAY8",
            "176":"1u1iC3UpDcjMNAqoB4qgLn2sGRl3Le2zH",
            "177":"1uw1BGhascMuTYW1bxIiTwhpr6DDVEBtf",
            "178":"1uwfnWytOwaIhQkoTkKkzF46V_8bu4-Gw",
            "179":"1wJ0Se8sOE-9U7xso-ohJvc5mW2BbcAb5",
            "180":"1w_6V7xXUqLE03vhqNBs0myfiKbo22eqo",
            "181":"1y0uoKVFagWfF9ReLYY5vJPE8ktBeUck8",
            "182":"1zAJ_7I7Tc2ZypgpppoYVxg21CvvQgOnk",
            "183":"1zHVjTPY1wWsDk5EIm4AeZxkR77TE05CW",
            "184":"1zHXGT1f7oi4FVk4JxAv0D1sGSOXBfJtF",
            "185":"17n9nASlmWYwR1EUN2ih-qL5widugBEGY",
            "186":"1pVAuEepnwd_hCPWKD-e_ST1EjwbCAXMC",
            "187":"1uX2dEan-qDf4Ic5GYlYcdJxtUjhIFzLc",
        },
        
        SURYA_CIPTA:{
            "1":"1XWHnOG8Qk3QEarnT71HFCN9tZ6VqRxbg",
            "2":"127eArMtzeQvjDw_xuvIiRIU9mMbyvzFd",
            "3":"13ecwSiq1CFm2A912BnXa5jmcMSqEBr8h",
            "4":"16RXSgzK7pbQUYw7k_-DkTKWwWjDiBbq5",
            "5":"17xcfsHbf785oljQxsvTFKblullNOYmqM",
            "6":"19PXnNCUQaL7PhUFJrrI7kwxhjyjAOgrM",
            "7":"1A4COqE3ikuYZmm5pAWfVm8HVnUsf1wNW",
            "8":"1Bjbb7T09oL5m4Wv-qTfd7M2owgOnQvuk",
            "9":"1BvlD708cmJ8VtIVfSUdNJIs_lM4A9zX7",
            "10":"1EcUj2ZqH3Lr8EUOtHKtzC7B4SzbLsF4s",
            "11":"1IvAygKVa5ApkEPflkOevi_wdY8WgAcFv",
            "12":"1JYqgLAsNwMPPI33zW5lpETvn1LW3MvJO",
            "13":"1QkIbMR-vhX8E1u2BjUo2BSb_7Z3bvDln",
            "14":"1RHyd4BwD_S1D3idARdwFtcZD3WugdCJh",
            "15":"1TMBjfLlSl-kKgTFYRmRkj0QyaoxW0ge6",
            "16":"1U558VVAqL8YhU25XqR5Y61NXL-6p4u5l",
            "17":"1U_-a_p_fTq-Q82qXrGt3WjMk_Vnv5-mi",
            "18":"1YJpOcUodul5K6Tuw7bvTe70syh3FAq5r",
            "19":"1behH7-N9HVlcDht5rlyLuHFazDlbdIne",
            "20":"1fwXGn7qQuu1EQxFU1IQpLOsbW03ySN_l",
            "21":"1lwxtNP4JUMbzrtZXfey-SC00Vty1N74U",
            "22":"1q30lreCgnrL1krz1d1zGkQg-Sq7eGUnG",
            "23":"1rWVM-CVzITlVxMtJOPy-fjriBHBO4COF",
            "24":"1soslkBalLcbTEj7xjAetEk4HXXJ5iA40",
            "25":"1u62SQEVNvqMTt4m5_Ubv4VjzYLOaR1sa",
            "26":"1uo71p2aFxMMKEsk1dbKgTgefvW16A9nj",
            "27":"1uoE2TrA3qfgn8ODYok3csfN1PrfJP-g1",
            "28":"1x8vSb-9fwHQum9eumNDbuuSZ4Lr9304W",
            "29":"1-AL-6ycnHDCw5KdUz0Z07UHp0_IUz0L0",
            "30":"10qAXfw1r8fSCSiGUWcimv47FJu2ivtBO",
            "31":"134PST0boe4CBDYqkurNwdYdV-xBmI3_A",
            "32":"13gfpnIcwIDoaeB1pPaaMN8dC0N24Sj-i",
            "33":"14uzKPU8nP0uJC1R9rMrwsuxweFhzk4Yz",
            "34":"15C_3-HkUzm-ytAlTAzdNZzavwpC8eoVS",
            "35":"19rvVkiP1gbxAMaSi_q1Ks6BRmT9odu5Y",
            "36":"1BfBKrnXJt4fxCwFE975ToYRA1_oFFdvH",
            "37":"1FP2mEJrPcN4ulAeoY5Mly1wj_hHKRXW1",
            "38":"1Gj16SmTbtXOFaZIV8gjmufPdboCpiOQn",
            "39":"1IhPxnEVyq3eiRquRVO84EddPkXcIMI_o",
            "40":"1M2LSYFWUKOAKOfLHVAvK54i4OX6ARTiH",
            "41":"1MeoJWIGlr39d7JBX4GJYz5aYzPhCZ20U",
            "42":"1OIpPiexlc9UWPZN9bP8M36oQWqX-DbNw",
            "43":"1Ol_ctTfhE3YIGqapDRvr-SLRnf1IbdUw",
            "44":"1PnooQ3lwypz6kTsJgGVBCCh-Flwf8H_N",
            "45":"1QApuJmhxhbMFUztaQQaynkn9HO0GT3os",
            "46":"1Rhj2uqM90fASb1K5-U9n_Gup3i6Jm0DO",
            "47":"1_5uA0lYsGGkQGLxYipmFEIanAQ8iV0Lq",
            "48":"1bW9KDsDh51oxhs5CS5itkg_qM3RMz63e",
            "49":"1dQKCYUZlpVTNdWXVSSCZXmvHWuSIOfEc",
            "50":"1el7uvylLmeUzNgiaDA3XfS7Hiuzm143N",
            "51":"1g4P-_eSzyLDj8o6OYoV8onxo06_maOFN",
            "52":"1nQ_aHeFQR2nWkT-M0e5tXlZESdI8ZxMv",
            "53":"1opc8VUrsaw3FHIBkw4G-xHTAkYrJOr6R",
            "54":"1pAQQVO8Xg0sMD3iLwsht_K146_zaj5hx",
            "55":"1qFHi8C7XlmyqrcDtoXDPS2BWmhDW5PYL",
            "56":"1tRGhvgZ6dBvK3ctBqC9tBpDtdvrtd7fp",
            "57":"1-IAu-mon0V7OjrCJCII2FpXUokDvzGUf",
            "58":"10W416mHasPOCs-DD-SCam_czPSHKueHE",
            "59":"10_VRyvNreloDzbAfgLQCihrPCG5f_7Gh",
            "60":"11Iz7knuMUr21hSIzatMmUqIMYJMZPAYs",
            "61":"17QwWVcpDB8bED_ACYCiUQ9eVfuByQqkv",
            "62":"1B7KO33ui2QnUwfDMKDwq-qU-YwAfuoEb",
            "63":"1DNC6_kQkunyXyG4Z5B6-nZT0vmKIcbHj",
            "64":"1Dw4rxRtGosrS8qXjTrLYH5NO5Vsq4LGR",
            "65":"1EjIBbxJoNcHH_YK3oAkUlety5nc4BjZe",
            "66":"1F48-yACo-AQkNFLZT0wl-n5Ysaw6SyEc",
            "67":"1FIsTQpgriPL4LbL4Mj2PiEdMHUwQVXqR",
            "68":"1FwVD-LU6zerm6k4Ei1ToKCVLgCBuCJ0V",
            "69":"1HQL5FQ_wxtA0d7t0OEou0VUx8bPmng21",
            "70":"1Hh3icJwUnSYG_MbaLzQC5m3Ky4SFiH6r",
            "71":"1IJALpzWRGTNruZgYxdjA63EhadFLeix1",
            "72":"1L1vW2wuEM41sY6ygHgnFV7nBgYdwx0-L",
            "73":"1LlEH4xq5ZZQnh48vdgOloMyGQztbZxgj",
            "74":"1LzKmDgecUc5XwGwUiG_Y7cxZpZ8MLpo0",
            "75":"1QE9J2LDc1ZiuPau851cS2IwusWkXyOph",
            "76":"1UFUTDXj-BY0P0WUVaDlwdWZpwqjrhe0h",
            "77":"1Vod1i0xvcuoK0cIRdogMQopkzhuTHWW3",
            "78":"1WATbKaImnkq6_Wi6qR7OxGoAVt4tvbdl",
            "79":"1XBFPTbRztJA88T46tpAD7I_NTlAB_aS0",
            "80":"1Z0ABIx94C7xPjmR8Eje-laPxfXnzcz2y",
            "81":"1ZWgwwMHzc3O0dBt-854e9coz3ETGMwd-",
            "82":"1e95dERpIbV5hh5nC8X_a9vEG5imRMbQX",
            "83":"1jzjvX4j1-wG-McsjGiaflwwuLnrLmoDD",
            "84":"1kTs9b_sFV3IfHvvUj30jP0pMq3U2jvsH",
            "85":"1lNyy5M08x0tYIxWnc_PFmBk0ez-hqJMW",
            "86":"1nPWyQP4mm5gCJ7YdfWeMOwqg19EQur8O",
            "87":"1qlKqJne3GmBP-PgdjXNAUZn8taSrCzBt",
            "88":"1uREVDDp5V6NMnwb_l35UHLiwpIfNAHK8",
        },
        
        BONGAS:{
            "1":"1Jqo3ufTod-20wlsRA_z8SEPddcHZLGBG",
            "2":"17uq-vcWGo-iolr6RGVkp69NAC6fjK1CI",
            "3":"1B4lE4xnzdjcQuT9PI2UWFJhgR6-Q3wn2",
            "4":"1MXN_oOiI0TPAenaX019wRtk2MoQ33nwo",
            "5":"1V2HAKt-iCRfuM168MRATxNd8tnyMjXz9",
            "6":"1cG1IUf8fyt4FBbY-x5bOuwmp1LQvboTa",
            "7":"1heXPppYapJ-hHsTTVsKH7ytwzw0NX2_A",
            "8":"1zzaivXFfr8NrHWmvtcVmkRiEXrZOUWy5",
            "9":"100CHPjqnk3vIK9Mahx6f3cAs3vx3QjWo",
            "10":"181kKEC-_gtP1gMOnzmPlxmAcBoPCADpd",
            "11":"18pWx6Nfd6PcgVuk6FZW3Aa8AjTa9bJ_M",
            "12":"1BGehUVe6HBTkOG92AdKr0HiLmPgTLvpv",
            "13":"1EvpL3xiKeT2oj-EVEG6sMe8bBiAhXQmf",
            "14":"1FhSJOxWxY2Y5gVxS6N36mPDRy0NzTCQQ",
            "15":"1HTmbsg5jOzolnGFVdWiGHkUpptDyDLMJ",
            "16":"1IeLv5fScvOw-y3d-ue6F8P1plC7MAf7z",
            "17":"1KQQFg0vSGF6-pIp_bBnZncBC__MyKAKU",
            "18":"1KRqMoBQXL1u7EgAlyqieiKgGzVg5y91O",
            "19":"1KaJgZhpkYMzXWZg48N2hP7_c38y8j6d2",
            "20":"1L98opW9kPGzZxaKKuBE6w8jw2qcS3Xn8",
            "21":"1NpjWB3N31fTgwk5pi2bOL7jRknQA9W7Z",
            "22":"1OSd_WKK3df3AgDtgIWhiAEDvdyuBqui6",
            "23":"1OYCf2V2TKU6oZwbIFJX1pGjua8WNj1iL",
            "24":"1QU7mUCrockUQZI8vTn7jrRsHsmk4rsDB",
            "25":"1RTagE5bdMCIcRX4AqEQuWLaPyUp5gE2H",
            "26":"1SPWWQRBt-TglF-fR-kUnbKDtE9Jj86p6",
            "27":"1TJ5_8sT-Y4BAJKfFJQeSmx-6ihLLOXng",
            "28":"1TezpJMDGg90FXkxcfFZI3V1NDb_w-aAW",
            "29":"1U7oAlqp-phsgg7e-tioFFFOmn5icmmOy",
            "30":"1UQUePrWOitL7AObmOx-GwdhBT4oUYKf6",
            "31":"1UXoe7YfmjGV8m0D8xuLkHHzWTxIMa6N7",
            "32":"1VPp8x6-yL_GjDTqPRXambp7bdyAlRdud",
            "33":"1_5tvUxPSdcXH4AXp0Dvida3dVQmfgR-N",
            "34":"1aKYM6oPIOjJVZNRVkm23OSAkEaO9N4sK",
            "35":"1aWfd2HV1xxl7DPPYfh3x3gu3iIhyobch",
            "36":"1aiJI8bIfkHu7EjsnUR-Un1xwzTEYdjps",
            "37":"1bECDvA7yT_M6IzMQWau3FfHCzRw1UyxY",
            "38":"1e0XMfNt1HbJ6vcHftriBABe0v-pUGV-Z",
            "39":"1ejpsvGS-wqvJGSV0-2cnkzIxTBitVdVt",
            "40":"1gWRBlPbFj4U4a48cvLKXdLWKCyPBfKZd",
            "41":"1h6eStPxx_kHyU1hHZZvdqt7Zt6ai-3ox",
            "42":"1hfoQHVqulSEBH7JY4fqahc3XDsMpDs4_",
            "43":"1jW_zRXd-CxsHtDY9wm5EyD1AwOXkblJ9",
            "44":"1l3vvnomy3ImIsTmKbmxI_VS68V6dxFDx",
            "45":"1peBDYjyVqVmE3AR0kH-wfepHw9oBDJNN",
            "46":"1r9e1Fcz2aXkeuRtPzniskbjNiZaCZxa-",
            "47":"1rDUqUuzwJsg5aMtSc574mB2kdRRQKS2A",
            "48":"1r_EQgTJT35frpd2hy8KZRQ3Ubk9nFUcl",
            "49":"1st4Gphys-KbvJ4VmU5kYID2PUzXEflu7",
            "50":"1szGlo5gin9HLSmwLs9eW-AvKvsLD2cAc",
            "51":"1tqnwwjr9FU1QcaYdqBrKdfm2J4kPKoi1",
            "52":"1uTDsa6dEM7OOOKSQLqJPjcqQxHbbuSBv",
            "53":"1yxAVlyJTA_VyVzw90p8N3Er3PktfkqRQ",
            "54":"1-ocQqLJ_Iyz6rwhvHqsEat9BL2IzZmuJ",
            "55":"101ojeOkKfCbk0DHnCUPzI3ecq6BpqxLd",
            "56":"1084Eig9xv4fq9iglIVLLINmY4JG0uAPT",
            "57":"11DqRZRoCiyoMi62ahjb9hVq6k00SStVh",
            "58":"11FgOBDwDUAz8tlJsxI_IfnLTvT9z-L39",
            "59":"11R977wWPxRO_BheGGyoJG8SVQumaG_30",
            "60":"15s-ZKfuHuLNC4y5k9OjZr98c227hB2av",
            "61":"16Dm5-jgzJARuViJ7v_IM9GKtwYLwvmPt",
            "62":"16c61OH-35TH5GVzdD7t9FTu9FSj-18Lf",
            "63":"175Vk16hcDGpXXlnZtNtyavYP2VNa3f_3",
            "64":"183C4mwsH5Dqn3D4Ab9gmzaq_mz4TQ1Hz",
            "65":"1CpTjkhE6uWw_d6Bly7Y_HPoieHwPATRe",
            "66":"1DzCaJH4z5FxXgd2Trl5ZLtpzGfXLR8ir",
            "67":"1Gl6ib8jUvf_WyO-lshx2KSpqASmebIfQ",
            "68":"1I3ihdTDa9P6wHgABWi9QhKJPtrTds8CR",
            "69":"1IWLo3f0oqTy8iYSxGDA0ScfdyHUh5J_W",
            "70":"1L8cpf277GUe1jxJJ5MKDyp_inp8GEgRn",
            "71":"1Lj40TAl4bL5eAtq4GHcrMO89Lm7x48pP",
            "72":"1Nxvk4did-i_12BO62u0EM03I86NmHpDr",
            "73":"1NzbGkKmWvsMb_JRkht9Q1EA08pI2OcxS",
            "74":"1P2-Nr5uqMZ6XnK1Yf9t4GER373WylPMm",
            "75":"1R0uiPKzLi0lvc7IdoGQTOkGbQejhyfGV",
            "76":"1T82UA8JrPOIlKsMnnZYy_aLwvH-9jt6U",
            "77":"1TZOCnDID4iZPnDFhfN3QxSGO9DjnYMw7",
            "78":"1TkdX5KGnKi1_OKq2PehKULZNdHU5eSxt",
            "79":"1YK2g1MpSWaCtVLY9a21PEDc109172eA9",
            "80":"1bqTqi7q2sBI3Oz-q5nRISueVhReY8UN3",
            "81":"1bxTBGNXO0GVlpmG6Zi4vdBJa2-BaRGUc",
            "82":"1gcsuI-5l5Thp4wG1GR7jbaU2xOiz6oZQ",
            "83":"1hSV3dcoC-PT7zRWCKthOy9IiaC8KZZNF",
            "84":"1hhldV69_A_BBCZR_xxU_qFcfykC5duCo",
            "85":"1j_6J6jQvYDvUJwkHlIaZ0laVS_Jk9qPc",
            "86":"1jjux08Hotw_V1OW7rbdqcI9ukeICES2p",
            "87":"1jqZCahs76eDsP6IB3c9wHaVaLH7ffz4T",
            "88":"1lUPPcN544hjLfAIsXoVN1xX2bAMo_7hI",
            "89":"1ml7I5fBhtI4wlrfDy7MfSz00TFm3QXXl",
            "90":"1p4jGVIEdD4r-XoNl-rnVCpVLErOCwxSX",
            "91":"1r8Am1uUXn51bUPYnEzJtmcJli_FLMrMl",
            "92":"1s2XAHwCqpsnBezgukhepg8w97vamc-ss",
            "93":"1sMIJqRPsp4bT1Q9UWeXgHnn96m5mvPO2",
            "94":"1tG3tcnc7BmM28hEvfbXlJb5pyQI2uJhG",
            "95":"1uGfnaEeYqPAykR0KxzeFJtA07CL16KCV",
            "96":"1udXa_MQ2nXrAtT0dexk0k_j91i0P6der",
            "97":"1ylVO7IvoPR2y89czSOkZ7TledtQkkgxe",
            "98":"16aIcPGAcEsTWDtZWe8Rl_FgNmm-CTBWg",
            "99":"1HRYoSE8EhquyIxaR_w8M92umnDkuD2rr",
            "100":"1bAyIN8coLo3s-jZjdiV0Wp0abEhLaCMm",
            "101":"1mBdUyYrCDzCw-IlHrk0i025Ig9gmxc-O",
            "102":"10c9bzkaEJuw_XaHYybW1SEzABrHAL62V",
            "103":"12BO3g_M8LftjQ2BpYR85tWAl_LUZiYN3",
            "104":"13_ffPq3FlthAtXHKHAJD5xCfb4tOVczb",
            "105":"14JIHyBxvZSmJELdu88r5po8OE3Tfp_vN",
            "106":"15dgK27HL2NrfRvJcoWSxTHIAHpEPQmJe",
            "107":"169kntKt_5-m5p5lu8CUoZOvPiNy6aK-w",
            "108":"169pMFcvZYTrcqv2cSaCOYHt4Q4X7MzTa",
            "109":"175FpdLatnU7eDaGHQhlsXrJNzlds1S96",
            "110":"177DKJ0ACjHuRZIwwoaHpZfqP3f2GqGD-",
            "111":"17HmABZIU7c-HIGV7p8chh9S27RzjbWil",
            "112":"1ApetRdPfzgXg3hukwa0dmyVJEL1LRSfR",
            "113":"1DX9ZoXJXdYtSsAlrQP-Jn4gEIYJpSF9k",
            "114":"1EYDP9c_V_1SdrZYPnUQproJOeExWIe4_",
            "115":"1F9XtXGmpjo7WqG7ipkw9cPYSGSWyVBHu",
            "116":"1KfsPrWPWULnzq-cLvwDbSRqsgnggAdi7",
            "117":"1LELmKgSLOnZyMn4V4G9KnXWbW0ySeho2",
            "118":"1Mfuv9TAENfOEDO0VlCX1Pdqbb58GJxwv",
            "119":"1OABX8vmXt0Kvpu4AXOWcvI7IMZEMF465",
            "120":"1QCD6cS2EmMyFxrmO6cD0G-Yi_A7rJt2h",
            "121":"1Rjet2A4O32UqpsYNCxHAltlQbXvJ_mGE",
            "122":"1Ufo5HBkL1Bc0l7YgfJei47DJaHxSDikA",
            "123":"1XJvtj5AfBY493WZvmWM_O3QAOwWmw9rU",
            "124":"1YmStLkrPGc2zdUDRbcqKgbHKDNsfh3sU",
            "125":"1aVd9goBpNioNsFN2oLEiZYhRix2pWRs4",
            "126":"1bTF-bbg57SJ-HyKMAaGyPXmq6SSD1gU5",
            "127":"1bZnrirvXY5P4ejCJ93icxZZqLmPolHwZ",
            "128":"1cMn3EqlznOiS6UGQu3lc9GurVItN3atV",
            "129":"1dALohAtao9rFltTfujmdhzVV6igFQ2dj",
            "130":"1dm5sSLXRDXA8LPlv0pmxrYcYi3PEgfDq",
            "131":"1eraAaQINCkRiyvrFoubToA7ckoKKs656",
            "132":"1jFNvvMfEAV9wcimZwQHOvji0wN2snk4I",
            "133":"1kzE7-yuDnGojd6QO1kbPfl_MY1vIpF6d",
            "134":"1m1cZJ2xgsiJaubyORcEvDLS7hbyBYP16",
            "135":"1q6FWEdRbUTvNoLv-Ykn6A2IEKiAUz3Gg",
            "136":"1rkNtA5HwHWwOdEfJtr09R0erHFSfkLy-",
            "137":"1wDWDPeUk4R2t7XeKfnoVDy7Ii9dGF3Sv",
            "138":"1z2J61rlNyA69AP5gKX09SZA21aKm6Ul-",
            "139":"1zRUhrLY0R3R9q3NE_JPKdMT0dB9g17i6",
            "140":"1-h40QZ-YZk_U4dHG1Tho8F39Fj5C900K",
            "141":"1-iYO4UCVRJTq6RNto_Y3IX-z7yvoLks4",
            "142":"1KRt_vSoQ0G6HOd07e6OG9oofx9OpPCrd",
            "143":"1Sy_FeyJ1i453gqVy3JzwVLLnH2_mUQpY",
            "144":"1Ue_iVywwhpJfGC_CeWfaqANe7WsF0uDz",
            "145":"1Xo_khs6DurovFINhD6HyAP9CGGZ1hoYV",
            "146":"1b2Mz_EPxGTByfQ1JRr5lttBh3AshNpAH",
            "147":"1cB2BeeBQBn944NafW4ssVAFUrioP4VDL",
            "148":"1mhnp5sC8p5GbeSMO5HRz60E2FC0flLuA",
            "149":"1szXQ6bEdngUopelrjjV6bqIBPewIpR2U",
            "150":"1wlSdYcZIKMGDgmb8o62IOoP-nRYRH-KV",
            "151":"121rdDtnrKYGQsdEUlGDkPZzAOhTGN5ZU",
            "152":"14asqr2QMpZ06ktjSnOd6iZ95WIWXuqb9",
            "153":"150piMMblLVO0vugA8wrgSCUtU3tWApSq",
            "154":"18F7iTnsETtsG6mSNkBZfnOjBdSzHahEY",
            "155":"1AF0utHbqGmTxhMYBa0Oofl-s_5VJHRgP",
            "156":"1C6JY59ypzLeVh1raMMAVBxDePPysKQsU",
            "157":"1ERJpXObnm527FY6DnXBA1fqCofiqnzA8",
            "158":"1F0azPAgZJHzueed78WdmG3X4iQQ9_RKa",
            "159":"1GuXzFuKyS4065Qnphu3FkXGUSUlAz_VX",
            "160":"1JHbbtNc4yuf6BbCSRH-bcLkF0tMMIFQq",
            "161":"1Jwbfr4qtOHuDf7ukRKFFsJVeEN7f6qEA",
            "162":"1KfgGFBI6gsaP-ZPRP21Auy3B6tWRORf1",
            "163":"1M5GqUBCyMynwoWZ0-zJQnCK26cHKpTWP",
            "164":"1MERuYp4v4SxdiN0A1-gZFKcdmPOn5F7q",
            "165":"1OPjfvEAFHfgOyjd22Xu4Z-AB0WXNoVQT",
            "166":"1PpP1tIRIyEzofJDEtfeHm6CJ-hB1OV75",
            "167":"1Rozur2xs_zK51OmLV6Lj0um8dt0kbryp",
            "168":"1UyoZNSAOqlnC7vFFUvZOLaejNAVWXzHX",
            "169":"1YzYUNDMnvwgv2kj4mWPVDp4djqYd1r8z",
            "170":"1aC6p8mIZ2UttDlg1UOe7HjMvAaNSKKop",
            "171":"1aM86PVc6U375UKBdxeCqqR41uDIflCZm",
            "172":"1amK4llTEWPlUaHYY0vaBO3Uk26izY4gi",
            "173":"1aqbx8B9o-hJQDIKblANMCYKnyGqUvNEu",
            "174":"1duzic0amtvi3h9_3qT5yOKKy4zbO3ahW",
            "175":"1dz9C84V4H_9yyc7rGljpzn2yKn_fObn0",
            "176":"1eOoupSw-Fn6X_juT7CLfGg5QrlENf0Oh",
            "177":"1hiET5VvVdrgmOF18Cd9JsMGAUf1ACmnx",
            "178":"1iCoJTRXMyrJVEM7PT7v0QZbnMPFkPT5v",
            "179":"1iSYFiDk_O8YHvcS6rJtVgbleo5TCa5Jt",
            "180":"1ibAxTa9ZypVS7UBGpdaqx44rGM1gGxEd",
            "181":"1j5yIUrx4222sJ_BvfmrLA5PVIhpS5wBl",
            "182":"1kHM3m_39FodMT3QPzh06Myd0Kd6Mu3oU",
            "183":"1kVXFyT286JRziRS4r7Crll7C1qimHiIB",
            "184":"1l7ppckFaWkvbNYPsfcidn0WW7sMJ-zKD",
            "185":"1mfC4qO2h7fk756n4X3nQkAD_tYiI1Dla",
            "186":"1pdioozgUINCbxxH94T3bSggMBstiYIon",
            "187":"1piSdMwCK7rPBoTOF8dpBdkPRMSQXj1zn",
            "188":"1rUX361-G2TMuJF35DLsMWAdwzz8WI-rX",
            "189":"1rYLnzB3rLneZYOrA2vi4U-Ukq1l40aL_",
            "190":"1sQFXxnARqQHaCRGSk7poqw-7kWM6OxhV",
            "191":"1snk5knkaGjuLKrTIYAfm--j5235qQ3-r",
            "192":"1uEYIw-BvH6elJoBZd2ocvgH7vB_kS6HV",
            "193":"1uTQr79J9WROcefq66c3_L4qApMloHcp9",
            "194":"1vpzjcgsLu3fG1zI-C3wlJds3R5BKR7XT",
            "195":"1vw7XmxGYzjwvy44JhESmvhzWq_qLua4i",
            "196":"1x6zoBrUz8-VV8TcLd1lgne3nZT6uyvUh",
            "197":"1ylMcUFbHG_85aqEdvdP5B6VGDEHRM2xz",
            "198":"1-U8TRyO3NKLgKls7efAToJXLb40EsIOr",
            "199":"1-V7JFgHFBADTRHWEIqKR1eWR5RLBvKZX",
            "200":"10qfOSQS9DkKVpRRjT1rfV-Nh9Ezgm54k",
            "201":"12FSalT5y2IVfB5eqX7N5VQkZuzolIFOX",
            "202":"16QgJRqkefONzc4mizI8EtmH4DxPhBsNE",
            "203":"183jVKP3Bh9hom5zxEC3xK9SkwNqjKZzY",
            "204":"18BzHuaJDhVcp1HQj5oEESNqpVftxvd_G",
            "205":"1B59LlR2N2eFRXfHn6rI145L2yWCTR0ji",
            "206":"1BKcu4HM_3kvGd5REfi3d7cz6o7VXDQXe",
            "207":"1Ctxym5sc1_mbBjpPZDBbfCe-zmBu8pNt",
            "208":"1FU14sTQqkXGtfU7In0qmkBVXjT8v2qbS",
            "209":"1HRzcHXMpqIRH1pK7I_BrB8hIZl9D7v61",
            "210":"1LqwZ9bjrzgM2xF0__-duWNn814MDxNlW",
            "211":"1Lws6Tx1GlpgY9S_op73QG3-JAIhDdQff",
            "212":"1MqNjIcXroKipxzRk1MmqbUcTmGUKAXQ7",
            "213":"1MtIE84XFdfFxhCivFJ4ElNqZo4Lv6K6o",
            "214":"1ObFQ_WBBkCURwpFQ9MYOkmq7UamicJ_Q",
            "215":"1OemEuzB4tzIiHH8IVytVmQpJWuAh1g55",
            "216":"1Onjie0l4fPMW2kXwjRpPhhTnwZNUDVoh",
            "217":"1PeaRIyxLvnJ5ta3qSii-qE7elFzgrCha",
            "218":"1Po2-312LxzUaH_iCyGJcW_f6b528oYcK",
            "219":"1R3UmOyuS3S-3KoJOqTRcGhFNgkdXJkNL",
            "220":"1TKhb4LiOnGxrgQ11LUUv1gc7wjp4JWVj",
            "221":"1X6bT55o469oOafSPrIbG8WyHC1LiptGA",
            "222":"1YGxpMoY8JRZ3QqAwxCq0KkZed6Yc0j_z",
            "223":"1Yb2K0RphQYYKoL8IAgANKp67s_yIP_kX",
            "224":"1Za2bqilOqbO9mcKrN04RqnBjMuFYuqsv",
            "225":"1czuWaKwldIqrQ3TtErCQ7zkCwiVOcy-G",
            "226":"1d2bUX7zcv5sY7EuPlT1Fj1ryvc_YAPXL",
            "227":"1dcOvHU8-w0TPyh9PZGdf8s0Sua9H72Fq",
            "228":"1di_CaLodK5C1WxKW-CBPDNLOFj5XQF7p",
            "229":"1e7jrWxDRCsrmKkoNVTwQoii55VItcCI_",
            "230":"1eNlMNLlzIDfyZPQKU_Ej5qGK9_z_errg",
            "231":"1eOJk6jyxPHc6ERpSRRfrC4nugv7gMxCJ",
            "232":"1kxvrYCrhIksZxaExIANymdEvI-lT5_qM",
            "233":"1lq03Do7vmBHpi_ocAMBtS_Z1O3_TP19a",
            "234":"1lsy5iI7ucDdEKLgvUFciXHWPUf-RIato",
            "235":"1pykQaLK0lIWTJqQRPsP6GH61Ums0t0EI",
            "236":"1rnolVyDOyOL1PKKmp8nhx-MLXiCfPC_a",
            "237":"1ufsFuGjMIF66XuoO21RVK7ANRaskXdLd",
            "238":"1vnRC3Ufqe_U76rQt21BHn-oJ6IxDzco6",
            "239":"1zcK-U5yxZOSgT-BX1_B49y8jfoV79Gob",
            "240":"1-H3NPawBJ3sGs9bYSD8kFa3hW_ASxEX5",
            "241":"1-yr_kbYgH3-GbiAAgpnHB0saI22r561B",
            "242":"10nX6jfYavBzw_Bt2rysRso73TILvU8Sv",
            "243":"10o4a7frEkkdNiTTr2vlV346YoibxDId-",
            "244":"12iyVpxx8yW8nfgi8vCVJ14Xod8ihUvWg",
            "245":"12v_9L4XAPMmPo405wEPIKEeDcVfhS4Dy",
            "246":"14boVeSqyZ9BIlOZ1T-SnXNfPFUrqQWQj",
            "247":"14lGlSlP5FqkVC3G65iqdTGwf8pnCocov",
            "248":"1527qQocHHkH-WYtZ9McKYJknUM9KG_Pw",
            "249":"15lgBH0JEWvdvwuu2NTCOgranGd10gPQn",
            "250":"17AOPBebs4eLuEsLo4BiIWnBeslUb4UOM",
            "251":"19WG0y6LFJxF15daOtoWSB5vbK-1Eox3R",
            "252":"1BA4XztMMnngiY6EWZV5abrU0ADAT663G",
            "253":"1CQmcZYBLGTed7k_dq0GrS8OGrvOjGH8z",
            "254":"1CffPZDHlud2CDfNrmpqNRxvBQEiCFbsP",
            "255":"1D0lVzhkLWigEHXBbqAW7qClqvM-DiL1A",
            "256":"1GRDmOjtSYF2w38zJ6iOr2h-xP4BVZonJ",
            "257":"1GWpAMT64Jj6n0z32NRzcLTw97HWMDjgr",
            "258":"1IvEYcxprJ7PnzAZ-nzWcuXzZ24hjinf2",
            "259":"1KX4Um1U6__01vkEma-D9jylHqcpHhNjL",
            "260":"1Nk2IuFAdWkQqIbPzvCUuGBW-9o8BEu57",
            "261":"1Pu2K8pYrCH2pa6dA_f20Do3NJlnhtQgj",
            "262":"1RAf0EvbbOcYLP2CrNpAMy5sQ0BkDPm-f",
            "263":"1WwRKvju2UFxPZpE53PJoGchbviLkD3do",
            "264":"1XxpV8ekzEH2PGxe2YPDigUzNoJbSaB8Y",
            "265":"1ZJ5SdauHklob5FHJf30T1-83RRrFujnA",
            "266":"1bOga9UxNzFIMMs16RSyKLw8rvXZSTizv",
            "267":"1e-DjCHrL51gNFeD2mC0N19hFziZncQkE",
            "268":"1ioq1OuzX9wbjW_4-4pdoARjy-zU_hDOB",
            "269":"1lARzR1TNPSeF6zPz08CHwrq4fdEW7nZm",
            "270":"1mcbfMLh9iX5PDIEMvYRZ4uL_cFCOx570",
            "271":"1nkf5N5DAVGeOIm_1JwoK9Nr2PAx-LxCn",
            "272":"1nmj-SHN7n1CTESNQXjiLygdDx0WFvSyB",
            "273":"1oqs9DH8XnQn9zfYZMMgYhcX8nSNboogF",
            "274":"1qOWlNX1IYP3iRadH0Q16kSxI9twnU_a4",
            "275":"1rSYoUL7QB0oq8uBGwElIcUtJmDCuoWMx",
            "276":"1xsAIIIboGlcq4yTUoqSYZvOOrpYHPAZW",
            "277":"1yI_pMDqL7OW_cvKN98uNjLMFsU23biBw",
            "278":"137cQvlILccZf333D_C_18HD1eK1HzsIO",
            "279":"1501LIvFTfzmfZHh5hllmjoIkJMmQ5TFC",
            "280":"160z7qjSIsbVM94uXp2z5L82d9LMHRwUN",
            "281":"16oS2o-_RF_eQJP5rP6qkcgvQeWqlvKO8",
            "282":"17OMrawHtqG7OnNXj77WuevEa5zSSou8I",
            "283":"18s8uZdLWoDjRcp-9Jbm_0lBt69PQISBR",
            "284":"1BSyvmp3s9LYiUU71JsgLn-cXmaB6U3nK",
            "285":"1G5ZKHJ83f34Dj9fdPXBg3OqgqDww8yFc",
            "286":"1GRkNQxPilLXkSAD38IEJVl49-QDJm-Kd",
            "287":"1HVm3JZFcUXkkUhtBN2JO_nBoUMXwA4YN",
            "288":"1JS5V8aAN2XY1L7aQ9nwxthnvGqSN6jDI",
            "289":"1K3KaTLM7zIkphwssx1NmFT2fUn94EPSf",
            "290":"1Lywt0FRijaBR4T52yhD_FPP_QReQXEV6",
            "291":"1OUMD0jPqQ8o2LYzL1BPRfuU2ZtNyYUY6",
            "292":"1PZhassNcEfCjdPUpS8xmHZVpw2gQke28",
            "293":"1Qord_zvuPcO11Et1qcFpyDAbAA2tEIv0",
            "294":"1RjNAmkmh5qEpOOiV9dppYlMghBo-XhJt",
            "295":"1SIrsvGkrnrzQl2OZjk9WzRuITryHMIR2",
            "296":"1T05h00sfe9LAeJTtSW25OzrGKL-R9cPe",
            "297":"1TNI7p5ax8s4QLeFbXrlT6suxh3_UJsEp",
            "298":"1Tbz5aHZlXUT7pBFk9Ys2Zx2K212qfOA5",
            "299":"1VteXcyju9kOsPW_F3seixRlOIhbdoVKM",
            "300":"1WhBtOWyVnlEF0JQ-68mhz-GnSh6L77tZ",
            "301":"1X1aMUkZxXuUASUA-ccdOwAr3R-6ucLXJ",
            "302":"1ZMDM0Vxwsahz-xmkQifyKdlHRV0XIlYE",
            "303":"1Zg_Lq7vB78wJ1GHN7jM9NBr5st2UlEIX",
            "304":"1ZjK7pbf1V9q8WP4HkermaUMSUI5umL6I",
            "305":"1_pxzf16ZdorZhbzse1WkRy2bcM11NB2U",
            "306":"1dYWcMQrPRra_ir5YJrhVMC2SF6Rgs8rt",
            "307":"1egUE7pvgypiSQ-8-U4y5BfvDkww2GuoA",
            "308":"1eouG69Y80ta9D6igWEjWgWMoVRJ8uRjT",
            "309":"1hIyP_Vg6KVQDyyytrZDQsP5lG9krlzP5",
            "310":"1hwkUSqMT6xFQF1VZSULuUv9uTihhVt6G",
            "311":"1jfXVV_xDMLjsoMwxyr79n3j6vtAXiw3o",
            "312":"1k_rZGKdYLRJJOe42pbugKRfIZc0dLmLj",
            "313":"1lCDu5fflvkAvc8mmZr2k5-vHBnaIgn9K",
            "314":"1m52btDrz7yvR-4wuomEmU_hK7t-wBMsl",
            "315":"1n0yySH2W6d3T5jsZ2sF2VaAP7oipIYzS",
            "316":"1vJjgdVQ-2FTT_DhfTy4Z69PdZ5nOd3Bw",
            "317":"1wLvQaOnETSMr170ktkcc8cLs3anKD2i9",
            "318":"1yO6FKIVOHgO5BDcRdgMNASMh9Fh6kPHE",
            "319":"1yhzZ71aroqEng5JLdTHsowEv3WDHjfF9",
            "320":"1ynLDKWpR-YbBR7oVaMr3vEEHLgvsOlii",
            "321":"1z4Uwp1ZicqSKa7MnXtifzGh5RB1tBXXe",
            "322":"1zBmT2YvPvFvEPB-7AvP6s4tTCQufAcbz",
            "323":"1zEHddssTGFsOrkRcpg0Z981XEMZnYR74",
            "324":"1-TFUM2vWaQa-Pk-oLExSWaMR75JUN27w",
            "325":"1-Ti-qa8LlJkeiEAUlrwbrraDZHx-Wqwi",
            "326":"11gB669d6FUXIbEn-YVnEo0nlcTlSvJww",
            "327":"12FPBgAjEjRdguUHApXiazLATJlFmsA6l",
            "328":"12UR5C5b6j7Uav1WGa386C0D45kRgPLjy",
            "329":"13v-pkIVKqP4SugY5RYRHMePJaHhS9d2k",
            "330":"140SfUt2iBrKRChJC5wk58y-DJbKNBKaA",
            "331":"15Feqewa06HuClK47oJRilODnctFBcIXl",
            "332":"15GYKddwyW3nmi1om78h_1NO8IqBiKS0y",
            "333":"16hKaAAimKghKa1Gvk8_w5hnM9tg1dKIg",
            "334":"1721r_bpXqfqpdE7SQPPcLRQzQ7OFMroF",
            "335":"17XweWxZYVZJNKely5bvNcrsO2CLvUx_u",
            "336":"1AxCsnhBGI3hJ1f6WVjdWMiNTaYykArJe",
            "337":"1D3jwLS7FPliAxEyXG9Cp_Xlsp1WdxERV",
            "338":"1EQwR15OMbGAdDV2iU2MbXrBSrMIb_51L",
            "339":"1FOWEc2jPhFnp78urhkc0CgMZUGf2g946",
            "340":"1FdApc1mU-rWJvRmGeuxKM0dPLbVqy79z",
            "341":"1FrBnQuy1V1bLMsot3EztekPono4Gi3y4",
            "342":"1FtmC3OaUSsUrtBUCHV8ZoTEYqaP5HUln",
            "343":"1GsZLocUs_G_NBUJXFaiTA5P7d5C_hrkC",
            "344":"1H1feG9fMj5RDEi0fIEnvsR4Bx78OdDhP",
            "345":"1HUYxFPnFw7ACtnCcUfrGHKtwdkNDbdrW",
            "346":"1IZRsMuGLFdsPKjI6PwesGu6nbWizZPWR",
            "347":"1IwWcAidtvBUVQppACj1lBndPOsQz5xIH",
            "348":"1Ku9C7qiPbsAWsr2WKmbZcNFpuAspzhh6",
            "349":"1M_0EXKDDBCj5_nbX0IIGsoRB6_Fe8S69",
            "350":"1PGuwhWmRSWLuG8PytRp1L-73m5aDrDzF",
            "351":"1SW02on1E28CLj4fv6aLeWHutlcCoGTB1",
            "352":"1V2A6-xXbPeHX8iI8iQEmm1DIYMZNkHSU",
            "353":"1XUWMKhJnaCMyB63Oo44-4vRBd3rYmbEY",
            "354":"1YL4o5bywrLzAj-cjQbKnKyratwxn1g22",
            "355":"1YRWiT_j74CeYGbwzZbQ0VRNnPrEz7Slm",
            "356":"1ZLw4pdFeFfK91-1XoJrsqjGzQmq3-43R",
            "357":"1ZhsLiv_p6aQ3QpWW3RirQoNvnf06E-_-",
            "358":"1_NVD_qY08mmKUNIR8DPSVmVv3H6Wf95n",
            "359":"1_QYcqwXdGCDZ3yLRPEV98-nCWkxaehBY",
            "360":"1cwtYCvLajH7RTBEafJEOxEANaRGV-NW-",
            "361":"1e4AdhgRxlCKHwjLGwi8iMTKzcaC9cWyp",
            "362":"1edLtHT8mrsu5xLut_TClSEZHDEgBR9__",
            "363":"1iM5T9k7emaH1lGm16aXnottS_MMTzJdX",
            "364":"1idPHAjjz0FTfpkzwBif6Bn-8qJdKzge2",
            "365":"1jXu2LdmfSNS9bPBEOSVVNYA_HTuo8b7X",
            "366":"1jYrysTfVBR_flZpbiht_1Pf4j5-2Obux",
            "367":"1kuZADorb5MDEifyoYguwQtAk7_vr6K9p",
            "368":"1kxiseO0zz3uXQuRrmMYvBQQyY-SpG-8A",
            "369":"1mBaEYPWH5zVwgQYh0fMv7lW55Ha8HaWv",
            "370":"1mFBlODzfOO0Ntjqr4j6XUBQ1tFfBlUwS",
            "371":"1oTzTSSIpa33A2BSvsAZo0eoFwtDUgZBx",
            "372":"1qr9uTzSge2OymAxrcWCIE-aFK9k60abw",
            "373":"1t7xo7n0kBkp4A94CM6AFWx1UrhKz9P4L",
            "374":"1t9mXrcx1s12FOhBBwEfy0OnHB0-foRqu",
            "375":"1tEKv2Lvzq2ChGValvNS8Sf5foQDqKjnl",
            "376":"1uVxKQdSvR_PphtIpcqjxE2F89rXHrvx2",
            "377":"1zw_eWtlu1YZG2uwmFy1o54BnDofOzIkw",
            "378":"1FteCv6y0hZ32dpesSy5UayOe329CwB45",
            "379":"1U6FWO9ZmmU3XxbF2xYifnQ1Do2-zS1py",
            "380":"1W4dTFRAZarC3lEzQoic9RUaqKaqUCY2T",
            "381":"1Ymq1uXdCSZ9xZf9TNM4WGoQwWUgILRee",
            "382":"1-i84OCT1dcOoaj1YD8rHOd_q2YVS6AZZ",
            "383":"1-zqbCmE3QnYirt3rd1_V3fEdMu5qxbts",
            "384":"128i0n4G-X4K31AdYV-n2nDy-FpxHqEYH",
            "385":"12uvTwkznmTGlxa0_AtLa_tywO0x9QqUv",
            "386":"16by2ESZE1XZeKFOh8233T6SsSkmowRCm",
            "387":"18WN2YMq3EfC4-hOoPNz6HNzFG0QIJa31",
            "388":"18dM4dqJZTyz8W7YuTWd-4lMizt5aLWVY",
            "389":"1BWllc0bvnqtywscoUjZ8dqIlJqjknQWq",
            "390":"1EGn02DC7Zrgm12R4oJzlny_F4nEUCDtB",
            "391":"1EjRvMheAmlz5brp9F2kXmmSw6tXsTZkD",
            "392":"1GNlYD96Bqdzzj7W4ROEE1LDiU07zAvZ5",
            "393":"1I67rQPFRXPFGZ1B6eSARSa61sPwP7Uv8",
            "394":"1JuejjprREAlX_zuXf4xXqhsvPS1FaU-8",
            "395":"1TpAJbHdYy2KKA0834tDElA2HlW19Ll8c",
            "396":"1UYvQe0OngvUPDcmDiAoZoWHdTsK1NEsd",
            "397":"1V4M3coBwwVrBgdf7z3CQgm6df9UGFu7r",
            "398":"1Vup8Ue5BhJtdFSp20ak6sPTTFqmyXrjb",
            "399":"1XBBONTFNSXLpuNjnTdWErtUklExc_wqw",
            "400":"1ZDxnoW_ir8qXUNg6mCeZGJojNu7RA6kW",
            "401":"1ZEueJrAuAxWy_HFKilqogIpWS9yuR-pB",
            "402":"1ZKcFqGG545cJxrin0t379pBdoC_fmz3v",
            "403":"1b076eJrhR9l5034bo2guKjx26Znz-3Gw",
            "404":"1b3F0l2gsIjZxILkRi3uU7xigw_4KrsFw",
            "405":"1eMnDzZH45jzIO3VKgSeDj2P7bxTqVkDg",
            "406":"1iw_iql4FpNP75z8srnsFY5jpLTQ0WLzS",
            "407":"1k56ijlxusuHOefLokHsqGryCroe65Upr",
            "408":"1ka4k0qYxJ6XbDBEeqp4mwL6hElBYcOAy",
            "409":"1l4YTWSeIu4cyyV7OqgxRPEIBt3a-XbK4",
            "410":"1lyshMMAX9YhWlPTRe2OA_AaCnHktGmjt",
            "411":"1mcPpyRjZ7aCxZkmAKIMlR-XU4en0CRwu",
            "412":"1qHeOjKQSHVVUxKW7z36BBYgMSia9r2_e",
            "413":"1rXwkJhHTogwKut0fFbh-_AunFWNIyYWx",
            "414":"1sBBvJCJNIb1_QGnluWd4GoySZcaIaksC",
            "415":"1sZ3VZTOHGKFtc9mG0tUmxRw0tMRv19J2",
            "416":"1uZroQCES784tyeu7BUsXJJM9kNzX2xxN",
            "417":"1vB0VKrern3ykdfSPk3I6O61jDrod2vg9",
            "418":"1wDsr5rAjuAGSv4pRDu5AFMJDpWiUIimO",
            "419":"1xOV6U2jJJsW2wpvsvJ8hUB0e9xrQaBk-",
            "420":"1zWPrEurUb4VL8Ag9dLAJkV7cNHdwdh-8",
            "421":"124uKW1kHPYvT92dxue_pxI5IAAKsf91i",
            "422":"1D7wrgXua4IJy4KEwTBnXr6lTT-28t4b1",
            "423":"1IsYIRaeC9ibvYAupq0MoKloWiR-sWyYJ",
            "424":"1SPKV2g8hbujcXqV_4toytsTMeJia8I7c",
            "425":"1YKxhghe610xCKo7B9zKiMAADgJEWU_w6",
            "426":"1bdCYDKNrrIzGlsUOYiEzabyJiM_-iE4j",
            "427":"1ib2alocKac0AoY23wofKZwJ18cQ-3aqV",
            "428":"1j-nQ49JypUNWvQ4Qj27MnGxGhn3iEbDT",
            "429":"1lrePklEroT-YmoQoBtRu3odrM6co0Jou",
            "430":"1najmKFn0ka7O7UT3u4xRIZROwh24cInu",
            "431":"1p1_vBmvSV0-I4lPn74ANmhr_bm3300Wh",
            "432":"1pjjjdJffKKZarAlHrpuJQlbJLKlnW_UV",
            "433":"1sA8VJKvho3E-ScLDBzDlCYf2iCzEDVk3",
            "434":"1wFOj3YFk9SJfUAvtRaHt16VqNF1VT5Kh",
            "435":"18LKZBycXeyQQUoSd9aLS1UyT0iw1YE2V",
            "436":"1AsBhA7rgodAZNVFYVEeXx23a4PIWuDSY",
            "437":"1B7Z371-9ca9zjS493xtVvoeRzE-TIHtY",
            "438":"1BKFRlZ0m980rzC1VFenDNAmgJSeyeWFe",
            "439":"1CrLEDsrvsO6wSPKxMQDf-9OSAxiEVW4B",
            "440":"1EjYvvOJ80m3SYpPEO8TPtKTLWlt1Uf7o",
            "441":"1LlB3DsMGubUC2M8ZkcA5hPtZ15oYS6WK",
            "442":"1MsPSSOy2x6x4S_MZldFaLPhLVKVWxJ8F",
            "443":"1O4Y3lQyOb3IoMd8dhsX9vtxL1GMU0fY6",
            "444":"1OthGJZDk8TRnknVGpu0rINApXrDu5myj",
            "445":"1R-XRMa0ZYsVXNkAN69a6r6ap950tAlwn",
            "446":"1aGnoX1EvKjdK7K7XO36keGoqJDeycwq4",
            "447":"1bC_lipeAs0ZZtHvhrGc2NqRSH3HkH3R3",
            "448":"1ddqDdaXZujBkvkJjS6uRlFT4_YVGLIwZ",
            "449":"1fRja8ky8WOR8qEqw0O3bzAMTnm82Kpvx",
            "450":"1iXTTY-huDK0GpaOHOXrF1hvTXn3-B7Dr",
            "451":"1kSFwogrpuN2bJyC9EdmdV8U_2DyBYiDq",
            "452":"1q2Jg6LpruE_NSykOEU-H6KByu2bq4LFt",
            "453":"1wGDPNc2Ow3rWdzCw68yGQ7ST9iu0vEoQ",
            "454":"1yJ3_fQnsFvZ-YU1ehWLS3kJ5ElPGNegO",
            "455":"145dVuUfGbBGywdcQIJJELG-OV_HIZG5r",
            "456":"12Pjgaoh40REMlo4Ko1jhTCeKuo4jIFRh",
            "457":"12hKY8bL0QLo9Ap8tIjuq2nTXw621bxOE",
            "458":"14mUBKbsyniHpnaW6u4YSGmRCNofCLEDo",
            "459":"15ss_S3W5zfwJfAzli05dkJKnh27uFUA6",
            "460":"161-EUCy8k46Jfyet9KXDhRT264lE0Mvw",
            "461":"16yv0BSuwa2kmiHsdAHaMiOMsB6h_pBTr",
            "462":"175nE8tUxGxj05npQfnBV_sMk2cIJNvIK",
            "463":"19toSSFAu5Dzq7OzXBOIIB2nM0aAn6PQn",
            "464":"1AlcDrC1tWZcHJBBzC7eVPEkdU39KaOEI",
            "465":"1Btg3pvCbDVFq0Uka6pxdZak8j7--K0F-",
            "466":"1D5ITHm-WI_vFXdUnD0e_ycrtm2C-ya_l",
            "467":"1DX86Gcm5pvfwAka2MU5w9IMnpHV6YtAY",
            "468":"1DornMlfoLJc8hJqP4iXwqh7edBQzrmuP",
            "469":"1DyHvv5GORs2B39ikvs1SheD--rliQ-Kj",
            "470":"1ESm1IEscUb2EKVUW9CXt-_EMVBRWUnij",
            "471":"1EzyiEYjCAhD7ZgDK-u2UvPL0APNVp9sg",
            "472":"1KxCmFkyRBLepnwGyh6RkHBfdAmhxjuwf",
            "473":"1QwDJdZoYTY9yJ_H6p-xRdwtBkoDfE17n",
            "474":"1R41DF-8OZgM6p09_p9mdwk1x32r_4ucx",
            "475":"1Rz_81M-olE7QsnCiuaumksBHkAUxhxOa",
            "476":"1UAKeBNbQLR2uETPaOaOcazTPjpTjFHxt",
            "477":"1XzGSl-dmuyzNmVXUYxuXKCCqaIgPNYMu",
            "478":"1Y0zfIuLO7K8_PggCfURtZ6wzod0zc9eN",
            "479":"1YOPFevWdpRd7C2Dt2H2VXcpapaAUZ3pM",
            "480":"1ZY2myrvHS-xwLf6Xn34mRyuzK5hdW9cZ",
            "481":"1_S8I4UoeGUMSiDdsIQQR0Y1_dwNvKGOV",
            "482":"1b5AyAS0l3_IyjGaFyEvhXBjYyPljPQtc",
            "483":"1dGMMYHppxcCTdNc8kmr4T818bG_wmu2C",
            "484":"1denw6ZvHFvYy9qaJqxJtlfN9x8bFlzlm",
            "485":"1ejlkVPmtjVv2NtNxdRvf-9sg0MPnbR9D",
            "486":"1fxWM68L1vSpEdlEAM4_4W2YO2WQO4FPy",
            "487":"1gm8eEDlD1I6_KqUS9tnOcZtWMgxJTYo4",
            "488":"1jeHnB2aZe0buRdrVOl9ricglorVJJ065",
            "489":"1jjiOhtQ5M1fz7zmO-NIMyoz0nC79dccN",
            "490":"1lC7CUHoxgfbeZKvYFmwaeVPrBYGidJ1u",
            "491":"1lI6-PfuPeTJ7gT1Js1fLxBtvz-dxQ103",
            "492":"1lxiOLRJc1GddOFaQovRtepa8QF1ndXG-",
            "493":"1mE3j8OB-lU_pw0N0cH62s0Vks7-9Prce",
            "494":"1na2lkbl0qco1uuvop6rwbuq11tDxjJEC",
            "495":"1njZQ7k3IkkFRjt7p8AfZwE6mTr-Hr9xR",
            "496":"1nlEzKYjmHEasG99mtiJqZVNniyvy11Hi",
            "497":"1nw7la3PPNtNHnHajk19lNyLtmD_JBkGf",
            "498":"1oPlwrC3XR026H0txPBEnE5aJnfh6lzpr",
            "499":"1p9POJp6XphCVsKliu3ENFCG9eBB8nek0",
            "500":"1sGIEklK3R1i3oV2jyWV6Q2cymKz6pgnI",
            "501":"1tH6wKhit1KVPXM5KYHUOPFoDqb7wk8al",
            "502":"1uZ1rUyF76L64KOp9LmNbizkpVwjCqenr",
            "503":"1ugzzVYiR7TRqKh2FXprZAKuWoRIw-XzJ",
            "504":"1vSPp7v983hFJwhFTuaW5BRqjFAHEXZpM",
            "505":"1vX0ORIUHSGl9TskheXxCc9K4t5Ywps4L",
            "506":"1zHr8nVV0elO_35YqTgakBsBV_Y5rEosO",
            "507":"1znvRF3F6ns1vw2CsZ1190ypqxlYwbD7s",
            "508":"1CoxAAKa9flA3q7FP_GwLkHAw822GIWXy",
            "509":"1Dh8fd9q8Vw8jhrJU6HW-hg4pKUDCFq3I",
            "510":"1PJ-0_c11x5zcMLIxiNdKZAhUwbIwQxlX",
            "511":"1SZWiCQovJ4-787DUjAGGAvLH-_3AJZl3",
            "512":"1Xos_c6QhVRH9z9P9yBUmaGjWCfs5cHvN",
            "513":"1bjoE3bBGqoct3h1CHGUftiYpraAuJGWY",
            "514":"1iv8jUC4iHIs4BvE2mpecA1yugzlyaaQq",
            "515":"1jknyRqBlxLWr0RNtQNI2XNlqGfn1a8LM",
            "516":"1nA77p95WcYhFznLumMgKv0aNmWHAFfj0",
            "517":"1ox3h7cUy2Sunzm8zoRW-dsiD7fFsN-67",
            "518":"1s7tjhclufFMC2VmHV7JTYzQkJdrGPBuO",
            "519":"1szlSx6AFgMgkn7DHoyagkQ3OIW3NZvDO",
            "520":"11Kjx1p0hH9NeMzBwMhsv5WiB_R-elGXe",
            "521":"14rG8-JlL3KmAxVh5Dg_fXEGUlja3qQUY",
            "522":"17QqJYAAo0YfdfU-jNDTXHEMvSVMYkRRI",
            "523":"18tDBVOJBjOjrCvdMW9CuVaEbba3ZqSyI",
            "524":"1C3Ud95gvHE_loyBqbEiPBiI9mkOifcaj",
            "525":"1CNWBLxOadgd-uHn_ixD5E2H8hQwmX1mI",
            "526":"1EOP3tS5-cRPxOieIIIOSfWX40NRagd1_",
            "527":"1F-T5kGm5i3fridT5gObH8nzbWjYWMQHv",
            "528":"1GUV9laZCnEr_vf9vkUBAaFhdJzCPnmv0",
            "529":"1G_BNgeNTvM-1JriU0li0mkdUsoQZZ0sf",
            "530":"1HQI2qa_cuihzhUfSjIDIiHnJfg673EGj",
            "531":"1HaJJzizhiQ5kRNtmJkPdlID4KbiPW23u",
            "532":"1HpCGs2PQOaabGSkag9kPCvlFOloKv7Mr",
            "533":"1IV296VbvQ4Tdv1A6U6N2FxOf0QU4913t",
            "534":"1KXqAQVtY66rbrETelLpnzsfGZv3HOFWF",
            "535":"1L0PazX_yMw6QJo4nwWyl85uuNmcXySrh",
            "536":"1MG-JPK99CroYmMAlFoG626H9f2T0_UES",
            "537":"1MR5R5VhuOtCqmi93e7p3CjhnW6YpJeXC",
            "538":"1SB68YJHDFc0wprdu0wMcNU6EfUvlVxY8",
            "539":"1SavMld3jMLhG0HntAT1aCtbKXgy69NPQ",
            "540":"1XXQJv36fB_D4aAJojPCRro6nsbd6sYlA",
            "541":"1Znvd6DM-nVmMgtRprWcyEGWxNiHPDFur",
            "542":"1aJNh5d9RuFax7ppv5nKVve3VRzOtqhBZ",
            "543":"1br3uiZ7E5BwFE43mq5_fbUyd4mPaLcN1",
            "544":"1gbPr1k1hTB2o_Da-DXYRN9E91TVex-6g",
            "545":"1hsGUvMtlyWGvphlQQF01phzG4M2K8coA",
            "546":"1kgCuvfim7kYPoqddp8FoWjyOSQU13xog",
            "547":"1sEBy6U7m2p5F1o3X57ZckfDJFrhPeRxc",
            "548":"1sw_3CbJKWDM3xxIlY_Ntbq35htywadRD",
            "549":"1uWoLGY3qA8UPGD9x2oMkHkhLRAqKCxmh",
            "550":"1wiy7gZqKC7NIzYLb02v7KoINpJA9T9VZ",
            "551":"1z26I9NELgUqfepMyWD1GUQDV-PjVUzqb",
            "552":"16JbZnZ2QylcextdySLZyzueLVasd4ZDq",
            "553":"16_AMmQCwhHQ39BAvga8B0q9kKHftTUd2",
            "554":"18Tay5jxpxRY9cN_ZR1MEchbtkV3Buet8",
            "555":"19NOEXfQci4x4DBtFF7VEMplq8-ylX1Ch",
            "556":"1ADGHu4xlxwMYlJf8J8k5EywvUa51WN-q",
            "557":"1Eex3nsuArl7Nd3t1aotgxjucYg53n8W_",
            "558":"1Lc4fgzLUchhkQmivKnkoG65LJAEzaNnG",
            "559":"1WB7N-G_ehMgg4ME8C6kBik7DwtrSHF_K",
            "560":"1XQsiKGVq0Q6p29iA33bcr2zrl33otOLv",
            "561":"1bdy36s2k7Jzi1nc59XNRHoSTzyMDDo-U",
            "562":"1dFXzI65MYSpbuNCgRtVGoiQrZX_x6prV",
            "563":"1hp4L6I5tj_LVSVh8PpMxDxU7cWTGnCQZ",
            "564":"1iWLh76U_s5A1TEV-NrfhdQaZE4cszXpu",
            "565":"1kfjxqMxyHSr-TnHqF5ykQE5gbe6aIR0P",
            "566":"1mIVJHhr_NTfv1Ny7U86zx84Ye90d3XSE",
            "567":"1oHkqXmspWS_mHxEK0REuk3vvyv5AKZIr",
            "568":"1pKGeW6yfAMsmROv-UXiYg251s1-jLEwy",
            "569":"1xnNdwx3yEk8VeKtnCk3Gig1ylNsJTBzk",
            "570":"1zCRA5fwZZFVj61rlpocK5Vkk1vY7iMH-",
            "571":"10jLDi70LYPlN4UXuCac2otUBx_GLjnhs",
            "572":"11cbPwebOZ4ThPr8VsFrkhkQY70D4X1yI",
            "573":"183r-kPIF7So-6tLQdE9FtTVJ12-BBmO1",
            "574":"1HnqlzMDvlk11Itd5SrMRn_w6wS8LGsS7",
            "575":"1NxzCTuSAP-pb7bUnmQbAuaAMrvAhaMTb",
            "576":"1QQmcLPGaXzyQju3AWpsHUdwOCCB6D4YT",
            "577":"1Sqstzgf8aAxLSLxNly8fDjR6CWDXJ3d0",
            "578":"1Y9H0_ZEKTmhgN5wvEHxZETWXkC_R_U4T",
            "579":"1liRRbNMT7V6jY4at1HgPtBHwmr8HARMM",
            "580":"1qO-3X21fVCvWhZt4SwnkjhZstS1ZcAuS",
            "581":"1twzrAHyhkszSa7SpWZewJ4Im_Cd5RJ33",
            "582":"1tzeYLCdml83ExqG96ySm9bWVt-po-KGs",
        },
        
        SUNYARAGI:{
            "1":"1exNbvVDXaVfAEVXwJdfRuHg4ygTQwVt0",
            "2":"1KQYCNSW5DBCUf5VOeQttA6jhELMCfQNT",
            "3":"1QQpLhtP_yRzrLSelpo-zxzaXZZiktYeD",
            "4":"1RhOddqeRKaNR29L3Yb1MNWA7wFZMsJeG",
            "5":"1XPjLtO521S1wKkVnBHJ9FfPImdLKiGKf",
            "6":"1l1DFHM3_ocXRby2WiaZp6pmR7jS9vvjA",
            "7":"1B_VUdH4YcImCR91vONqqfnYp2pX28XlM",
            "8":"1QtrfmrOEdrLHPMqIAQp1Rx5yUDc_v9Z1",
            "9":"1SNisCNE423FyVxNNqi0MptNikE7mii54",
            "10":"1Y_HpZM3H-d9T3IYmfQcE1YmIEN6lXLRI",
            "11":"1jUAYvG_9qzX5O-dzYWQcn3w8J--4gODf",
            "12":"1-ba9SEa63kCxQ32x6_IhE9MeT_S6_kY0",
            "13":"14NDJMgZ3PS7Qn1k4VSY04jA9TAfSkNOs",
            "14":"14le7qsGneGkverkMko-PDNpIlnogcaDK",
            "15":"14zxRfjnjiVvJyAxYFZxkLqmHfPYq89lU",
            "16":"15ZejhXahO9jGR3Q0oA0YZs-uVRVutjns",
            "17":"19zSfCS6wcAA3i7k_JAbBj3O0A1giofkK",
            "18":"1BupAmYw3cXo2vZIK9dgE2rFxUkQp26Pa",
            "19":"1JHl6GUCLSI1lOFuVCO3B7Sc207-itPjF",
            "20":"1KOyxMekbA_xF5DkS6wPYao_ttuUVa7sU",
            "21":"1WR5JBmHG3dpOcfFWhGBU7A4i7Irl21_Q",
            "22":"1akCcJ1c5pc2W3UfJUhhRpwUyM9E2ZcUu",
            "23":"1rNO5GGr-kEQr9FKGmU1iN69bXTnXjI2t",
            "24":"1uyaTzoTn2KgKpnjfYoZCZ83tNle85eF2",
            "25":"1-C5532-p5y0QMc5OaU6HlBrUBRmhqMol",
            "26":"1266ktsqv2NLr5Mhqxxrf6n8h2TFUR-OG",
            "27":"16huHqRY-Tj2IdLF4VgkuJl5ZeaFp2_8x",
            "28":"18_9JL-yt_pIzxJgLqcNizNYcSoQe-YOa",
            "29":"1ATTITz6KOP58u0CTv06ybhuW0HjylyVS",
            "30":"1AYP8V8DsIzyxbt9Q_OqozDf9xDW-ZhU9",
            "31":"1GFleIoUdQ5nUNP48P97cLn1-NVa5KJqu",
            "32":"1H3M3Nv9qpq8bimdCuWGEa0SRjddMtjIf",
            "33":"1NAVbTi94L076ijbJRUC45LTqcv8I4zFi",
            "34":"1NYXQhZzAh1oe5qYiu5e829fkuaHs7Lm2",
            "35":"1RztvRMBPmHL6mm8Cxs8WccmRAzmoyd5v",
            "36":"1SAoJV6nF4vnwdbAuKuaoB-n4rlVHZdvy",
            "37":"1WeNDwwJsIYqKeVF2fi5vFJjTYezsaycZ",
            "38":"1XsXH7_3VY8chrQVh6XHD_ChjB2SieF4a",
            "39":"1_HuEmIrDCHwXv7hqZ-8y3-iA6rhqnY7k",
            "40":"1_WENHYVRSmGNCB708RC93IFVZVPAlRV3",
            "41":"1apsJy8MB2KIr6MuKTVVh9HKBsOUF4vsD",
            "42":"1c2-yXQ99cOZWzSih2nZbPwQuPY4-YUBI",
            "43":"1dCjDpZAaBwHkZAVNYCpUaLz9aXl_T9k8",
            "44":"1fSpKJqaE7juam1OSfdZQ9kbRmGol788L",
            "45":"1fYlycS6_0nseIBf6DacAeWULHJfj09uk",
            "46":"1ho8ghWjv57pzc-wWikjDkmsOv0bproXI",
            "47":"1k4BEQ3gi6urn92dYG6e5efUQSmUgwfG_",
            "48":"1kAzUOpd5kPOykKhwwNz8ntqZtits8XWq",
            "49":"1kiHw6AHeiNBx_FZyjqdNVSt-4HRSErVn",
            "50":"1n7ci23rm8KVF6eFvJxrx6v_xZGQirIB7",
            "51":"1p_b0Oz7ujjJgyI1DC_ndlgGogVO1QSxB",
            "52":"1qhYZeTWAgrPsyrIYfxsaR01ouY1NcEMb",
            "53":"1rRW1TUA8KBw-qp-7qPmHgZcUxQzLpEj7",
            "54":"1shMCL4C2LknBAIrxgfloHx_9L_CgP9ZF",
            "55":"1svU1XmOX81fLr1vxAlnIHM_K5Fpw_U8K",
            "56":"1yV9zlDtb4P2w5NA3CYWHgjr6ZfSO-OjR",
            "57":"18MugvZjY8y-PdYonVRFLJNbnYp4Vh_8H",
            "58":"1WHVDsj_k6dyOeWcuAsUhOz21yNcrCJPq",
            "59":"1g4sHjvG-dkBCS2H5UvC9Rq1xM2w7nZMF",
            "60":"1tw-D5nhdan1lrWFxkZOkbdKv6B-VwRA4",
            "61":"106Qs-lwGOiP5Qgx1s4aefgUkOAUPbAxY",
            "62":"12NWWE7L-EwY_WeV4uGpkB0XN2a8rqGaa",
            "63":"19K2NLj83I5WtDXKPJuR1q_TrBkQ4YTCJ",
            "64":"1B2gZmhX52nH8zhvnBiBYIlf9vWIACV3Z",
            "65":"1C_zjJ9iUlwAoTs18PUbq9h1WlE9xKfmG",
            "66":"1Usw5kuPhSeI5nNZFDHwG0eWOSXmKRT7p",
            "67":"1ZIMRhovmaeZ0tfXvZr2QFcHPg5B7r3Ru",
            "68":"1jjQ8vYE1oPt7ruAaDzj_cxJAQNN9_-3J",
            "69":"1kci1IhcfPiaC-KaYscJP41BnimrUQsp-",
            "70":"1li_WqQSF4joZWgxQx_9UspEHqopbv8b_",
            "71":"1-ICE_kl13fXlgJI08p2IhTX-sRfCPGs6",
            "72":"1-JNWbzS0cIGri8axy0gUUjpe5_bF6xBM",
            "73":"11qergBvl-4Vsi7YX34vsmzdgY1RERFfh",
            "74":"12GALUEkZkMsak7OfPIFQVmFl2LhNOk6L",
            "75":"12LFpIv_jwuu6yZuG0nMgXP3UC4pqXLEb",
            "76":"131feyhXGlbMM7FgFbvvzXESSGB2-cfDv",
            "77":"16td_6fpugijTZ-wMHSPSr7217sSm2vrv",
            "78":"17YYS4ZYLEzeEYlR_NJflWF9PTKAmVmy3",
            "79":"17wJWaz0yD8fBLAd7W-aEhobJpIl5NwQE",
            "80":"18fn4VncCRJWrFQJFkeqs8nefLOHbgGtp",
            "81":"18vru0qaOVAOMrhgUV_JUCGE3sfnRGATi",
            "82":"19LzYpkLpDqGOGxkxs4rfoIQLrRFaGD3z",
            "83":"1AOh7h_Vv3RmVy-Duoxy0iv-J536dZ8QG",
            "84":"1CTXX6rJei5ptGmSR14pMxMFylPtvO2tC",
            "85":"1DaoX9n6mI4JKmVAy7iKDrbgKF5SVhSKL",
            "86":"1GBqxumWbxzVMEjQ9tomvljLEDaatC8lB",
            "87":"1GiMhGdpLapCfs3yL6uRb9tROKX6xWpCF",
            "88":"1HPEaXe8wR_YDopb_pLhdF_RCTMA2L2_6",
            "89":"1Hk_xx__GQ7TI4f4dOlQ0zSCBoduaD54T",
            "90":"1JkFNzfObG6_pXujJjWnlCGnCXwxkGC37",
            "91":"1LJi74A74R58j0urQPguiAP3BP77xI4V3",
            "92":"1MDs9ZR4fqOJa923wVz-1cSzmfHPRdCs6",
            "93":"1Om6SJXBcXoUijEXgZYlzrvNszGIoQ1Zb",
            "94":"1ScsdzdLsFowrY1TqKAEJ3MLBcdtXlTdH",
            "95":"1SrXDRt8fgQ3FM8BnG9-pnA1A_ynOMEkj",
            "96":"1UFf3eigYyMw5BvTVgDvxNW5N0g2yKKXV",
            "97":"1UUJGaKCjZnSwiM2WN64E87myyav-oHok",
            "98":"1UeOMXLmwksq2ccepMZAlxdIrm8yX2-vV",
            "99":"1VQGWdfiKrToD90eQ5u1_eBWqvu-bUIT0",
            "100":"1VU3RgrUH90BjLFPc_XPMXseWTSMCn4F2",
            "101":"1W5AN7OnH6SjU9E1VJfFFG35b0Y6S9z9p",
            "102":"1WBJu7zMouGG6JTEO4Pgfw7pGtvHYi1Kb",
            "103":"1YPYKyLplu9QMW9dS0hlM5rfl745h1wgk",
            "104":"1Yep-nuN07hpQBnFO-LexzWN_xnxNEdvW",
            "105":"1YlTf4sZWG2UNLfauARtKLCX3TXVOekAW",
            "106":"1YtA9heftzAAdVkSUjEoo-b_s4kfcNA36",
            "107":"1ZGQH25cTBwV1js_QhksyaD6_9rDrXU0R",
            "108":"1a5poVfq8FuesJO6GyYxWs743INjMoE0c",
            "109":"1aK1TaTIhxdgTAExLY-4BmnK9E4BHvKyH",
            "110":"1ayNlYnY228_b77-DsG1qO7JtmKWk2FZc",
            "111":"1bCGX3lyhII4ka7V9GZB2Z07EQ-es32ul",
            "112":"1cA4V4cOtFCuWTGPyzWS_0Z9v9mM03OAD",
            "113":"1cRV_tc8cOtC6dgldgE47n-_U9hUAm5MC",
            "114":"1cwmXCnvXt1D1Ie88IyNaqyuurLinetNI",
            "115":"1d9ftCyxwtSuvzRrLUr5jkzu4RZRbqUrB",
            "116":"1dEbP7gkD2WhRIhdUkGfHivvJkvWsMgFN",
            "117":"1eC0BWbiTu8xVI4mUv2Wi30yvJKt_Vdbu",
            "118":"1eIs-A2QYY7A9ygTyw6-mTfn3H7yDywVr",
            "119":"1eqHkHSWiAgadbGXnhXqwGomE1l7IiaIN",
            "120":"1ghpEmk3FZfKpyedSFtSrap__60D2gmSe",
            "121":"1gu4KeU5zQ6R55OdTNVFHdd_7uYpXpw_4",
            "122":"1laNu2bDGmuSih2JvMqeATubuqOggjy95",
            "123":"1lk68V98dT2Hn7UJ4EO4TWZIHD8tO3QsW",
            "124":"1nugsyT00kp-CdqvqWD-6wLME-_mX5ni8",
            "125":"1pCuOSVdowWHqNOzsvH79CheimqnQTA_J",
            "126":"1qtByDCAZr10Sz4_FdRpkqPcOSTbaH-mL",
            "127":"1t6v3KUCtfAfLOr9-gn8Ikz4cTgYkzHua",
            "128":"1vOjZZs8SSvw3yhRl_k5g-N8g6Ru3fdYX",
            "129":"1wMEsE-U8jNx3-DSvKmzKiXiaZ0f13he5",
            "130":"1yZZZXira9S9Af1kf_KqOSKL0Zx7T1dUI",
            "131":"1ysXuDvKsIQrK2K8gqpR-KokUZw1UqUIS",
            "132":"1zDXdxZ0vvz2Y1CDdltdHbIWhXMHxg5Ax",
            "133":"1zEOltF4o7vJZJh8pNZvzyJqttTenKMag",
            "134":"15hNRDMlt82V_Cvx8-qu60DJ7yewkaqbZ",
            "135":"161Wn1n4b3LP5ofLLc1d2HC0pNcXDMACh",
            "136":"18wG_a6EBi0UBw7qAPewXDzIPRGVaqRQR",
            "137":"19BQ5YLs1ZgpnrmNaxHiiV7QdK1KaBdQ2",
            "138":"19QMYkhb4ODEyOJN2OspwllXju8_ykQSJ",
            "139":"1AbHVAWeCgumXR11-c4LHxN_KKJHcTsai",
            "140":"1DZO5uGd5Nht3Sv0kh32HQFWUqzt4MGJZ",
            "141":"1H8-U7lRqaAdYxNYxgSqBBdEw6-fm0Hnw",
            "142":"1HlOiy0ahmKVQ2cQXGEFcy1eJVOSFoTPp",
            "143":"1JwosTHMs7K40-4S7dfUiCD_BNJWmgMTu",
            "144":"1Lg9S9xtwaWaMY3adg6ElFfF9_J-sXKgp",
            "145":"1P4B_9uZ4P9LkKqgSLi1_VpfttRmrUcGm",
            "146":"1RjRBjXXVZR_tKiQTKC2qx2MbCt07WyZb",
            "147":"1T9mv7kmlVBRLB64omIrbSGs2LqHsIqG-",
            "148":"1ToZMr9dnDolZq-FNWXpxd-wozNvUVa-B",
            "149":"1_bwvOJxp35j9_GtVVlJM2RdJDArXsmaB",
            "150":"1bJfjMsjFGVO0PDcgmn52-75hYGHHp7i7",
            "151":"1djN94VxA3jZGkHyCBhoZ-dcHZcRbiqRY",
            "152":"1kLDAHNg96CKayH7m10H1IIxk8JoLPVEW",
            "153":"1kToLugnLFCd2KjQtZ1vlfoJpWiaZJfiT",
            "154":"1pgdanpeL3DUaDFVAXVDOIb_e5sYicqXp",
            "155":"1sFTwxxq0CkIvrmdOoSquLtSMf8iOQOg7",
            "156":"1situu-oi5XDrxXVX7z8bgHERdaS9soxB",
            "157":"1tmSULrfLQHlyl9EG0FSwCrawYaCy357A",
            "158":"1v3WapZrXN5NexxjalETSLjM2_BQ35Sa5",
            "159":"1wki2qFBdF5W4EX_gHyeLjnDj2I6w538q",
            "160":"1z710IARHC6bzqxmuQvTNXD3ADaZGysGt",
            "161":"1DO4T_hjmvY-7TELBY9wmdFqHZO0Cy8-W",
            "162":"1aWFJFzK99GAWS3I9ftW1-I01NJP57246",
            "163":"16XcooZlkhxoMByoqp2Rm62aOI9ugcTHf",
            "164":"19--IWMVBi9fIWZYztqYeC3U9UUCe1Jqw",
            "165":"1Aw-7So7EOT2GBwnc0EwEtL7nqwmuSy_V",
            "166":"1C7JTv0-hBDqeSaf61n-ceGXXrycOhwIM",
            "167":"1EQVBx_2w3ck6RxECDFAlbExJ1pd9p7zT",
            "168":"1JyYaFmTkfibVqeyA2HSJ2bmlQIOa-Ya9",
            "169":"1NoXYS4jT7o-n4I7PuejGG1TDA7SAYy2_",
            "170":"1PQQAJZZA5xaDhbBUSdV6Gpk9WoIh9Iyl",
            "171":"1SvKHn1nixRdwPAQZx5wforvCPtFDYZCz",
            "172":"1Z_PtvPZ0iP2zgML-lwP48_W8jh2uD9nL",
            "173":"1_0nQWZhKNhVc42gpLBMyamVlqGYFlIJW",
            "174":"1bb8fRbtmmhrlPvPvv15TXBqc2r_uG3Dr",
            "175":"1iDU5oGPsPsj63Dg5BorGQ1DouVNuki1I",
            "176":"1lDTAJ0-RFopY6GMOblavzr8GOzGr_tBR",
            "177":"1lSFJ8GjXCsCT7hVqerzL69YseevhlD73",
            "178":"1qo1jvz8-XzxJC7Oi4zZDhbG828y4lU5Z",
            "179":"1s75OYlOutS7gfzgwjNKLM3_89IoFP5R4",
            "180":"1u82yJDjsZvnv43sb0JwkqeqcGKFy6gW1",
                    },
        
    };
	
	
 const photostockData = [
  { id: 'BITUNG1A', label: 'BITUNG 1A' },
  { id: 'BITUNG1B', label: 'BITUNG 1B' },
  { id: 'BITUNG2A', label: 'BITUNG 2A' },
  { id: 'BITUNG2B', label: 'BITUNG 2B' },
  { id: 'BITUNG2OUTLET', label: 'BITUNG 2 OUTLET' },
  { id: 'BITUNG3A', label: 'BITUNG 3A' },
  { id: 'BITUNG3B', label: 'BITUNG 3B' },
  { id: 'BITUNG3OUTLET', label: 'BITUNG 3 OUTLET' },
  { id: 'BITUNG4_OUTPUT_SKG', label: 'BITUNG 4 OUTPUT SKG' },
  { id: 'BITUNG4_STREAM_A', label: 'BITUNG 4 STREAM A' },
  { id: 'BITUNG4_STREAM_B', label: 'BITUNG 4 STREAM B' },
  { id: 'BITUNG_KATALOG', label: 'BITUNG KATALOG' },
  { id: 'CIKANDE', label: 'CIKANDE' },
  { id: 'SERPONG', label: 'SERPONG' },
  { id: 'CIMANGGIS', label: 'CIMANGGIS' },
  { id: 'NAROGONG', label: 'NAROGONG' },
  { id: 'PONDOK_UNGU', label: 'PONDOK UNGU' },
  { id: 'TANGSI', label: 'TANGSI' },
  { id: 'TEGAL_GEDE', label: 'TEGAL GEDE' },
  { id: 'DELTAMAS', label: 'DELTAMAS' },
  { id: 'SURYA_CIPTA', label: 'SURYA CIPTA' },
  { id: 'BONGAS', label: 'BONGAS' },
  { id: 'SUNYARAGI', label: 'SUNYARAGI' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'Photostock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
				<div
					style={{
					  marginTop: '10px', 
					  marginLeft: '-30px', 
					  width: '200px', 
					  maxHeight: '600px',
					  overflowY: 'auto',
					  scrollbarWidth: 'none',
					}}
					className={`custom-scroll ${isHovered ? 'hover' : ''}`}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				>
					
						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock background new2.jpeg`} 
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_sor1;

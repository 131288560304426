import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logout from './Logout';
import Select from 'react-select';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import $ from 'jquery';

const HomePage = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);
  const [jalurData, setJalurData] = useState([]);
  const [selectedJalur, setSelectedJalur] = useState(null);
  const [selectedClasslocation, setSelectedClasslocation] = useState(null);
  const [selectedStandarddimensionratio, setSelectedStandarddimensionratio] = useState(null);
  const [selecteda1exposure, setSelecteda1exposure] = useState(null);
  const [selecteddepthofcovertypepartydamage, setSelecteddepthofcovertypepartydamage] = useState(null);
  const [selectedimpactbarrierpartydamage, setSelectedimpactbarrierpartydamage] = useState(null);
  const [selectedsignandmarkerspartydamage, setSelectedsignandmarkerspartydamage] = useState(null);
  const [selectedPatrolfrequency3rdparty, setSelectedPatrolfrequency3rdparty] = useState(null);
  const [selectedCommunicationinformation3rdparty, setSelectedCommunicationinformation3rdparty] = useState(null);
  const [selectedGeneralpubliceducation3rdparty, setSelectedGeneralpubliceducation3rdparty] = useState(null);
  const [selecteda3resistance3rdparty, setSelecteda3resistance3rdparty] = useState(null);
  const [selectedExposuregeohazardrelateddamage, setSelectedExposuregeohazardrelateddamage] = useState(null);
  const [selectedPipelinestabilizationgeohazardrelateddamage, setSelectedPipelinestabilizationgeohazardrelateddamage] = useState(null);
  const [selectedGroundreinforcementgeohazardrelateddamage, setSelectedGroundreinforcementgeohazardrelateddamage] = useState(null);
  const [selectedAvoidancegeohazardrelateddamage, setSelectedAvoidancegeohazardrelateddamage] = useState(null);
  const [selectedInspectionsurveygeohazardrelateddamage, setSelectedInspectionsurveygeohazardrelateddamage] = useState(null);
  const [selectedDitchmodificationgeohazardrelateddamage, setSelectedDitchmodificationgeohazardrelateddamage] = useState(null);
  const [selectedb3resistancegeohazardrelateddamage, setSelectedb3resistancegeohazardrelateddamage] = useState(null);
  const [selectedExposurespanbridgerelated, setSelectedExposurespanbridgerelated] = useState(null);
  const [selectedGroundreinforcementspanbridgerelated, setSelectedGroundreinforcementspanbridgerelated] = useState(null);
  const [selectedCorrosionprotectionspanbridgerelated, setSelectedCorrosionprotectionspanbridgerelated] = useState(null);
  const [selectedInspectionsurveyspanbridgerelated, setSelectedInspectionsurveyspanbridgerelated] = useState(null);
  const [selectedspanbridgebarrierforpeople, setSelectedspanbridgebarrierforpeople] = useState(null);
  const [selectedc3resistancespanbridgebarrierforpeople, setSelectedc3resistancespanbridgebarrierforpeople] = useState(null);
  const [selectedExposuresabotage, setSelectedExposuresabotage] = useState(null);
  const [selectedstucturalstabilizationpanbridgerelateddamage, setSelectedstucturalstabilizationpanbridgerelateddamage] = useState(null);
  const [selectedcommunitypartneringsabotage, setSelectedcommunitypartneringsabotage] = useState(null);
  const [selectedintelligencesabotage, setSelectedintelligencesabotage] = useState(null);
  const [selectedsecuritysabotage, setSelectedsecuritysabotage] = useState(null);
  const [selectedresolvesabotage, setSelectedresolvesabotage] = useState(null);
  const [selectedindustrycooperationsabotage, setSelectedindustrycooperationsabotage] = useState(null);
  const [selectedaccessabilitysabotage, setSelectedaccessabilitysabotage] = useState(null);
  const [selectedd3resistancesabotage, setSelectedd3resistancesabotage] = useState(null);
  
  
  const [kpnameData, setKpnameData] = useState([]);
  const [selectedKpname, setSelectedKpname] = useState(null);
  
  const generateRandomNumber = () => {
    return Math.floor(100000000 + Math.random() * 900000000); // menghasilkan angka dari 100000000 hingga 999999999
  };

const capitalizeLabel = (label) => {
  
  return label.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const [formData, setFormData] = useState({
	
	//baru
	Operationpressure: 1,
	
	//
	
	Jalur:"",
	genrannum: generateRandomNumber(),
	Kpname: "",
	Kpname2: "",
	diameter: 90,
	segmen: "",
	kpstart: "",
	kpend: "",
	Classlocation: "",
	Operationpressure: "",
	Standarddimensionratio: "",
	a1exposure: "",
	depthofcovertypepartydamage: "",
	impbarrierpartydamage: "",
	signandmarkerspartydamage: "",
	Patrolfrequency3rdparty: "",
	Communicationinformation3rdparty: "",
	Generalpubliceducation3rdparty: "",
	a3resistance3rdparty: "",
	Exposuregeohazardrelateddamage: "",
	Pipelinestabilizationgeohazardrelateddamage: "",
	Groundreinforcementgeohazardrelateddamage: "",
	Avoidancegeohazardrelateddamage: "",
	Inspectionsurveygeohazardrelateddamage: "",
	Ditchmodificationgeohazardrelateddamage: "",
	b3resistancegeohazardrelateddamage: "",
	Exposurespanbridgerelated: "",
	stucturalstabilizationpanbridgerelateddamage: "",
	Groundreinforcementspanbridgerelated: "",
	Corrosionprotectionspanbridgerelated: "",
	inspectionsurveyspanbridgerelated: "",
	spanbridgebarrierforpeople: "",
	c3resistancespanbridgebarrierforpeople: "",
	Exposuresabotage: "",
	communitypartneringsabotage: "",
	intelligencesabotage: "",
	securitysabotage: "",
	resolvesabotage: "",
	industrycooperationsabotage: "",
	accessabilitysabotage: "",
	d3resistancesabotage: "",
	
 });

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };
  
  const handleJalurChange = (selectedOption) => {
	document.getElementById('kpnamejalur').style.display="none";
	setLoading2(true);
    setSelectedJalur(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Jalur: selectedOption ? selectedOption.value : ""
    }));
    console.log("Jalur Construction selected:", selectedOption);
	
	const fetchData2 = async () => {
      const url = `https://script.google.com/macros/s/AKfycbx1Wcu0K9VvCSy-qDu-fuSl8PgM5KqpSGTS2eJGjf9rb_6tIM6vAj4Wx3GlY6A6nWPx/exec?prm=asset&jalur=${selectedOption.value}`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();
        setKpnameData(data);

        const defaultKpname = data.find(Kpname => Kpname === "");
        if (defaultKpname) {
          const defaultOption = { value: defaultKpname, label: defaultKpname };
          setSelectedKpname(defaultOption);
          setFormData(prevFormData => ({
            ...prevFormData,
            Kpname: defaultKpname
          }));
        }
		
		setLoading2(false);
		document.getElementById('kpnamejalur').style.display="block";
		
        
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData2();
	
  };
  
  const [riskData, setRiskData] = useState(null);
  
  const handleSubmit = async (e) => {
    e.preventDefault(); // Mencegah refresh halaman setelah form submit
    console.log("Data yang dikirim: ", formData); // Menampilkan data yang akan dikirim
	setLoading(true);

    try {
        // Melakukan request POST ke Google Apps Script
        await fetch('https://script.google.com/macros/s/AKfycbx1Wcu0K9VvCSy-qDu-fuSl8PgM5KqpSGTS2eJGjf9rb_6tIM6vAj4Wx3GlY6A6nWPx/exec', {
            method: 'POST',
            mode: 'no-cors',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formData) // Pastikan ini adalah data yang benar
        });
		
		const id = formData.genrannum; // ID yang akan dikirim sebagai parameter
        const getResultUrl = `https://script.google.com/macros/s/AKfycbx1Wcu0K9VvCSy-qDu-fuSl8PgM5KqpSGTS2eJGjf9rb_6tIM6vAj4Wx3GlY6A6nWPx/exec?prm=resultcalc&nomor=${id}`;
        
        const getResultResponse = await fetch(getResultUrl);
        const result = await getResultResponse.json(); // Membaca hasil respons dari server
        console.log("Hasil dari server:", result); // Menampilkan hasil respons dari server

		if (result && result.length > 0) {
                setRiskData(result[0]); // Asumsi result[0] berisi objek dengan Risk_matrix dan Risk_level
            } else {
                setRiskData(null); // Mengatur ulang jika tidak ada hasil yang ditemukan
            }
     
		
    } catch (error) {
        console.error('Error:', error); // Menangani jika ada error dalam request
    }finally {
      setLoading(false);
	  setTimeout(() => {
          document.getElementById('printmuhlbauericon').style.display="block";
        }, 5000);
    }
};


//baru
const handleClasslocationChange = (selectedOption) => {
    setSelectedClasslocation(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Classlocation: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleStandarddimensionratioChange = (selectedOption) => {
    setSelectedStandarddimensionratio(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Standarddimensionratio: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlea1exposureChange = (selectedOption) => {
    setSelecteda1exposure(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      a1exposure: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handledepthofcovertypepartydamageChange = (selectedOption) => {
    setSelecteddepthofcovertypepartydamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      depthofcovertypepartydamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleimpactbarrierpartydamageChange = (selectedOption) => {
    setSelectedimpactbarrierpartydamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      impbarrierpartydamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlesignandmarkerspartydamageChange = (selectedOption) => {
    setSelectedsignandmarkerspartydamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      signandmarkerspartydamage: selectedOption ? selectedOption.value : ""
    }));
  };

const handlePatrolfrequency3rdpartyChange = (selectedOption) => {
    setSelectedPatrolfrequency3rdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Patrolfrequency3rdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleCommunicationinformation3rdpartyChange = (selectedOption) => {
    setSelectedCommunicationinformation3rdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Communicationinformation3rdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleGeneralpubliceducation3rdpartyChange = (selectedOption) => {
    setSelectedGeneralpubliceducation3rdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Generalpubliceducation3rdparty: selectedOption ? selectedOption.value : ""
    }));
  };

const handlea3resistance3rdpartyChange = (selectedOption) => {
    setSelecteda3resistance3rdparty(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      a3resistance3rdparty: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleExposuregeohazardrelateddamageChange = (selectedOption) => {
    setSelectedExposuregeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuregeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlePipelinestabilizationgeohazardrelateddamageChange = (selectedOption) => {
    setSelectedPipelinestabilizationgeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Pipelinestabilizationgeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleGroundreinforcementgeohazardrelateddamageChange = (selectedOption) => {
    setSelectedGroundreinforcementgeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Groundreinforcementgeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleAvoidancegeohazardrelateddamageChange = (selectedOption) => {
    setSelectedAvoidancegeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Avoidancegeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleInspectionsurveygeohazardrelateddamageChange = (selectedOption) => {
    setSelectedInspectionsurveygeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Inspectionsurveygeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleDitchmodificationgeohazardrelateddamageChange = (selectedOption) => {
    setSelectedDitchmodificationgeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Ditchmodificationgeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleb3resistancegeohazardrelateddamageChange = (selectedOption) => {
    setSelectedb3resistancegeohazardrelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      b3resistancegeohazardrelateddamage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleExposurespanbridgerelatedChange = (selectedOption) => {
    setSelectedExposurespanbridgerelated(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposurespanbridgerelated: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleGroundreinforcementspanbridgerelatedChange = (selectedOption) => {
    setSelectedGroundreinforcementspanbridgerelated(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Groundreinforcementspanbridgerelated: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleCorrosionprotectionspanbridgerelatedChange = (selectedOption) => {
    setSelectedCorrosionprotectionspanbridgerelated(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Corrosionprotectionspanbridgerelated: selectedOption ? selectedOption.value : ""
    }));
  };

const handleInspectionsurveyspanbridgerelatedChange = (selectedOption) => {
    setSelectedInspectionsurveyspanbridgerelated(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      inspectionsurveyspanbridgerelated: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlespanbridgebarrierforpeopleChange = (selectedOption) => {
    setSelectedspanbridgebarrierforpeople(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      spanbridgebarrierforpeople: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlec3resistancespanbridgebarrierforpeopleChange = (selectedOption) => {
    setSelectedc3resistancespanbridgebarrierforpeople(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      c3resistancespanbridgebarrierforpeople: selectedOption ? selectedOption.value : ""
    }));
  }; 
  
const handlestucturalstabilizationpanbridgerelateddamageChange = (selectedOption) => {
    setSelectedstucturalstabilizationpanbridgerelateddamage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      stucturalstabilizationpanbridgerelateddamage: selectedOption ? selectedOption.value : ""
    }));
  }; 
  
const handleExposuresabotageChange = (selectedOption) => {
    setSelectedExposuresabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Exposuresabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlecommunitypartneringsabotageChange = (selectedOption) => {
    setSelectedcommunitypartneringsabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      communitypartneringsabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleintelligencesabotageChange = (selectedOption) => {
    setSelectedintelligencesabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      intelligencesabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handlesecuritysabotageChange = (selectedOption) => {
    setSelectedsecuritysabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      securitysabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleresolvesabotageChange = (selectedOption) => {
    setSelectedresolvesabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      resolvesabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleindustrycooperationsabotageChange = (selectedOption) => {
    setSelectedindustrycooperationsabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      industrycooperationsabotage: selectedOption ? selectedOption.value : ""
    }));
  };
  
const handleaccessabilitysabotageChange = (selectedOption) => {
    setSelectedaccessabilitysabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      accessabilitysabotage: selectedOption ? selectedOption.value : ""
    }));
  };

const handled3resistancesabotageChange = (selectedOption) => {
    setSelectedd3resistancesabotage(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      d3resistancesabotage: selectedOption ? selectedOption.value : ""
    }));
  }; 
  
  
// 

  const handleKpnameChange = (selectedOption) => {
	  //alert(selectedOption);
    setSelectedKpname(selectedOption);
    setFormData(prevFormData => ({
      ...prevFormData,
      Kpname: selectedOption ? selectedOption.value : "",
      Kpname2: selectedOption ? selectedOption.label : ""
	  
    }));
    console.log("Jalur Construction selected:", selectedOption);
  };
  
	
	const handleOpenMuhlbauerprint = () => {
	  // Simpan data ke localStorage
	  localStorage.setItem('printData', JSON.stringify({ riskData, formData }));

	  // Buka tab baru
	  window.open('/muhlbaueroffshoreprint', '_blank');
	};


  
  useEffect(() => {
    
	const fetchData = async () => {
      const url = `https://script.google.com/macros/s/AKfycbx1Wcu0K9VvCSy-qDu-fuSl8PgM5KqpSGTS2eJGjf9rb_6tIM6vAj4Wx3GlY6A6nWPx/exec?prm=jalur`;

      try {
        const response = await fetch(url);
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        const data = await response.json();

        // Map data untuk Select
        const options = data.map((item) => ({
          label: item.label,
          value: item.value,
        }));
        setJalurData(options);

        // Set default value
        if (options.length > 0) {
          //setSelectedJalur(options[0]);
        }
      } catch (error) {
        console.error('Error:', error);
      }
    };

    fetchData();
	
	//baru
	const defaultOptionClasslocation = { value: "4", label: "4" };
    setSelectedClasslocation(defaultOptionClasslocation);
	handleClasslocationChange(defaultOptionClasslocation);
	
	const defaultOptionStandarddimensionratio = { value: "SDR 11", label: "SDR 11" };
    setSelectedStandarddimensionratio(defaultOptionStandarddimensionratio);
	handleStandarddimensionratioChange(defaultOptionStandarddimensionratio);
	
	const defaultOptiona1exposure = { value: "3rd Party Activity (until pipe exposed)", label: "3rd Party Activity (until pipe exposed)" };
    setSelecteda1exposure(defaultOptiona1exposure);
	handlea1exposureChange(defaultOptiona1exposure);
	
	const defaultOptiondepthofcovertypepartydamage = { value: "48 in", label: "48 in" };
    setSelecteddepthofcovertypepartydamage(defaultOptiondepthofcovertypepartydamage);
	handledepthofcovertypepartydamageChange(defaultOptiondepthofcovertypepartydamage);
	
	const defaultOptionimpactbarrierpartydamage = { value: "None", label: "None" };
    setSelectedimpactbarrierpartydamage(defaultOptionimpactbarrierpartydamage);
	handleimpactbarrierpartydamageChange(defaultOptionimpactbarrierpartydamage);
	
	const defaultOptionsignandmarkerspartydamage = { value: "Sign and Markers Condition Good", label: "Sign and Markers Condition Good" };
    setSelectedsignandmarkerspartydamage(defaultOptionsignandmarkerspartydamage);
	handlesignandmarkerspartydamageChange(defaultOptionsignandmarkerspartydamage);
	
	const defaultOptionPatrolfrequency3rdparty = { value: "Every Three Months", label: "Every Three Months" };
    setSelectedPatrolfrequency3rdparty(defaultOptionPatrolfrequency3rdparty);
	handlePatrolfrequency3rdpartyChange(defaultOptionPatrolfrequency3rdparty);
	
	const defaultOptionCommunicationinformation3rdparty = { value: "Three Monthly Meeting", label: "Three Monthly Meeting" };
    setSelectedCommunicationinformation3rdparty(defaultOptionCommunicationinformation3rdparty);
	handleCommunicationinformation3rdpartyChange(defaultOptionCommunicationinformation3rdparty);
	
	const defaultOptionGeneralpubliceducation3rdparty = { value: "None", label: "None" };
    setSelectedGeneralpubliceducation3rdparty(defaultOptionGeneralpubliceducation3rdparty);
	handleGeneralpubliceducation3rdpartyChange(defaultOptionGeneralpubliceducation3rdparty);
	
	const defaultOptiona3resistance3rdparty = { value: "SDR 11", label: "SDR 11" };
    setSelecteda3resistance3rdparty(defaultOptiona3resistance3rdparty);
	handlea3resistance3rdpartyChange(defaultOptiona3resistance3rdparty);
	
	const defaultOptionExposuregeohazardrelateddamage = { value: "Remote or Never", label: "Remote or Never" };
    setSelectedExposuregeohazardrelateddamage(defaultOptionExposuregeohazardrelateddamage);
	handleExposuregeohazardrelateddamageChange(defaultOptionExposuregeohazardrelateddamage);
	
	const defaultOptionPipelinestabilizationgeohazardrelateddamage = { value: "None", label: "None" };
    setSelectedPipelinestabilizationgeohazardrelateddamage(defaultOptionPipelinestabilizationgeohazardrelateddamage);
	handlePipelinestabilizationgeohazardrelateddamageChange(defaultOptionPipelinestabilizationgeohazardrelateddamage);
	
	const defaultOptionGroundreinforcementgeohazardrelateddamage = { value: "None", label: "None" };
    setSelectedGroundreinforcementgeohazardrelateddamage(defaultOptionGroundreinforcementgeohazardrelateddamage);
	handleGroundreinforcementgeohazardrelateddamageChange(defaultOptionGroundreinforcementgeohazardrelateddamage);
	
	const defaultOptionAvoidancegeohazardrelateddamage = { value: "Pipe Re-routing", label: "Pipe Re-routing" };
    setSelectedAvoidancegeohazardrelateddamage(defaultOptionAvoidancegeohazardrelateddamage);
	handleAvoidancegeohazardrelateddamageChange(defaultOptionAvoidancegeohazardrelateddamage);
	
	const defaultOptionInspectionsurveygeohazardrelateddamage = { value: "Annually", label: "Annually" };
    setSelectedInspectionsurveygeohazardrelateddamage(defaultOptionInspectionsurveygeohazardrelateddamage);
	handleInspectionsurveygeohazardrelateddamageChange(defaultOptionInspectionsurveygeohazardrelateddamage);
	
	const defaultOptionDitchmodificationgeohazardrelateddamage = { value: "Annually", label: "Annually" };
    setSelectedDitchmodificationgeohazardrelateddamage(defaultOptionDitchmodificationgeohazardrelateddamage);
	handleDitchmodificationgeohazardrelateddamageChange(defaultOptionDitchmodificationgeohazardrelateddamage);
	
	const defaultOptionb3resistancegeohazardrelateddamage = { value: "SDR 11", label: "SDR 11" };
    setSelectedb3resistancegeohazardrelateddamage(defaultOptionb3resistancegeohazardrelateddamage);
	handleb3resistancegeohazardrelateddamageChange(defaultOptionb3resistancegeohazardrelateddamage);
	
	const defaultOptionExposurespanbridgerelated = { value: "Remote or Never", label: "Remote or Never" };
    setSelectedExposurespanbridgerelated(defaultOptionExposurespanbridgerelated);
	handleExposurespanbridgerelatedChange(defaultOptionExposurespanbridgerelated);
	
	const defaultOptionGroundreinforcementspanbridgerelated = { value: "None", label: "None" };
    setSelectedGroundreinforcementspanbridgerelated(defaultOptionGroundreinforcementspanbridgerelated);
	handleGroundreinforcementspanbridgerelatedChange(defaultOptionGroundreinforcementspanbridgerelated);
	
	const defaultOptionCorrosionprotectionspanbridgerelated = { value: "Painting Adequate", label: "Painting Adequate" };
    setSelectedCorrosionprotectionspanbridgerelated(defaultOptionCorrosionprotectionspanbridgerelated);
	handleCorrosionprotectionspanbridgerelatedChange(defaultOptionCorrosionprotectionspanbridgerelated);
	
	const defaultOptionInspectionsurveyspanbridgerelated = { value: "Annually", label: "Annually" };
    setSelectedInspectionsurveyspanbridgerelated(defaultOptionInspectionsurveyspanbridgerelated);
	handleInspectionsurveyspanbridgerelatedChange(defaultOptionInspectionsurveyspanbridgerelated);
	
	const defaultOptionspanbridgebarrierforpeople = { value: "None", label: "None" };
    setSelectedspanbridgebarrierforpeople(defaultOptionspanbridgebarrierforpeople);
	handlespanbridgebarrierforpeopleChange(defaultOptionspanbridgebarrierforpeople);
	
	const defaultOptionc3resistancespanbridgebarrierforpeople = { value: "SDR 11", label: "SDR 11" };
    setSelectedc3resistancespanbridgebarrierforpeople(defaultOptionc3resistancespanbridgebarrierforpeople);
	handlec3resistancespanbridgebarrierforpeopleChange(defaultOptionc3resistancespanbridgebarrierforpeople);
	
	const defaultOptionExposuresabotage = { value: "Remote or Never", label: "Remote or Never" };
    setSelectedExposuresabotage(defaultOptionExposuresabotage);
	handleExposuresabotageChange(defaultOptionExposuresabotage);
	
	const defaultOptionstucturalstabilizationpanbridgerelateddamage = { value: "None", label: "None" };
    setSelectedstucturalstabilizationpanbridgerelateddamage(defaultOptionstucturalstabilizationpanbridgerelateddamage);
	handlestucturalstabilizationpanbridgerelateddamageChange(defaultOptionstucturalstabilizationpanbridgerelateddamage);
	
	const defaultOptioncommunitypartneringsabotage = { value: "Not Effective", label: "Not Effective" };
    setSelectedcommunitypartneringsabotage(defaultOptioncommunitypartneringsabotage);
	handlecommunitypartneringsabotageChange(defaultOptioncommunitypartneringsabotage);
	
	const defaultOptionintelligencesabotage = { value: "Number of Acts Sucsessfully/Number of Attemps", label: "Number of Acts Sucsessfully/Number of Attemps" };
    setSelectedintelligencesabotage(defaultOptionintelligencesabotage);
	handleintelligencesabotageChange(defaultOptionintelligencesabotage);
	
	const defaultOptionsecuritysabotage = { value: "Not Effective", label: "Not Effective" };
    setSelectedsecuritysabotage(defaultOptionsecuritysabotage);
	handlesecuritysabotageChange(defaultOptionsecuritysabotage);
	
	const defaultOptionresolvesabotage = { value: "Not Effective", label: "Not Effective" };
    setSelectedresolvesabotage(defaultOptionresolvesabotage);
	handleresolvesabotageChange(defaultOptionresolvesabotage);
	
	const defaultOptionindustrycooperationsabotage = { value: "Not Effective", label: "Not Effective" };
    setSelectedindustrycooperationsabotage(defaultOptionindustrycooperationsabotage);
	handleindustrycooperationsabotageChange(defaultOptionindustrycooperationsabotage);
	
	const defaultOptionaccessabilitysabotage = { value: "Difficult to Access (Burried Pipeline)", label: "Difficult to Access (Burried Pipeline)" };
    setSelectedaccessabilitysabotage(defaultOptionaccessabilitysabotage);
	handleaccessabilitysabotageChange(defaultOptionaccessabilitysabotage);
	
	const defaultOptiond3resistancesabotage = { value: "SDR 11", label: "SDR 11" };
    setSelectedd3resistancesabotage(defaultOptiond3resistancesabotage);
	handled3resistancesabotageChange(defaultOptiond3resistancesabotage);
	//
	
  }, []);



  const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);

	
	
	const JalurOptions = jalurData.map(JalurObj => {
		return {
		  value: JalurObj,
		  label: JalurObj,
		};
	  });
	  
	  const KpnameOptions = kpnameData.map(KpnameObj => {
		return {
		  value: KpnameObj['value'],
		  label: KpnameObj['label'],
		};
	  });
	  
	//baru
	const ClasslocationOptions = [
	  { value: '1', label: '1' },
	  { value: '2', label: '2' },
	  { value: '3', label: '3' },
	  { value: '4', label: '4' },
	];
	
	const StandarddimensionratioOptions = [
	  { value: 'SDR 11', label: 'SDR 11' },
	  { value: 'SDR 17', label: 'SDR 17' },
	];
	
	const asatuexposureOptions = [
	  { value: 'Agriculture Areas', label: 'Agriculture Areas' },
	  { value: 'Village & Outskirt of the City Areas', label: 'Village & Outskirt of the City Areas' },
	  { value: '(or Insert a value based on History)', label: '(or Insert a value based on History)' },
	  { value: '3rd Party Activity (until pipe exposed)', label: '3rd Party Activity (until pipe exposed)' },
	];
	
	const depthofcovertypepartydamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: '12 in', label: '12 in' },
	  { value: '36 in', label: '36 in' },
	  { value: '48 in', label: '48 in' },
	  { value: '60 in', label: '60 in'},
	  { value: 'Concrete Slab (Re-inforced)', label: 'Concrete Slab (Re-inforced)'},
	  { value: 'Pipe Casing', label: 'Pipe Casing'},
	];
	
	const impbarrierpartydamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Chain Link Fences', label: 'Chain Link Fences' },
	  { value: 'Ditch', label: 'Ditch' },
	  { value: 'Waterbodies', label: 'Waterbodies' },
	  { value: 'Concrete Traffic', label: 'Concrete Traffic' },
	];
	
	const signandmarkerspartydamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Sign and Markers Condition Poor', label: 'Sign and Markers Condition Poor' },
	  { value: 'Sign and Markers Condition Good', label: 'Sign and Markers Condition Good' },
	];
	
	const Patrolfrequency3rdpartyOptions = [
	  { value: 'Daily', label: 'Daily' },
	  { value: 'Every 3 Days', label: 'Every 3 Days' },
	  { value: 'Weekly', label: 'Weekly' },
	  { value: 'Bi-Weekly', label: 'Bi-Weekly' },
	  { value: 'Semi Annually', label: 'Semi Annually' },
	  { value: 'Annually', label: 'Annually' },
	];
	
	const Communicationinformation3rdpartyOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Anually Meeting', label: 'Anually Meeting' },
	  { value: 'Semi Anually Meeting', label: 'Semi Anually Meeting' },
	  { value: 'Three Monthly Meeting', label: 'Three Monthly Meeting' },
	];
	
	const Generalpubliceducation3rdpartyOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Anually Meeting', label: 'Anually Meeting' },
	  { value: 'Semi Anually Meeting', label: 'Semi Anually Meeting' },
	  { value: 'Three Monthly Meeting', label: 'Three Monthly Meeting' },
	];
	
	const a3resistance3rdpartyOptions = [
	  { value: 'Steel Cased PE Pipeline', label: 'Steel Cased PE Pipeline' },
	  { value: 'SDR 11', label: 'SDR 11' },
	  { value: 'SDR 17', label: 'SDR 17' },
	];
	
	const ExposuregeohazardrelateddamageOptions = [
	  { value: 'Remote or Never', label: 'Remote or Never' },
	  { value: 'Very Low', label: 'Very Low' },
	  { value: 'Low', label: 'Low' },
	  { value: 'Often', label: 'Often' },
	  { value: 'Landslide/river erosion until pipe exposed', label: 'Landslide/river erosion until pipe exposed' },
	];
	
	const PipelinestabilizationgeohazardrelateddamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Pile Anchoring ', label: 'Pile Anchoring' },
	  { value: 'Concrete Anchoring', label: 'Concrete Anchoring' },
	];
	
	const GroundreinforcementgeohazardrelateddamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Retaining Walls', label: 'Retaining Walls' },
	  { value: 'Slope Re-grading', label: 'Slope Re-grading' },
	  { value: 'Erosion Control Vegetation', label: 'Erosion Control Vegetation' },
	  { value: 'Armouring Rocks', label: 'Armouring Rocks' },
	];
	
	const AvoidancegeohazardrelateddamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Above Ground Pipe', label: 'Above Ground Pipe' },
	  { value: 'Pipe Re-routing', label: 'Pipe Re-routing' },
	];
	
	const InspectionsurveygeohazardrelateddamageOptions = [
	  { value: 'Twice a Day', label: 'Twice a Day' },
	  { value: 'Daily', label: 'Daily' },
	  { value: 'Every 3 Days', label: 'Every 3 Days' },
	  { value: 'Weekly', label: 'Weekly' },
	  { value: 'Bi-Weekly', label: 'Bi-Weekly' },
	  { value: 'Monthly', label: 'Monthly' },
	  { value: 'Every Three Months', label: 'Every Three Months' },
	  { value: 'Semi Annually', label: 'Semi Annually' },
	  { value: 'Annually', label: 'Annually' },
	];
	
	const DitchmodificationgeohazardrelateddamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Wider Ditching', label: 'Wider Ditching' },
	  { value: 'Bedding and Padding', label: 'Bedding and Padding' },
	  { value: 'Excavation to Relieve Strain Loading', label: 'Excavation to Relieve Strain Loading' },
	];
	
	const b3resistancegeohazardrelateddamageOptions = [
	  { value: 'Steel Cased PE Pipeline', label: 'Steel Cased PE Pipeline' },
	  { value: 'SDR 11', label: 'SDR 11' },
	  { value: 'SDR 17', label: 'SDR 17' },
	];
	
	const ExposurespanbridgerelatedOptions = [
	  { value: 'Remote or Never', label: 'Remote or Never' },
	  { value: 'Very Low', label: 'Very Low' },
	  { value: 'Low', label: 'Low' },
	  { value: 'Often', label: 'Often' },
	];
	
	const GroundreinforcementspanbridgerelatedOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Retaining Walls', label: 'Retaining Walls' },
	  { value: 'Slope Re-grading', label: 'Slope Re-grading' },
	  { value: 'Erosion Control Vegetation', label: 'Erosion Control Vegetation' },
	  { value: 'Armouring Rocks', label: 'Armouring Rocks' },
	];
	
	const CorrosionprotectionspanbridgerelatedOptions = [
	  { value: 'Structuce Corroded /Painting In-adequate', label: 'Structuce Corroded /Painting In-adequate' },
	  { value: 'Painting Adequate', label: 'Painting Adequate' },
	  { value: 'Painting Excellent Condition', label: 'Painting Excellent Condition' },
	];
	
	const inspectionsurveyspanbridgerelatedOptions = [
	  { value: 'Twice a Day', label: 'Twice a Day' },
	  { value: 'Daily', label: 'Daily' },
	  { value: 'Every 3 Days', label: 'Every 3 Days' },
	  { value: 'Weekly', label: 'Weekly' },
	  { value: 'Bi-Weekly', label: 'Bi-Weekly' },
	  { value: 'Monthly', label: 'Monthly' },
	  { value: 'Every Three Months', label: 'Every Three Months' },
	  { value: 'Semi Annually', label: 'Semi Annually' },
	  { value: 'Annually', label: 'Annually' },
	];
	
	const spanbridgebarrierforpeopleOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Fenching (Bad Condition)', label: 'Fenching (Bad Condition)' },
	  { value: 'Fenching (Medium Condition)', label: 'Fenching (Medium Condition)' },
	  { value: 'Fenching (Good Condition)', label: 'Fenching (Good Condition)' },
	];
	
	const c3resistancespanbridgebarrierforpeopleOptions = [
	  { value: 'Steel Cased PE Pipeline', label: 'Steel Cased PE Pipeline' },
	  { value: 'SDR 11', label: 'SDR 11' },
	  { value: 'SDR 17', label: 'SDR 17' },
	];
	
	const ExposuresabotageOptions = [
	  { value: 'Remote or Never', label: 'Remote or Never' },
	  { value: 'Very Low', label: 'Very Low' },
	  { value: 'Low', label: 'Low' },
	  { value: 'Often', label: 'Often' },
	];
	
	const stucturalstabilizationpanbridgerelateddamageOptions = [
	  { value: 'None', label: 'None' },
	  { value: 'Pile Anchoring', label: 'Pile Anchoring' },
	  { value: 'Concrete Anchoring', label: 'Concrete Anchoring' },
	];
	
	const communitypartneringsabotageOptions = [
	  { value: 'Not Effective', label: 'Not Effective' },
	  { value: 'Quite Effective', label: 'Quite Effective' },
	  { value: 'Very Effective', label: 'Very Effective' },
	];
	
	const intelligencesabotageOptions = [
	  { value: 'Number of Acts Sucsessfully/Number of Attemps', label: 'Number of Acts Sucsessfully/Number of Attemps' },
	  { value: 'Never', label: 'Never' },
	];
	
	const securitysabotageOptions = [
	  { value: 'Not Effective', label: 'Not Effective' },
	  { value: 'Quite Effective', label: 'Quite Effective' },
	  { value: 'Very Effective', label: 'Very Effective' },
	];
	
	const resolvesabotageOptions = [
	  { value: 'Not Effective', label: 'Not Effective' },
	  { value: 'Quite Effective', label: 'Quite Effective' },
	  { value: 'Very Effective', label: 'Very Effective' },
	];
	
	const industrycooperationsabotageOptions = [
	  { value: 'Not Effective', label: 'Not Effective' },
	  { value: 'Quite Effective', label: 'Quite Effective' },
	  { value: 'Very Effective', label: 'Very Effective' },
	];
	
	const accessabilitysabotageOptions = [
	  { value: 'Difficult to Access (Burried Pipeline)', label: 'Difficult to Access (Burried Pipeline)' },
	  { value: 'Med to Access (Pipeline with Vent)', label: 'Med to Access (Pipeline with Vent)' },
	  { value: 'Difficult to Access (Burried Pipeline)', label: 'Difficult to Access (Burried Pipeline)' },
	];
	
	const d3resistancesabotageOptions = [
	  { value: 'Steel Cased PE Pipeline', label: 'Steel Cased PE Pipeline' },
	  { value: 'SDR 11', label: 'SDR 11' },
	  { value: 'SDR 17', label: 'SDR 17' },
	];
	
	//
	
  return (
  
    <div style={{marginTop: '30px',padding: '10px'}}>
		 
      <div style={{ clear: 'both' }}></div>
		
		<div class="row">
			<div class="card col-4" style={{margin: '20px 5px 5px 5px', border: 'none'}}>
				
				<div class="card-header" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
					<div style={{float: 'left'}}>
						<h5 style={{textAlign: 'left'}}>Result</h5>
					</div>
					
					<div style={{float: 'right'}}>
						<div style={{float: 'left', margin: "5px"}}>
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/Manual Book 2.png`} 
								alt="Alive home" 
								style={{ width: '20px', height: '20px', borderRadius: '5px'}} 
							/>
						</div>
						<div id="printmuhlbauericon" style={{float: 'left', margin: '5px', display: 'none'}}>
							
							<a 
							onClick={handleOpenMuhlbauerprint}
							style={{fontSize: '20px'}}
							>
								<i class="fa fa-print" aria-hidden="true"></i>
							</a>
						</div>
					</div>
					
				</div>
						
				<div class="card-body" style={{backgroundColor: "#646464", color: 'white'}}>
						
					{riskData ? (
						<div>
							<p><strong>COF:</strong> {riskData.cof}</p>
							<p><strong>Risk Level:</strong> {riskData.risk_category}</p>
						</div>
					) : (
						
						<p>Hasil Perhitungan dan Kalkulasi</p>
					)}
					
					{loading && (
					  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
						
						<div>Sedang memproses...</div>
					  </div>
					)}
					
				</div>
				
			</div>
			
			
		</div>
		
		
		<form onSubmit={handleSubmit}>
		
		<button style={{ margin: '10px 10px 0px 0px', float: 'right' }} className="btn btn-success" type="submit">Submit</button>
		
		<div style={{clear: 'both'}}></div>
		
		<div class="row">
		
		<div class="col-lg-4 col-md-4 mb-2">
				
			<div style={{margin: '5px'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Parameter</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							Jalur 
						</div>
										
						<div className="col-7">
											
						<Select
							options={jalurData}
							onChange={handleJalurChange}
							placeholder="Jalur"
							value={selectedJalur}
							required
						  />
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							KP Name
						</div>
										
						<div className="col-7">
						
						{loading2 && (
						  <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
							
							<div>Sedang memproses...</div>
						  </div>
						)}
						
						<div id="kpnamejalur" style={{display: 'none'}}>				
							<Select
								options={KpnameOptions}
								onChange={handleKpnameChange}
								placeholder="KP Name"
								value={selectedKpname}
								required
							/>
						</div>
											
						</div>
										
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Diameter (mm)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="diameter"
								value={formData.diameter}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Segmen
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="segmen"
								value={formData.segmen}
								onChange={handleChange}
								
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							KP Start
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="kpstart"
								value={formData.kpstart}
								onChange={handleChange}
								
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							KP End
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="text"
								name="kpend"
								value={formData.kpend}
								onChange={handleChange}
								
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Class Location
						</div>
							
						<div className="col-7">
								
							<Select
								options={ClasslocationOptions}
								onChange={handleClasslocationChange}
								placeholder="Class Location"
								value={selectedClasslocation}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Operation Pressure (bar)
						</div>
							
						<div className="col-7">
								
							<input
								className="form-control"
								type="number"
								name="Operationpressure"
								value={formData.Operationpressure}
								onChange={handleChange}
								required
							/>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Standard Dimension Ratio (SDR)
						</div>
							
						<div className="col-7">
								
							<Select
								options={StandarddimensionratioOptions}
								onChange={handleStandarddimensionratioChange}
								placeholder="Standard Dimension Ratio (SDR)"
								value={selectedStandarddimensionratio}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
				</div>
				
			
			</div>
			
			<div style={{margin: '5px'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>A. 3rd Party Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
							
						<div className="col-5">
							EXPOSURE
						</div>
							
						<div className="col-7">
								
							<Select
								options={asatuexposureOptions}
								onChange={handlea1exposureChange}
								placeholder="A.1 Exposure"
								value={selecteda1exposure}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Depth of Cover
						</div>
							
						<div className="col-7">
								
							<Select
								options={depthofcovertypepartydamageOptions}
								onChange={handledepthofcovertypepartydamageChange}
								placeholder="Depth of Cover"
								value={selecteddepthofcovertypepartydamage}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Impact Barrier
						</div>
							
						<div className="col-7">
								
							<Select
								options={impbarrierpartydamageOptions}
								onChange={handleimpactbarrierpartydamageChange}
								placeholder="Impact Barrier"
								value={selectedimpactbarrierpartydamage}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
							
						<div className="col-5">
							Sign and Markers 
						</div>
							
						<div className="col-7">
								
							<Select
								options={signandmarkerspartydamageOptions}
								onChange={handlesignandmarkerspartydamageChange}
								placeholder="Sign and Markers"
								value={selectedsignandmarkerspartydamage}
								required
							>
							</Select>
								
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Patrol Frequency
						</div>
										
						<div className="col-7">
							
							<Select
								options={Patrolfrequency3rdpartyOptions}
								onChange={handlePatrolfrequency3rdpartyChange}
								placeholder="Patrol Frequency (3rd party)"
								value={selectedPatrolfrequency3rdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Communication & Information
						</div>
										
						<div className="col-7">
							
							<Select
								options={Communicationinformation3rdpartyOptions}
								onChange={handleCommunicationinformation3rdpartyChange}
								placeholder="Communication & Information"
								value={selectedCommunicationinformation3rdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							General Public Education
						</div>
										
						<div className="col-7">
							
							<Select
								options={Generalpubliceducation3rdpartyOptions}
								onChange={handleGeneralpubliceducation3rdpartyChange}
								placeholder="General Public Education"
								value={selectedGeneralpubliceducation3rdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							RESISTANCE
						</div>
										
						<div className="col-7">
							
							<Select
								options={a3resistance3rdpartyOptions}
								onChange={handlea3resistance3rdpartyChange}
								placeholder="A.3 RESISTANCE"
								value={selecteda3resistance3rdparty}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>B. Geohazards Related Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							EXPOSURE
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuregeohazardrelateddamageOptions}
								onChange={handleExposuregeohazardrelateddamageChange}
								placeholder="B. Geohazards Related Damage - B.1 EXPOSURE"
								value={selectedExposuregeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Pipeline Stabilization
						</div>
										
						<div className="col-7">
							
							<Select
								options={PipelinestabilizationgeohazardrelateddamageOptions}
								onChange={handlePipelinestabilizationgeohazardrelateddamageChange}
								placeholder="Pipeline Stabilization "
								value={selectedPipelinestabilizationgeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ground Reinforcement / Improvement
						</div>
										
						<div className="col-7">
							
							<Select
								options={GroundreinforcementgeohazardrelateddamageOptions}
								onChange={handleGroundreinforcementgeohazardrelateddamageChange}
								placeholder="Ground Reinforcement / Improvement"
								value={selectedGroundreinforcementgeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Avoidance
						</div>
										
						<div className="col-7">
							
							<Select
								options={AvoidancegeohazardrelateddamageOptions}
								onChange={handleAvoidancegeohazardrelateddamageChange}
								placeholder="Corrosion Protection"
								value={selectedAvoidancegeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Inspection / Survey 
						</div>
										
						<div className="col-7">
							
							<Select
								options={InspectionsurveygeohazardrelateddamageOptions}
								onChange={handleInspectionsurveygeohazardrelateddamageChange}
								placeholder="Inspection / Survey "
								value={selectedInspectionsurveygeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ditch Modification
						</div>
										
						<div className="col-7">
							
							<Select
								options={DitchmodificationgeohazardrelateddamageOptions}
								onChange={handleDitchmodificationgeohazardrelateddamageChange}
								placeholder="Ditch Modification"
								value={selectedDitchmodificationgeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							RESISTANCE
						</div>
										
						<div className="col-7">
							
							<Select
								options={b3resistancegeohazardrelateddamageOptions}
								onChange={handleb3resistancegeohazardrelateddamageChange}
								placeholder="B.3 RESISTANCE"
								value={selectedb3resistancegeohazardrelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>C. Span Bridge Related Damage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							EXPOSURE
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposurespanbridgerelatedOptions}
								onChange={handleExposurespanbridgerelatedChange}
								placeholder="Span Bridge Related Damage - C.1 EXPOSURE"
								value={selectedExposurespanbridgerelated}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Stuctural Stabilization
						</div>
										
						<div className="col-7">
							
							<Select
								options={stucturalstabilizationpanbridgerelateddamageOptions}
								onChange={handlestucturalstabilizationpanbridgerelateddamageChange}
								placeholder="C. Span Bridge Related Damage - Stuctural Stabilization"
								value={selectedstucturalstabilizationpanbridgerelateddamage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Ground Reinforcement / Improvement
						</div>
										
						<div className="col-7">
							
							<Select
								options={GroundreinforcementspanbridgerelatedOptions}
								onChange={handleGroundreinforcementspanbridgerelatedChange}
								placeholder="Ground Reinforcement / Improvement"
								value={selectedGroundreinforcementspanbridgerelated}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Corrosion Protection
						</div>
										
						<div className="col-7">
							
							<Select
								options={CorrosionprotectionspanbridgerelatedOptions}
								onChange={handleCorrosionprotectionspanbridgerelatedChange}
								placeholder="Corrosion Protection"
								value={selectedCorrosionprotectionspanbridgerelated}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Inspection / Survey 
						</div>
										
						<div className="col-7">
							
							<Select
								options={inspectionsurveyspanbridgerelatedOptions}
								onChange={handleInspectionsurveyspanbridgerelatedChange}
								placeholder="Inspection / Survey "
								value={selectedInspectionsurveyspanbridgerelated}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Span Bridge Barrier for People
						</div>
										
						<div className="col-7">
							
							<Select
								options={spanbridgebarrierforpeopleOptions}
								onChange={handlespanbridgebarrierforpeopleChange}
								placeholder="Span Bridge Barrier for People"
								value={selectedspanbridgebarrierforpeople}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							RESISTANCE
						</div>
										
						<div className="col-7">
							
							<Select
								options={c3resistancespanbridgebarrierforpeopleOptions}
								onChange={handlec3resistancespanbridgebarrierforpeopleChange}
								placeholder="C.3 RESISTANCE"
								value={selectedc3resistancespanbridgebarrierforpeople}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			</div>
		
		</div>
		
		<div class="col-lg-4 col-md-4 mb-2">
			
			<div>
			
			<div style={{margin: '5px', height: '100%'}} class="card">
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>D. Sabotage</h5>
				</div>
					
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2">
										
						<div className="col-5">
							EXPOSURE
						</div>
										
						<div className="col-7">
							
							<Select
								options={ExposuresabotageOptions}
								onChange={handleExposuresabotageChange}
								placeholder="Sabotage - D.1 EXPOSURE"
								value={selectedExposuresabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Community Partnering
						</div>
										
						<div className="col-7">
							
							<Select
								options={communitypartneringsabotageOptions}
								onChange={handlecommunitypartneringsabotageChange}
								placeholder="Community Partnering - Sabotage"
								value={selectedcommunitypartneringsabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Intelligence
						</div>
										
						<div className="col-7">
							
							<Select
								options={intelligencesabotageOptions}
								onChange={handleintelligencesabotageChange}
								placeholder="Intelligence - Sabotage"
								value={selectedintelligencesabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Security
						</div>
										
						<div className="col-7">
							
							<Select
								options={securitysabotageOptions}
								onChange={handlesecuritysabotageChange}
								placeholder="Security - Sabotage"
								value={selectedsecuritysabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Resolve
						</div>
										
						<div className="col-7">
							
							<Select
								options={resolvesabotageOptions}
								onChange={handleresolvesabotageChange}
								placeholder="Resolve - Sabotage"
								value={selectedresolvesabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Industry Cooperation
						</div>
										
						<div className="col-7">
							
							<Select
								options={industrycooperationsabotageOptions}
								onChange={handleindustrycooperationsabotageChange}
								placeholder="Industry Cooperation - Sabotage"
								value={selectedindustrycooperationsabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							Accessability
						</div>
										
						<div className="col-7">
							
							<Select
								options={accessabilitysabotageOptions}
								onChange={handleaccessabilitysabotageChange}
								placeholder="Accessability - Sabotage"
								value={selectedaccessabilitysabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
					
					<div className="row mb-2">
										
						<div className="col-5">
							RESISTANCE
						</div>
										
						<div className="col-7">
							
							<Select
								options={d3resistancesabotageOptions}
								onChange={handled3resistancesabotageChange}
								placeholder="D.3 RESISTANCE"
								value={selectedd3resistancesabotage}
								required
							>
							</Select>
							
						</div>
							
					</div>
				
				</div>
				
			</div>
			
			</div>
			
		</div>
		
		</div>
		
		</form>		
		
      <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status of Remaining Life Infrastructure Assets and Visualization Data Exchange @2024
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default HomePage;
//sebelumnya
import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_pertagas = () => {
	const [isHovered, setIsHovered] = useState(false);
	
var photostocklist = {
        
		
		PERTAGAS_GRISSIK: {
		
			"1":"15l4YY4AD9BHqSd9nJE1B1nV6OCJE-iGj",
			"2":"1LjAlWDUhj0kpOcqdGSPV13866k4GkVCO",
			"3":"1ATwsIEE7x-z8UeXxRhAxrh0g-uWVS1en",
			"4":"1j2gX5BEOG5zzgMm8eT-k1Jh9vntsexxJ",
			"5":"1msQwzn6QYUs225QOeTfwEC9L3fxyb5QP",
			"6":"1ICu-RYPLg2oZ43LNaNtzbYXO8S0NKhGR",
			"7":"1sjlu9rG_MuaRTFCIdONU5Bjlrp9k5SVC",
			"8":"1cXeghFe6J3nxhZj-oxLTvh77_XlCAYn5",
			"9":"1CelNJvr2dNojDmrykytctYn0TK_BSYJ8",
			"10":"1zmyGu7-zyvJ9czwypP7IHz8nYY_HZYAj",
			"11":"1XEZ5FgnLGWpmzWZfr2qqnj26aFwaggsK",
			"12":"1y934cdQYhgUt5eUW97cwHM0yGCQCtHmN",
			"13":"1b4JUz-vBzPsVOFw1LuFWgSucYcXcpVt-",
			"14":"1uXw2ZhzbcFHlmU06IYPd8QN85sEjaXtQ",
			"15":"1bU9i8mnxn3BRE5fHZKcx3v7SDhrwMJhG",
			"16":"11LumFQme9CR--zh9KDWAgZ5w-OkqQ9Ts",
			"17":"1jb5-XAD5Bga4c7SIQgW35fBqTlLLScfE",
			"18":"1jU6Bz8HeaMEhy2MJ3juX_v1Fb2ufnMYX",
			"19":"1c5Gpmyt0SZe0hstgQ_TusjdMoGkG3fTO",
			"20":"1z35Mh-gul5HjeCNjqUbO2-LtAmrY5kdW",
			"21":"1U1JU2kZI_Cje4Ha4OmiAXL2CHT_9NsIB",
			"22":"1K9kmxTajyVADPlPYh26n-y-GaLPTyC2x",
			"23":"1BrMNMtPoIxkjVEKNhegy7kSBolnWHnN7",
			"24":"1CrCJfTBKsqL1OzRtgJRciWb-jLEB4OjT",
			"25":"1aFZz6XfHhafyP4tQW7Zh3nbNosyy3h6c",
			"26":"1yBs4Uf9g_bsiYovhYmr7q5uqyAIE1w3p",
			"27":"1Mew6cDW36t94LSWFNV6yxhsVAbmYp4ye",
			"28":"1QUdVX7BZnALXRsD9hLUL06oXPY2sqyPP",
			"29":"1tXcdon9FZn6E8MfCyzAb0Yj6za2ZFOu2",
			"30":"1LNbxmYB0dWgpwAwEprU5ZRNLIeAgoUDI",
			"31":"1O8XeZpn3so6XM7QUPbrPgQ_e5e4w5h7n",
			"32":"1y7OAEULIuW0EbL0lZR7z-MZM08rN43WV",
			"33":"1hRxWoBBge1phTAiqYnyvaRnordP37yIV",
			"34":"1r1lyB1VXfFzcQRWFEl405vW0K0GrkGit",
			"35":"1cjP4V8a6nkC4QrYJeBNS1ZDnvz-6UcUR",
			"36":"1mwvsiZcqSUlVB5pt8mZhgnOWzU9g-bBZ",
			"37":"10U0t5ufO2JDEQz4nXjPdlH0pvEm0P426",
			"38":"14eCPyzYA6g8zidbQPGqgDptGO2U5n3Rc",
			"39":"174Ka00wjz1KBR6QkLjiUuOpSRfrNjFnc",
			"40":"1Y8bHdNmGUoXYJI58VYsZ1NLnkKEV0lTW",
			"41":"1dOtfTLktD1avybhe69WBBCrdLZgsru-2",
			"42":"1yc8ZGglYLjGRiQvguEERPKZzP9PA4OXs",
			"43":"1NULFHQxS0VcKVzIt_Iol4quuvLZcyQfJ",
			"44":"1SXz3oaom64AikJqR9ZNv6JOIJLDxYNbE",
			"45":"1gNYSPr7eO_UU29YLDR7Wghk9LR8Lx0gG",
			"46":"1GYsyFmX7r51y1lY2Y_0-SVeM4TK-yBc-",
			"47":"1dP3-YAoLtIUR67_1_EZwTJvJ3gtpyJzU",
			"48":"1tE2DYQ2Cms5KdGE041qEv895kv6FhWfD",
			"49":"1_CJeFeWFAtQ-P69J6rRy23wrfEVgJJKT",
			"50":"1suCt71UWgaCVVvQIWCW5LVEX_yMXi7Tx",
			"51":"1ziQWSeN6YlxuPIQU4B3acaFIZ1haSa7m",
			"52":"11w2D2glAALf2Jm2emSApNehAWb9yf_mq",
			"53":"1eMI1juScRghFe9-YcPcvRyzHqwuKydgq",
			"54":"1vYEIUaH4g_7hIejob6uz4EUuRmAsZ1o-",
			"55":"1IMETQEAKd_38xHnUAcALJ8xFhfvsmLZF",
			"56":"1osds8gejw2GqboIVEMFEfYvSjXSL99cm",
			"57":"1rPmSHEbIr3hQLH02TGaAZLOL2_pGACHl",
			"58":"1JBLZEwkAX_XU4oRbYNPOX1lYjgUUM6mF",
			"59":"144Jzqxc75IM-_S63Q25igXkg30T6iVCT",
			"60":"1EPHEpCbrYtjqPyQFyiEnLiBxgcGb1Bur",
			"61":"11mpmtp0MIOOu6_kfBBU6N5JALS_YsNpG",
			"62":"1mVNrk-t-WZ4dPq1whr0O9HYyiiQz30BP",
			"63":"18ZXI11F3C80NUTiU4oTN-wRNTHePXTAO",
			"64":"1CUuojMQ7YHkujcnSq4T2_Fxz0qkbXPIa",
			"65":"1XLk2i9N512VMBKjlNTw7trIIl2Ttj5BP",
			"66":"1gPucvRgW5hs5Bs6GCcDdD-wuRYtcswLA",
			"67":"1g05aHeLayUOesti-D0T7Yg2TrCpqFr_V",
			"68":"1YW7O7YAty5rcA9W6R77_6r2KzUmJ5ZUo",
			"69":"1oUMLh6z95eBIWwsWur0mRR1cJ4DRJEUH",
			"70":"1ydiEgbsn_YU-mxAWKBzEAxQe36ExywUR",
			"71":"1nu6DXjGc46N9wVm8wOREZXbcwC-w1b6R",
			"72":"1ito_GB5bzgZGqxXdmPuOl344MDEv_Hmi",
			"73":"1YwJLgju205ZkNPfyZEV-EsTRkaSM1vVx",
			"74":"1NemjewF5NpDqlLD9E_n6Nd04IUx9HSwH",
			"75":"1arPC4wjkCRtwfuzoAVBKNNYylizw1g32",
			"76":"1uC2zA51Z4hvWcFNFCM1ws3EByaBhXteB",
			"77":"14VTyOEqVHNqOsqE7udCTKOP6T2jnY9Cd",
			"78":"13HpeFTguJha1vKZY1JYIbn8evcLIuSDc",
			"79":"1S1rXJ3HIT9pRCJBeLeJyr7MRuepP6s9O",
			"80":"1NwuH2BxZgZHAs4sDoa847K43DkvlsIeE",
			"81":"119CsI1Jgvsjraz78zy12PFEqK1AnMmjn",
			"82":"12BOGTsmxwe6UJvhXq0ahI51zL9UJu8kd",
			"83":"1muJRz26qBD3pEMKKLB0ylQEb1SXQ14tP",
			"84":"1RE068eCJ43_5x1jTPQFCkApMz18xY2lz",
			"85":"1ULL7y2iGvOS7IbncF37xJt9UFjjZswoj",
			"86":"1F34Dw3nrCmyyBRzSdMgnRlVl0zSuJjba",
			"87":"1aKe6x1QIRcRCR8ycNQ4vhWWfD65eulVm",
			"88":"1haq8RVpMvbNSpI298b5ttRu_fwN6H0pQ",
			"89":"1Quan1YWiSQ3UGAbo5VwNfpTvjcz1ThVJ",
			"90":"15BhD6cVjkXMVcxUZoLWVxVPaTEPa54v3",
			"91":"13S2fBaWkg5GlE-Kgsh8NcSiCE_44exDK",
			"92":"1TbnLC61c0UIAcLLURBX3djCvPEFgaXvi",
			"93":"1Zl5FUuVOCv5OBwX9vCsmv05BKr-Vnx6Y",
			"94":"1aoz-I9t4k-LICvgoIGNUKVLjeIRZBZPx",
			"95":"18qFODcNqiuX55HJ9wftwqxmIRar4J8bZ",
			"96":"1lonI33pE726aMUJ3EOzReV8WmFVZirqE",
			"97":"18jTNn15trthSpbFNZSWLvxX2osBfqxkc",
			"98":"1GGcO95CKQpH8BK03wzvZAnn5dO2RDrEa",
			"99":"1ciiTsn5IX7-Xo0pnDRePmdZmZZqkHN3Q",
			"100":"1nuhdg53YiNTKCYQyOLn9G4IL8GDCms02",
			"101":"1-b1sQ4CSKFiJjlL_vDpMQVfftJ8-07Hc",
			"102":"10Byj8VuGOMQ4BUmZBJJBzQL0gh7LIHSA",
			"103":"1P01MQ4c9xXtiGV7avJUIOFauOhpA7bNu",
			"104":"1gVfhmc2vPhV0vxuzWNRzYbianvK2oQft",
			"105":"1HvgroOhD7cTZTtab7ii7f68LjtOnR4-a",
			"106":"1whNkwSIsRo41YF_ci4MYTl7NFAyWxE2Q",
			"107":"1ljq7UNnMcOfAnKEp2QGhINZN4tHfQak3",
			"108":"15L81OTNREqLyA8ldGws7U0BiuikXDE9S",
			"109":"1JOwSqSXyIssPt85UWq8QVolzAEL86YJ5",
			"110":"10mhaWD3p-0Tx-Zet4v2xJEdn7Qir6ckR",
			"111":"11wIeBGoBLHe5LZ5pQWMTcK8Ek4YP8elb",
			"112":"1jNqbjGwCa4nFziQW-HR2o1u7W-iSaqRH",
			"113":"1bEqE6mKRqKwdaXiCwJ3zH1HFLwX02nrY",
			"114":"1UxgpNC1ssSlfzp4L9-W__EyD48i_zwxo",
			"115":"1M0zMxTKpC3Ab6XcYFvbhI7NYPepuesYb",
			"116":"1OPRPCw73khEyvfDCDquSS9g2nSw9lgr_",
			"117":"1d7UfmyGGtqSFmheYwXv9pMXXkMShlBry",
			"118":"1MRXQdWFvcv5WE5L1bnpcJSdWYBbG-SH1",
			"119":"1w_H4Nm0VdwQSt9NHPGkQHhfLQ2WyECvn",
			"120":"13y-aOxIQZ4QBrubKkSk6yFKYFFwwtNzK",
			"121":"1-AJrMSAgvby5gytBT9Wfjxwus2-or3C8",
			"122":"1QmL1nsa703TaVGpqGnchMTeoqUPDU2GG",
			"123":"1egpEKpGDoQYOk_WuW3SD6kd4XWtsIII2",
			"124":"1vdkppRZFQe1ILCdUxKlNkcOhmRVY2JPZ",
			"125":"19yrYGX3EKjNx8ZUCkMZAMEOhmTxXGF3h",
			"126":"1bAxeFmWytsxgb9wCuSBrkj1GOv-q3ytH",
			"127":"1oIPr0a_tYju4WhqiUrTcZnSBMP8whL66",
			"128":"1YaPKccM3Pab2ZNb8CNpNnsKRUQHDG484",
		
		},
		
		
    };
   
   const photostockData = [
  { id: 'PERTAGAS_GRISSIK', label: 'GRISSIK' },
];

   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock background new2.jpeg`}
						
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_pertagas;

import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Select from 'react-select';

const Pekerjaanasantiupload = () => {
  const [files, setFiles] = useState([]);
  const [status, setStatus] = useState("");
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [genranum, setGenranum] = useState(null);
  const [idcatatan, setIdcatatan] = useState(null);
  const [ideditable, setIdeditable] = useState(null);
  const [idpdf, setIdpdf] = useState(null);
  const [idfoldertahun, setIdfoldertahun] = useState(null);
  const [pekerjaan, setPekerjaan] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const navigate = useNavigate();

  const KelompokfileOptions = [
    { value: '', label: '--Pilih--' },
    { value: 'Catatan', label: 'Catatan' },
    { value: 'Editable', label: 'Editable' },
    { value: 'PDF', label: 'PDF' },
  ];

  const [selectedKelompokfile, setSelectedKelompokfile] = useState({ value: "", label: "--Pilih--" });

  const handleFileChange = (e) => {
    setFiles([...e.target.files]);
    console.log("Selected files:", e.target.files); // Debugging
  };

  const handlehapusfile = async (id_fileval) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        idfilehapus: id_fileval,
      }).toString();

      const response = await fetch(
        `https://script.google.com/macros/s/AKfycbycRs7tkZZARoF8S81IaIYV-wBzOPnIhr11niIAF6o3NlH-ck36nWQ208oYgTuK3UaL/exec?${queryParams}&filepkr=hapus`
      );
      const result = await response.json();
      setData(result);

      await refresh(genranum);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleUpload = async () => {
    if (files.length === 0) {
      setStatus("Silakan pilih file yang akan di-upload.");
      return;
    }
    if (selectedKelompokfile.value === '') {
      setStatus("Silakan pilih kelompok file.");
      return;
    }

    setStatus("Uploading...");

    const formData = {
      files: await Promise.all(
        Array.from(files).map(async (file) => ({
          name: file.name,
          type: file.type,
          idpekerjaan: genranum,
          idcatatanval: idcatatan,
          ideditableval: ideditable,
          idpdfval: idpdf,
		  idfoldertahunval: idfoldertahun,
		  pekerjaanval: pekerjaan,
          kelompokfile: selectedKelompokfile.value,
          data: await fileToBase64(file),
        }))
      ),
    };

    console.log("Form Data:", formData); // Debugging data yang akan dikirim

    try {
      const response = await fetch(
        "https://script.google.com/macros/s/AKfycbycRs7tkZZARoF8S81IaIYV-wBzOPnIhr11niIAF6o3NlH-ck36nWQ208oYgTuK3UaL/exec",
        {
		  
          method: "POST",
		   mode: 'no-cors',
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(formData),
        }
      );
      const result = await response.json();
      console.log("Upload result:", result); // Debugging
      
      
    }catch (error) {
	console.error('Error:', error);
    }finally {
       setStatus("");
	   await refresh(genranum);
    }
  };

  useEffect(() => {
    const storedData = JSON.parse(localStorage.getItem("datafolder"));

    if (!storedData || !storedData.genranum) {
      console.log("genranum tidak ditemukan atau datafolder tidak ada, cek referer...");

      if (!document.referrer) {
        navigate("/");
      } else {
        console.log("Menutup tab karena diakses dari link baru dan genranum kosong...");
        window.close();
      }
      return;
    } else {
      setGenranum(storedData.genranum);
      setIdcatatan(storedData.idcatatan);
	  setIdeditable(storedData.ideditable);
	  setIdpdf(storedData.idpdf);
	  setIdfoldertahun(storedData.idfoldertahun);
	  setPekerjaan(storedData.pekerjaan);
      refresh(storedData.genranum);
    }
  }, [navigate]);

  const fileToBase64 = (file) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        console.log("Base64 result:", reader.result); // Debugging
        resolve(reader.result.split(",")[1]);
      };
      reader.onerror = (error) => reject(error);
    });

  const refresh = async (genranumval) => {
    setLoading(true);
    try {
      const queryParams = new URLSearchParams({
        genranum: genranumval,
      }).toString();

      const response = await fetch(
        `https://script.google.com/macros/s/AKfycbycRs7tkZZARoF8S81IaIYV-wBzOPnIhr11niIAF6o3NlH-ck36nWQ208oYgTuK3UaL/exec?${queryParams}&filepkr=yes`
      );
      const result = await response.json();
      console.log("Hasil dari refresh:", result);
      setData(result);
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  const handleKelompokfileChange = (selectedOption) => {
    setSelectedKelompokfile(selectedOption);
  };

  useEffect(() => {
    const checkEmptyPage = () => {
      const remainingItems = data.slice(
        (currentPage - 1) * itemsPerPage,
        currentPage * itemsPerPage
      );

      if (remainingItems.length === 0 && currentPage > 1) {
        setCurrentPage(currentPage - 1);
      }
    };

    checkEmptyPage();
  }, [data, currentPage]);

  return (
    <div>
      <div className="row">
        <div className="col-lg-4 mb-3">
          <div className="card shadow-lg border-0">
            <div className="card-header" style={{ backgroundColor: "#646464", color: 'white' }}>
              <h5 align="center">Input File</h5>
            </div>
            <div className="card-body" style={{ backgroundColor: "#d3d3d3", color: 'black' }}>
              {errorMessage ? (
                <div>
                  <p>{errorMessage}</p>
                  <button onClick={() => window.history.back()}>Kembali</button>
                </div>
              ) : (
                <>
                  <div className="mb-3">
                    <label>Kelompok File</label>
                    <Select
                      options={KelompokfileOptions}
                      value={selectedKelompokfile}
                      onChange={handleKelompokfileChange}
                    />
                  </div>

                  <label>Pilih File</label><br />
                  <input type="file" multiple onChange={handleFileChange} /><br /><br />
                  <button className="btn btn-success" onClick={handleUpload}>Upload</button>
                  <p>{status}</p>
                </>
              )}
            </div>
          </div>
        </div>

        <div className="col-lg-8">
          <div className="card shadow-lg border-0">
            <div className="card-header" style={{ backgroundColor: "#646464", color: 'white' }}>
              <h5 align="center">File Pekerjaan</h5>
            </div>
            <div className="card-body" style={{ backgroundColor: "#d3d3d3", color: 'black' }}>
              {loading && <p>Loading data...</p>}
              {!loading && data.length === 0 && <p className="text-danger">Data masih kosong.</p>}
              {currentItems.length > 0 && (
                <>
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Nama File</th>
                        <th>Kelompok File</th>
                        <th>Opsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.nama_gambar}</td>
                          <td>{item.kelompok_file}</td>
                          <td>
                            <a
                              href=""
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                window.open(item["link_gambar"], "_blank");
                              }}
                            >
                              <i className="fa fa-eye" style={{ color: 'grey' }} aria-hidden="true"></i>
                            </a>
                            &nbsp;
                            <a
                              href="#"
                              onClick={(e) => {
                                e.preventDefault();
                                handlehapusfile(item["id_file"]);
                              }}
                            >
                              <i className="fa fa-trash" style={{ color: 'grey' }} aria-hidden="true"></i>
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                  <div className="d-flex justify-content-center my-3">
                    {pageNumbers.map((number) => (
                      <button
                        key={number}
                        className={`btn ${currentPage === number ? 'btn-success' : 'btn-outline-success'} mx-1`}
                        onClick={() => setCurrentPage(number)}
                      >
                        {number}
                      </button>
                    ))}
                  </div>
                  <div className="d-flex justify-content-between">
                    <button
                      className="btn btn-success"
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-success"
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      Next
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pekerjaanasantiupload;

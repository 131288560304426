import React, { useEffect, useState } from 'react';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';

import { useNavigate } from 'react-router-dom';
import { loginUser } from '../api';

const Login = () => {
	const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showLogin, setShowLogin] = useState(false);
	const [error, setError] = useState('');
  const navigate = useNavigate();

// Cek apakah token sudah ada, jika ada langsung redirect
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      // Token ada, langsung redirect ke halaman yang diinginkan
      window.location.href = "/alive_react";
    }
  }, [navigate]);

	const handleSubmit = async (e) => {
    e.preventDefault();
	const response = await fetch('https://alive.my.id/restapialive3/api/login', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            email: email,
            password: password,
         }),
      });

      // Cek status respons
      if (response.ok) {
         // Parsing data JSON dari Laravel
         const data = await response.json();
         //console.log("Laravel Response Data:", data); // Debugging

         // Simpan token Laravel di localStorage
         localStorage.setItem('token', data.access_token);
         alert('Login successful');
		 window.location.href = "/";
      } else {
		  alert('password dan email tidak cucoook, silahkan di ulang lagi');
      }
	
  };

  useEffect(() => {
  const originalBodyBackground = document.body.style.background;

  // Mengubah latar belakang body
  document.body.style.background = 'url(gambar/background_alive_new.jpeg) no-repeat center center fixed';
  document.body.style.backgroundSize = 'cover';
  document.body.style.overflow = 'hidden';

  // Reset gaya body ketika komponen di-unmount
  return () => {
    document.body.style.background = originalBodyBackground;
  };
}, []);


const googleSignIn = async () => {
   const provider = new GoogleAuthProvider();
   try {
      // Sign in dengan Google
      const result = await signInWithPopup(auth, provider);
      const user = result.user;

      // Ambil token ID Firebase
      const idToken = await user.getIdToken();
      console.log("ID Token:", idToken); // Debugging

      // Kirim token Firebase ke Laravel untuk login
      const response = await fetch('https://alive.my.id/restapialive3/api/loginWithFirebase', {
         method: 'POST',
         headers: {
            'Content-Type': 'application/json',
         },
         body: JSON.stringify({
            firebase_token: idToken,
         }),
      });

      // Cek status respons
      if (response.ok) {
         // Parsing data JSON dari Laravel
         const data = await response.json();
         //console.log("Laravel Response Data:", data); // Debugging

         // Simpan token Laravel di localStorage
         localStorage.setItem('token', data.access_token);
         alert('Login successful');
		 window.location.href = "/";
      } else {
		  alert('Email tidak diizinkan, silahkan hubungi admin');
         // Jika respons tidak OK, parsing error respons
         //const errorData = await response.json().catch(() => response.text());
         //console.error('Error logging into Laravel:', errorData);
         //alert('Login failed: ' + (errorData.message || errorData)); // Tampilkan pesan error
      }
   } catch (error) {
      //console.error('Error signing in:', error); // Logging error dari signInWithPopup
      //alert('Error signing in: ' + error.message); // Tampilkan error
   }
};




  const handleHover = () => {
    setShowLogin(true);
  };

  const handleLeave = () => {
    setShowLogin(false);
  };

  const styles = {
    container: {
      fontFamily: 'Arial, sans-serif',
      margin: 0,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      height: '100%',
    },
    logo: {
      position: 'absolute',
      width: '70px',
      right: '10px',
      top: '5px',
    },
	
	logo2: {
      position: 'absolute',
	  height:'700px',
      width: '550px',
      right: '-100px',
      top: '-0px',
    },
    background: {
      margin: 0,
      padding: 0,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'flex-end',
      height: '100vh',
      position: 'relative',
    },
    bgImage: {
	  float: 'right',
      width: '500px',
      height: '80%',
    },
    loginContainer: {
		display: 'block',
		color: 'white',
		background: 'linear-gradient(to bottom, #292e34 30%, #1a1d22 70%)',
		position: 'absolute',
		/**left: 20px;*/
		top: '55%',
		width: '330px',
		height: '250px',
		padding: '10px',
		borderRadius: '5px',
		boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',      
		right: '0',
		transform: 'translateX(-50%)',
		boxShadow: `
		  inset 0.2em 0.2em 0.2em 0 #555, 
		  inset -0.2em -0.2em 0.2em 0 rgba(0, 0, 0, 0.5)
		`,
		backgroundColor: '#f0f0f0',
    },
    formContainer: {
      background: '#ffffff',
      padding: '10px',
      height: '200px',
    },
    inputContainer: {
      float: 'left',
      width: '250px',
    },
    input: {
      borderLeft: 'none',
      borderRight: 'none',
      borderTop: 'none',
      marginBottom: '15px',
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      boxSizing: 'border-box',
    },
    googleSignInContainer: {
      float: 'left',
    },
    googleSignInImage: {
      width: '20px',
      height: '20px',
      margin: '5px 0px 0px 2px',
      cursor: 'pointer',
    },
    row: {
      width: '250px',
    },
    checkboxContainer: {
      float: 'left',
      width: '150px',
    },
    checkbox: {
      width: '10px',
      height: '10px',
    },
    checkboxLabel: {
      fontSize: '12px',
    },
    submitContainer: {
      float: 'left',
      width: '100px',
    },
    submitButton: {
      width: '100%',
      padding: '10px',
      border: 'none',
      borderRadius: '5px',
      backgroundColor: '#007bff',
      color: '#fff',
      cursor: 'pointer',
    },
    footerRow: {
      marginTop: '5px',
    },
    footerText: {
      color: '#fbf8f8',
      fontSize: '11px',
    },
    signUpLink: {
      color: '#294af6',
    },
    forgetPasswordLink: {
      float: 'right',
    },
	
	textboxstyle:{
        padding: '10px',
        marginBottom: '15px',
        borderRadius: '0px',
        boxSizing: 'border-box',
	},
	
  };

  return (
  <div style={styles.container} >
  <img src="gambar/helmet new PGN2.png" alt="Helmet" style={styles.logo2} />
  
   <img 
  src="gambar/BG_JKT2.png"
  style={{
      width: '1500px',
      height: '1000px', 
      position: 'absolute', 
      left: '-150px',
      top: '-55px',
  }} 
/>

  
  <div style={styles.loginContainer}>
    <div style={{ padding: '10px', height: '200px', textAlign: 'center', marginBottom: '20px' }}>
      <h4 style={{ color: 'white' }}>SIGN IN</h4>
	  
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div style={{float: 'left', width: '280px'}}>
            <input 
              style={{ 
                background: 'none', 
                borderLeft: 'none', 
                borderRight: 'none', 
                borderTop: 'none', 
                color: '#f4f5fa',
				width: '240px',
				...styles.textboxstyle
              }} 
              type="email"
			  value={email} 
			  onChange={(e) => setEmail(e.target.value)}
              name="username" 
              placeholder="Username" 
              required 
            />
            <input 
              style={{ 
                background: 'none', 
                borderLeft: 'none', 
                borderRight: 'none', 
                borderTop: 'none', 
                color: '#f4f5fa',
				width: '240px',
				...styles.textboxstyle
              }} 
              type="password"
			  value={password} 
			  onChange={(e) => setPassword(e.target.value)}			  
              name="password" 
              placeholder="Password" 
              required 
            />
          </div>
          <div style={{float:'left', width: '30px', margin: '20px 0px 0px -20px'}}>
            <a onClick={googleSignIn}>
              <img 
                src="gambar/logo google berwarna.png" 
                alt="Google Sign In" 
                style={{
                  width: '20px', 
                  height: '20px', 
                }} 
              />
            </a>
          </div>
        </div>
        
        <div style={{ width: '220px' }}>
          <input 
            type="submit" 
            value="Login" 
            style={{ 
              background: '#43d08b', 
              width: '100%' 
            }} 
          />
        </div>
        
        <div className="row" style={{ width: '250px' }}>
          <div style={{ color: '#fbf8f8', fontSize: '11px', marginTop: '10px' }}>
            <div className="form-group form-check" style={{ float: 'left' }}>
              <input 
                style={{ 
                  width: '10px', 
                  height: '10px' 
                }} 
                type="checkbox" 
                className="form-check-input" 
                id="exampleCheck1" 
              />
              <label 
                className="form-check-label" 
                htmlFor="exampleCheck1" 
                style={{ fontSize: '12px' }}
              >
                Remember me
              </label>
            </div>
            <div style={{ position: 'absolute', right: '5px' }}>
              <a href="#">Forget your password?</a>
            </div>  
          </div>
        </div>
        
      </form>
    </div>
  </div>
 
</div>

  );
};

export default Login;

import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Nativestock_kjg = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        /**mechanical_piping:{
            "1":"1RgHawcKxDCvd8pGKdSURdJ4IQfEpF69I",
            "2":"1mbUZo2qVrfq7pbhuETEDGELa3GFR2TLQ",
            "3":"1pgSy-fvOOw9kqQ3N4LTyfBQKQO_8I8wH",
            "4":"19UnHcyGUfVCQaJCUNnQtHR9f8fkELW3B",
            "5":"1MqdFu_BA5sjS_5VOYJHR8y7zmAPsNcRY",
            "6":"1I3sZCy7qJydAx3hCGdcp0WOLTsxakO8E",
        },
        
        instrument:{
            "1":"13DKmUd_ccOUSrjyYRqLAhomYNv_st3R7",
            "2":"1Mpkqzb4sx5O1I7kCCOyA-kIR1Kidvq5X",
            "3":"1gKbHd5moo4M7w7AZJHDBF-sk5tvBDuks",
            "4":"19BP8GEGQJiksgHOyhiBWEPCIE4hDEnpC",
            "5":"1LTXySlTISzbzcp4aiELwRMn5sKcgPApt",
            "6":"1pWYPtIPTEbjAkPt26bxOqn5jJO97kJp-",
            "7":"1-FLU4IJQE07PcxC3ljztNgkyMWBo2mU2",
        },
        
        process:{
            "1":"1YDkbUkYdckoJFExDYNlBVXHamDtyZKqq",
            "2":"1CD77yJGt-gTdhwpsNjygQFVxumKIZJYy",
        },
		*/
		
		asbuilt_built_drawing_orf:{
            
			"1":"12a5dJr8Jq72smMk3YyvrSPsdLrDLVwNA",
			"2":"14IPrdMhMsBoWFG_EANd8HX1RwllPUSa0",
			"3":"19GCZXfhNLBbsAaz5CZyS6VduOilAGoDd",
			"4":"1AIxF5AtUO7UWflvtEHXbK5ZriB-3Ub4p",
			"5":"1BlVZ8JurHgIhMl2he3eo4ac2XTtdGpga",
			"6":"1Hzm0q15yTVggCZhuqNN0K-TsRGjE9KKJ",
			"7":"1N3yAmAY4XvmpZ2rtNR72F8ChzVFtE7lF",
			"8":"1Q7ruGaud1KDirIvnkZnX4rjPgTLao_XW",
			"9":"1Y51k6uei9OYGuQjhmgOX6cUimZz-Trp_",
			"10":"1_gQo8eteLUdnnQaZduyxdKAceoKUpjnx",
			"11":"1pERlBYXuo7kTlDcqgwdF4-WaX_1WiIGt",
			"12":"103GagYSeo_Ye_8CdgLAd2ztAVR_DjCKl",
			"13":"12ONQDeHRwipQlYFMjhXSg8gNjy2bM8Rt",
			"14":"19RYtQgzbvtZh19FjXMiPObi9EErmVml5",
			"15":"1A2yLJHnUia-9ORdJPSYE0Kzi_zAe3W99",
			"16":"1BPKXusqn4HGLNsrh7KxeiNLKjrGY3OOJ",
			"17":"1DZJRQShEfKcXIoO4Ax9FxiQonXxu86-g",
			"18":"1DqI8RLsHvORqVfe615p5QJI1jwpwow7M",
			"19":"1Iy_UX4965wSzinCwtWHu3r9dHr5ildJr",
			"20":"1Mw7BUMKZ6n2bvN5RSyka_W4CTkKy-_8_",
			"21":"1QlqL_2oZSv8qR67k1b-GGh70Zg1ytXkz",
			"22":"1T3sqfdi0fQ_kuRuEJ8hii9kCWji8D9cU",
			"23":"1VVNw-_BGszl8HF9sbYRUdm7G6DGOSlR1",
			"24":"1fkcF1oZA-LHKbHtCH23b59MYLiG5xsMo",
			"25":"1jW3RR6WUHIUD7GCOAwYL1he-LfNssL8C",
			"26":"1r9Iholcih3tJISwD2MtBB3gUZx8xNzUm",
			"27":"1smz-D6zIReNB565mxTczFZ1MOa3YKDFC",
			"28":"1tisYbvLdyvjAcLY3g2GSdzUbi6L1Ef3n",
			"29":"1x37OjVRGdM9JvtymoJxXy0Ib1vuPzObW",
			"30":"1zT4SV-MflSQiojHELYE00VE1OQ37IT1G",
			"31":"1CSbnUgf3iVbx349ev6r_55PN7WmLt5Ll",
			"32":"1P-lDEDu2AyECPWOMe85mcN-ok-PEo_TG",
			"33":"1VsrkeXcgx4jbY6_P8zXuNhpkbjDZxGXa",
			"34":"1qJJyCbfKmkbf0mjGEI6mNiUM_tmKTBL8",
			"35":"1tXZDYqbAx_cEewHzvP4jsUBAcMBXRQqv",
			"36":"13kWA5p_IBmuS4CEpCvFDsS4bJa5Xh_t5",
			"37":"19-9khUOwghbxeoXGFIjCq3vBQKrePKST",
			"38":"1AW0H0x8NuQx2DmyhlDeBSVmNtOiZNlPn",
			"39":"1AqjGUHlSE2IFmwRIvyd9X8DbCbDxL1cg",
			"40":"1CYr-DwDsxLMS1WAmcssx9cSB-SzXumjX",
			"41":"1LclKZ9I0_NEThHN0RCEC4aIJN0QeSJR0",
			"42":"1RNRP5elApnnIK26ojc_6rv2dSM3131MM",
			"43":"1ijEQcWOU1IqVe43HqX1IjiPnA1ZuMaOC",
			"44":"1sIZvuWjPUB6-Qsuflo3bvonISu7n00bz",
			"45":"1-2474_HGF7xoNntgGQNGl33ODDzJPfod",
			"46":"16cm1MuQl44zzJ6PmBg-E1g9J5fGEJ7tE",
			"47":"17vQxycr4EvslZU1eh_kTlgO8wYXiQnOr",
			"48":"1DlhJ8lO2qBb4x5BWMPZeZr-xfrIqy9iH",
			"49":"1H2zVcco9uziTVIbZS3kp1JDXBu76ZQLs",
			"50":"1O6PpAQG4Cv0xXb9cn607nAFt9bpdR77S",
			"51":"1P6Ss9dOgODoMgf1_k7UyJwgTnJ9pTKiH",
			"52":"1SJYnTvukW9YltyIFSTfOirlo3wafkVeJ",
			"53":"1ZDUo5lBer37JwFABLX_bNQggGMpEuZBk",
			"54":"1_0qaFdmcEhwIhj3sZcPNUZoqgWgFShHM",
			"55":"1_GRJ86BGNTMEp0eqcSSuaxCN4PnCP8_-",
			"56":"1bgMtkgcu-Czn3N5B9Ohgn3yyljio-NtH",
			"57":"1cNqVnRPYIfhvs4lMXVKfMYdqoQmtZegr",
			"58":"1iTE3MSukeuy0LqyzHgvzZyUR_otJLaAC",
			"59":"1jMIsz5Te1VQs52xC7MVdqJ8MCWhO80KY",
			"60":"1kG9LeANnRdxbg7K_wyX3fBTlw3_IQ09h",
			"61":"1nUp7FxpZS8KLUgZKwqaVLqLXOHs6VKkz",
			"62":"1qoCly3yLfOr-knwAtRd6IZHJvBl3vivJ",
			"63":"1uaXhQs4hbnS4NfncebPRCXxJRFCsht9v",
			"64":"1zwpkygmp1j623q1OQMkiukbcTZjM5fR0",
			"65":"1zl9D7BZLK56R3qN5hFMRmlgzcM6QA4ul",
			"66":"12GNqpS3te4QU7HbsEPHgxXcobhpwCQjT",
			"67":"12Oz8XtP4AOCuvG0wJe4w05vhQlIdLrxK",
			"68":"148Lm9zGZNyPSxaR8fXvJtKk8kcmmCu-I",
			"69":"1Gbt-zJAjGIxeVWdWosQpxu8Nen5rl25o",
			"70":"1Im100rJaNJib2ERoeaxb76v_lhUUBhZ-",
			"71":"1Jo3bOWJb1SICFXqTN72BnRWaEKkr4A9a",
			"72":"1MGqazduaXIZjNewE8MJ2cdgoUHvBnInN",
			"73":"1OZS3a3P9BswaT0OlhWOqae-7v0dJLlNu",
			"74":"1PmclbY7aKdCfwmZCXJHWctA-w3ESLsh4",
			"75":"1k-srSo6IcLYVy8LZCulKLSC9a23VCcLy",
			"76":"1rUr1eYEVvUNTPj7_4wfzxp0lKPwOv85w",
			"77":"1tzmZ5Pl4_68KgJGraIAhbWrJijf-4Rjv",
			"78":"12yyfICGXTuk4pW8x_k0ajoF7GSLCo8Ck",
			"79":"14It-o0F4_0uC1kV5uIFcwIHukFy6KOZI",
			"80":"16uhpPyOWc1uDRJHEIKHziTF35FzuQa0c",
			"81":"1LM2VgEd0ietEta517O6F8QIrSWCc41SA",
			"82":"1ZdznDYhWtsiKW0kw61p2eH5LyX3Cruh3",
			"83":"1Zpk5Kxmp4Ogrkc0Guh0VdjcamvoCcTDF",
			"84":"1e2GidllFpIOytot6pQe_y1eDVEVqmXnF",
			"85":"1gBTVkO-PViiqAnF24I35yGKNvDSqyNN_",
			"86":"1qFO8Q5fQtRLRvYU9UATXpDZeedd8cKlx",
			"87":"1w34O-22qzcAhXKIlLFAknDjoUiTdxBU0",
			"88":"10LDkpmQSUsZk5vTv8mJfDdcZVq9X8rgX",
			"89":"1S27b_lLjFiTZVColpIvxpnzpnFpRrxwY",
			"90":"1r0Po9ej7y94ggUFPWYmjTF1qfh0kxcl2",
			"91":"17p3lp7Nz1_d4_xiK9FbP7-BoqRn7A1p_",
			"92":"1Wbc-2Uz2UBxKfk2Od4kAlTIfdXvdkcf_",
			"93":"1b4ZXSWwNNtKqXwCpJr7nPyZXLmdL3bLL",
			"94":"1frJMYeQkDE9ZjRuSIJ0gQZAFKhH8n-LB",
			"95":"1hyYsvPijvkrKbqoTKkQEkVwsGlow3DpX",
			"96":"1iyh5L6SxKiqc9a9V-JHjQHSr8MqIEcDZ",
			"97":"1nqGNd6Yy0snw1a_I8JM8g1VVZ8Wf-s0h",
			"98":"1yjVe0I5cbJ8dXrdybveOl6-Uz3e8osAR",
			"99":"1-qufNyVgZiqdOlsdm1jm4jIG-IPxlM85",
			"100":"1CqUksQsM4jAB5laR_1zAzzpshJhKVsXP",
			"101":"1KEY_gbG11FUs1YoML3GSgJICHAjF8mHN",
			"102":"1hf_aAFxn_RW-GT-AV2ZFF4DEoo8jFD2F",
			"103":"1q4RNUiPgBV1wP-fi_gdMDuSZ3NmeuVsZ",
			"104":"1IKizIKcqdIFGv86Awx9Sd5BpVkQUpE8T",
			"105":"1NuqZbBa6Awo_iVFUh3wgSpK4Gaq4zQKm",
			"106":"1SlfZ7FYSKnUx-ZfzKcbBcEzhnfCvflq5",
			"107":"1ZIyuyOjjEdBObCWhhZwQyPJWGmSYb4ci",
			"108":"1moI8G3c075RJ2esGyvDg4krZwKLB5E_n",
			"109":"1qgd0ILVFYkilEEo1s9gJvPG-kZqEkx5-",
			"110":"10FoBT76rix6hkU7ldI6ZnUAZIl3cTWRo",
			"111":"13hcGjO0y1Gn6tC0Tx6LefoWLMkjY0a4A",
			"112":"13lWGIWRfNR9G1icREW-e9afwYT0F574o",
			"113":"14goM8SW6BODjhLInK7s3DbF1zS-hK8cl",
			"114":"1EPGS-7mwAOKXd7VBPNt1Y3G1GmnPfeH9",
			"115":"1G5ItiXZ1liEDyrfF6uuzOH-_mgSzec31",
			"116":"1JDaDJuwfvosLkN1pd5SflCeeXCc9jQRQ",
			"117":"1OqHtVM7f_ER4xNrggsEbWk4K1udnxiRD",
			"118":"1T3EqQtk5T4B7WTHHy_wCVw5omo7qzy6_",
			"119":"1YnN7NIDlEkCcFv_5LSq6e0L28b_zhs3K",
			"120":"1bAFjptdoFCvfJI8Kg57mki0WHL2otwMO",
			"121":"1bt2h0EpeLH0b0Q1hMPVObsmmBF5UOERg",
			"122":"1l6cZSOvU91AlOIRnpua6kPrUcyU3GUtO",
			"123":"1mGiiCI7uP0AWZP0M4YM8V34x7Dh2C96Q",
			"124":"1nfDb29c-qmLdcA7R8mvWo-__GRnxN-9e",
			"125":"1o8JXPZri_mv-2J8Lio57ATJReuPR69xc",
			"126":"1px9wLGfqaKyN6l28idZ_3-a-yR7WtKCP",
			"127":"1rS6drcdWsYs1QWLLaWSF4grnsrs8Xnma",
			"128":"1thLXAL54Qi42K9VnzNljTtT1qvBvm8LT",
			"129":"1x9Rqzek7OLfSer1Vd9A28wf5qB5Mxnti",
			"130":"1xNpRryjwvbT9QALcpM-jbsJadtNaK6fe",
			"131":"1y8tGy8QNCuT1Ug4EsIaWUd0RqEayCEHF",
			"132":"1-jCxGXTzJM8_pJS2GQmpteXPvi-pj1iJ",
			"133":"1-rbKhRaZrU6jg6Om7_QZbShr4kkOr3g5",
			"134":"12p32ma8tEApFBdItIqLG5BNWwrvFZ0bF",
			"135":"13SuNaucTQW23KCqf665Fl_L0SCzlPvZK",
			"136":"15dj11YFojD1YB9xGsYVd5dPOgjQmfbkJ",
			"137":"1DTOYYSb9IFo12B9t73yJBKo67mHcD-kx",
			"138":"1IL6YD4Mes_43Qplg0ALIPZQHbXb_iEm1",
			"139":"1O9R7wFMwCx7UOwiNNQsdojVlFQd_OF2D",
			"140":"1cHE0Xa9lHnsS2rDPxZkBJV_mzmUjSpNK",
			"141":"1dCS-DI0KBDUBy6kB0REKqav3q_Bfwycv",
			"142":"1j9ESmvLfVO2YsfK7zhOoruogENSd6sQM",
			"143":"1jF4J3vd_W1epuNMMLqlW0XM4OavFpqWn",
			"144":"1jwtI4HAIVO43PrkSp1tU8Ire12hG5Ci_",
			"145":"1lLB-23FlXe21RITbCiL7H6aSmIujk_Co",
			"146":"1p5XKw-_pHQeEd09tI2YeqocmtXdf9eu1",
			"147":"1pgHZk4eh7STBP7DrrX6ewDhaLxrtGWVB",
			"148":"1tqSAFt9BcILnkUoMF4uh4m6xJ-PhzE8m",
			"149":"1vmshensv-jhiUcLFJylliuNqK6bf_-q1",
			"150":"151HP6YSDOkCY6hBlEBoth7s0j5bsiQEI",
			"151":"16D1IiS8hI1SfQo3YdRH8blm5Vi37WSiZ",
			"152":"1CDk79xqc8GhYCUdPPSOLpNX0S23gwxb5",
			"153":"1G6cQqAGeEfFAHdJjFb2ZHdwSwGyPnIhp",
			"154":"1PvBk6-VVQCyST9JkQmNsjsFHJ14uEqs_",
			"155":"1W0h5T0d_HYq3fvWk3LNJEwflMFaC3SSj",
			"156":"1WK3Ypl1_XMkbmb_zUCmyfWBHEsrMQ7n6",
			"157":"1fP1vK8tD5_MQpAo2_tB9nMrH8vw4iXto",
			"158":"1gxqWtVCaQJShmvebSNtybOiVa5XTQrlC",
			"159":"1hqpyCmN9YnhMi90InBxvYLPSrBOcIux4",
			"160":"1mWKC6jjgfzAbzGVcl2hbBsR8mAmKrpwV",
			"161":"1vBNvWkiwrDDTaDF7RsgEb2dZIaxT9vwA",
			"162":"1wk3ZwgdxV09xTTcdX-mxzsSIKvM7GVae",
			"163":"10uuA9Mv3lEuBY2sSiTbBgTF0cSikdRG4",
			"164":"15MFVc9VFVqVVsttoXRQc80cZhpKEY7jp",
			"165":"16AJ2LWDjr7d3ieZJSibDM-rV66_14tIZ",
			"166":"1Bd__Pck09RNmLg-8hgJow5ORNu5lD7QV",
			"167":"1QqmzsETt29Cjm89LZroHR6MU9mLSs4FH",
			"168":"1WYRUlleOtk_3so--fOo_WuV0LoN-9Vm4",
			"169":"1dhSdib4R-LZmgWt5L0PyJV0MfMMyNFk8",
			"170":"1eGzknBw1uk8DhASfkf3mHla-Arf19ATq",
			"171":"1iGis5wKnmBL7dR_CdOxLa8k14dyyGlo5",
			"172":"1jRYOCFlQStritl0EVNyRHKVfzWIEnYPo",
			"173":"1kYn2vhKYszykzzvuDaKZGuhg-LcY0lPQ",
			"174":"1p0chjVI_L46y_6Pl-viXiOOxw6x8EX9p",
			"175":"1s_PyODgD9JDIjaTw2wB8gaDc3oZZgOgn",
			"176":"1_yzb14YUTqdh4RVwXnjsbiTwTjRthip9",
			"177":"1ovGY_wK0M-yrhHAoZmtDM-Kp-cC2VmMh",
			"178":"1pFTB5fxR91tet_N5DBXkFY5euT9Iz79g",
			"179":"11tpHiZ7EtVDvjvqDOhc0_XVVm3GMbAX2",
			"180":"13ADhr6dHjl8OVhHr57v_hZP5_sPBfgQl",
			"181":"13xZ0hKqFH-wH1PDjynhFgBhnzSQX4w7y",
			"182":"15QMM77zbVkhRLhqvnD3Ir2nzHdTCX_41",
			"183":"1BHUetgcy-C3lL48Uv8A_WLUaVlLg6cv8",
			"184":"1C5OeQMgSNMCzRjJpASJbBPZaYUNRATxk",
			"185":"1H2IOBQW1II5oo23G3Iw7dkz4R4lDrXqx",
			"186":"1Hrbj8yJDASHPI4-o2149Md2ddrPbWO_9",
			"187":"1Nkr3hQK2bkxoSuHh6_FIYdQqfOGqbadj",
			"188":"1NnCcIwxu4hciM3dSPPm-4z5Jf3NcYuzU",
			"189":"1SFlIvxBaU2ippctEthhEttsdNzWwaYwa",
			"190":"1TcfoYGJwvUJho0UFjG6rSk0wTw7DXCNW",
			"191":"1Trg7hZNVlDqWxFWHFfpQ_mTi5lKBpe9Y",
			"192":"1X-MCOAflYauC5W3BiBkp-dqZDoz6GPCi",
			"193":"1YW090_bwNGkZnTI_vOM-Xp6yKN-nyB86",
			"194":"1gMnQVkBcTxdRMLTBQXhShvygNC9EVsV-",
			"195":"1jnSVvwBt0W0LoexJJl7EqKKvC2wvw6Wi",
			"196":"1k7rYeo_y06uYl0GUEYZQqqiu27qb57YG",
			"197":"1ny8xERXdJC56P-czgwIt3BYBb_TIgrSD",
			"198":"1qbEyelKCuT0fJglWPKcnmMhlL01sLoyY",
			"199":"1s3jgjsPQcZcCdjPxG3UKRQYoINzn7iG9",
			"200":"1xtN8kf2UXDiIrEus5g0w-tYk_vakzRGQ",
			"201":"1yHuoDRn70KRSRah5ljQWwIYDMrgk3i5Y",
			"202":"15Ko9kFQGoDs2uLx5-huYZNRfQTJr3bhR",
			"203":"15UYkbmVB3E_LYpMkI5K3PbwSkvOD9qRS",
			"204":"1ICsQZSShBVm3jB-4DIHl1k7pqTz-nuDX",
			"205":"1IgB5XusmUGuz_vPWaUbe4HVXvv0sXYhz",
			"206":"1Jd9YK5XpgO2e7wYfDfvn8zUJdZJnlNxX",
			"207":"1KpGfROp4kv2EP8L5UN5D3sK4xxEwLDuO",
			"208":"1RDxsm_tdMPznMxR3gprIZzVfkfPUDdHU",
			"209":"1X4uxPmBIEHHeRD3xKsr1vPctpJFbMZX0",
			"210":"1ZbgTkdiVt5N6ruVJ5HGBPv26-iEk3TFm",
			"211":"1_vfZ0BhS8MaZKOBW_iycnDnl2QAIOFFn",
			"212":"1a5SKD7fg1QbbJv96RE4mSd-yU24UPu9B",
			"213":"1iaNiEK3cIvebZIqVd99LPAeSSR7kXjea",
			"214":"1iuaZ45ROTsSEB3--LHpG0PHcg95fcYgD",
			"215":"1o8imJ3oP6Qi0vVwOc9osVacF1_trD-Qu",
			"216":"1pDe-uXb0Au4rQsqlUz0JCeffICdcn7ym",
			"217":"1pRSVVd2lOoIXBQOwdmzuGQtVy7bHyDv8",
			"218":"1pdB6OWR9t1YuVZhVwvio-owU288WnIOg",
			"219":"10fZziftBcxYWgtvxZfwLux696ZEXC5ZT",
			"220":"1244_TvvYlN6vraa7BQYJy8UVKMgNpe6j",
			"221":"12iFX63oDE5DCluy9TI5dx4or5OQdYsKm",
			"222":"18V9oR89-adXzop3eDAWYyeXUvZsQ9ORR",
			"223":"1CTFI1n5OSbkkr256TjQhN2Q8Z67LBure",
			"224":"1H_1VSEDexRbQKTIzGpb39_D8oQokcFc4",
			"225":"1R8Nb5uxZzf68uHxullGyMLDyPvtkChVN",
			"226":"1SDQkMIk6t7hqP58Jz8aU9xqRYjkHv3gG",
			"227":"1SlptXr7aavaxnyk9hdaWXNMF8O_lkS0r",
			"228":"1T8IbZCUEDnyZUzbQvP98XZMpqBQ59d1r",
			"229":"1X7aOO2cc1XWyeKbg-MH4uJpHzqkgQlpM",
			"230":"1_SVgwKZ1FB92mxuWjUqExycCiZcMIVw4",
			"231":"1dxkh2rD5-utbrwfMqoOWtG_ro-v3xsvq",
			"232":"1qMkWCiiC9gS_Lvue4Svw3dc6RVkZBXk5",
			"233":"1sxc_1nEVlcKsqhkIitwOJXpDakAs96MC",
			"234":"1xcAhR3oJZEV23Faq7t2mNbMukzTf4Eqc",
			"235":"1xvSqn03yM0v3zQuJ9BkhoQ-Sx9eW_ZTw",
			"236":"1xzF80xWrC_1BmlwSF5qpyZJyskTazPpm",
			"237":"1y0_WFUB8BBbbkAmX59LVcSUF_jvgx99a",
			"238":"11TtiUs21W71xqd01-pY_7-dd6l2HH0SG",
			"239":"17H92ujkR9e_YZb68PwUcm8taNQEqWn92",
			"240":"19e6WMZh8FMEMEZw39OU4tRnAsfWzUyWA",
			"241":"1DIDKD7tFrYsYswXJHS-6WO0BIq95bU8k",
			"242":"1O868-2-_d7rwJ0L1autxXkfxIaLLqvZT",
			"243":"1O_THV3cMpS7iFAN_XoZUzpDwnuvyPgAq",
			"244":"1SwQo4JyFLggcsyZFIB1LWzAPbj_k2sl3",
			"245":"1U-Sncz4A5GrNwiaAOU_S_HpPqcwU9si8",
			"246":"1XukbOBYn5VXi-BiaeiTMx_0jnW8PoxYP",
			"247":"1eS8Cpz0zqJhRtEHlDrL8I9FmAePPGzWs",
			"248":"1h83PwEHojLQPllEiMPu5LolS_JzVdutU",
			"249":"1r6Il32HVFBZGFT61jIqUnvloM1qxb3XA",
			"250":"1xR3KtzOX4o-HuEv9zOp7VMW72HqU9ZSk",
			
        },
        
		onshore_receiving_facilities:{
			
			"1":"1iPcu6yi_EjjcJKmLKc4B1rOsjB1mztXU",
			"2":"12gYpSF0xcp7hAmYoWnP_6fxXcE3Ck6sN",
			"3":"1AyecKoTpK9tN9ERnAF4XFyQfdQeC6vS3",
			"4":"1IGjd8oOhe8_YhHbsWxiSKF1eNBpGxxjC",
			"5":"1N2Ava1iEELN4YHbxdVAwmH4kE6J1iJMs",
			"6":"1UXuEnkcTOXzVORxRzTDlg51fCAWyGo6S",
			"7":"1W71_Z4t6IPBcI8isyCegbywWfcxZWQSi",
			"8":"1dKiMa3iz_Mfm2ruQL52ubifqSNBl3OZR",
			"9":"1KBEEW1WF0DgBwG0f4v99ulC394opUcGt",
			"10":"1M1KDmOki-NwEG7ZmkY7z2pWdwfzGYwCR",
			"11":"1V0K_cY_UHFfeNqytS0TjLOCQ4tM4DOor",
			"12":"1X0beAjGrt-IE9eeINDSo5GSc_8afz3A3",
			"13":"1buPguQQdMP3rzzwyJzAOtjAK2Altp4kW",
			"14":"1d8sTCVnY5OIA2iTdQ6q_oUuMfHKSCjU5",
			"15":"1xFWkKqKj3N2stf2lMa4BKVIKCgda_gkk",
			"16":"132GSgW8wLTVgnOr9qDA9fw7ZynlplL0Z",
			"17":"1OXl0umD9dk_w3SEiZZYlETw9tETWcLxK",
			"18":"1UrVIEQsE3hf-8imgCsXdHXl5IB8r804Q",
			"19":"1iOJ7mACBpM090TuKQbC0Hehjl2JuW2uh",
			"20":"1j-BPuBsPFtOxFkDn8UCvQm9KQMwGNbKC",
			"21":"1pNOh6z9H73aZPD5LdqQIQE2lMEqD1LM4",
			"22":"153dLDr2M-hUGxm8-lOwlMmoGRhcmbB5C",
			"23":"15qv6YgR10CgSbv9KP-JkYcc8wkHmhWMZ",
			"24":"17TbXtqorANZLPE8U4ycF2_36dQ9rJzyO",
			"25":"1EzSADQ9TL4G_L-mhPUmmZdrGRiqLaSWq",
			"26":"1MYdbArFcGLvfbeg-gXoWNC7UEKzVTA_j",
			"27":"1TwWUlTrsD3zhjDeujK72LHS24X8UF5Qo",
			"28":"1XKc1PkFFB5ooF26pBrt0Anndm5cFx2IL",
			"29":"1eHXJyzQYX1XcuALbIQEXDuh7-EUj60L8",
			"30":"1vUpnm0Gn7NWgYd4T1B6s6e3QPoP7SmLs",
			"31":"1AugQo5Csx-lLOAqeeVkT1DysTYvQbF8y",
			"32":"1ghFwc-52bo24stRJMgA87Wd6SvcEta5S",
			"33":"1oNp-jXbEB6xeA6i8ujupnQkUGBJZcTyd",
			"34":"12CzIpXI4rHCt7fYGZqE0JWLkNIBXyBoT",
			"35":"15DfBiBzpcfNRNhXMAA6_9x4fA9dHSPTT",
			"36":"1Fjsz9LmElmqmyCZjsWNhLMgyKtdvyWAX",
			"37":"1GZAsGdnvqyqJQvcbV4YuqDePgX42UsO1",
			"38":"1P8kTel2VqqMJ-jipuDt4NXrYFfACuPnw",
			"39":"1hvAQrij-GSQWx17ViTS6xookRGVCBZ2u",
			"40":"1qOGaxaa7vQCvvXBRmas_eXLT7-rsiydX",
			"41":"1rYwKU3328zSgOdrqmsDLDkzntZogx9UA",
			"42":"1AwtRRSllkOGXc-aGWh8fAwWcj9b6VT09",
			"43":"1FkSWP_fq3n4KY-oNwuYil4J15wIdh_Sm",
			"44":"1csyzgM_Z9g9H4K7VABiRiRG2Oht6aMET",
			"45":"1nPmy6T-Ah9mO6bw1OOW5W-sa0MFtycg4",
			"46":"1peX8tNZDEk7KJOnjmfS4A_CJ8UenczeT",
			"47":"1uK1Z0CAfJvvxBxB1zsB7gq4-KknTokwx",
			"48":"1yjxI3gT1cVPpzDsGE6mw3L5N4iqr8i_8",
			"49":"1fDUYnkv6fTsV4h8AxtwAPWbVhDXGGu1X",
			"50":"1kVJAV41HXIsqtf7pqTDdLOoYo_r5ZmD-",
			"51":"1JcKizy87UBJFgNeggJvwDc-W8jnqe1qp",
			"52":"1ghcsZcuIqsqEQgVZTG3PjfIsMpWQ1Es_",
			"53":"1tDcfUK09Wih9srHRfBav4uCLFoJLohNo",
			"54":"1JjqrfFBfGzXCk-YQ-wcpVCYjcnWC8B06",
			"55":"1QLikYxnYbEF3NUlMbnnMySUzmVT-mF0T",
			"56":"1mzXxqnJTHp-GPfBBPRhr8SiwSj4tchx0",
			"57":"1E80O1HuXQlGBqoWcuFtAYk7jIO3Bsd79",
			"58":"1Ek8UjvduKcWGOl1ohO6rNGXaR_tI-UFn",
			"59":"1Mij7KqjEzVsmAlj_gU9X10YAHeZKmrDt",
			"60":"1Z6WU36QWLaCMA9-ikMu1fvhU5FP42D6i",
			"61":"1g9Z5vN-DfD4zxGzPRF_6DK5FhCdWdY5I",
			"62":"1S4iodLRxSGaprRvTNGZmgQ3VccfJM0bm",
			"63":"1gGZqwe8ovNIo71mXD3vTxFmr0Y6GOY7B",
			"64":"19qKK3GgLLpTixDkFvpQ5Hbt_R5TDYX14",
			"65":"1Mr6ry7LvwyDQMM4pSyaGcJuq536DTuzN",
			"66":"1wn8k6E19DGrhqVXBV8uMSciAW5z77kz7",
			"67":"133YLOnSad9pKXY5L0lHQqdRu6ynHgu5E",
			"68":"15hOxVUwT3ThK1vw2XF5WM9CrEt-wCGT7",
			"69":"1722HoYNs-FkaUpCibokxuKewn0eC5Jh-",
			"70":"1OH_CDBafb_bUzdKfLSpqbq3FmZUZXUFh",
			"71":"1R7vKaZsKggFM5xn60Kx2MIzIlUCtf88U",
			"72":"1RJS391qX78WE3_3xh8dGAizCx2G2XL-V",
			"73":"1W7UaBRZTaUGChAhaq6yBMK_DVMP07NzS",
			"74":"1_4bZek9ii5Hb6JW5Pct-PeM1eQbHeFBd",
			"75":"1aWCMChjiHg8c0lfMK7G3NFoORHsqNJAt",
			"76":"1bMvDKZ8AYOuj0wQLxNZYSMrZsPqri4vi",
			"77":"1diPhczuTh735oCIvtB9NoUWitCii4jsq",
			"78":"1nHM2bTx3GI19OZ_Bc9k2O5VhEo_ls2An",
			"79":"1uPpN83y1vgIUJDHtywV-gq-63vY0m5Mc",
			"80":"1vJp2uNr6VV1Sm5usc53KN5j6aX4i22mK",
			"81":"1vq-BCGTYRYuLuibTJm0OuLaxB7E0rb7c",
			"82":"1FUm-gmhF2B8qdGJnDaMKi4Jb3qKvK0d0",
			"83":"1MIbEKxOlgFRj8IfPOxX0XqCxw9dZhfNm",
			"84":"1W_Y8EJ9nFAztz1VsmzZFtxaBP8mL6TLt",
			"85":"1XP35-phzc1fNuC69aesnzDhwzM_bT5EB",
			"86":"1dG9CT5KOX_02kndk7pdCOGi7RopJfGRl",
			"87":"1fEZR7aR5oKiScQMyqtLMX0TNb6_vApkt",
			"88":"1iPjUuPI4QIiNvwtKW-4IXI7k7jsO1SfF",
			"89":"1kfRpB0421RWfOaIh-Xdb5lNbkqhwwzWw",
			"90":"1ofdV3z0ZEwuEmlP-658hV_VMYxtPGg8i",
			"91":"1zlmVEQw2CWlc-Qw3OqzpWePcGymeSxUQ",
			"92":"16OUIKDns78HyDMtrFHzNLi0aEghH2ckg",
			"93":"1NRQQ7KfylApmh1zJolU8lEWrWuKsBtU2",
			"94":"1Oyhkdr91k5VC6Ru1zmFLf4jjuXtLN5Q-",
			"95":"1aGdhFaYg19xjKA2Z1sYs5FJoyKs5neIv",
			"96":"1dtpIoYONyi5tM1oX_ntKnYa5c0JO9VMi",
			"97":"1hfVv4AyOX1z0ix9muBq7NwUqGldFHlu-",
			"98":"1lo_SCbje-FycAGNj5IVQKSB01afxLVFp",
			"99":"1mFmxOuf_O3fnaePpZPLn9_ZxJazX50Gc",
			"100":"1qGszVmgR6liiEfN5pODH-bliyibvU2qj",
			"101":"1tamfgm7gOR0dkDWPXzXFyyCWPRAEGPIN",
			"102":"1uVgTVxxgGTi15iwme6sgNBcUXKRuvBf9",
			"103":"1zOScCOlMQruf2jLKSw-sRamt1Xn9c7GN",
			"104":"1MkzfOcKFGNp2NjbZifJs3TcHZQumLuJV",
			"105":"1TrvkGeTIp64LwvPf_UIHCNkUnto1518B",
			"106":"1940Q7Nftkdt5kiRse3g3nt12RfNkQbrV",
			"107":"1APx9p9jGIWMs533Z5EuWStr4LdvF3Yfg",
			"108":"1ghnaKoEG6yx9Gqc-lbgzeuo5B5-fHCQd",
			"109":"15gYC1PXqhVaG4j8PYJHNR7K0fHYGB-ms",
			"110":"1Y6pHV3qryImT1FZkhv3Y_vrdc8awejES",
			"111":"1eO7vw1lmjc_IRD3w03piRjoLU9XdSzpP",
			"112":"1gGQhTDGhi3FKH1WmxTVkqlOb9vEWMhUZ",
			"113":"1iAcyjhgUhxgF16dMl0UjdA-sH0xm1jjp",
			"114":"1r91MULqvIWcxW6AB1oagNQGUw7twlK8w",
			"115":"1vyJIDbQ_4kKngP4LVyTIuYHuZIRtIqvp",
			"116":"1xjywk5vupUt-j-2DIs1xgH70kBLs3Abg",
			"117":"1yx0eYZhbKF6Q3RT49esdvxQozNrSVX8Q",
			"118":"17gBJPpCpJnlgWYBkw17wN86l9B4wNyrO",
			"119":"1wEGS-RZ3Rp7H0esIIzvq_ZdrXW-_naW8",
			"120":"1LnaDgoinj-PBNdscxfCP1lpCZNbQaVEo",
			"121":"1kwYxaQfY8jrz_8Y5L4z__zss43aS2fUr",
			"122":"1057FM_RXB8MKDgWVtxeUCvH_EYehaDO4",
			"123":"1BfgHIB7nrPx_E0QhtITBaMDMgYoqP9Q0",
			"124":"1PNe5ES4oBWaUjNZYVXXhxT3cMJElmhTm",
			"125":"1VuWzSIdgIxQiPKsikA-6DgmACHluOVK6",
			"126":"1kx7wm5vaxk5Tb62IDS4YdCbuiubySZlB",
			"127":"1p20gIcdPXenxirpFZ4T_1boXLlK7mEAg",
			"128":"1us5Uld_2ly_8NWZKIhihjnmul9eSMrL3",
			"129":"1v7X9bu7fKL2Qb2dMNwnD5pLAnoFmn_qX",
			"130":"1-YyzxGql9u0yf9sfYM_BoxguORQ2LQgi",
			"131":"15f7W0k8N7rH2bRI0cgMkgQps1GjL7XE9",
			"132":"18_3MyIePatUCsCOeWD_hIyXiJDd_40Q8",
			"133":"1yHfSToSQpKjZ3Soa8vsRb41InpuwYmyv",
			"134":"12gN8MCmeePy4EF7mKXfavQcWBF7Q3F73",
			"135":"1qoT4uTm_KYoqP1yX7z1iwKkVIDuLSYw4",
			"136":"1OeHw9jhMAI5XmAWNxMLLVwoM_6WjJgQB",
			"137":"1Vaz879IY8IYXGNxH7HKZL9PQ5NaxCPqD",
			"138":"1I5BXhr-rUHAoWIM1OBzIAVDwNym9IjUA",
			"139":"1dbSRX1ccsrPAeotHSdklbDc87ZzCZ35d",
			"140":"1UD-8cS0nXHB9d73PCZ-IEX_7DaIc-gc4",
			"141":"1arrLkHZ2E0KbPazKJ2viDL1QFO2yGb2B",
			"142":"1c1j8NNMvSd__7x7OA0YHTsijl1qk6AC2",
			"143":"1PkPhv04qMqAE5UcfJnX0wqRE0jeLeaRH",
			"144":"1jB1kpK4GMZEGPEkVTeP4detiodJfEY7F",
			"145":"1u2oQ99U_13fbKlGpMzICXOGgyZ30TP6l",
			"146":"11xAiTebUhlc_Zxa7GjOjea84AqEI9tsS",
			"147":"13dfXOlVkZB4yvd5yfnih19gHawMp8aVD",
			"148":"1AafeSAhJstEYZGLPlFtVFLRYa4q9SZfn",
			"149":"1SshTUnjpnOz5eM4vEc4-jjWwgnRp7RuE",
			"150":"1V3lPGJgmn48s94scZUPyDdfpg-S05-sB",
			"151":"1bNH21hlb6gmNzhM12xJ-Egn4a476NKxf",
			"152":"18eYnv99AEVggHlW_xZJpLHlCMtB4HH0B",
			"153":"1tBem4YBhta3pk_-IbvgUrOvUAX_wgFRf",
			"154":"1tlm-BPxwanBimDZQB09p4V6h8cvHfNpE",
			"155":"12fEizVibuiAhPwthGI-Dx0nnfH_65Wfu",
			"156":"16X80xEnfeO_PHSB6FoQ7v1Fb8By6ALrq",
			"157":"10OwTCTAaesnaL4QVJiHwxJqkMabKcskz",
			"158":"1HwD2741Rbid1R9W49HqLZfiUedBPYEKc",
			"159":"1QDiXewfJ4bxlb7cVwUO8cfKZfpZPyDiw",
			"160":"1Y-8sxvemxylL3O7JU0XQA4RxndeMqp86",
			"161":"1YwwWmpvo0sXN0xY3ulYkd6z1Mb4e811e",
			"162":"1bZMH5sT9kPa_O1Po92Um4omF7QYtJRNl",
			"163":"10RECAwR9i5Dql9D_O-wciSdr1gn70Jpq",
			"164":"16aQ0V5jAJKYzf9hCAkrr_sEedRa6b54Y",
			"165":"17kUURT5kHQtb5Q2byNnerDPC3l-hBKGP",
			"166":"1SL09Thysch6BUQbMaoUIqmw6p0MBbSY6",
			"167":"1UF_ZEjwEbSSzpHUyviBuC0ybAUSroDgn",
			"168":"1y12LXrAr5UyDiTOQtdn1p9Q0ac-YMBwQ",
			"169":"197nkwBrNPDKOp3QOgP4x1GyikGvrOKS5",
			"170":"1qdwMOZmPhFfWUrVqG5JU34IuqFqHe6FD",
			"171":"1-m0CYzbLluXL0j_xAfOjmkOoa_TbW26u",
			"172":"1AfHLkatOwsT0tp_hIyIU8e-37g-Zb6Sx",
			"173":"1LJSGQVoTeTWTTIFJePN2vjeGoYA7Q8EM",
			"174":"1aK_3nbtcDPD4DbusNMa1TU7wlIxC5MwB",
			"175":"1pz6HGreorw-WL6whuWMaUxiGggLHoEgV",
			"176":"1vUGyAr0u2qMYQwPynepWww8nRC00Imgd",
			"177":"1XYIW3qyuBsFPuEcjYT7mN4i0HOutJx0P",
			"178":"1zQ7kUuByXOkSwW-jmyWhnoBSc7u8iPbS",
			"179":"14fgjtH_mUnepi9iUz-AS-8OHyvPsmBch",
			"180":"17aewcVPtM-kzSz6R6oiRTN_cAkxpiFdp",
			"181":"1DVkurKoK5_Zg7sXuz1DxnrPCJwNaYx00",
			"182":"1GkzVNkJlBBNzVPHb4O_01FR7dxaECZ82",
			"183":"1UwhDGI2B8zzKVMMF46duXeTy_XRLApSl",
			"184":"1h3k1ZPkqqRYOe6gnpnKzNOqzUUrqHGlO",
			"185":"1mCRLISdV5Qgby4hDoDw7XLFYoMLCqCMD",
			"186":"1BQCqNhBI0xPcEsw2DjbsCD8hKZxDzGdy",
			"187":"1HIik4x6hT7cyYV3nEYBDNBvfmnwimOcN",
			"188":"1PK7Mck10Z7EnI7cmnwzFyYJBnJP1ba21",
			"189":"1d6Z57CTb2Ob3ToZRWnpI0LHwb6yuBCtw",
			"190":"1nM1i01fNzM6TNRTkbZi4ig-PL4yiB0gF",
			"191":"1BUsE0QjijBQieUBqXTqttnCWkd451zqG",
			"192":"1Ea3uBZgCUzpC7BEoO_8L9JyPKp9roWpR",
			"193":"1F_7DtX7q8zMfsB1YH1XmZi3FVYgAU4Ri",
			"194":"1yvl_RrzXA0Uv8Z0opluFbUSqMU3co7aR",
			"195":"14dzsICW0dBmEv7ZgUioW-LBeHdLWkUfX",
			"196":"1HMWWKYHWvU0Swd1IL4NtCf3HnvIuVZ_8",
			"197":"1SFEVeFSUnRtn1cseANYOYxmnewHGPb5d",
			"198":"1kDCwV_5fIxIarTzRe9Edlw2LeWPy-z7N",
			"199":"101Zk9c1GE8qI4O0F-iPw4vAKiIvp4I6h",
			"200":"1Sz7bgwJlL3htDLFXTFfc0BYErXOb0xMM",
			"201":"1ka98v8C49hhPFxixqt38vc9otTlwli3x",
			"202":"16fMGv51d3mQZKCsARF2Ot3T1QHtOkj5C",
			"203":"1BtbWqfaidyf4UCxi2kzTPYwvpgBxQz-f",
			"204":"1CwDcMwBYdzAJeDvC7WEbL_U4Q_rFlN3r",
			"205":"1D4HWOyfr-j9KiCEhhqKzA2hseauwGgtR",
			"206":"1GD09cnlOhceSq0Y96v3Bd6D6JOOQ3eeZ",
			"207":"1GJH_7JAASCrvSoIxUtmTNhDFQNIvnNO1",
			"208":"1IJzvmPl2XCydEYmAuLGQuS94qFF5MwEd",
			"209":"1L_8phrL3LTe3MbyF_bedAW6EdUfv2ZoT",
			"210":"1LsXoqFlAD7c8Gv2UBdjuNe7F1_8S-czW",
			"211":"1ORRtj76uH2CQm1S7cQIgCbHnRenRJ43O",
			"212":"1OjtyszlyOjYvmFcVKRKfh61f5SexG_fF",
			"213":"1P5ovfDatGy3NRzTgy5YOqLMfcKocFcfR",
			"214":"1Qh1BO9asSJ3b47RvywDPxoPEk-RI04ix",
			"215":"1RnWno2DT8RZ2RbBDhGY0958OXTnqbDrL",
			"216":"1VxxgRgIYpMF9ldUWJeg65Dfd7jPlPSnJ",
			"217":"1cGLiFNYXiqO6XyZ97QN6BJkuVakXiVw8",
			"218":"1hb_IOI5RVrHIJMlQZwNNtP3G5HXcUEIl",
			"219":"1ov8CJdyW-ay-L7QnXE4ul-H3BfYqF0V7",
			"220":"1zv7bKvzWwsVTWvxn9JoejBRmV_V9kTFn",
			"221":"17z3VSPETnioXMsybEj5zlZPfEUHjLeYN",
			"222":"1A9dwyJZMkiy8Sw9OjlgfrwVTjAifHPLP",
			"223":"1FfrXqmXGZ92nVPyM3zDejtxwjaUTC5XR",
			"224":"1Fioi-WSitDF9YZoXZHIlTWGYc30IvhQf",
			"225":"1G-nQ0BlxJ1r3sXO7rNRl472TAIW50z0l",
			"226":"1JfWpvEkVXPufu14rVtqZTmBL_pnT7swN",
			"227":"1P06YWfS3lb8t94aMfNVGDBgSYjymUYee",
			"228":"1tpK7_1IvFEgOEimRAh5xE1-YPeUI0Qsz",
			"229":"1CWcL4yYeqllqrn99QFpdPoM1kEXxGSsG",
			"230":"1Do1mrQFCDS8Ip_ri_P0vCPC-XZ-Pq8c5",
			"231":"1KWaW_LNf0VeNnAs8Aa-D36qceh3i2Qh5",
			"232":"1s2Y-rlpVUhS6REcqlIbu-Pyvs361mQrS",
			"233":"1ufvPE0MBgIOgah8DO2Q0ntkEJF80mpcg",
			"234":"10RLpl-v8y-JYlV8I_g0PdMGANQTRlN1K",
			"235":"10WTmyF7JqYlL_P013O8e3MFmxStR9ttA",
			"236":"19Eze1b95qiai5JUw3DDX3MUDgP3KM58I",
			"237":"1P3XJWDNZ12wPvUgIXqxwSjhSN6IXYBtF",
			"238":"1a6Dlsj5Rs9lQMLdpKck0hc5WjUO0oNo2",
			"239":"1fMhDh3jB7DMUU-FZFHUiV4GfTb7A8c7N",
			"240":"16SzVqBV0GGkeN-Bjyuho5NBDm-0JYtJl",
			"241":"1hXbYk8Vj9llXAgxgq5e4DMJWI6UNsHVu",
			"242":"1xQV-yosmGb11LFrUSFaQyyOa0tv8E6gU",
			"243":"1JQOv44GyuhgXoo3YMtXPLUAs1QvRHqXG",
			"244":"1Ms3-N3vaLqpvbTduoqs0Me2AMX7_QUlI",
			"245":"1Vv5DDN7vO-ylEGCTLajIUWVfajws0rPs",
			"246":"1__2ZPd-Qk14-WKfkgTnByNGDMq360l1i",
			"247":"1crlVqJlwZg1n4g2UzP0Km-7-m-TfhYEO",
			"248":"1k-0xT2-mzy8Txf1J3TkBe3qaj3NT5yYQ",
			"249":"1n52E0qAYNOmEivrOUyCVvsdpMFAHsRpH",
			"250":"1uWQBsyJDTDVKU2DGEHI9jeXILWVVKzML",
			"251":"1PA31gXKLJy0JWOp8S8vwOQTK1hDvpMG2",
			"252":"1QLyo7Ff3fNJRoGqB15RKKJGPueaIkWUo",
			"253":"1YPlZCMyLnrG4nXFb2KWZADRT7XmdT2fU",
			"254":"1lMH_B7_m0jwLeTue6aPUt3Ui5W2mq0br",
			"255":"1-qnw8AM2svBgQ2mhIzKcif-pFLiDt8KT",
			"256":"19pXrIyApQromwPOC0UWxJGx4_r5D17vy",
			"257":"1AFHdkF7zKpsmhvYavb1uZjzt18sZ5UxN",
			"258":"1BSzSBeJlksrAIgevW00dAgrDCQH59IzT",
			"259":"1QNQEiAuNgOfmDBHBPMs-ldLUI9z2b8fI",
			"260":"1WFd2wJA6s-yq1bt9drsBtw-ZL6FNuaYW",
			"261":"1cyAdqQgTra-S_cWntYTRHd6REPHYsGgi",
			"262":"1fooVSHhuim8Qx5kH6icf06vFwmHBMWIE",
			"263":"1pm4-JyshpYt9XytuDRvXTtpOFBZSf9bl",
			"264":"1tZwUIzJH3m4lzqxS2qAcOahUUZRxVThM",
			"265":"1v6AvfJGmoFjAuZ_iFEpI7U45bVRMgeWn",
			"266":"1w4lHqXx2MP-rJrZeuvGK7kCSNbVaJM0l",
			"267":"14X8j0qN46J9XTpK4YfZkBTKeW2Ly4tph",
			"268":"1EHGkpjDsaz_ImMf5kRoTbH8XnAN1KNy-",
			"269":"1Hi-GSJprZjhQCKgpclCnvM5FN1aNfKdV",
			"270":"1J_icSm2ys30CBGeeAITGkxxR4by2zeW9",
			"271":"1AkK1G3XEhbZuc_j2ZslU6egxE8rG6yzp",
			"272":"1E7FXivFgwH9DwHz1RR4Doi0vmN0JA9rW",
			"273":"1LaYVfka6mZSL56zKImzz60VtbCcr_OTl",
			"274":"16EZGQeFWvkuJ-8ut7luCmyL4pd5z1TxP",
			"275":"1FYLQyNPvPS-ZFJvl9zJNe-BT_rypZ8NH",
			"276":"1Lmpfz6FkUrwqpR-luA-sUgv0-pdGZggR",
			"277":"1T5h5hUJ1uCYq0gO0XK6j6L7zfQwLOwik",
			"278":"1Tg0KCxyid1unOyl9u8crdQHLF7NwN40D",
			"279":"1UoCZXu9VUx_tp-L2bBPD3Vhz4-yPQnwZ",
			"280":"1V0cDV7pFQ-iGVk6sOuY-AeAdP2c02PL5",
			"281":"1bz4ua-0PaF_Z3CYaENburwWy54dm5D3q",
			"282":"1dghWPsJUpwJpTnEAVh1gb6mqGEhuMZ_K",
			"283":"1gQsgV-IKry796PxtQf6U482PicHhQU5W",
			"284":"1llEF1qTuMfn3nB9ng6KmBu3Ni1VfRCkX",
			"285":"1yUaCb8nn_ObZAXh7qFnPg2wTGfJAJsHi",
			"286":"1Fw6iAD9xkXU14uIDzSLZC73WvHaseNbE",
			"287":"1IHQE9pkf0IQTMbazyNFoww5C3PjmdOvv",
			"288":"1Veqn9O5F1D6sNs0NogclM4GqGL-2YYMi",
			"289":"1aC1g1dg2EzTF7e6WtXTx9dEKPsi9R2Fm",
			"290":"1bB-oevQehGSonJeZpa_lvHVgUILPK2q8",
			"291":"1h62dP7iBTBrBna_ITAuCAMAIZn9hDIZm",
			"292":"1neti9uNSJ3wAolKtXRvu116xQiVYOVSy",
			"293":"1nzJ2w9t0oiz5uiepgXRdy1DXuOU4Fdan",
			"294":"1wy22oHpKarnqfHZLKvU0Rnazsy-ULDqH",
			"295":"1y949w49be76Sd8cAVhajSPErrnvkB28Q",
			"296":"107N13YlkB9utWaAUBXgVpSpKw2t7tV2L",
			"297":"10nEBdUG6xaiwllHldW2kMS5CUwxSz_f2",
			"298":"1CFyuGDzTIayNKPGrx-aOeiQyxDubBS4j",
			"299":"1OmL94rh84-Fl3_D0rnBP0zb-Y7Vub80n",
			"300":"1RA9Cw4UzeIiHLExe0D4Tx93wr5T59JOH",
			"301":"1MDxSnI3RGTjnoNBqOKFCkBdXYnDQ5FR5",
			"302":"1qT3s5eJvinM2c1W2G8JGtSGiMAK7g3MI",
			"303":"1rDGpym1VMu3K86G1rl_4JlCEzoeR8Rh8",
			"304":"1xNxSG03WD0yYM8Y9WNyKEmRHwYhf02HS",
			"305":"153-IT16r1yRyTy-f6GDYFo8_GdkSiv25",
			"306":"153wrtFZPco-HAp9HPxsd2clUxOafa2TX",
			"307":"1DlVoxl5pke0Zl3ddU_OqmMTWIB1ejxeW",
			"308":"1F8z6X1Uweb31dpGxOyNzHtiGuUsDpd61",
			"309":"1Okeoub27SLTfP8RM5MSur0Elu9OnrBQN",
			"310":"1P-LqHNDDjrA99nF4gmAeL1-Sdeoj6Dg3",
			"311":"1chz-m7sk3O4QdNJet4ykC4fRjUzjAthq",
			"312":"1fI7VR3QMdBw8Hb4GRAfSRJ2y9-SSY8RQ",
			"313":"1j9O_2qwmyc1keXeAu9jwvqAR6IxC3U3h",
			"314":"1kGsq52mzLfqdr7csUZhXLsTRCBsyD2UN",
			"315":"1n2QCvTsr-AE5zAnjfxiZCFMIX7MRiDFJ",
			"316":"1uDYGU0B1u75mIpjn_hDw42mIWqPuZnUw",
			
		},
	
		peta_laut:{
			
			"1":"183nQM9mV5_lYQjMY7ud2-0cG3aDkZCGY",
			"2":"1SmemO2zBRPu0SajebiwyI2mx21Yblqm9",
			"3":"1a02C0M-sPYsuNn52Hg_PAne4rXpEfC64",
			"4":"1nQ-c1MsK81ylfFtYbudbykbBK0maBwRX",
						
		},
		
		pipeline:{
			
			"1":"1I2itEOjJtD2-ZUwnhunw60lhtWMNHZy1",
			"2":"11JjTLB3rexlLD6TmrvNfDy9bhaAl0-V4",
			"3":"17XXHUmfrdMvqtUU6SuAt_iBSyqUZaIkQ",
			"4":"17_7HAT9mmiF5i_g8JggJfz9r-sTruoUm",
			"5":"1BN2daCwU5JaLCF-HL6ysHGImiT-17Fe3",
			"6":"1Gp-EWrxncDz4gHEANuhY3t4vVYR_Zg99",
			"7":"1Qsc3eQBvUlt6i0XyhDj0IqYARRsWL8G8",
			"8":"1_bvZNdcqLrKciwINcjqVHlC-upYlqgJg",
			"9":"1gvYiAdW_fZ5AqYVc_EmSVSI_nyz7kVT8",
			"10":"1qimPBbgb8grP273xSI0aceZt8-PEY2Bb",
			"11":"1sGo56VbicTLElH1LSFh5LS_fQqzA4nlk",
			"12":"1yUqlX_KMnsrwBQTetvyaoCpKwvLoQQOT",
			"13":"10pz7e-NhbOcMNSfU8I5UWyy4-6hdm-sP",
			"14":"14Bnp-y5A47kF2A8v-VQDyoVC7LkP58B6",
			"15":"14xIl0-mf20K8Cg9vvkjY4D-SkC1YOmJE",
			"16":"16YYtZazdFg6JHAozpriWvcfXw4xJujWu",
			"17":"188v6ZF0I2gEpRJFUWkTrKSOFilycL6A8",
			"18":"1InGIcuCZ7IC0tdBB2AkNss3Z9j2E8IQi",
			"19":"1Ji023dPAkALqBOnhAgtgFzQdfENpz84X",
			"20":"1P-Lg8UBcuG66pSFh0W8JTncMT9R0go3c",
			"21":"1PeYaC2zXxauGiqInwnMvH453F-TN9-sA",
			"22":"1Pv34zQ5ejHi0tFimrT9wkmjulcVQiCIz",
			"23":"1Y2Vvw7-GjzUoTcK0GwP-aC3zklC9Nbo0",
			"24":"1Y3gRrbR0txWHvwbGCgo42L8YfWFtbzk8",
			"25":"1ZtZqHxY3HSeMjfCcCIP7oKI41DifZB_N",
			"26":"1ZyVM6DV3RY_DEfLyGtmp1O3T8-JO4u6k",
			"27":"1aWpCFu6Wli_JHtRx9AiVAFVb4wwgPwg3",
			"28":"1bKYXQSX3-r84VbuNBzxPi5oSG9bXMLGE",
			"29":"1cpHSjVkTq5bikCt_SZ8YZq-UtCvxwpEL",
			"30":"1ebTvBhyb7X4XWw0O6uY0_G9PEjQ1awoi",
			"31":"1iVuRP5Vsuxb0T98yI638mgdIqOKREvDL",
			"32":"1lbBS8lI-JDdFkv-949CpvHjCOnoeqJi2",
			"33":"1mZHVWfGwCwnXih1XhhhACojOuyGDQ7Gj",
			"34":"1nlGam5D_SCa32H7DCMMG6zH097EQCTir",
			"35":"1q4zgkurW2B_ikdjGKVik9GgGokK0E4My",
			"36":"1vDMnBkn8h7CkdRCRmOXeAASc5tByoeoW",
			"37":"1vW63-9LyZ0aqAif_76EeD8Cj8-kVigzf",
			"38":"1w58TEybL5yRWN7BzfJp5VYP2n5iDMvgi",
			"39":"1y5Vc-t_Nk2VFCpO3r6JGmtGRjwZZFLId",
			"40":"10vRJprDgCJaxetJrvcBJFQTKkcITdkyX",
			"41":"12kigektjyZKDElqR0PydUTJGinYKtWj7",
			"42":"12mw4kXe4cTTtwqCCyK_etbgC2Ciol5aY",
			"43":"134MuP3rCe2Q5y0Ll8AJcTsnsA2jXO-Pd",
			"44":"14I_NCmIujIX2fR0lvZY_39HYWhKgd56G",
			"45":"19ABU3_CRRUib3S0FgMVirjO7eFGJCsZO",
			"46":"1EkQ8tQDmcpkdtaTXf63SXR9h2lDfYkut",
			"47":"1EyGUEbhoUIkbAewvVJ0tglCfDqG4LKgC",
			"48":"1PLuHuwT2QYLw58HPCR1PdxWzMYqWBR3h",
			"49":"1Q8RAChvxIrz8MDlDYGu1waq3xyzBf2F-",
			"50":"1TtR0xbfsDF_2_rpeiufVcczTsOVf9GKz",
			"51":"1W0QxkkdaA4f6tsDjl3gfRXiP4C81qpYd",
			"52":"1Zv2PUCnfJDwWzp7J5_xN6BrxieAhmIHv",
			"53":"1iElDckQVzv0_mcQWrNhrtYgUcNOZhQGu",
			"54":"1ifZfx0KfBoQ5RDnDMlP-zg-0x4sDiVNA",
			"55":"1pOAG9jzMbr7mITpOLlhU8aMksK1lgUEk",
			"56":"1qf5_D9Vh0xKTH8H4rfbL88eellWW-EgW",
			"57":"1wp_STgJ1CLCS6gkvBb63ygrWrl32UGHJ",
			"58":"1dokMGHiiPd4PEqtrhClDNzA17S_MrUQr",
			"59":"1r5oj3YK2meXr7TGfLNGMytZDd19t0F4o",
			"60":"1000tCqYjW3gml8x3VHLCu2qvGsj3X0hJ",
			"61":"10aIpsZVrwoThIuVbvgfggg_dPuq6Y9kn",
			"62":"12Dh1N6tmy6YpE3uNdiRHwc2cHt08h5fH",
			"63":"13f5xXPqdLJjJdfYRZLoxH2i6iVeGvuz-",
			"64":"19L2BzKs6oloNQEBE4_wdOHLnlF-7u79q",
			"65":"1DbVY3yBlX06zyWWEszAS6aD-MGJjKXUY",
			"66":"1GbfozKAy1cQ_PO-CbsfD30WE863zkcoF",
			"67":"1M-HaVuesAWu5P1ArJqqSQw4xoDbUyiOS",
			"68":"1M6VXyoXxI8oCJYy0EEDs4cBH-FvuNozZ",
			"69":"1S4XJZD5G9Qkf0_25pYIzmi6fdUgv1SXr",
			"70":"1Sq2zdTHF3Cj2uhqaT8xppWRlhh0rgXX5",
			"71":"1U-py3FunRnsmppiTDiUChGcWJ1n9lj73",
			"72":"1Y-3iZNDi253JvSbo9vVR3Aps4f9aQ8hp",
			"73":"1fpRrADasYqLXsQmegYG0J_foc6Ta4h7r",
			"74":"1hQleRWJU8jPq5_kiyizbXIRdDNE16alx",
			"75":"1pfItbVBrsk3ZJu3Obj1ZYm1zzGGcQF1M",
			"76":"1sGeo_Ul_2wx51XuHJYqtk89fJodaZCZS",
			"77":"1sLr-4s3o4ot9Ag74A_1SMlqIXnwKktvS",
			"78":"1wJI_N9plW7ZrLaeM4sU4-NnosuLlUhFr",
			"79":"1yzBiEPffq7k3z6q4XXifBqSB5uKH3RW2",
			"80":"179Qfy-OjC-MRGIezKCdKDIGZ_a19U0V9",
			"81":"1RRcOnoMuCV3rlM6noAeNvFnxVSj0uVbq",
			"82":"1Xtom_25sPWahdHGr_tojeXME9wb_zaUS",
			"83":"1iEQ682bDi7a-0tByIBkewPDZDz1V_LZI",
			"84":"1xaAd9BeaAn-GYK82i6Rbc3LQQqony2QO",
			"85":"1wWto3dCAdbQ3wm2BxPqkSrBeXX7Zf6AR",
			"86":"11keFbNYoTgjbL-45fhPwoFsOaHEW7xNT",
			"87":"1HXFCrA-DqBtDbVQf-9JqjYpZzysGM0Kb",
			"88":"1Sz_VLfmNdwo-Jo3b5ZriBy-mYM_dOdur",
			"89":"1e0KkPV9_elLxd0IWTyok5pjg2GHIXFNH",
			"90":"1eIocrKQEb95TmuoSPDfmhnAru5Qja05P",
			"91":"13fdZJOovEClfY-pnuPe9ZmDNXitw5Ohi",
			"92":"1Upw4kwkGheVuNOW5XEaAHvjG3_J6gUlI",
			"93":"1Uw0XoIFnUuxbZTp9sc8OngScw-6XyEz6",
			"94":"1WfRNuYfap_aXh3eG01AAnkSyh60UdSyh",
			"95":"1_WjTOhvH3495G4vgQnl4ooDoxyb8eSHH",
			"96":"1asFuRyLJ4oF2gdOTGYWYl_Krymp4YqMZ",
			"97":"1dJHxRv0EtJ2vERPkeZNbnyqLyejHQO7v",
			"98":"1yiU__ZQ-IyB-pOClrgs0_V0NB066CVsm",
			"99":"168wFkcvqc7SkwSvvzBWT8tEeG1c_Hqme",
			"100":"1Bwd4GWvO9rq52VaKJFjUPe2sMHVO-rXV",
			"101":"1DdhBDjzaVbAGUH0FviajbbyO_8QISmYm",
			"102":"1Kwr9PRQ5HdwOicJ1o_TMh0J4HdcUrFnh",
			"103":"1MfOO83vvyN4Zu57sJlz6cUQL-4U8GdqF",
			"104":"1Wy1o3kpf4E45oHnFBO8kq-KOqV9A7UlE",
			"105":"1njM4ZCvHAlFJiFNJbe_GYBZPrWg_2g2x",
			"106":"1wXBVL2VgLmBDbIAvHycuDeNyEzpuUsv4",
			"107":"1z7XdA0dumwrexyNLzkkxHwqadgfKFEUD",
			"108":"11wWuPrM9SDzmZOW8fuQ2f5JYBk3bZ4JY",
			"109":"1DLTqnFNAIF_RJf4G5r1hbHH-gr8xSuKK",
			"110":"1FVg14_hlTuxp-vZJOjm3XG9gVBL2X09p",
			"111":"1Z1rng0o_MqkevHc2qVlBCyKYzWpIOSC2",
			"112":"1h1Dsv6aRFKvhW-eZdCLcezWg9TLpjWrS",
			"113":"1rVkuOyM2T2urzQVmMHfIJs0DOQ-rco_A",
			"114":"1vdUneLtEyNQVm7J7SONkgRbHXHJzaK0u",
			"115":"1wuDABBiYSL1ZJVzm6bVUz987-psvZFzv",
			"116":"1zJ6d-tYVRs2NTBoJCmCcC47lemOyB4Kx",
			"117":"116s0TuUB5jcOE74OIq3nof8h2xI_fkBl",
			"118":"11AfPX2irtu5Zi8fslPxVu70nJM7sT_SX",
			"119":"15IwZrgnVn6WCn8c7bUqgW13kgsP_7_WU",
			"120":"1D10f91HJnybE-8Bips3zgAtaxPmsntt4",
			"121":"1JeffmSmb1p--T-Zdu-knCoUn5alVHvZp",
			"122":"1V7ph_csm9MOYNVIUU5VqRDcUvwbUi1b9",
			"123":"1YbxyUhme20VgYUbqsUYk-rS4slCKyRZR",
			"124":"1ZMuc4g3wG9l7lrprLM6eDPPn60X4Y_t9",
			"125":"1b-0DRkAzd1Rp6MNwvdhGAtPyytsMkEPb",
			"126":"1bLKxKjCgUdHk-a_qNQXdF83i9wf_MvVW",
			"127":"1cNpgVYxxEMsE0v4T925M3bBmLzaNeUWG",
			"128":"1gk8AN1t0Su1tfguCH7eKgXohh4FHFjm0",
			"129":"1hzdDzxeYrjgfhTXs2zoYoivlrPTuZVtJ",
			"130":"1oPRHf6TCja6ubaRlarMhep7qPlXCxdzG",
			"131":"1wSXd5ZawYdidUPWU96yb67tsHmiNcWdi",
			"132":"1wlfQPVGDpbOYohEK5qYKBZDdtsv8z0mV",
			"133":"100cadZrlh_WX4JTJ7Di4SPXPgMQn5u-k",
			"134":"19gIeFEP6rxz1_0GsV7YjF8VOJucX0Uc-",
			"135":"1D8kjTlio1s4kqxJfNxriAGHIIWXpW3Zr",
			"136":"1DjjIa8d59lWcab7bGuV-xnBQC7j07mM2",
			"137":"1Epw_mfFh3-N20PMMvRStdNmFSpkfktIM",
			"138":"1IDxhiFpE4mZNqJf0RPKAaSwhq71RhDy_",
			"139":"1LK8HCrrGQ9zy6xFyi6-DBH_6hNdhsm8q",
			"140":"1RijKicVynUplHIxelgmWUWtUVrbgfuaS",
			"141":"1aLbvTDnFIBSSkMwWlrjeXs1_vwGBVADm",
			"142":"1b1vD7mFOjjsZrQb4r9dJ7hqz46fYo_lX",
			"143":"1cLopOI7NShUabgRl6ggoPn8_VhGEpPQ1",
			"144":"1e-m0JHoqWkI_DgL0WyeE0x7HC7uvXNHa",
			"145":"1igcMbE1fi3zFLMqjZ6oc_nrSoZ_HBUT2",
			"146":"1jpddpUGKGzFfdRa7zS-uJweUZplKxCyx",
			"147":"1l1HZwbdK2bYfS0ZKG9HkYcTZDfPBFEOY",
			"148":"1tU8F1YaPt9fox8XrRcIBdaU5xTP4OxM-",
			"149":"1vNsPebjIuwkwojMufN1gpY6ldkRGluIR",
			"150":"1x0ZBV9X5Lwh4ugW_d9IwdxR0Kd-rHhp-",
			"151":"1X635Hmsyy0ASVQ7wWc9x2kbvT2dTC0HT",
			"152":"1ca9nF0mjJl5cKsn4eyOEdEDKpX12xsbk",
			"153":"1n6Xi9U24oZrLtFJkB7ItG4t3RdwNOD6E",
			"154":"1vBlDDHC20gpfI8vPx8qpSczGpngTExHv",
			"155":"16Tif8lHcOS1KiVzo5Qfw1_UJx48wyLvj",
			"156":"1GXbKNAAlUzh16dQZrdIGFO2-nwiRzQOR",
			"157":"1LG0-uqd9jenm6Um0JEx5x2r2wpzIOwWz",
			"158":"1VB4-Rf9gyCFJcY1QPh9kG2SzgjGg9XqF",
			"159":"18BnWOwsDBuMfWn0tPtfvnZYCYx7tkGH2",
			"160":"1CHl1YmXhTSpTECDK98V4R8YKWdDdau3O",
			"161":"1MIAgDw6pV-jf0dtEd1C9ozC8p9XogzQK",
			"162":"1lN_f-QmVqHkwvKvTwwTWBQSpD0niR60K",
			"163":"1-na7t4TE9tQCA3QwCUD2NU4_1gdhHBXl",
			"164":"15mrYcElhmE4dwvqamqLbeTzA4Q1aeINM",
			"165":"1koVdPEu1Dm2LD8cWEKZFK6lIT2z05SBp",
			"166":"15_z8sq8VpFO0f68svVsRKccdt-SR0lCe",
			"167":"1X3xrMuHKZemfCibmYDb5e8e3UNgn3Qqt",
			"168":"1N1P68NfE9F6QcsRj_9PmAyinVqlR-fBO",
			"169":"1S0MmA-_erlOkL1_mykJSV9Zvy6Ga7gta",
			"170":"1GBHgcYcqN_gSWshYjThnjD3b0nnnj-IS",
			"171":"1gVPnV2_ICN8Oeg0TEpaslMvB5oX4UIk8",
			"172":"1dA5sCBGEsP_Du2CuAm4UpnHP6tyuGd9L",
			"173":"1SAlvpgMHPbciqhOW5WMshLqDx2Hg9lzP",
			"174":"1HeHF_z4EWQxNrOSVvhKpUpwVTftCxkrB",
			"175":"1CxQdoprlVbkBCuHEl3QSyQQR-SeQPqd0",
			"176":"1fdIcu5KjqM_em7UWR_Ao2NwHYM_FRseH",
			"177":"1CPWWzS_ny96HIyIUVIwXu0OkvBBi5ZaN",
			"178":"16AhjRr944rwzrlBtUIk5rtuglOmyxDC-",
			"179":"1I1tx-iqmBO6Tmn1xKFB0S0gK4zvzjFm_",
			"180":"18BcXK6TDa2pCwAS0D_1jVvwD4GW2kduq",
			"181":"1xF8nm1VtthzaffqrX4QGAiy5rqUWaF_u",
			"182":"1SD8Akh16g-A4fzw0r8u1JKDCGmBClglm",
			"183":"1ynYfI2cHgaYGj_FWEdPdvFo7YDCT2qut",
			"184":"1ue_7atZ-EZfhmSeQwtA8sxcVHs9RXrWH",
			"185":"1FrRl_ByVy6oRzqG6ANlj4XuYQ8poVof2",
			"186":"17GO42wYnSGOq1Hiss54xowILe2GSi1ED",
			"187":"1I9maPzVvd2argXYhOemiWMqRAvQ873mb",
			"188":"1fx1ef8VBh6m8jhVKs3q3nN1XxBlcOIVJ",
			"189":"1SxgUCoxnwvkN_T1vS1_JNyaeOVaPDt8p",
			"190":"1qtPFlQIlfZ5cTWIHQVAp2D18FSkleUyP",
			"191":"1K6Fs-hNOUMJqTF2rso4q94DKdGVevTTK",
			"192":"1wM7aEufUn_XFndoA9drp8Khs32Aph2vu",
			"193":"1esAH46JQVi5eEChHYW8tHh0b0m_JHtlc",
			"194":"19lFFl3YKPp9AVbwBxg7zLi09HxAhhuHV",
			"195":"15LXFJ8SZXoImPEnYZjc1TQrX7J6rgXdO",
			"196":"1Y8M19ciese8EXTYW9174Ng_ipUoa4VLF",
			"197":"1nEU3_LRWa6wUxAQhPAwkkoC4aFTjobfp",
			"198":"1s9XAagAu9IX-EsGmrNY_-IBS8uo5BS4u",
			"199":"1asUhQbr0HQUnM8GR2eA149h1zdNd6ZFZ",
			"200":"1-QCYXCbstXSxVOD8900K3KhxyNfMIo7h",
			"201":"15ek4ZmKRcJSzoU3Ow4-jSvZT71M-lgfm",
			"202":"1pWXDYiKsMVJUgyYJ9jxlvDvIRTOvKRWh",
			"203":"1-IRf13cE4wL_7LIdh5HATXL8I5M4m3L6",
			"204":"13xggkdHC9NcRcrN_rmjH9CBlwHH6hxnH",
			"205":"1HhZc7CUDsWqiRCO5YnvEW0ff7vQ0tSCm",
			"206":"1N8GZjlqGw3idbKMYVGzy4F7q-ZzTsqU-",
			"207":"1Y8pmhsoes4c2OZf3vqXGqF_-H4-c7Xl-",
			"208":"1mWQCcJKCdwaE_HvqeV05Ih8Kj_SYKSiX",
			"209":"1sEoWIC1KfyNnY96PUtJ5HU5Ypif5PiJ1",
			"210":"1NrZY_CYYyY5keV76Poptod05Apv-8d67",
			"211":"1NE7YkvCpIRR1NIBfo4IBk0ArQ4DoviZh",
			"212":"1bdPpEQb8KG051fjyskIbDk-hOOQ55xYX",
			"213":"1g4Gm8YFMiP8Y6Spoh18sQwH-VXLPqsoG",
			"214":"1r5aNjpr31RGdz4CnZPh5DJHDhsc2vfgF",
			"215":"1x-yHieeDLzXw2Gkkbjz5QTcgneNvEd3d",
			"216":"1ST7s5cdAvKrxx7oxd2cKDJdnLZAUmaC_",
			"217":"1atI2XCZrtcIkybaVGgSX7otpPw25uVdQ",
			"218":"1bV0Y9JiwHyBrHLyitTJM4kDCefg-Zfor",
			"219":"1yJifBjTyrQdtAVOSdWGS_f0Pl3nugQDz",
			"220":"14z5vVVvEPEmIcXRxGo7BUojePk4Dz3Jg",
			"221":"1EfL549hGRQBwtHHX5G_GXR-me8PHlur4",
			"222":"1IlZDlr0WRg9NSnUGOH3fZmjiy3fTrODt",
			"223":"1LofM8seF0k_gKwuQfgxltvTrxeNmeET4",
			"224":"1NdUNuUhHTL4uIM6t2Kqb8Fe1Ok0e_imh",
			"225":"1RH7VJ2qjAozLoQ1gIVXKScsPQExbUdu6",
			"226":"1Rsd2OfABp3VW_q3udwhxc96r0uZaNATg",
			"227":"1lCTv90crfFauSqdq7xZv4rvS4wt3PNeX",
			"228":"1yS-FMY5XV4mcm0gY_7dITP3qFA0Mu3FY",
			"229":"1-aVWTUDv9trAdEYPvhoq-15b7cv900xL",
			"230":"14G1DFyI0tRFhirWx4kdO4GmGz0kbfjlo",
			"231":"1x5oZnZKOQRoFWisJuCHXjidnG8uYVOQg",
			"232":"1MYLkyqx-aFPZKttau2v3kaRKixowSFmL",
			"233":"1--5_-sFbCZxJWoYlHb0L4IvTJnZxHSQC",
			"234":"1102BL_uJv7FbdmZOht_vW3rjtSeudBuA",
			"235":"1HQeDkdJTn3uP_Lgii-jb6moAQT0E6mx0",
			"236":"1LX0UvMCUA5P-MG29locD1DpwyrHfLKLV",
			"237":"1SB8F-kStKw9umk4Z5S_i_v3VKG7Pv-6H",
			"238":"1iXVhoaxvj6Q9OiGdwkEB3GLJQH9EJu3j",
			"239":"1r6QCzH2oH9FQcaq4PlnH-pLk9rDv1Zzm",
			"240":"1uTqg93tIt-EsG2AFLtJxy6vlYN8JnguO",
			"241":"1zuny4hiZIx2kiciiijmC76UV1FM7rj8z",
			"242":"18W5ez1Sq1B5AqQ923ANQ_H0ZEUSxNc9Z",
			"243":"1PLVeym3jAPgrJSmvOhdXIsfsRbELHQt9",
			"244":"1UBPL2uNIoJySZcfqo5KHf_zyicEPz_CF",
			"245":"146xsR-nxlTxPJsC3OSwVRV5T-pV5fZwN",
			"246":"1MCnUT2lH8bqaQw1fWntUZfBFZuwRaEoN",
			"247":"1WLUZmR-jhWOab9pV8PsjDtL8YQ0i5eRr",
			"248":"1Wt0Dczbig2eNCeHYI3389sx65vmD_yg_",
			"249":"1_t57avdZs00pJT82s8IN-zW2LMIQwBFn",
			"250":"1sTDPP8rsW4EnPS7IBvi5JsK28FuizLa0",
			"251":"1t9dbQXwaRmRV2-oMsAHBj1BfUqG68y3-",
			"252":"1uJw43bRIx4d4I2y6BzYtI7-oBrpeAU3w",
			"253":"1yysvn1mbyRaX7AbI2VLzdXyLpnBx6EIW",
			"254":"11ZcJ5xCGwahTFksL8rT9y5ByuT49ePze",
			"255":"17_qm12ztp1_s-17JQZyTaXVO8n4iaEuI",
			"256":"1BaQzTZV99bb1t_md-eCFWlVZxgF2Qf3Y",
			"257":"1Dly7uaSZi4dMpBXIW68j0aOKJJ-yOqRC",
			"258":"1Mcc40ioX9x2Uie6yNiqLaPaW4vuFAwag",
			"259":"1_A1m9clyPTspm-IuNXq4_72tWBgsZD2H",
			"260":"1_VJFsuFWWZyR8VN_ysyeLfxSSFNBfNbc",
			"261":"1iRSIoiU4-7jvbPAP_T8a0O7iDfum87Nu",
			"262":"1ROHxZHrG3fpGMSEV4tZzlbN8Fao1LAwF",
			"263":"1RcRJCxDlNzqDOF3ix8UM69i_cMDvZTnh",
			"264":"1WZ8KODPGhjlQ2lpCqX5ktuAFCwyVsuZQ",
			"265":"1_0WgFxAeb3iy_PCdOJf0yViB47UO65M6",
			"266":"1-SHgp2UxHCcEOL24QuPuMi1AIkxaDMec",
			"267":"1L7Ezc_qK6QNiqcjVb2rUwRGvrfbqxJb3",
			"268":"10tmaYt6kBNH7Eh7tobaY3ImwU1_OUSbh",
			"269":"130NTB1YStFoz_R0GoVX-2duyEAmV0kVE",
			"270":"161GykNh3rs4BDVsLcvAvcyMerDcJNceV",
			"271":"16aerb5dopGoToGER7RzsyLaCJEDXkTn-",
			"272":"19a7z9YbQiQabEKwZJRDe_5zvxLtdkp6Y",
			"273":"1AAaxezKgomUM5sG3c17aFxTK7BrKZMHc",
			"274":"1MEP82ap5rU0hUGHTe5G3GfLabJXT5OqW",
			"275":"1PNufbnv7UlSn5eBQtgigKdjZfEH3Dhqk",
			"276":"1bbEwAiYwI5ACo2LTl84PuWTs2yVA_QoM",
			"277":"1hHxZqJzOfegIuUyIg7vGDTBKQPTuuAI-",
			"278":"1m1JVnMbuloDADB5tEnRnW7ZByiRmFwKt",
			"279":"1mvdhkWUAConLkDzk1KTtzNvyk00vlLHr",
			"280":"1pucM6BUhU_au-CKoG50WYYWtwGbUjPnU",
			"281":"1twTbHkLl4qO2kRxLROqV18zdDpDor13s",
			"282":"1uuVD36XHxonFEpY8VOwYmgn9ts4WB5s6",
			"283":"1C2JI5zL-TjXlygiU8WtyY6IqNkI1nfXw",
			"284":"1W4_ATAk_G9vC9cIvV50Z5H1d5cTPekgK",
			"285":"1Xx9BOTEI7cxhHvNICkmH1vt0Z3Hv3cSn",
			"286":"1uMVVnuRZxAjbbjw29uNj3a2meViYth3r",
			"287":"1LFKITTfLgJ-PXmOb7L_SJTYckwzksGYf",
			"288":"1kzIamWscRAUkmApEdpLd98a4PLrlrsRa",
			"289":"1CPlZnR3F7EwL94-Na0V1UndXWt8zjF_y",
			"290":"1G3TiYY3_vUMt5PgghpDV9g-bNfq-qV6Q",
			"291":"1Ga1kBZQ9xRLl0yy92AaVA1rCwsBFG0Mg",
			"292":"1IVDCbEjOD12_dHqx3_iUvR-UxHc2RSJf",
			"293":"1gxAjQ5ExVDuPraIne--j_1uGzeIt6Jck",
			"294":"1phljqfvwHSJC6TOU1WMJudV1x-IhfLw3",
			"295":"1s9eruGuXMatbHxqsErii9NY_82SSil1g",
			"296":"1-i5z4Ccly7-jWBtQOBmJqwuEorgDb84H",
			"297":"13LC4tzRUBbK7yZYyRX5qLBuJPrRWZ09k",
			"298":"15eXqQEaE9A21tL_uE6lmdNL14EnxJJY0",
			"299":"1C6FEOwKpNMpeBfjDPYDqVOsRdI2YcTZ8",
			"300":"1LcgQb7IWvEpPAf3yMF3msAQghNYZwonY",
			"301":"1NH77lUpVOhRxNU3iZ9-GTiVIhMhOwB4g",
			"302":"1SEVTEGHpD5BqM-w0erX_J_WD0Ljse375",
			"303":"1ZonLspprCiXkUNd8lIxkA5Y2vUQ5dUAn",
			"304":"1cJi_MOQ7HCdDFo7IZbYkx5vDOPJLeH95",
			"305":"1fB885rmGSjA-WqFTap5VLIHXc7yny-6l",
			"306":"1sT-q3TJFyUlmnjDPmMO7Qig83vFlvSbe",
			"307":"17dfbTe5idmwPAInp0N_7qeSOO9jHJZtH",
			"308":"1B3e7S8d3SeRzCqjOxCuBavB25Q3iHF4y",
			"309":"1BGAmw6Ons9Pht6eothiV4Tr5yIfbcYar",
			"310":"1E0DS4jsnM6SMm7QWk4TK2FssaSgqPd90",
			"311":"1GRLyr5MmU9BLePZIHe3k9Qof3E5juXoW",
			"312":"1LzvY29PpIartgBJCXBAHhXFAL77lVsxG",
			"313":"1RIrs7zlgje4uu0M72L9qaLBQr4QWj1L1",
			"314":"1_xQP7Aiy_ZKZJGpU68qZOZ6ZIioncaaR",
			"315":"1hwZdJoVyFxg3U3gcA64H5ST_9GuhZdEr",
			"316":"1nY8dNl9mbrfUuJBFVK9--FBWQVjGzZAA",
			"317":"1b2Ga6I7aJ9KcrlnGRez0hrkdfqyUjas0",
			"318":"14EwiLP32AXqcRUCYRVZw2zDRGqjxqn40",
			"319":"194KKxPVJdRfrfbTWdVqFeVENYR0Lej-2",
			"320":"1HFHudWrVNLIuaUyT5jz5WC3YGO0oIKUJ",
			"321":"1_jja2ptQrjYPplxJPhuzPoyiQ5-N6qmi",
			"322":"1rk_5NXn_G_jSftZ9wv8KxsIg_zfZ5bCp",
			"323":"1-q5ImG_4ZXp7A0CxGyjyNLhL-GFSaF2w",
			"324":"15DxWPXtE4H9zW6zYTa7MZQY5v9-GEqGb",
			"325":"1AVrnQrlAYGpeLCgahI9sWhAkePDUxdmZ",
			"326":"1Mcd5ofT0NmPb6DgB11jg2g1JJjGx2eKk",
			"327":"1Rkjo2FVa5m860HeQogz1Vdh_8nCnvnlU",
			"328":"1ZdVuZMG0F3t4Y9NNQbWD1RRMXOnoYEcG",
			"329":"1ji2AzyRXGeT-st5NQ13JjYRJQo93Pry9",
			"330":"1rUAfQdndRq6dRDRGHyF8kWAMye54PWYF",
			"331":"1v2Dhc_TvJT6tFdIO1voFKyQArl5PGTnv",
			"332":"1vaairDQj-9Y-BjLCkquljFlQRnBYX3WR",
			"333":"1082QIYX1OTsPv9uOpaElnmTIvG3udeTM",
			"334":"11qAx3gcypD9d2N1uAepsX1nAvVjzolRq",
			"335":"13yG5B_YA2H5K8pFiwu4ubNH_Y20bjJlp",
			"336":"15kPtNag8odjcYo0CCuvDMJEGzbIwpbHq",
			"337":"1C43cDWG19qV3-vJ3dcBsNP8cyZ0ywx6p",
			"338":"1LWnWdUYrrHdRpbszUblYIDa8B5OwZEbc",
			"339":"1NJwWPYn4TE3nuodKInzmqj-53q3P2FJN",
			"340":"1NKb8BXdXJCWrS6jmkiUa_opnq5phj3pP",
			"341":"1Xrn7bcaG8QkZs4KwxRvzS9jOZ-p6BsPw",
			"342":"1akkkPaiEULijHU_D08aINRqnBkrYoETM",
			"343":"1bo2ln1ALYbA7g_mPf0x_vovrM2CGSB0j",
			"344":"1l6Y353ARaDRgu63RWLBjZpNJzqYPK4ek",
			"345":"1mEQPrDaNLl1g8VRdpf6iFiRu2BceuTNs",
			"346":"1t6SeIhDRCFxVDkAcc7Gfap9jtik_M77H",
			"347":"1vmO2mzoLjR-We1foKEawT77z0qU8ITZc",
			"348":"1vrvBtmQswBYl-pr9GdMxsYiIHm9OB5QD",
			"349":"1yRmCH4RcnjcUi2DND2ZRBN2TvuGKT_2m",
			"350":"1VRQOYLX9eGlRXBVIWPGLeE3DTumNivML",
			"351":"1WHForldOH3waghmgpRcfO0JewCWyEsh_",
			"352":"1fWKrva3XlqbCSKJi9p5D-iRa_JCvNO8N",
			"353":"1ufqYsvn3vhiGzyndRk7VInDO4iAazFz7",
			"354":"1K5JgBbNvz8_PS-S8OMTANOua0Smy2hKY",
			"355":"1EhUc3wJYnLU_ygwxSa3rKyl0K5-LCCJA",
			"356":"1EwBdRfAaBFHKvXnJ9KZwpTthPP4Ca2iH",
			"357":"1RCv4lXoo_1Bzg8wV58LGZToj0RXIS6zC",
			"358":"1SW4AOvzywl_2v6OvGAmrP1qWmR8eZcsy",
			"359":"16FIsWt4y_HiOfGDSyQrPycU77Yrj7JP6",
			"360":"1MqLMduur41Eq4SHHVpY3idIhHlggttCW",
			"361":"1NPwkBr0aY8fj98IdL-J_yQwSoQvxxx9O",
			"362":"1SFLsN8xC_owKPuPI3vSVqyIzP8YReieB",
			"363":"1SZZX_npowK_3yDR1vXT1NIN46xkpiFUi",
			"364":"1XOY17Te1mIr-IQtZQJOTydUZAMv7S4ZO",
			"365":"1dQ3stnkfZrg7bqG3CXrjZgyYJ1-TAJ4E",
			"366":"1g6FR88kSRij1Xy9iLbwwGonOcFO68syU",
			"367":"1tVfxDT6GoMzkoqg6ZedxpDfCTh-r-YXp",
			"368":"1zjdxvHBFWJ_3lxU4XMDjBb7cddo2WYl3",
			"369":"109QYsM0cBFpABw7yYaLLnTG9i2LOtp7N",
			"370":"10Zri6muvKtqUNK5HPpk0BF9TdHSYtl7S",
			"371":"19rumB51bRF3DimE-DrKQu2hnuotLxXq5",
			"372":"1G5Ct4awVrEqiZGS34fItU9-eYl_Yq1OG",
			"373":"1GnFaPMm1Ge8n_OeVwt68kXocKFVSJAdu",
			"374":"1H99LxdvoivGUJfJNh_qFyfO57yei63NG",
			"375":"1M5NIaH07_ANcwvVaVyxgSQPE2FJxCYe6",
			"376":"1N0OB9QjhI7OCfKi57RxEEydf6ocetFUn",
			"377":"1VoXgS34ATfQw5CZ5PAUCRa2PluUlWKl8",
			"378":"1W6Arjc0PkN9cZWPDgYAhW-ymFG7h4_5x",
			"379":"1fqHdrjZD4tf5OKVHvZFf88MkDUV24z7J",
			"380":"1iVGFxwwUksOwtqE_AQdBdzRA_SaILuUE",
			"381":"1kuISu2FSHjQUExrKmY23i3UcnYgrXHPX",
			"382":"1vKja9xOxZCDRFvoj-YA2MGulkf7WvFT4",
			"383":"1wxcESCstA2Xohjj758gEupgmZA13H2Lw",
			
		},
	
	};
	
 const photostockData = [
  { id: 'asbuilt_built_drawing_orf', label: 'Asbuilt Built Drawing ORF' },
  { id: 'onshore_receiving_facilities', label: 'Onshore Receiving Facilities' },
  { id: 'peta_laut', label: 'Peta Laut' },
  { id: 'pipeline', label: 'Pipeline' },
];

   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};
	
	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: '', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate("/"+selectedTablesNativeStock.value);
		}
	  
    }
  }, [selectedTablesNativeStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tnts" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>

						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/homealive_home.png`}
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nativestock_kjg;

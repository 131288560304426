import React, { useRef, useState, useEffect } from "react";
import Select from 'react-select';
import styled from 'styled-components';
import { useLocation } from "react-router-dom";
import jsPDF from "jspdf";
import html2canvas from "html2canvas";
import { useNavigate } from "react-router-dom";


const RBIprint = () => {
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const componentRef = useRef();
  const [riskData, setRiskData] = useState(null);
  const [formData, setFormData] = useState({});
  const [riskMatrix, setRiskMatrix] = useState(null);
  const [riskLevel, setRiskLevel] = useState(null);
  const [loading, setLoading] = useState(true);
  const [selectedPWHT, setSelectedPWHT] = useState(false);
  const [selectedLining, setSelectedLining] = useState(false);
  const [selectedCoating, setSelectedCoating] = useState(true);
  const [selectedHeattracing, setSelectedHeattracing] = useState(false);
  const [selectedCladding, setSelectedCladding] = useState(true);
  const [selectedInsulation, setSelectedInsulation] = useState(false);
  const [selectedDatainspeksi, setSelectedDatainspeksi] = useState(false);
  const [selectedPosition, setSelectedPosition] = useState(null);
  const [selectedMaterial, setSelectedMaterial] = useState(null);
  const [selectedEquipmenttype, setSelectedEquipmenttype] = useState(null);
  const [selectedComponenttype, setSelectedComponenttype] = useState(null);
  const [selectedGeometrytype, setSelectedGeometrytype] = useState(null);
  const [materialData, setMaterialData] = useState([]);
  const [EquipmenttypeData, setEquipmenttypeData] = useState([]);
  const [selectedyearoffabricationmaterial, setSelectedyearoffabricationmaterial] = useState(null);
  const [selectedRepresentativefluid, setSelectedRepresentativefluid] = useState(null);
  const [selectedFluidphase, setSelectedFluidphase] = useState(null);
  const [selectedFluidatnormaloperatingconditions, setSelectedFluidatnormaloperatingconditions] = useState(null);
  const [selectedFluidatambientconditions, setSelectedFluidatambientconditions] = useState(null);
  const [selectedCausticpresen, setSelectedCausticpresen] = useState(null);
  const [selectedAlkalinewater, setSelectedAlkalinewater] = useState(null);
  const [selectedOilwithsulfurcompounds, setSelectedOilwithsulfurcompounds] = useState(null);
  const [selectedChloridesppm, setSelectedChloridesppm] = useState(null);
  const [selectedFreewater, setSelectedFreewater] = useState(null);
  const [selectedH2Sandhydrogen, setSelectedH2Sandhydrogen] = useState(null);
  const [selectedOxygen, setSelectedOxygen] = useState(null);
  const [selectedH2S, setSelectedH2S] = useState(null);
  const [selectedHCl, setSelectedHCl] = useState(null);
  const [selectedH2SO4, setSelectedH2SO4] = useState(null);
  const [selectedHF, setSelectedHF] = useState(null);
  const [selectedCO2, setSelectedCO2] = useState(null);
  const [selectedAllregular300, setSelectedAllregular300] = useState(null);
  const [selectedHGrade300, setSelectedHGrade300] = useState(null);
  const [selectedLGrade300, setSelectedLGrade300] = useState(null);
  const [selectedStainlesssteel321, setSelectedStainlesssteel321] = useState(null);
  const [selectedStainlesssteel347, setSelectedStainlesssteel347] = useState(null);
  const [selectedTF350, setSelectedTF350] = useState(null);
  const [selectedTF300, setSelectedTF300] = useState(null);
  const [selectedT100, setSelectedT100] = useState(null);
  const [selectedT350, setSelectedT350] = useState(null);
  const [selectedT1070, setSelectedT1070] = useState(null);
  const [selectedT1050, setSelectedT1050] = useState(null);
  const [selectedT1700, setSelectedT1700] = useState(null);
  const [selectedT400, setSelectedT400] = useState(null);
  const [selectedT900, setSelectedT900] = useState(null);
  const [selectedDataconfidencedamagestate1, setSelectedDataconfidencedamagestate1] = useState(null);
  const [selectedDataconfidencedamagestate2, setSelectedDataconfidencedamagestate2] = useState(null);
  const [selectedDataconfidencedamagestate3, setSelectedDataconfidencedamagestate3] = useState(null);
  const [selectedMistoverspray, setSelectedMistoverspray] = useState(null);
  const [selectedSteamvents, setSelectedSteamvents] = useState(null);
  const [selectedDelugesystems, setSelectedDelugesystems] = useState(null);
  const [selectedChloridecontaining, setSelectedChloridecontaining] = useState(null);
  const [selectedSulfurbearing, setSelectedSulfurbearing] = useState(null);
  const [selectedChlorideswndwater, setSelectedChlorideswndwater] = useState(null);
  const [selectedHydrofluoricacid, setSelectedHydrofluoricacid] = useState(null);
  const [selectedAcidgastreatingamines, setSelectedAcidgastreatingamines] = useState(null);
  const [selectedSoil, setSelectedSoil] = useState(null);
  const [selectedHydrogenpartial, setSelectedHydrogenpartial] = useState(null);
  const [selectedSubjecttoprocess, setSelectedSubjecttoprocess] = useState(null);
  const [selectedNotnormally, setSelectedNotnormally] = useState(null);
  const [selectedWithdeteriorated, setSelectedWithdeteriorated] = useState(null);
  const [selectedPenetrationsandvisually, setSelectedPenetrationsandvisually] = useState(null);
  const [selectedMDMTorMAT, setSelectedMDMTorMAT] = useState(null);
  const [selectedHavebeenpast, setSelectedHavebeenpast] = useState(null);
  const [selectedPh7, setSelectedPh7] = useState(null);
  
  //DF thinning lining
  const [selectedApplicable, setSelectedApplicable] = useState(null);
  const [selectedInjectionmixpoints, setSelectedInjectionmixpoints] = useState(null);
  const [selectedDeadlegcontain, setSelectedDeadlegcontain] = useState(null);
  const [selectedWeldedconstructionwelded, setSelectedWeldedconstructionwelded] = useState(null);
  const [selectedInspectioneffectivenesscategory, setSelectedInspectioneffectivenesscategory] = useState(null);
  const [selectedMaintenanceAPISTD563Maintained, setSelectedMaintenanceAPISTD563Maintained] = useState(null);
  const [selectedSettlementcriteria, setSelectedSettlementcriteria] = useState(null);
  const [selectedOnlinemonitoringThinningmechanism, setSelectedOnlinemonitoringThinningmechanism] = useState(null);
  const [selectedOnlinemonitoringMonitoringtype, setSelectedOnlinemonitoringMonitoringtype] = useState(null);
  const [selectedLiningtype, setSelectedLiningtype] = useState(null);
  const [selectedAgeofLiningoryearage, setSelectedAgeofLiningoryearage] = useState(null);
  const [selectedInorganicliningstype, setSelectedInorganicliningstype] = useState(null);
  const [selectedOrgaliningstype, setSelectedOrgaliningstype] = useState(null);
  const [selectedMonitoringcontain, setSelectedMonitoringcontain] = useState(null);
  const [selectedLiningconditionqualitativecondition, setSelectedLiningconditionqualitativecondition] = useState(null);
  
  //DF External
  const [selectedExtDriver, setSelectedExtDriver] = useState(null);
  const [selectedEquipmentDesignorFabrication, setSelectedEquipmentDesignorFabrication] = useState(null);
  const [selectedInterface, setSelectedInterface] = useState(null);
  const [selectedCoatingQuality, setSelectedCoatingQuality] = useState(null);
  const [selectedCfcDriver, setSelectedCfcDriver] = useState(null);
  const [selectedInsulationType, setSelectedInsulationType] = useState(null);
  const [selectedComplexity, setSelectedComplexity] = useState(null);
  const [selectedInsulationCondition, setSelectedInsulationCondition] = useState(null);
  const [selectedCfcDesignorFabrication, setSelectedCfcDesignorFabrication] = useState(null);
  const [selectedInterfaceenterssoilorwater, setSelectedInterfaceenterssoilorwater] = useState(null);
  const [selectedEcfDrive, setSelectedEcfDrive] = useState(null);
  const [selectedEcfDrivecui, setSelectedEcfDrivecui] = useState(null);
  const [selectedEcfCracking, setSelectedEcfCracking] = useState(null);
  const [selectedEcfCrackingcui, setSelectedEcfCrackingcui] = useState(null);
  const [selectedChloridefreecui, setSelectedChloridefreecui] = useState(null);
  const [selectedEcfInspectionPerformed, setSelectedEcfInspectionPerformed] = useState(null);
  const [selectedInspectionPerformedcui, setSelectedInspectionPerformedcui] = useState(null);
  
  //DF SCC
  const [selectedDetermineSusceptibility, setSelectedDetermineSusceptibility] = useState(null);
  const [selectedACDetermineSusceptibility, setSelectedACDetermineSusceptibility] = useState(null);
  const [selectedCRNumberofInspections, setSelectedCRNumberofInspections] = useState(null);
  const [selectedACNumberofInspections, setSelectedACNumberofInspections] = useState(null);
  const [selectedSscphwater, setSelectedSscphwater] = useState(null);
  const [selectedSscfunctionh2swater, setSelectedSscfunctionh2swater] = useState(null);
  const [selectedSsccyanides, setSelectedSsccyanides] = useState(null);
  const [selectedSscfunctiontreatment, setSelectedSscfunctiontreatment] = useState(null);
  const [selectedSscmaxbrinnell, setSelectedSscmaxbrinnell] = useState(null);
  const [selectedSsccracking, setSelectedSsccracking] = useState(null);
  const [selectedSCCNumberofInspections, setSelectedSCCNumberofInspections] = useState(null);
  const [selectedShicphwater, setSelectedShicphwater] = useState(null);
  const [selectedShicfunctionh2swater, setSelectedShicfunctionh2swater] = useState(null);
  const [selectedShiccyanides, setSelectedShiccyanides] = useState(null);
  const [selectedShiccracking, setSelectedShiccracking] = useState(null);
  const [selectedShicsulfurcontent, setSelectedShicsulfurcontent] = useState(null);
  const [selectedShicNumberofInspections, setSelectedShicNumberofInspections] = useState(null);
  const [selectedHicmonitoringmethod, setSelectedHicmonitoringmethod] = useState(null);
  const [selectedAcephwater, setSelectedAcephwater] = useState(null);
  const [selectedAceCO3, setSelectedAceCO3] = useState(null);
  const [selectedAcecracking, setSelectedAcecracking] = useState(null);
  const [selectedPACracking, setSelectedPACracking] = useState(null);
  const [selectedCscccracking, setSelectedCscccracking] = useState(null);
  const [selectedHsccracking, setSelectedHsccracking] = useState(null);
  const [selectedHicsohiccracking, setSelectedHicsohiccracking] = useState(null);
  const [selectedHicsohicsulfurcontent, setSelectedHicsohicsulfurcontent] = useState(null);
  const [selectedHicsohicadjustment, setSelectedHicsohicadjustment] = useState(null);
  const [selectedPamaterial, setSelectedPamaterial] = useState(null);
  const [selectedCsccoperatingtemperature, setSelectedCsccoperatingtemperature] = useState(null);
  const [selectedCsccchloride, setSelectedCsccchloride] = useState(null);
  const [selectedAcefoht, setSelectedAcefoht] = useState(null);
  const [selectedShicfunctiontreatment, setSelectedShicfunctiontreatment] = useState(null);
  const [selectedHsccondition, setSelectedHsccondition] = useState(null);
  const [selectedHscmaxbrinnell, setSelectedHscmaxbrinnell] = useState(null);
  
  //DF Brittle
  const [selectedHthadamagecondition, setSelectedHthadamagecondition] = useState(null);
  const [selectedAdmprocon, setSelectedAdmprocon] = useState(null);
  const [selectedBfasmeexcurve, setSelectedBfasmeexcurve] = useState(null);
  const [selectedLaseaorprocon, setSelectedLaseaorprocon] = useState(null);
  const [selectedLasasmecurve, setSelectedLasasmecurve] = useState(null);
  const [selectedEmbaopc, setSelectedEmbaopc] = useState(null);
  const [selectedSigmaembcontent, setSelectedSigmaembcontent] = useState(null);
  
  //DF Fatigue
  const [selectedPrefacriteria, setSelectedPrefacriteria] = useState(null);
  const [selectedPrefadfbpf, setSelectedPrefadfbpf] = useState(null);
  const [selectedVisshcriteria, setSelectedVisshcriteria] = useState(null);
  const [selectedTocycriteria, setSelectedTocycriteria] = useState(null);
  const [selectedAdcocriteria, setSelectedAdcocriteria] = useState(null);
  const [selectedAdpicriteria, setSelectedAdpicriteria] = useState(null);
  const [selectedAdcoicriteria, setSelectedAdcoicriteria] = useState(null);
  const [selectedAdjotcriteria, setSelectedAdjotcriteria] = useState(null);
  const [selectedAdbrdicriteria, setSelectedAdbrdicriteria] = useState(null);
  
  //COF
  const [selectedHolesize1, setSelectedHolesize1] = useState(null);
  const [selectedHolesize2, setSelectedHolesize2] = useState(null);
  const [selectedHolesize3, setSelectedHolesize3] = useState(null);
  const [selectedHolesize4, setSelectedHolesize4] = useState(null);
  const [selectedDetsysty, setSelectedDetsysty] = useState(null);
  const [selectedIsosysty, setSelectedIsosysty] = useState(null);
  const [selectedConarmit, setSelectedConarmit] = useState(null);
  const [selectedFluidtypecof, setSelectedFluidtypecof] = useState(null);
  const [selectedToxflucov, setSelectedToxflucov] = useState(null);
  
  const [error, setError] = useState(null);

const handleDownloadPDF = async () => {
  
  setIsLoading(true);
  const elementIds = ["print1", "print2", "print3", "print4", "print5"]; // Daftar ID elemen
  const customPageWidth = 1200; // Lebar halaman dalam px
  const customPageHeight = 1800; // Tinggi halaman dalam px
  const marginTop = 50; // Margin atas dalam px
  const marginBottom = 50; // Margin bawah dalam px

  const pdf = new jsPDF({
    orientation: "portrait", // Orientasi
    unit: "px",
    format: [customPageWidth, customPageHeight], // Ukuran halaman
  });

  try {
    for (let i = 0; i < elementIds.length; i++) {
      const elementId = elementIds[i];
      const element = document.getElementById(elementId);

      if (!element) {
        console.warn(`Elemen dengan ID "${elementId}" tidak ditemukan.`);
        continue;
      }

      // Render elemen ke canvas menggunakan html2canvas
      const canvas = await html2canvas(element, { scale: 2 });
      const imgData = canvas.toDataURL("image/png");

      const contentWidth = canvas.width; // Lebar konten asli
      const contentHeight = canvas.height; // Tinggi konten asli

      // Hitung rasio untuk menyesuaikan elemen ke halaman PDF
      const ratio = Math.min(customPageWidth / contentWidth, (customPageHeight - marginTop - marginBottom) / contentHeight);

      // Ukuran gambar setelah diskalakan
      const imgWidth = contentWidth * ratio;
      const imgHeight = contentHeight * ratio;

      // Posisi gambar di PDF
      const posX = (customPageWidth - imgWidth) / 2; // Centering horizontal
      const posY = marginTop; // Mulai dari margin atas

      // Tambahkan gambar elemen ke PDF
      pdf.addImage(imgData, "PNG", posX, posY, imgWidth, imgHeight);

      // Tambahkan halaman baru kecuali elemen terakhir
      if (i < elementIds.length - 1) {
        pdf.addPage([customPageWidth, customPageHeight]);
      }
    }

    // Simpan file PDF
    pdf.save("RBI.pdf");
  } catch (error) {
      console.error("Gagal membuat PDF:", error);
    } finally {
      setIsLoading(false);
      setTimeout(() => {
        window.close();
      }, 2000);
    }
};



  
  const isPDFGenerated = useRef(false);

  useEffect(() => {
  // Ambil data dari localStorage
  const storedData = JSON.parse(localStorage.getItem("printData"));

  if (storedData) {
    setRiskData(storedData.riskData);
    setFormData(storedData.formData);
	// Set nilai checkbox PWHT berdasarkan storedData
      const { PWHT, Lining, Coating, Cladding, Heattracing, Insulation, Datainspeksi } = storedData.formData || {};
      setSelectedPWHT(PWHT?.toLowerCase() === "yes");
      setSelectedLining(Lining?.toLowerCase() === "yes");
      setSelectedCoating(Coating?.toLowerCase() === "yes");
      setSelectedCladding(Cladding?.toLowerCase() === "yes");
      setSelectedHeattracing(Heattracing?.toLowerCase() === "yes");
      setSelectedInsulation(Insulation?.toLowerCase() === "yes");
      setSelectedDatainspeksi(Datainspeksi?.toLowerCase() === "yes");
	
    localStorage.removeItem("printData");

    // Pastikan PDF hanya dibuat satu kali
    if (!isPDFGenerated.current) {
      setTimeout(() => {
        handleDownloadPDF(); // Cetak PDF
        isPDFGenerated.current = true; // Tandai bahwa PDF sudah dibuat
		setTimeout(() => {
			
			window.close();
			
		}, 20000)
      }, 2000);
    }
  }
}, [navigate]);

if (!riskData || !formData) return <div>Loading...</div>;
const { Risk_matrix, Risk_level } = riskData;
	
	const { 
	
	//tambahan ketinggalan
	Componenttype,
	Geometrytype,
	Fluidatnormaloperating,
	  
    tag_number,
    piping_service,
    location_from,
    location_to,
    installation_date,
    Thicknessactual,
    coating_installation_date,
    grade,
    material, 
    Designcode,
    Outsidediameter,
    Length,
    Corrosionallowance,
    Nominalthickness,
    Inspectiondate,
    Inspectiondatecomplete,
    Remaininglifeofthecladding,
    Corrosionratecladding,
    Weldjointefficiency,
    Yieldstrength,
    Tensilestrength,
    Allowablestress,
    Designpressure,
    Designtemperature,
    Operatingpressure,
    Operatingtemperature,
    Minimumdesigntemperature,
    Upsettemperature,
    Coatinginstallationdate,
    Previousinspectiondate,
    Previousthicknessreading,
    Corrosionprobe,
    Corrosioncoupon,
	PIDNumber,
	position,
	Equipmenttype,
	Gasliquid,
	
	//Screening
	yearoffabricationmaterial,
	Representativefluid,
	Fluidphase,
	Fluidatnormaloperatingconditions,
	Fluidatambientconditions,
	Areaft2,
	Dataconfidencedamagestate1,
	Dataconfidencedamagestate2,
	Dataconfidencedamagestate3,
	Numberofpastinspection,
	InspectionA,
	InspectionB,
	InspectionC,
	InspectionD,
	Causticpresen,
	Alkalinewater,
	Oilwithsulfurcompounds,
	Chloridesppm,
	Freewater,
	H2Sandhydrogen,
	Oxygen,
	H2S,
	HCl,
	H2SO4,
	HF,
	CO2,
	Mistoverspray,
	Steamvents,
	Delugesystems,
	Chloridecontaining,
	Sulfurbearing,
	Chlorideswndwater,
	Hydrofluoricacid,
	Acidgastreatingamines,
	Soil,
	TF350,
	TF300,
	T100,
	T350,
	T1070,
	T1050,
	T1700,
	T400,
	T900,
	Hydrogenpartial,
	Subjecttoprocess,
	Notnormally,
	Withdeteriorated,
	Penetrationsandvisually,
	MDMTorMAT,
	Havebeenpast,
	Ph7,
	Allregular300,
	HGrade300,
	LGrade300,
	Stainlesssteel321,
	Stainlesssteel347,
	
	//DF Thinning
	Applicable,
	ReliabilityindicesDS1,
	ReliabilityindicesDS2,
	ReliabilityindicesDS3,
	ReliabilityindicesCOVAT,
	ReliabilityindicesCOVSf,
	ReliabilityindicesCOVP,
	Inspectioneffectivenesscategory,
	Injectionmixpoints,
	Deadlegcontain,
	Weldedconstructionwelded,
	MaintenanceAPISTD563Maintained,
	Settlementcriteria,
	OnlinemonitoringThinningmechanism,
	OnlinemonitoringMonitoringtype,
	Liningtype,
	AgeofLiningoryearage,
	Inorganicliningstype,
	Orgaliningstype,
	Monitoringcontain,
	Liningconditionqualitativecondition,
	
	//DF External
	ExtDriver,
	EquipmentDesignorFabrication,
	Interface,
	CoatingQuality,
	CfcDriver,
	InsulationType,
	Complexity,
	InsulationCondition,
	CfcDesignorFabrication,
	Interfaceenterssoilorwater,
	EcfDrive,
	EcfCracking,
	EcfInspectionPerformed,
	EcfDrivecui,
	EcfCrackingcui,
	Chloridefreecui,
	InspectionPerformedcui,
	
	//DF SCC
	DetermineSusceptibility,
	CRNumberofInspections,
	ACDetermineSusceptibility,
	ACNumberofInspections,
	Sscphwater,
	Sscfunctionh2swater,
	Ssccyanides,
	Sscfunctiontreatment,
	Sscmaxbrinnell,
	Ssccracking,
	SCCNumberofInspections,
	Shicphwater,
	Shicfunctionh2swater,
	Shiccyanides,
	Shiccracking,
	Shicfunctiontreatment,
	Shicsulfurcontent,
	ShicNumberofInspections,
	Hicmonitoringmethod,
	Acephwater,
	AceCO3,
	Acecracking,
	Acefoht,
	PACracking,
	Pamaterial,
	Csccoperatingtemperature,
	Csccchloride,
	Cscccracking,
	Hsccondition,
	Hscmaxbrinnell,
	Hsccracking,
	Hicsohiccracking,
	Hicsohicsulfurcontent,
	Hicsohicadjustment,
	
	//DF Brittle
	Hthadamagecondition,
	Admprocon,
	Bfasmeexcurve,
	Laseaorprocon,
	Lasasmecurve,
	Embaopc,
	Embreftem,
	Sigmaembcontent,
	
	//DF Fatigue
	Prefacriteria,
	Prefadfbpf,
	Visshcriteria,
	Tocycriteria,
	Adcocriteria,
	Adpicriteria,
	Adcoicriteria,
	Adjotcriteria,
	Adbrdicriteria,
	
	//COF
	Holesize1,
	Holesize2,
	Holesize3,
	Holesize4,
	Atmosprepatm,
	Confac2,
	Detsysty,
	Isosysty,
	Toledur,
	Conarmit,
	Fluidtypecof,
	Toxperthetoxcom,
	Toxflucov,
	Acidorcaus5,
	Acidorcaus6,
	Acidorcaus7,
	Acidorcaus8,
	Blenfac1,
	Blenfac2,
	Blenfac3,
	Blenfac4,
	Caforstoracleaknon1,
	Caforstoracleaknon2,
	Caforstoracleaknon3,
	Caforstoracleaknon4,
	Finoflnotoxcon,
	Matcosfacmat,
	Eqcosfac,
	Eqoutmul,
	Procoxprod,
	Encosen,
	
  } = formData;



const formatDate = (dateString) => {
	
	
  // Mengonversi string tanggal ISO 8601 ke objek Date
  const date = new Date(dateString);
  
  // Mengambil hari, bulan, dan tahun
  const day = String(date.getDate()).padStart(2, '0');  // Menambahkan angka 0 di depan jika kurang dari dua digit
  const month = String(date.getMonth() + 1).padStart(2, '0');  // Mengambil bulan (dimulai dari 0, jadi tambahkan 1)
  const year = date.getFullYear();  // Mengambil tahun

  // Mengembalikan tanggal dalam format DD-MM-YYYY
  return `${year}-${month}-${day}`;
};

const styles = {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 1000,
        cursor: 'not-allowed',
    },
};


  return (
	
	<div>
	
	<div>
		{isLoading && (
        <div className="loading-overlay">
          <p>Loading... Please wait</p>
        </div>
      )}
	</div>
	
    <div id="print_layout" ref={componentRef}>
	
	<div style={styles.overlay} ></div>
	
	<div id="print1">
	
	<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		  
		<div style={{ clear: 'both' }}></div>
	
      <div
        
        style={{ padding: "20px", marginLeft: '-20px'}}
      >
		<div style={{marginLeft: '10px'}}>
			<u>
			  <h2>RBI Calculator</h2>
			</u>
			<h5 style={{ marginTop: "-10px" }}>Based API 580</h5>
		</div>
		
		<div class="row">
			<div class="card col-4" style={{margin: '20px 5px 5px 5px', border: 'none'}}>
			  <div
				className="card-header"
				style={{ backgroundColor: "#d3d3d3", color: "black" }}
			  >
				<h5 style={{ textAlign: "left" }}>Result</h5>
			  </div>
			  <div
				className="card-body"
				style={{ backgroundColor: "#646464", color: "white" }}
			  >
				
					<p>
					  <strong>Risk Matrix:</strong> {Risk_matrix}
					</p>
					<p>
					  <strong>Risk Level:</strong> {Risk_level}
					</p>
			  </div>
			</div>
			
			<div className="card col-4" style={{ margin: "20px 5px 5px 5px", border: 'none' }}>
				
				
					{Risk_matrix === "1A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "1B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "1C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{Risk_matrix === "1D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "1E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/1E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "2A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "2B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "2C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{Risk_matrix === "2D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "2E" && (
					  
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/2E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "3A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "3B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "3C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{Risk_matrix === "3D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "3E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/3E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "4A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "4B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "4C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{Risk_matrix === "4D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "4E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/4E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "5A" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5A.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "5B" && (
						
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5B.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
						
					)}
					{Risk_matrix === "5C" && (
					 
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5C.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					{Risk_matrix === "5D" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5D.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
					{Risk_matrix === "5E" && (
					  
						<a href="./">
							<img 
								src={`${process.env.PUBLIC_URL}/gambar/gambar matrix RBI/5E.png`}
								style={{ width: '150px', marginLeft: '-10px' }} 
							/>
						</a>
					  
					)}
					
			</div>
			
		</div>
		
      </div>
	  
	  <div class="row">
		
			<div style={{float: 'left', width: '90%'}}>
				
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				Basic Data
			</button>

			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				Screening
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Thinning Lining
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF External
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF SCC
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Brittle Fracture
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px',  backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				DF Fatigue
			</button>
			
			<button
				style={{ margin: '10px 10px 10px 0px', backgroundColor: '#dc3545', color:'white' }}
				className="btn"
				type="button"
				
			>
				COF
			</button>
				
			</div>
			
			<div style={{float: 'left', width: '10%'}}>
			
				<div className="col-12">
					<button style={{ marginTop: '10px', float: 'right' }} className="btn btn-success" type="submit">Submit</button>
				</div>
			
			</div>
			
		</div>
	  
	  <hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
	  
	  {/*Inisialisasi*/}
	  <div class="card" style={{margin: '20px 5px 5px 5px', width: '650px' }}>
					
				<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
					<h5 style={{textAlign: 'center'}}>Inisialisasi</h5>
				</div>
						
				<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
				
					<div className="row mb-2" style={{textAlign: 'center'}}>
								
							
						<div className="col-12">
									
							<input
								style={{marginRight: '2px'}}
								type="checkbox"
								checked={selectedPWHT}
								onChange={(e) => setSelectedPWHT(e.target.checked)}
							/>
							PWHT
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedLining}
								onChange={(e) => setSelectedLining(e.target.checked)}
								
							/>
							Lining
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCoating}
								onChange={(e) => setSelectedCoating(e.target.checked)}
								
							/>
							Coating
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedCladding}
								onChange={(e) => setSelectedCladding(e.target.checked)}
								
							/>
							Cladding
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedHeattracing}
								onChange={(e) => setSelectedHeattracing(e.target.checked)}
								
							/>
							Heat Tracing
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedInsulation}
								onChange={(e) => setSelectedInsulation(e.target.checked)}
								
							/>
							Insulation
							
							<input
								style={{margin: '0px 2px 0px 8px'}}
								type="checkbox"
								checked={selectedDatainspeksi}
								onChange={(e) => setSelectedDatainspeksi(e.target.checked)}
								
							/>
							Data Inspeksi
							
						</div>
							
								
					</div>
				
				</div>
				
			</div>
		{/*end Inisialisasi*/}
		
		
		{/*Basic Data*/}
		
		<div class="row g-3">
			
			{/*start identifier*/}
			<div class="col-lg-4 col-md-3 mb-2">
				<div style={{margin: '5px'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Identifier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tag Number
							</div>
							
							<div className="col-7">
								
								<input
										className="form-control"
										type="text"
										name="tag_number"
										value={tag_number}
										required
									/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Piping Service
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="piping_service"
									value={piping_service}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location From
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_from"
									value={location_from}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Location To
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="location_to"
									value={location_to}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="installation_date"
									value={installation_date}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Inspectiondate"
									value={Inspectiondate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Inspection Date (complete)
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="Inspectiondatecomplete"
									value={Inspectiondatecomplete}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Position
							</div>
							
							<div className="col-7">
								
								<Select
									placeholder="Position"
									value={{ value: position, label: position }}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Equipment Type
							</div>
							
							<div className="col-7">
								
								<Select
									placeholder="Equipment Type"
									value={{ value: Equipmenttype, label: Equipmenttype }}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Component Type
							</div>
							
							<div className="col-7">
								
								<Select
									placeholder="Component Type"
									value={{ value: Componenttype, label: Componenttype }}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Geometry Type
							</div>
							
							<div className="col-7">
								
								<Select
									placeholder="Geometry Type"
									value={{ value: Geometrytype, label: Geometrytype }}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								P&ID Number
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="PIDNumber"
									value={PIDNumber}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Thickness Actual
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Thicknessactual"
									value={Thicknessactual}
									disabled={!selectedDatainspeksi}
								/>
								
							</div>
							
						</div>
						
					</div>
					
				</div>
				
				{/*start Corrosion Data*/}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Corrosion Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Allowance
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionallowance"
									value={Corrosionallowance}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Probe
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosionprobe"
									value={Corrosionprobe}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Coupon
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Corrosioncoupon"
									value={Corrosioncoupon}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			{/*end Corrosion Data*/}
				
			</div>
			{/*end identifier*/}
		
			{/*start Construction Design Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div style={{margin: '5px', height: '100%'}} class="card">
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Construction Design Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="date"
									name="coating_installation_date"
									value={coating_installation_date}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Material Construction
							</div>
							
							<div className="col-7">
								
								<Select
									placeholder="Material Construction"
									value={{ value: material, label: material }}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Grade
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="grade"
									value={grade}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Code
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Designcode"
									value={Designcode}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Outside Diameter
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Outsidediameter"
									value={Outsidediameter}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Length
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Length"
									value={Length}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Nominal Thickness
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Nominalthickness"
									value={Nominalthickness}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Remaining Life of the Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Remaininglifeofthecladding"
									value={Remaininglifeofthecladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Corrosion Rate Cladding
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="text"
									name="Corrosionratecladding"
									value={Corrosionratecladding}
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Weld Joint Efficiency
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Weldjointefficiency"
									value={Weldjointefficiency}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Yield Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Yieldstrength"
									value={Yieldstrength}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Tensile Strength
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Tensilestrength"
									value={Tensilestrength}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Allowable Stress
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Allowablestress"
									value={Allowablestress}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Coating Installation Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Coatinginstallationdate"
									value={Coatinginstallationdate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Inspection Date
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousinspectiondate"
									value={Previousinspectiondate}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Previous Thickness Reading
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Previousthicknessreading"
									value={Previousthicknessreading}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Construction Design Data*/}
			
			{/*start Operation Data*/}
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Operation Data</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
							
							<div className="col-5">
								Design Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designpressure"
									value={Designpressure}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Designtemperature"
									value={Designtemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Pressure
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingpressure"
									value={Operatingpressure}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Operating Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Operatingtemperature"
									value={Operatingtemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Minimum Design Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Minimumdesigntemperature"
									value={Minimumdesigntemperature}
									required
								/>
								
							</div>
							
						</div>
						
						<div className="row mb-2">
							
							<div className="col-5">
								Upset Temperature
							</div>
							
							<div className="col-7">
								
								<input
									className="form-control"
									type="number"
									name="Upsettemperature"
									value={Upsettemperature}
									required
								/>
								
							</div>
							
						</div>
					
					</div>
					
				</div>
			</div>
			{/*end Operation Data*/}
			
			</div>
		
		{/*end Basic Data*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		</div>
		
		<div id="print2">
		
		{/*start Screening*/}
		
			<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Year of Fabrication Material
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Year of Fabrication Material"
									value={{ value: yearoffabricationmaterial, label: yearoffabricationmaterial }}
								/>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Representative Fluid
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Representative Fluid"
									value={{ value: Representativefluid, label: Representativefluid }}
									required
								/>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid Phase
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Fluid Phase"
									value={{ value: Fluidphase, label: Fluidphase }}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Normal Operating Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Fluid at Normal Operating Conditions"
									value={{ value: Fluidatnormaloperatingconditions, label: Fluidatnormaloperatingconditions }}
									required
									>
								</Select>
											
							</div>
							
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Fluid at Ambient Conditions
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Fluid at Ambient Conditions"
									value={{ value: Fluidatambientconditions, label: Fluidatambientconditions }}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Number of Past Inspection
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Numberofpastinspection"
									value={Numberofpastinspection}
									required
								/>
											
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="row">
			
			{/* What Is Contained the process ? */}
			
   
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px', height: '810px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Contained the process ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Caustic (Presence of NaOH at Elevated Temperature)
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Caustic (Presence of NaOH at Elevated Temperature)"
									value={{ value: Causticpresen, label: Causticpresen }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Alkaline Water (pH > 7.5)
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Alkaline Water (pH > 7.5)"
									value={{ value: Alkalinewater, label: Alkalinewater }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oil With Sulfur Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Oil With Sulfur Compounds"
									value={{ value: Oilwithsulfurcompounds, label: Oilwithsulfurcompounds }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides {"<"}50 ppm
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chlorides <50 ppm"
									value={{ value: Chloridesppm, label: Chloridesppm }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Free Water
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Free Water"
									value={{ value: Freewater, label: Freewater }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S and Hydrogen
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="H₂S and Hydrogen"
									value={{ value: H2Sandhydrogen, label: H2Sandhydrogen }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Oxygen
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Oxygen"
									value={{ value: Oxygen, label: Oxygen }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂S
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="H₂S"
									value={{ value: H2S, label: H2S }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HCl
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HCl"
									value={{ value: HCl, label: HCl }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H₂SO₄
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="H₂SO₄"
									value={{ value: H2SO4, label: H2SO4 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								HF
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HF"
									value={{ value: HF, label: HF }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2 div">
										
							<div className="col-7">
								CO₂
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="CO₂"
									value={{ value: CO2, label: CO2 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
				<div class="card" style={{margin: '5px', height: '610px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What Is Exposed To ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Mist Overspray from Cooling Towers
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Mist Overspray from Cooling Towers"
									value={{ value: Mistoverspray, label: Mistoverspray }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Steam Vents
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Steam Vents"
									value={{ value: Steamvents, label: Steamvents }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Deluge Systems
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Deluge Systems"
									value={{ value: Delugesystems, label: Delugesystems }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Containing Fluids, Mists, and Solids
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chloride Containing Fluids, Mists, and Solids"
									value={{ value: Chloridecontaining, label: Chloridecontaining }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Bearing Compounds
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sulfur Bearing Compounds"
									value={{ value: Sulfurbearing, label: Sulfurbearing }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chlorides And Water
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chlorides And Water"
									value={{ value: Chlorideswndwater, label: Chlorideswndwater }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrofluoric Acid
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Hydrofluoric Acid"
									value={{ value: Hydrofluoricacid, label: Hydrofluoricacid }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Acid Gas Treating Amines
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Acid Gas Treating Amines"
									value={{ value: Acidgastreatingamines, label: Acidgastreatingamines }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Soil
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Soil"
									value={{ value: Soil, label: Soil }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
			</div>
			
			{/* */}
			
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				{/*Area */}
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Area</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								Ft2
							</div>
										
							<div className="col-7">
											
								<input
									className="form-control"
									type="number"
									name="Areaft2"
									value={Areaft2}
									required
								/>
											
							</div>
							
						</div>
					
					</div>
				</div>
				{/*end Area*/}
				
				{/*Data Confidence Damage State*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Data Confidence Damage State</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								State 1
							</div>
										
							<div className="col-9">
											
								<Select
									placeholder="Data Confidence Damage State 1"
									value={{ value: Dataconfidencedamagestate1, label: Dataconfidencedamagestate1 }}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 2
							</div>
										
							<div className="col-9">
											
								<Select
									placeholder="Data Confidence Damage State 2"
									value={{ value: Dataconfidencedamagestate2, label: Dataconfidencedamagestate2 }}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								State 3
							</div>
										
							<div className="col-9">
											
								<Select
									placeholder="Data Confidence Damage State 3"
									value={{ value: Dataconfidencedamagestate3, label: Dataconfidencedamagestate3 }}
									required
									>
								</Select>
											
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*end Data Confidence Damage State*/}
				
				{/*start Material Grade Alloy (For  DF Polythionic Acid)*/}
				
				<div class="card" style={{margin: '5px', height: '450px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Grade Alloy (For  DF Polythionic Acid)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								All regular 300 series Stainless Steels and Alloys 600 and 800 
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="All regular 300 series Stainless Steels and Alloys 600 and 800 "
									value={{ value: Allregular300, label: Allregular300 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								H Grade 300 series SS
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="H Grade 300 series SS"
									value={{ value: HGrade300, label: HGrade300 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								L Grade 300 series SS 
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="L Grade 300 series SS"
									value={{ value: LGrade300, label: LGrade300 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								321 Stainless Steel 
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="321 Stainless Steel"
									value={{ value: Stainlesssteel321, label: Stainlesssteel321 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay 
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="347 Stainless Steel, Alloy 20, Alloy 625, All austenitic weld overlay"
									value={{ value: Stainlesssteel347, label: Stainlesssteel347 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				{/*end Material Grade Alloy (For  DF Polythionic Acid)*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Other Conditions ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Hydrogen Partial Pressure P > 50 psia
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Hydrogen Partial Pressure P > 50 psia"
									value={{ value: Hydrogenpartial, label: Hydrogenpartial }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Subject to Process Spills, Ingress of Moisture, or Acid Vapors
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Subject to Process Spills, Ingress of Moisture, or Acid Vapors"
									value={{ value: Subjecttoprocess, label: Subjecttoprocess }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Not Normally Operate Between 10 {"<"} TO {"<"} 350 °F, but Cool or Heat
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Not Normally Operate Between 10 < TO < 350 °F, but Cool or Heat"
									value={{ value: Notnormally, label: Notnormally }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								With Deteriorated Coating and/or Wrapping
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="With Deteriorated Coating and/or Wrapping"
									value={selectedWithdeteriorated}
									value={{ value: Withdeteriorated, label: Withdeteriorated }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Penetrations and Visually Damage Insulation Areas
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Penetrations and Visually Damage Insulation Areas"
									value={{ value: Penetrationsandvisually, label: Penetrationsandvisually }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="MDMT or MAT is Unknown or The Component is Known to Operate at or Below or Under Normal or Upset Conditions"
									value={{ value: MDMTorMAT, label: MDMTorMAT }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Have Been Past Fatigue Failures or There is Visible/Audible Shaking in This Piping"
									value={{ value: Havebeenpast, label: Havebeenpast }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								pH {"<"} 7.0
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="pH < 7.0"
									value={{ value: Ph7, label: Ph7 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection A ( NAThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionA"
									value={InspectionA}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection B ( NBThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionB"
									value={InspectionB}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection C ( NCThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionC"
									value={InspectionC}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-3">
								Inspection D ( NDThin )
							</div>
										
							<div className="col-9">
							
								<input
									className="form-control"
									type="number"
									name="InspectionD"
									value={InspectionD}
									required
								/>
							
							</div>
							
						</div>
						
					
					</div>
				</div>
				
				{/*start What is The Operating Temparature ?*/}
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>What is The Operating Temparature ?</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								10 {"<"} T {"<"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="10 < T < 350 °F"
									value={{ value: TF350, label: TF350 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								120 {"<"} T {"<"} 300 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="120 < T < 300 °F"
									value={{ value: TF300, label: TF300 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 100 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="T > 100 °F"
									value={{ value: T100, label: T100 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 350 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="T > 350 °F"
									value={{ value: T350, label: T350 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								650{"<"} T {"<"} 1070 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="650< T < 1070 °F"
									value={{ value: T1070, label: T1070 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								700 {"<"} T {"<"} 1050 °C
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="700 < T < 1050 °C"
									value={{ value: T1050, label: T1050 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								1100 {"<"} T {"<"} 1700 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="1100 < T < 1700 °F"
									value={{ value: T1700, label: T1700 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T {">"} 400 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="T > 400 °F"
									value={{ value: T400, label: T400 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								T ≥ 900 °F
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="T ≥ 900 °F"
									value={{ value: T900, label: T900 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				{/*end What is The Operating Temparature ?*/}
				
				
			</div>
			</div>
		
		{/*end Screening*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		</div>
		
		<div id="print3">
		
		{/*start DF Thinning Lining*/}
		
		<div class="col-lg-5 col-md-5 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
						
						<div className="row mb-2">
										
							<div className="col-5">
								Applicable
							</div>
										
							<div className="col-7">
											
								<Select
									placeholder="Applicable"
									value={{ value: Applicable, label: Applicable }}
									required
								>
							</Select>
											
							</div>
							
						</div>
					
					</div>
				</div>
			</div>
			
			
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Thinning</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 1)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS1"
									value={ReliabilityindicesDS1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 2)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS2"
									value={ReliabilityindicesDS2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (DS 3)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesDS3"
									value={ReliabilityindicesDS3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVΔt)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVAT"
									value={ReliabilityindicesCOVAT}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVSf)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVSf"
									value={ReliabilityindicesCOVSf}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reliability Indices (COVP)
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="ReliabilityindicesCOVP"
									value={ReliabilityindicesCOVP}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Inspection Effectiveness (For Tank Bottom)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Category
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Injection/Mix Points - Contain"
									value={{ value: Inspectioneffectivenesscategory, label: Inspectioneffectivenesscategory }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Injection/Mix Points</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Injection/Mix Points - Contain"
									value={{ value: Injectionmixpoints, label: Injectionmixpoints }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Deadleg</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Contain
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Deadleg - Contain"
									value={{ value: Deadlegcontain, label: Deadlegcontain }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Welded Construction (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Welded
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Deadleg - Contain"
									value={{ value: Weldedconstructionwelded, label: Weldedconstructionwelded }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Maintenance API STD 563 (Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Maintained
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Maintenance API STD 563 (Applicable only for AST) - Maintained"
									value={{ value: MaintenanceAPISTD563Maintained, label: MaintenanceAPISTD563Maintained }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Settlement(Applicable only for AST)</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Settlement(Applicable only for AST) - Criteria"
									value={{ value: Settlementcriteria, label: Settlementcriteria }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>On-Line Monitoring</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Thinning Mechanism
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="On-Line Monitoring - Thinning Mechanism"
									value={{ value: OnlinemonitoringThinningmechanism, label: OnlinemonitoringThinningmechanism }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Monitoring Type
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="On-Line Monitoring - Monitoring Type"
									value={{ value: OnlinemonitoringMonitoringtype, label: OnlinemonitoringMonitoringtype }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>COMPONENT LINING</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Type
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Lining Type"
									value={{ value: Liningtype, label: Liningtype }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Age of Lining or Years Since Last Thorough Visual Inspection
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Age of Lining or Years Since Last Thorough Visual Inspection"
									value={{ value: AgeofLiningoryearage, label: AgeofLiningoryearage }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Inorganic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Inorganic Linings - Type"
									value={{ value: Inorganicliningstype, label: Inorganicliningstype }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Organic Linings - Type
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Organic Linings - Type"
									value={{ value: Orgaliningstype, label: Orgaliningstype }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Lining Condition - Qualitative Condition
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Lining Condition - Qualitative Condition"
									value={{ value: Liningconditionqualitativecondition, label: Liningconditionqualitativecondition }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring  - Contain
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="On-Line Monitoring - Contain"
									value={{ value: Monitoringcontain, label: Monitoringcontain }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			
			</div>
		
		{/*end DF Thinning Lining*/}

		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		{/*start DF External*/}
		
		<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver - Arid / Dry
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Driver"
									value={{ value: ExtDriver, label: ExtDriver }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Equipment Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
								placeholder="Equipment Design or Fabrication"
								value={{ value: EquipmentDesignorFabrication, label: EquipmentDesignorFabrication }}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface
							</div>
										
							<div className="col-5">
							
								<Select
								placeholder="Interface"
								value={{ value: Interface, label: Interface }}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Coating Quality
							</div>
										
							<div className="col-5">
							
								<Select
								placeholder="Coating Quality"
								value={{ value: CoatingQuality, label: CoatingQuality }}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Coating Quality
							</div>
										
							<div className="col-5">
							
								<Select
								placeholder="Coating Quality"
								value={{ value: CoatingQuality, label: CoatingQuality }}
								required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC Ferritic</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="External CLSCC Ferritic - Drive"
									value={{ value: EcfDrive, label: EcfDrive }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="External CLSCC Ferritic - Cracking"
									value={{ value: EcfCracking, label: EcfCracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: EcfInspectionPerformed, label: EcfInspectionPerformed }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
			
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CUI Ferritic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Driver"
									value={{ value: CfcDriver, label: CfcDriver }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Type
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Insulation Type"
									value={{ value: InsulationType, label: InsulationType }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Complexity
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Complexity"
									value={{ value: Complexity, label: Complexity }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Insulation Condition
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Insulation Condition"
									value={{ value: InsulationCondition, label: InsulationCondition }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Design or Fabrication
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="CUI Ferritic Component - Design or Fabrication"
									value={{ value: CfcDesignorFabrication, label: CfcDesignorFabrication }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Interface Enters Soil or Water
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Interface Enters Soil or Water"
									value={{ value: Interfaceenterssoilorwater, label: Interfaceenterssoilorwater }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>External CLSCC CUI</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Driver
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="External CLSCC CUI - Drive"
									value={{ value: EcfDrivecui, label: EcfDrivecui }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="External CLSCC CUI - Cracking"
									value={{ value: EcfCrackingcui, label: EcfCrackingcui }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Free Insulation
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="External CLSCC CUI - Chloride Free Insulation"
									value={{ value: Chloridefreecui, label: Chloridefreecui }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspection Performed
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: InspectionPerformedcui, label: InspectionPerformedcui }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
			
				
			</div>
			
			
			</div>
		
		{/*end DF External*/}

		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		</div>
		
		<div id="print4">
		
		{/*start DF SCC*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Caustic Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								value={{ value: DetermineSusceptibility, label: DetermineSusceptibility }}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: CRNumberofInspections, label: CRNumberofInspections }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amine Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Determine Susceptibility for Cracking
							</div>
										
							<div className="col-5">
							
								<Select
								value={{ value: ACDetermineSusceptibility, label: ACDetermineSusceptibility }}
								required
							>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: ACNumberofInspections, label: ACNumberofInspections }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Alkaline Carbonate</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: Acephwater, label: Acephwater }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								CO3 Concentration
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Alkaline Carbonate - CO3 Concentration"
									value={{ value: AceCO3, label: AceCO3 }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="ALkaline Carbonate - Cracking"
									value={{ value: Acecracking, label: Acecracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="ALkaline Carbonate - Function of Heat Treatment"
									value={{ value: Acefoht, label: Acefoht }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
			
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Polythionic Acid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Polythionic Acid - Cracking"
									value={{ value: PACracking, label: PACracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Material
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Polythionic Acid - Material"
									value={{ value: Pamaterial, label: Pamaterial }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sulfide Stress Cracking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: Sscphwater, label: Sscphwater }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: Sscfunctionh2swater, label: Sscfunctionh2swater }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sulfide Stress Cracking - Cyanides"
									value={{ value: Ssccyanides, label: Ssccyanides }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sulfide Stress Cracking - Function of Heat Treatment"
									value={{ value: Sscfunctiontreatment, label: Sscfunctiontreatment }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sulfide Stress Cracking - Max Brinnell Hardness"
									value={{ value: Sscmaxbrinnell, label: Sscmaxbrinnell }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sulfide Stress Cracking - Cracking"
									value={{ value: Ssccracking, label: Ssccracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: SCCNumberofInspections, label: SCCNumberofInspections }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Chloride SCC</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Operating Temperature (°F)
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chloride SCC - Operating Temperature (°F)"
									value={{ value: Csccoperatingtemperature, label: Csccoperatingtemperature }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Chloride Ion
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chloride SCC - Chloride Ion"
									value={{ value: Csccchloride, label: Csccchloride }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Chloride SCC - Cracking"
									value={{ value: Cscccracking, label: Cscccracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hydrogen Stress Cracking HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Condition
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Hydrogen Stress Cracking HF - Condition"
									value={{ value: Hsccondition, label: Hsccondition }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Max Brinnell Hardness
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Hydrogen Stress Cracking HF - Max Brinnell Hardness"
									value={{ value: Hscmaxbrinnell, label: Hscmaxbrinnell }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Hydrogen Stress Cracking HF - Cracking"
									value={{ value: Hsccracking, label: Hsccracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC-H2S</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								pH of Water
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: Shicphwater, label: Shicphwater }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of H2S Content of Water
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: Shicfunctionh2swater, label: Shicfunctionh2swater }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cyanides
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC-H2S - Cyanides"
									value={{ value: Shiccyanides, label: Shiccyanides }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC-H2S - Cracking"
									value={{ value: Shiccracking, label: Shiccracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Function of Heat Treatment
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC-H2S - Function of Heat Treatment"
									value={{ value: Shicfunctiontreatment, label: Shicfunctiontreatment }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC-H2S - Sulfur Content"
									value={{ value: Shicsulfurcontent, label: Shicsulfurcontent }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Number of Inspections Coresponding Inspection Effectiveness
							</div>
										
							<div className="col-5">
							
								<Select
									value={{ value: ShicNumberofInspections, label: ShicNumberofInspections }}
									required
								>
								</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On-Line Monitoring - Monitoring Method
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC-H2S - On-Line Monitoring - Monitoring Method"
									value={{ value: Hicmonitoringmethod, label: Hicmonitoringmethod }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>HIC/SOHIC HF</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Cracking
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC HF - Cracking"
									value={{ value: Hicsohiccracking, label: Hicsohiccracking }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Sulfur Content
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC HF - Sulfur Content"
									value={{ value: Hicsohicsulfurcontent, label: Hicsohicsulfurcontent }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								On Line Monitoring - Adjustment
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIC/SOHIC HF - Sulfur Content"
									value={{ value: Hicsohicadjustment, label: Hicsohicadjustment }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						
						
						
					</div>
				</div>
			
			</div>
			
			</div>
		
		{/*end DF SCC*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		{/*start DF Brittle Fracture*/}
		
		<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>High Temperature Hydrogen Attack</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								HTHA Damage - Condition
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="HIGH TEMPERATURE HYDROGEN ATTACK - HTHA Damage - Condition"
									value={{ value: Hthadamagecondition, label: Hthadamagecondition }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Brittle Fracture</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Administrative or Process Control"
									value={{ value: Admprocon, label: Admprocon }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Brittle Fracture - ASME Exemption - Curve"
									value={{ value: Bfasmeexcurve, label: Bfasmeexcurve }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				
				</div>
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Low Alloy Steel Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - Administrative or Process Control"
									value={{ value: Laseaorprocon, label: Laseaorprocon }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								ASME Exemption - Curve
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="LOW ALLOY STEEL EMBRITTLEMENT - ASME Exemption - Curve"
									value={{ value: Lasasmecurve, label: Lasasmecurve }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Sigma Phase Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Sigma Content
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Sigma Phase Embrittlement - Sigma Content"
									value={{ value: Sigmaembcontent, label: Sigmaembcontent }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>885  °F Embrittlement</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Administrative or Process Control
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="885  °F Embrittlement - Administrative or Process Control"
									value={{ value: Embaopc, label: Embaopc }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-7">
								Reference Temperature - Tref
							</div>
										
							<div className="col-5">
							
								<input
									className="form-control"
									type="number"
									name="Embreftem"
									value={Embreftem}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
			</div>
			
		</div>
		
		{/*end DF DF Brittle Fracture*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		</div>
		
		<div id="print5">
		
		{/*start DF Fatigue*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Previous Failure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Previous Failure - Criteria"
									value={{ value: Prefacriteria, label: Prefacriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Amount of Visible/Audible Shaking ot Audible Noise</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Amount of Visible/Audible Shaking ot Audible Noise - Criteria"
									value={{ value: Prefadfbpf, label: Prefadfbpf }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Visible/Audible Shaking</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Previous Failure - Criteria"
									value={{ value: Visshcriteria, label: Visshcriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Type of Cyclic Loading</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Type of Cyclic Loading - Criteria"
									value={{ value: Tocycriteria, label: Tocycriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Corrective Action</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Adjustment Corrective Action - Criteria"
									value={{ value: Adcocriteria, label: Adcocriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Pipe Complexity</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Adjustment Pipe Complexity - Criteria"
									value={{ value: Adpicriteria, label: Adpicriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Condition of Pipe</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Adjustment Condition of Pipe - Criteria"
									value={{ value: Adcoicriteria, label: Adcoicriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Joint Type or Branch Design</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Adjustment Joint Type or Branch Design - Criteria"
									value={{ value: Adjotcriteria, label: Adjotcriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Adjustment Branch Diameter</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-7">
								Criteria
							</div>
										
							<div className="col-5">
							
								<Select
									placeholder="Adjustment Branch Diameter - Criteria"
									value={{ value: Adbrdicriteria, label: Adbrdicriteria }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
			</div>
			
		</div>
		
		{/*end DF Fatigue*/}
		
		<hr style={{backgroundColor: '#d3d3d3', height: '4px'}} />
		
		{/*start COF*/}
		
			<div class="row">
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Hole Size</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								1
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Hole Size 1"
									value={{ value: Holesize1, label: Holesize1 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								2
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Hole Size 2"
									value={{ value: Holesize2, label: Holesize2 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								3
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Hole Size 3"
									value={{ value: Holesize3, label: Holesize3 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								4
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Hole Size 4"
									value={{ value: Holesize4, label: Holesize4 }}
									required
								>
							</Select>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Atmospheric Pressure</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Patm
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Atmosprepatm"
									value={Atmosprepatm}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Conversion Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								C2 (lbs)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Confac2"
									value={Confac2}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Detection System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Detection System - Type"
									value={{ value: Detsysty, label: Detsysty }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Isolation System</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Isolation System - Type"
									value={{ value: Isosysty, label: Isosysty }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Total Leak Durations</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-5">
								4 (minutes)
							</div>
										
							<div className="col-7">
							
								<input
									className="form-control"
									type="number"
									name="Toledur"
									value={Toledur}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Consequence Area Mitigation Reduction Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								factmit
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Consequence Area Mitigation Reduction Factor - factmit"
									value={{ value: Conarmit, label: Conarmit }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Environmental Costs</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								envcost (($/bbl))
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Encosen"
									value={Encosen}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Fluid Type</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Type
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Fluid Type"
									value={{ value: Fluidtypecof, label: Fluidtypecof }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Percentage of the Toxic Component</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								mfractox
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Toxperthetoxcom"
									value={Toxperthetoxcom}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Toxic Fluid</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Toxic Fluid
							</div>
										
							<div className="col-8">
							
								<Select
									placeholder="Toxic Fluid"
									value={{ value: Toxflucov, label: Toxflucov }}
									required
								>
							</Select>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Acids or Caustics</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								5 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus5"
									value={Acidorcaus5}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								6 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus6"
									value={Acidorcaus6}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								7 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus7"
									value={Acidorcaus7}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								8 [ft2]
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Acidorcaus8"
									value={Acidorcaus8}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Material Cost Factors</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								matcost
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Matcosfacmat"
									value={Matcosfacmat}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Cost Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								equipcost ($/ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqcosfac"
									value={Eqcosfac}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Equipment Outage Multiplier</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Equipment Outage Multiplier
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Eqoutmul"
									value={Eqoutmul}
									required
								/>
							
							</div>
							
						</div>
						
					</div>
				</div>
				
				
			</div>
			
			<div class="col-lg-4 col-md-4 mb-2">
			
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Blending Factor</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac1"
									value={Blenfac1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac2"
									value={Blenfac2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac3"
									value={Blenfac3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								Acids or Caustics factnIC 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Blenfac4"
									value={Blenfac4}
									required
								/>
							
							</div>
							
						</div>
					
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 1
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon1"
									value={Caforstoracleaknon1}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 2
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon2"
									value={Caforstoracleaknon2}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 3
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon3"
									value={Caforstoracleaknon3}
									required
								/>
							
							</div>
							
						</div>
						
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmd ,nleak (ft2) 4
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Caforstoracleaknon4"
									value={Caforstoracleaknon4}
									required
								/>
							
							</div>
							
						</div>
						
						
						
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>CA for Steam or Acid Leaks Non-Flammablem Non-Toxic Personnel Injury</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								CAcmdnfnt (ft2)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Finoflnotoxcon"
									value={Finoflnotoxcon}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				<div class="card" style={{margin: '5px'}}>
					
					<div class="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
						<h5 style={{textAlign: 'center'}}>Production Cost</h5>
					</div>
					
					<div class="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
					
						<div className="row mb-2">
										
							<div className="col-4">
								prodcost ($/days)
							</div>
										
							<div className="col-8">
							
								<input
									className="form-control"
									type="number"
									name="Procoxprod"
									value={Procoxprod}
									required
								/>
							
							</div>
							
						</div>
					</div>
				</div>
				
				
			</div>
			</div>
		
		{/*start COF*/}
		
		<div style={{backgroundColor: '#d3d3d3', width: '100%', textAlign: 'center'}}>
			ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		</div>
		
		</div>
		
    </div>
	
	</div>
  );
};

export default RBIprint;

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Logout from './Logout';
import Select from 'react-select';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import $ from 'jquery';

const Kontribusi = () => {
  const navigate = useNavigate();
  const [userData, setUserData] = useState(null);
  const [token, setToken] = useState(null);

const capitalizeLabel = (label) => {
  
  return label.toLowerCase().replace(/\b\w/g, char => char.toUpperCase());
};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};



  const [pointOfConcernOptions, setPointOfConcernOptions] = useState([
    { value: '', label: 'Point of Concern' },
    { value: 'sfpoc', label: 'Station Facilities' },
    { value: 'nipoc', label: 'Network Infrastructure' },
    { value: 'repoc', label: 'Rotating Equipment' },
    { value: 'lngrpoc', label: 'LNG Regasification' },
    { value: 'lpgppoc', label: 'LPG Plant' },
    { value: 'cngrpoc', label: 'CNG Refueling' },
    { value: 'pgpoc', label: 'Power Generation' }
  ]);

  const [standardCodeOptions, setStandardCodeOptions] = useState([
    { value: 'PEDOMAN', label: 'Standard Code' },
	{ value: 'PEDOMAN', label: 'Pedoman' },
    { value: 'AICHE', label: 'AICHE' },
    { value: 'ANSI', label: 'ANSI' },
    { value: 'API', label: 'API' },
    { value: 'ASME', label: 'ASME' },
    { value: 'DNV', label: 'DNV' },
    { value: 'IEC', label: 'IEC' },
    { value: 'NACE', label: 'NACE' },
    { value: 'NFPA', label: 'NFPA' }
  ]);

  const [tablesConversionOptions, setTablesConversionOptions] = useState([
    { value: '', label: 'Tables, Conversion and Calculator' },
    { value: 'DEFECT FREE PIPE', label: 'DEFECT FREE PIPE' },
    { value: 'CORROSION', label: 'CORROSION' },
    { value: 'GOUGES', label: 'GOUGES' },
    { value: 'DENTS', label: 'DENTS' },
    { value: 'DENTS AND GOUGES', label: 'DENTS AND GOUGES' },
    { value: 'DEFECTS IN PIPELINE FITTINGS', label: 'DEFECTS IN PIPELINE FITTINGS' },
    { value: 'DEFECT INTERACTION', label: 'DEFECT INTERACTION' },
    { value: 'TIME DEPENDENT BEHAVIOUR', label: 'TIME DEPENDENT BEHAVIOUR' },
    { value: 'LEAK AND RUPTURE', label: 'LEAK AND RUPTURE' },
    { value: 'FRACTURE PROPAGATION', label: 'FRACTURE PROPAGATION' }
  ].map(option => ({
    ...option,
    label: capitalizeLabel(option.label)
  })));

  const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: 'photostock_sor1', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);

  const [tablesNativeStockOptions, setTablesNativeStockOptions] = useState([
    { value: 'nativestock_sor1', label: 'NativeStock' },
    
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'kosong', label: '2. Pertamina Gas' },
	{ value: 'nativestock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'nativestock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'nativestock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'nativestock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'nativestock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'nativestock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'nativestock_sor1', label: '16. PGN-SOR1' },
	{ value: 'nativestock_sor2', label: '17. PGN-SOR2' },
	{ value: 'nativestock_sor3', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);
  
  const [tablesPsimodellerOptions, setTablesPsimodellerOptions] = useState([
    { value: 'Psimodeller_pertagas', label: 'PSI Modeller' },
    
	{ value: 'psimodeller_pgn', label: '1. Perusahaan Gas Negara' },
	{ value: 'psimodeller_pertagas', label: '2. Pertamina Gas' },
	{ value: 'psimodeller_nr', label: '3. Nusantara Regas' },
	{ value: 'psimodeller_pli', label: '4. PLI' },
	{ value: 'psimodeller_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'psimodeller_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'psimodeller_pertasamtan', label: '7. Perta Samtan Gas' },
	{ value: 'psimodeller_lng', label: '8. PGN LNG Indonesia' },
	{ value: 'psimodeller_widar', label: '9. Widar Mandripa Nusantara' },
	{ value: 'psimodeller_saka', label: '10. Saka Energi Indonesia' },
	{ value: 'psimodeller_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'Psimodeller_pertagasniaga', label: '12. Pertagas Niaga' },
	{ value: 'psimodeller_paertaarungas', label: '13. Perta Arun Gas' },
	{ value: 'psimodeller_pertadayagas', label: '14. Perta Daya Gas' },
	{ value: 'kosong', label: '15. PGN-SSWJ' },
	{ value: 'kosong', label: '16. PGN-SOR1' },
	{ value: 'kosong', label: '17. PGN-SOR2' },
	{ value: 'kosong', label: '18. PGN-SOR3' },
	{ value: 'kosong', label: '19. PGN-SOR4' },
	
  ]);

  const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);
  const [selectedStandardCode, setSelectedStandardCode] = useState(null);
  const [selectedTablesConversion, setSelectedTablesConversion] = useState(null);
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);
  const [selectedTablesNativeStock, setSelectedTablesNativeStock] = useState(null);
  const [selectedTablesPsimodeller, setSelectedTablesPsimodeller] = useState(null);


  useEffect(() => {
    if (selectedPointOfConcern) {
      navigate(selectedPointOfConcern.value);
    }
  }, [selectedPointOfConcern, navigate]);
  
  useEffect(() => {
    if (selectedTablesNativeStock) {
		
		if(selectedTablesNativeStock.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesNativeStock(null);
		}else{
			navigate(selectedTablesNativeStock.value);
		}
	}
  }, [selectedTablesNativeStock, navigate]);
  
  useEffect(() => {
    if (selectedTablesPsimodeller) {
		
		if(selectedTablesPsimodeller.value === "kosong"){
			alert("Coming Soon!!!");
			setSelectedTablesPsimodeller(null);
		}else{
			navigate(selectedTablesPsimodeller.value);
		}
	}
  }, [selectedTablesPsimodeller, navigate]);
  
  useEffect(() => {
    if(selectedTablesPhotoStock) {
		if (selectedTablesPhotoStock.value === "kosong") {  
			alert("Coming Soon!!!");
			setSelectedTablesPhotoStock(null);
		}else{
			navigate(selectedTablesPhotoStock.value);
		}
    }
  }, [selectedTablesPhotoStock, navigate]);
  
  useEffect(() => {
  if (selectedStandardCode) {
    navigate('/standardCode', { state: { code: selectedStandardCode.value } });
  }
}, [selectedStandardCode, navigate]);

useEffect(() => {
  // Cek jika selectedTablesConversion tidak kosong/null dan memiliki nilai yang valid
  if (selectedTablesConversion && selectedTablesConversion.value) {
    navigate('/Tables_conversion_calculator', { state: { code: selectedTablesConversion.value } });
  }
}, [selectedTablesConversion, navigate]);

  return (
  
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
		  <img 
			src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
			style={{ width: '150px', marginTop: '-30px' }} 
		  />
		</a>
	  
      <img style={{ float: 'right', marginTop: '10px', marginRight: '15px' }} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
        
      <div style={{ clear: 'both' }}></div>
      
      <div id="pof" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
        <Select
		  styles={customStyles}
          options={pointOfConcernOptions}
          onChange={setSelectedPointOfConcern}
          placeholder="Point of Concern"
          value={selectedPointOfConcern}
        />
      </div>
      
      <div id="sc" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
        <Select
		  styles={customStyles}
          options={standardCodeOptions}
          onChange={setSelectedStandardCode}
          placeholder="Standard Code"
          value={selectedStandardCode}
        />
      </div>
    
		{userData && userData.entitas !== "PTTransportasiGasIndonesia" && (
        <>
		
		  <div id="tcac" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={tablesConversionOptions}
			  onChange={setSelectedTablesConversion}
			  value={selectedTablesConversion}
			  placeholder="Tables, Conversion and Calculator"
			/>
		  </div>
		  
		  <div id="tph" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		  
		  <div id="tnts" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={tablesNativeStockOptions}
			  onChange={setSelectedTablesNativeStock}
			  placeholder="NativeStock"
			  value={selectedTablesNativeStock}
			/>
		  </div>
		  
		  <div id="tpm" style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPsimodellerOptions}
			  onChange={setSelectedTablesPsimodeller}
			  placeholder="ModellerStock"
			  value={selectedTablesPsimodeller}
			/>
		  </div>
		</>
		)}
      
	  <iframe style={{width: "70%", marginLeft: "14%", marginRight: "14%", height: "70vh"}} src="https://drive.google.com/file/d/1Y3QmlNxL3b5nIrvNjeM_SmwwsN-WDUUy/preview"></iframe>
	  
      <div style={{
        position: 'fixed', 
        bottom: '0', 
        width: '100%', 
        backgroundColor: '#ffff', 
        padding: '5px', 
        fontSize: '12px', 
        textAlign: 'center'
      }}>
        ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
        <div style={{
          position: 'absolute', 
          bottom: '5px', 
          right: '50px'
        }}>
          <Logout />
        </div>
      </div>
    </div>
  );
};

export default Kontribusi;
//sebelumnya
import React, { useState, useEffect } from 'react';
import Select from 'react-select';

const Pekerjaanasantiupload = () => {
  const currentYear = new Date().getFullYear();
  
  const years = [
  { value: "", label: "Pilih Tahun" },
  ...Array.from({ length: 21 }, (_, index) => ({
    value: currentYear - index,
    label: currentYear - index,
  })),
];

  const PekerjaanOptions = [
    { value: 'RIM SSWJ', label: 'RIM SSWJ' },
    { value: 'RIM SHG', label: 'RIM SHG' },
    { value: 'IM', label: 'IM' },
  ];

  const JenispekerjaanOptions = [
    { value: 'Non Rutin', label: 'Non Rutin' },
    { value: 'Rutin', label: 'Rutin' },
  ];

  const [selectedPekerjaan, setSelectedPekerjaan] = useState({ value: "", label: "Pilih Pekerjaan" });
  const [selectedPekerjaaninput, setSelectedPekerjaaninput] = useState({ value: "", label: "Pilih Pekerjaan" });
  const [selectedTahun, setSelectedTahun] = useState({ value: "", label: "Pilih Tahun" });
  const [selectedTahuninput, setSelectedTahuninput] = useState({ value: "", label: "Pilih Tahun" });
  const [selectedJenispekerjaan, setSelectedJenispekerjaan] = useState({ value: "", label: "Pilih Jenis Pekerjaan" });
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loading2, setLoading2] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);

  const [formData, setFormData] = useState({
    Pekerjaan: "",
    Jenispekerjaan: "",
    tahun: "",
  });

  const [error, setError] = useState("");

  const handleSubmit = async (e) => {
	setLoading2(true);
    e.preventDefault();
    if (selectedPekerjaan?.value === "RIM SSWJ" && !selectedJenispekerjaan) {
      setError("Jenis Pekerjaan harus dipilih jika Pekerjaan adalah RIM SSWJ.");
      return;
    }
    setError("");
    console.log("Data yang dikirim: ", formData);

    try {
      await fetch(
        'https://script.google.com/macros/s/AKfycbycRs7tkZZARoF8S81IaIYV-wBzOPnIhr11niIAF6o3NlH-ck36nWQ208oYgTuK3UaL/exec?input=fordertingkat1',
        {
          method: 'POST',
          mode: 'no-cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(formData),
        }
      );
	  
	  
    } catch (error) {
        console.error('Error:', error); // Menangani jika ada error dalam request
    }finally {
      setLoading2(false);
	  setTimeout(() => {
           
		   setSelectedTahun(selectedTahuninput);
		   setSelectedPekerjaan(selectedPekerjaaninput);
		   
        }, 5000);
    }
	
   
  };
  
  const handlePekerjaaninputChange = (selectedOption) => {
    setSelectedPekerjaaninput(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Pekerjaan: selectedOption ? selectedOption.value : "",
      Jenispekerjaan: selectedOption?.value === "RIM SSWJ" ? prevFormData.Jenispekerjaan : null,
    }));

    if (selectedOption?.value !== "RIM SSWJ") {
      setSelectedJenispekerjaan(null);
    }
  };
  
  const handlePekerjaanChange = (selectedOption) => {
    setSelectedPekerjaan(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Pekerjaan: selectedOption ? selectedOption.value : "",
    }));

  };

  const handleJenispekerjaanChange = (selectedOption) => {
    setSelectedJenispekerjaan(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      Jenispekerjaan: selectedOption ? selectedOption.value : "",
    }));
  };

  const refresh = () => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const queryParams = new URLSearchParams({
          tahun: selectedTahun.value,
          pekerjaan: selectedPekerjaan ? selectedPekerjaan.value : "",
        }).toString();

        const response = await fetch(
          `https://script.google.com/macros/s/AKfycbycRs7tkZZARoF8S81IaIYV-wBzOPnIhr11niIAF6o3NlH-ck36nWQ208oYgTuK3UaL/exec?${queryParams}&filepkr=no`
        );
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error('Error:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  };

  useEffect(() => {
    refresh();
  }, [selectedTahun, selectedPekerjaan]);

  const handleTahunChange = (selectedOption) => {
    setSelectedTahun(selectedOption);
    setFormData((prevFormData) => ({
      ...prevFormData,
      tahun: selectedOption ? selectedOption.value : "",
    }));
  };
  
  const handleTahuninputChange = (selectedOption) => {
    setSelectedTahuninput(selectedOption);
	setFormData((prevFormData) => ({
      ...prevFormData,
      tahun: selectedOption ? selectedOption.value : "",
    }));
    
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = data.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(data.length / itemsPerPage);
  const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);

  const handleNextPage = () => {
    if (currentPage < totalPages) setCurrentPage(currentPage + 1);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) setCurrentPage(currentPage - 1);
  };

  return (
    <div className="container my-4">
      <div className="row">
        {/* Card untuk Form */}
        <div className="col-lg-4 mb-3">
          <div className="card shadow-lg border-0">
            <div className="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
			  {loading2 && <p>Loading data...</p>}
              <h5 align="center">Form Filter</h5>
            </div>
            <div className="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
              <form onSubmit={handleSubmit}>
                <div className="mb-3">
                  <label>Tahun</label>
                  <Select options={years} value={selectedTahuninput} onChange={handleTahuninputChange} />
                </div>
                <div className="mb-3">
                  <label>Pekerjaan</label>
                  <Select options={PekerjaanOptions} value={selectedPekerjaaninput} onChange={handlePekerjaaninputChange} />
                </div>
                {selectedPekerjaaninput?.value === "RIM SSWJ" && (
                  <div className="mb-3">
                    <label>Jenis Pekerjaan</label>
                    <Select
                      options={JenispekerjaanOptions}
                      value={selectedJenispekerjaan}
                      onChange={handleJenispekerjaanChange}
                    />
                  </div>
                )}
                {error && <p className="text-danger">{error}</p>}
                <button className="btn btn-success w-40" type="submit">
                  Submit
                </button>
              </form>
            </div>
          </div>
        </div>

        {/* Card untuk Tabel */}
        <div className="col-lg-8">
          <div className="card shadow-lg border-0">
            <div className="card-header" style={{backgroundColor: "#646464", color: 'white'}}>
              <h5 align="center">Folder Pekerjaan</h5>
            </div>
            <div className="card-body" style={{backgroundColor: "#d3d3d3", color: 'black'}}>
			
				<div className="row">
				
				<div className="mb-3 col-lg-3 col-md-3">
                  <label>Tahun</label>
                  <Select options={years} value={selectedTahun} onChange={handleTahunChange} />
                </div>
                <div className="mb-3 col-lg-4 col-md-4">
                  <label>Pekerjaan</label>
                  <Select options={PekerjaanOptions} value={selectedPekerjaan} onChange={handlePekerjaanChange} />
                </div>
				
				</div>
			
              {loading && <p>Loading data...</p>}
              {!loading && data.length === 0 && <p className="text-danger">Data masih kosong.</p>}
              {currentItems.length > 0 && (
                <>
				
                  <table className="table table-bordered table-striped table-hover">
                    <thead>
                      <tr>
                        <th>Nama File</th>
                        <th>Pekerjaan</th>
                        <th>Jenis Pekerjaan</th>
                        <th>Opsi</th>
                      </tr>
                    </thead>
                    <tbody>
                      {currentItems.map((item, index) => (
                        <tr key={index}>
                          <td>{item.tahun} -  {item.genranum}</td>
                          <td>{item.pekerjaan}</td>
                          <td>{item["jenis pekerjaan"] || "-"}</td>
						  <td>
						  
							<a
                              href="/pekerjaanasantiupload"
                              target="_blank"
                              onClick={(e) => {
                                e.preventDefault();
                                const dataToStore = {
                                  tahun: item.tahun,
                                  pekerjaan: item.pekerjaan,
                                  jenisPekerjaan: item["jenis pekerjaan"] || null,
                                  genranum: item["genranum"],
								  idcatatan: item["id folder catatan"],
								  ideditable: item["id folder editable"],
								  idpdf: item["id table pdf"],
								  idfoldertahun: item["id folder tahun"],
                                };
                                localStorage.setItem("datafolder", JSON.stringify(dataToStore));
                                window.open("/pekerjaanasantiupload", "_blank");
                              }}
                            >
                              Upload File
                            </a>
						  
						  </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
					<div className="d-flex justify-content-center my-3">
					  {pageNumbers.map((number) => (
						<button
						  key={number}
						  className={`btn ${currentPage === number ? 'btn-success' : 'btn-outline-success'} mx-1`}
						  onClick={() => setCurrentPage(number)}
						>
						  {number}
						</button>
					  ))}
					</div>
					<div className="d-flex justify-content-between">
					  <button
						className="btn btn-success"
						onClick={handlePrevPage}
						disabled={currentPage === 1}
					  >
						Previous
					  </button>
					  <button
						className="btn btn-success"
						onClick={handleNextPage}
						disabled={currentPage === totalPages}
					  >
						Next
					  </button>
					</div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Pekerjaanasantiupload;

import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Select from 'react-select';
import axios from 'axios';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import Muhlbauer_onshore from './Muhlbauer_onshore';
import Muhlbauer_offshore from './Muhlbauer_offshore';
import Muhlbauer_pe from './Muhlbauer_pe';

const Nipoc = () => {
	//array sampai 145
	
	const [isHovered, setIsHovered] = useState(false);
  const [activeView, setActiveView] = useState(null);
   const [userData, setUserData] = useState(null);
   const [token, setToken] = useState(null);
   const [selectedPointOfConcern, setSelectedPointOfConcern] = useState(null);
   const [selectedVisualrov, setSelectedVisualrov] = useState(null);
   const [selectedCompany, setSelectedCompany] = useState('');
   const [selectedArea, setSelectedArea] = useState('');
   const [selectedJalur, setSelectedJalur] = useState('');
   const [areas, setAreas] = useState([]);
   const [jalur, setJalur] = useState([]);
   const [jalurData, setJalurData] = useState({});
   const [jalurDataheight, setJalurDataheight] = useState({});
   const navigate = useNavigate();
   const [muhlbauer_onshoreKey, setMuhlbauer_onshore] = useState(0);
   const [muhlbauer_offshoreKey, setMuhlbauer_offshore] = useState(0);
   const [Muhlbauer_pekey, setMuhlbauer_pe] = useState(0);
   
   const [scrollPosition, setScrollPosition] = useState(0); // Untuk menyimpan posisi scroll saat ini
  const scrollRef = useRef(null); // Membuat referensi untuk elemen scroll

  const handleScroll = () => {
    // Memperbarui posisi scroll dengan nilai scrollTop elemen
    setScrollPosition(scrollRef.current.scrollTop);
	//alert(`Posisi Scroll: ${scrollPosition} px`); 
  };
	
	const [hoveredItem, setHoveredItem] = useState(null);

	const handleMouseEnter = (item) => {
		
		setHoveredItem(item);
	};

	const handleMouseLeave = () => {
		
		setHoveredItem(null);
		  
	};
	
	const showOnshore = () => {
    setActiveView('onshore');
  };

  // Fungsi untuk menampilkan Offshore dan menyembunyikan Onshore
  const showOffshore = () => {
    setActiveView('offshore');
  };
  
  const showpe = () => {
    setActiveView('nonsteel');
  };
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [Muhlbauer_onshoreKey, setMuhlbauer_onshoreKey] = useState(0);
	const [Muhlbauer_offshoreKey, setMuhlbauer_offshoreKey] = useState(0);
	const [Muhlbauer_peKey, setMuhlbauer_peKey] = useState(0);
	
	var ifr1 = {
		"ffs": {
        "preview": "https://integritycalculator.blogspot.com",
		},
		"pipetally": {
			"preview": "https://public.tableau.com/views/ip_baru_17146088122110/Depth?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
		},
		"cpmonitoring": {
			"preview": "https://public.tableau.com/views/Cp_Point_AOL2Fase/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
		},
		"aligmentsheet": {
			"preview": "https://a360.co/4bcisxW",
		},
		"asgcpmonitoring": {
			"preview": "https://ario93.github.io/asgpotentialpattern.html",
		},
	};
	
	var ifr2 = {
	"refcpmonitoring": {
		"preview":"https://drive.google.com/file/d/1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL/preview",
		"view":"https://drive.google.com/file/d/1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL/view",
		"download":"https://drive.google.com/uc?export=download&id=1u7Yu1E0fnAEzRC_LX-YcxLvc8VBN43gL",
	},
	"api579": {
		"preview":"https://drive.google.com/file/d/1dDhk01413umHYGHZH2vp6W_SluGvj503/preview",
		"view":"'https://drive.google.com/file/d/1dDhk01413umHYGHZH2vp6W_SluGvj503/view",
		"download":"https://drive.google.com/uc?export=download&id=1dDhk01413umHYGHZH2vp6W_SluGvj503",
	},
	"DNVRPF101":{
		"preview":"https://drive.google.com/file/d/1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG/preview",
		"view":"https://drive.google.com/file/d/1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG/view",
		"download":"https://drive.google.com/uc?export=download&id=1tg2ar4tmjbySsq1HWwl5iB5y1onuRppG",
	},
	"KEPDIRTENGLING_MIGAS":{
		"preview":"https://drive.google.com/file/d/1-qg0VVoYepIssVhjd2uAKyAG7ddjRfwG/preview",
		"view":"https://drive.google.com/file/d/1-qg0VVoYepIssVhjd2uAKyAG7ddjRfwG/view",
		"download":"https://drive.google.com/uc?export=download&id=1dDhk01413umHYGHZH2vp6W_SluGvj503",
	},
	"asmeb318":{
		"preview":"https://drive.google.com/file/d/1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n/preview",
		"view":"https://drive.google.com/file/d/1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n/view",
		"download":"https://drive.google.com/uc?export=download&id=1mYoX84rFjw_OyQ2C1gkAgcNWnnwE9C1n",
	},
	"muhlbaueronshorepe":{
		"preview": "https://docs.google.com/spreadsheets/d/1gO5JqssC8qwnyVPJbEyVORCpWGRT6fS4/edit?usp=sharing&ouid=113297632341945503281&rtpof=true&sd=true",
		"view": "https://drive.google.com/file/d/1gO5JqssC8qwnyVPJbEyVORCpWGRT6fS4/view",
		"download": "https://drive.google.com/uc?export=download&id=1gO5JqssC8qwnyVPJbEyVORCpWGRT6fS4"
	},
	"muhlbaueronshoresheet":{
		"preview":"https://drive.google.com/uc?export=download&id=1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW",
		"view":"https://drive.google.com/file/d/1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW/view",
		"download":"https://drive.google.com/uc?export=download&id=1eD7hfIjjjSc1IV3b-XlMSY4IyYjFLVJW",
	},
	"muhlbaueroffshoresheet":{
		"preview":"https://drive.google.com/uc?export=download&id=1S8Ccy_8KW_FeXuai9NZYYIBeRx5O3O8G",
		"view":"https://drive.google.com/file/d/1S8Ccy_8KW_FeXuai9NZYYIBeRx5O3O8G/view",
		"download":"https://drive.google.com/uc?export=download&id=1S8Ccy_8KW_FeXuai9NZYYIBeRx5O3O8G",
	},
	"repot_1p_2024":{
		"preview":"https://drive.google.com/file/d/1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM/preview",
		"view":"https://drive.google.com/file/d/1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM/view",
		"download":"https://drive.google.com/uc?export=download&id=1w9aTr3Mv8VBTXh_vPaeWmnFJfI5fmigM",
	},
	"refmuhlbauer":{
		"preview":"https://drive.google.com/file/d/1OIE_BURXAdzWcKgVUILHHzI6XCwSYD2S/preview",
		"view":"https://drive.google.com/file/d/1OIE_BURXAdzWcKgVUILHHzI6XCwSYD2S/view",
		"download":"https://drive.google.com/uc?export=download&id=1OIE_BURXAdzWcKgVUILHHzI6XCwSYD2S",
	},
	
	"refip":{
		"preview":"https://drive.google.com/file/d/1Rr3O91L1sjyCIs9iZ9czWhmkgv8kRiWG/preview",
		"view":"https://drive.google.com/file/d/1Rr3O91L1sjyCIs9iZ9czWhmkgv8kRiWG/view",
		"download":"https://drive.google.com/uc?export=download&id=1Rr3O91L1sjyCIs9iZ9czWhmkgv8kRiWG",
	},
	
	"finalreportrov":{
		"preview":"https://drive.google.com/file/d/17JRwCkSepkLyIN6w7AF_l4Lw35vwdG_z/preview",
		"view":"https://drive.google.com/file/d/17JRwCkSepkLyIN6w7AF_l4Lw35vwdG_z/view",
		"download":"https://drive.google.com/uc?export=download&id=17JRwCkSepkLyIN6w7AF_l4Lw35vwdG_z",
	},
	
	"finalreportrovlbmmbk":{
		"preview":"https://drive.google.com/file/d/1Yp3dst1jhUTjU9_JzeBpBPvYRIaLd4Mc/preview",
		"view":"https://drive.google.com/file/d/1Yp3dst1jhUTjU9_JzeBpBPvYRIaLd4Mc/view",
		"download":"https://drive.google.com/uc?export=download&id=1Yp3dst1jhUTjU9_JzeBpBPvYRIaLd4Mc",
	},
	
	"reportgehazard":{
		"preview":"https://drive.google.com/file/d/1GI1SXI_ausy0B3GRH9mOYvaVBC2-ZeH3/preview",
		"view":"https://drive.google.com/file/d/1GI1SXI_ausy0B3GRH9mOYvaVBC2-ZeH3/view",
		"download":"https://drive.google.com/uc?export=download&id=1GI1SXI_ausy0B3GRH9mOYvaVBC2-ZeH3",
	},
	
	"ACOUSTICSURVEYSOUTH":{
		"preview":"https://drive.google.com/file/d/1tvRoG8HSQHk-asxbaKVejqo_CqW8I_YG/preview",
		"view":"https://drive.google.com/file/d/1tvRoG8HSQHk-asxbaKVejqo_CqW8I_YG/view",
		"download":"https://drive.google.com/uc?export=download&id=1tvRoG8HSQHk-asxbaKVejqo_CqW8I_YG",
	},
	
	"RIGHTOFWAYMANAGEMET":{
		"preview":"https://drive.google.com/file/d/1fkG9dqYtaedOrlu1Uawvbn6oOq6JqBAW/preview",
		"view":"https://drive.google.com/file/d/1fkG9dqYtaedOrlu1Uawvbn6oOq6JqBAW/view",
		"download":"https://drive.google.com/uc?export=download&id=1fkG9dqYtaedOrlu1Uawvbn6oOq6JqBAW",
	},
	
	"ABDLPR72LDG001A13129REINFORCEMENT":{
		"preview":"https://drive.google.com/file/d/1LieueMkrnNPGp7dx4LCAEZU1AfUx6N8p/preview",
		"view":"https://drive.google.com/file/d/1LieueMkrnNPGp7dx4LCAEZU1AfUx6N8p/view",
		"download":"https://drive.google.com/uc?export=download&id=1LieueMkrnNPGp7dx4LCAEZU1AfUx6N8p",
	},
	
	"KONSORSIUMDCNSGI":{
		"preview":"https://drive.google.com/file/d/1tpPaCVnW3IoroNJ6uMqqu-XeC95ex-ec/preview",
		"view":"https://drive.google.com/file/d/1tpPaCVnW3IoroNJ6uMqqu-XeC95ex-ec/view",
		"download":"https://drive.google.com/uc?export=download&id=1tpPaCVnW3IoroNJ6uMqqu-XeC95ex-ec",
	},
	
	"KONSORSIUMDCNSGI2":{
		"preview":"https://drive.google.com/file/d/1Gn_iY0gL4zTJvnyqmlluHAnOKuJgqJAs/preview",
		"view":"https://drive.google.com/file/d/1Gn_iY0gL4zTJvnyqmlluHAnOKuJgqJAs/view",
		"download":"https://drive.google.com/uc?export=download&id=1Gn_iY0gL4zTJvnyqmlluHAnOKuJgqJAs",
	},
	
	"LA_CIPS_DCVG_LBM_PGD_PHASE2_2010":{
		"preview":"https://drive.google.com/file/d/16Jr6zcU5Eq2e2vi58tnfR31rKiBoQ6GE/preview",
		"view":"https://drive.google.com/file/d/16Jr6zcU5Eq2e2vi58tnfR31rKiBoQ6GE/view",
		"download":"https://drive.google.com/uc?export=download&id=16Jr6zcU5Eq2e2vi58tnfR31rKiBoQ6GE",
	},
	
	"LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104":{
		"preview":"https://drive.google.com/file/d/1JqDZxmYGE2wgxb9aIjoQ1Qd71YbTOJI4/preview",
		"view":"https://drive.google.com/file/d/1JqDZxmYGE2wgxb9aIjoQ1Qd71YbTOJI4/view",
		"download":"https://drive.google.com/uc?export=download&id=1JqDZxmYGE2wgxb9aIjoQ1Qd71YbTOJI4",
	},
	
	"LA_CIPS_DCVG_LBM_PGD_PHASE1_2011":{
		"preview":"https://drive.google.com/file/d/1CDiOiqR-ujrUJbYhhSZ6AAbgr5kE_Wch/preview",
		"view":"https://drive.google.com/file/d/1CDiOiqR-ujrUJbYhhSZ6AAbgr5kE_Wch/view",
		"download":"https://drive.google.com/uc?export=download&id=1CDiOiqR-ujrUJbYhhSZ6AAbgr5kE_Wch",
	},
	
	"PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015":{
		"preview":"https://drive.google.com/file/d/1eObswo-Yy-8ja6TVyYZHyHXTtUpHhzr9/preview",
		"view":"https://drive.google.com/file/d/1eObswo-Yy-8ja6TVyYZHyHXTtUpHhzr9/view",
		"download":"https://drive.google.com/uc?export=download&id=1eObswo-Yy-8ja6TVyYZHyHXTtUpHhzr9",
	},
	
	"LT_CIPS_DCVG_GRS_PGD_PHASE2_2018":{
		"preview":"https://drive.google.com/file/d/1L_iegUYwHy5JKQ_hNBs1ojX0uYnGlfsu/preview",
		"view":"https://drive.google.com/file/d/1L_iegUYwHy5JKQ_hNBs1ojX0uYnGlfsu/view",
		"download":"https://drive.google.com/uc?export=download&id=1L_iegUYwHy5JKQ_hNBs1ojX0uYnGlfsu",
	},
	
	"IP2012_GRS_PGD":{
		"preview":"https://drive.google.com/file/d/1YwTIhetTkiNw70n6lAvQsiRzpgHDeOGr/preview",
		"view":"https://drive.google.com/file/d/1YwTIhetTkiNw70n6lAvQsiRzpgHDeOGr/view",
		"download":"https://drive.google.com/uc?export=download&id=1YwTIhetTkiNw70n6lAvQsiRzpgHDeOGr",
	},
	
	"IP2012_PGD_TBB":{
		"preview":"https://drive.google.com/file/d/11WJjyJenQhTqr-O986f8aUk1fEWhv7oA/preview",
		"view":"https://drive.google.com/file/d/11WJjyJenQhTqr-O986f8aUk1fEWhv7oA/view",
		"download":"https://drive.google.com/uc?export=download&id=11WJjyJenQhTqr-O986f8aUk1fEWhv7oA",
	},
	
	"IP2012_TBB_LBM":{
		"preview":"https://drive.google.com/file/d/1vnS-X4vh3YeRxk0DYldUjFsjs4fjG0uf/preview",
		"view":"https://drive.google.com/file/d/1vnS-X4vh3YeRxk0DYldUjFsjs4fjG0uf/view",
		"download":"https://drive.google.com/uc?export=download&id=1vnS-X4vh3YeRxk0DYldUjFsjs4fjG0uf",
	},
	
	"IP2016_GRS_PGD_SECTION1":{
		"preview":"https://drive.google.com/file/d/1XyYo2xmRss7K2VNgpxIUFYYZ98VOOr5s/preview",
		"view":"https://drive.google.com/file/d/1XyYo2xmRss7K2VNgpxIUFYYZ98VOOr5s/view",
		"download":"https://drive.google.com/uc?export=download&id=1XyYo2xmRss7K2VNgpxIUFYYZ98VOOr5s",
	},
	
	"IP2016_LBM_BJN_SECTION5":{
		"preview":"https://drive.google.com/file/d/1zrHKWzWMqNP2vbx0kVatMLEfFjFLl6G4/preview",
		"view":"https://drive.google.com/file/d/1zrHKWzWMqNP2vbx0kVatMLEfFjFLl6G4/view",
		"download":"https://drive.google.com/uc?export=download&id=1zrHKWzWMqNP2vbx0kVatMLEfFjFLl6G4",
	},
	
	"IP2016_PGD_TBB_SECTION3":{
		"preview":"https://drive.google.com/file/d/1XZwHua1ZDCDrOrBN_DnmshrEsPnhCFaH/preview",
		"view":"https://drive.google.com/file/d/1XZwHua1ZDCDrOrBN_DnmshrEsPnhCFaH/view",
		"download":"https://drive.google.com/uc?export=download&id=1XZwHua1ZDCDrOrBN_DnmshrEsPnhCFaH",
	},
	
	"IP2016_SPGPGD_PGD_SECTION2":{
		"preview":"https://drive.google.com/file/d/1Ixa7kB9tTzJk72kEJ1MKJbHiSwHKxHkL/preview",
		"view":"https://drive.google.com/file/d/1Ixa7kB9tTzJk72kEJ1MKJbHiSwHKxHkL/view",
		"download":"https://drive.google.com/uc?export=download&id=1Ixa7kB9tTzJk72kEJ1MKJbHiSwHKxHkL",
	},
	
	"IP2016_TBB_LBM_SECTION4":{
		"preview":"https://drive.google.com/file/d/1k26OA2IJV68kFU-RNLkpZjnLVIn4tVIU/preview",
		"view":"https://drive.google.com/file/d/1k26OA2IJV68kFU-RNLkpZjnLVIn4tVIU/view",
		"download":"https://drive.google.com/uc?export=download&id=1k26OA2IJV68kFU-RNLkpZjnLVIn4tVIU",
	},
	
	"IP2017_LBM_MBK_SECTION3":{
		"preview":"https://drive.google.com/file/d/1qUXYQW7AnwWLIQC3m5Q9V8RGeuBLDAhJ/preview",
		"view":"https://drive.google.com/file/d/1qUXYQW7AnwWLIQC3m5Q9V8RGeuBLDAhJ/view",
		"download":"https://drive.google.com/uc?export=download&id=1qUXYQW7AnwWLIQC3m5Q9V8RGeuBLDAhJ",
	},
	
	"IP2017_LBM_MBK_PHASE2_Section3":{
		"preview":"https://drive.google.com/file/d/1CuSU_U6SE4beKEZxYYXdJFj6uWvp9Yck/preview",
		"view":"https://drive.google.com/file/d/1CuSU_U6SE4beKEZxYYXdJFj6uWvp9Yck/view",
		"download":"https://drive.google.com/uc?export=download&id=1CuSU_U6SE4beKEZxYYXdJFj6uWvp9Yck",
	},
	
	"IP2017_PGD_TBB_SECTION1":{
		"preview":"https://drive.google.com/file/d/1yhiTMz5NRP7d98QFySQq9JLJv3l-kOmo/preview",
		"view":"https://drive.google.com/file/d/1yhiTMz5NRP7d98QFySQq9JLJv3l-kOmo/view",
		"download":"https://drive.google.com/uc?export=download&id=1yhiTMz5NRP7d98QFySQq9JLJv3l-kOmo",
	},
	
	"IP2017_PGD_TBB_PHASE2_SECTION1":{
		"preview":"https://drive.google.com/file/d/1ih5FsNj4_ZyZe6jw__0RVIpDmt-NdmIC/preview",
		"view":"https://drive.google.com/file/d/1ih5FsNj4_ZyZe6jw__0RVIpDmt-NdmIC/view",
		"download":"https://drive.google.com/uc?export=download&id=1ih5FsNj4_ZyZe6jw__0RVIpDmt-NdmIC",
	},
	
	"IP2017_TBB_LBM_SECTION2":{
		"preview":"https://drive.google.com/file/d/1XgcB9a1aOHYUaZ7SicvFUKgYaemmsHSc/preview",
		"view":"https://drive.google.com/file/d/1XgcB9a1aOHYUaZ7SicvFUKgYaemmsHSc/view",
		"download":"https://drive.google.com/uc?export=download&id=1XgcB9a1aOHYUaZ7SicvFUKgYaemmsHSc",
	},
	
	"IP2017_TBB_LBM_PHASE2_SECTION2":{
		"preview":"https://drive.google.com/file/d/1SJgYHVDQIbXAdAJxA8ZgCAHUcCng5Thy/preview",
		"view":"https://drive.google.com/file/d/1SJgYHVDQIbXAdAJxA8ZgCAHUcCng5Thy/view",
		"download":"https://drive.google.com/uc?export=download&id=1SJgYHVDQIbXAdAJxA8ZgCAHUcCng5Thy",
	},
	
	"PSDA_KFJPO_2013":{
		"preview":"https://drive.google.com/file/d/1SRNphs5z2dacQGvBtPzng96dG1YFdpVp/preview",
		"view":"https://drive.google.com/file/d/1SRNphs5z2dacQGvBtPzng96dG1YFdpVp/view",
		"download":"https://drive.google.com/uc?export=download&id=1SRNphs5z2dacQGvBtPzng96dG1YFdpVp",
	},
	
	"LAP_AKH_CDA_2012":{
		"preview":"https://drive.google.com/file/d/1INjqpXE4RM5fqja_6Ctl0uvzLt9atq9Q/preview",
		"view":"https://drive.google.com/file/d/1INjqpXE4RM5fqja_6Ctl0uvzLt9atq9Q/view",
		"download":"https://drive.google.com/uc?export=download&id=1INjqpXE4RM5fqja_6Ctl0uvzLt9atq9Q",
	},
	
	"LP_PDA_KP_68_5_PGD_TBB":{
		"preview":"https://drive.google.com/file/d/1y0rVES4IVEwUFf1hurF0IKnkYe-5u4JL/preview",
		"view":"https://drive.google.com/file/d/1y0rVES4IVEwUFf1hurF0IKnkYe-5u4JL/view",
		"download":"https://drive.google.com/uc?export=download&id=1y0rVES4IVEwUFf1hurF0IKnkYe-5u4JL",
	},
	
	"Fotogrametri2014":{
		"preview":"https://drive.google.com/file/d/1annFtl9hJupBeN5OCHejPqpgwSwc-UeL/preview",
		"view":"https://drive.google.com/file/d/1annFtl9hJupBeN5OCHejPqpgwSwc-UeL/view",
		"download":"https://drive.google.com/uc?export=download&id=1annFtl9hJupBeN5OCHejPqpgwSwc-UeL",
	},
	
	"izinusaha1110":{
		"preview":"https://drive.google.com/file/d/1s9WmEk223U65jo4oZ6mpkJcxer3JYF6L/preview",
		"view":"https://drive.google.com/file/d/1s9WmEk223U65jo4oZ6mpkJcxer3JYF6L/view",
		"download":"https://drive.google.com/uc?export=download&id=1s9WmEk223U65jo4oZ6mpkJcxer3JYF6L",
	},
	
	"PLOSSWJ2023":{
		"preview":"https://drive.google.com/file/d/1bho15aJSX1r9uA66KwufSjLN49hXlOBI/preview",
		"view":"https://drive.google.com/file/d/1bho15aJSX1r9uA66KwufSjLN49hXlOBI/view",
		"download":"https://drive.google.com/uc?export=download&id=1bho15aJSX1r9uA66KwufSjLN49hXlOBI",
	},
	
	"SPPECDAM":{
		"preview":"https://drive.google.com/file/d/1svPdjUK9jkvodjO6PePwnLHH0ab1Ec35/preview",
		"view":"https://drive.google.com/file/d/1svPdjUK9jkvodjO6PePwnLHH0ab1Ec35/view",
		"download":"https://drive.google.com/uc?export=download&id=1svPdjUK9jkvodjO6PePwnLHH0ab1Ec35",
	},
	
	"ROPEAP":{
		"preview":"https://drive.google.com/file/d/1QE8Jj8A-_D-ykxFJ2x0YlfCLPrK6o9zq/preview",
		"view":"https://drive.google.com/file/d/1QE8Jj8A-_D-ykxFJ2x0YlfCLPrK6o9zq/view",
		"download":"https://drive.google.com/uc?export=download&id=1QE8Jj8A-_D-ykxFJ2x0YlfCLPrK6o9zq",
	},
	
	"APISPEC5L":{
		"preview":"https://drive.google.com/file/d/1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W/preview",
		"view":"https://drive.google.com/file/d/1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W/view",
		"download":"https://drive.google.com/uc?export=download&id=1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W",
	},
	
	"APISPEC5L":{
		"preview":"https://drive.google.com/file/d/1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W/preview",
		"view":"https://drive.google.com/file/d/1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W/view",
		"download":"https://drive.google.com/uc?export=download&id=1yZANhTXkKDoc9VDLnv6BAU4us8MpaK-W",
	},
	
	"PIMSJAN24":{
		"preview":"https://drive.google.com/file/d/1WpgiLCKhfrYxUuADYkTaZVxVOxvRWG9E/preview",
		"view":"https://drive.google.com/file/d/1WpgiLCKhfrYxUuADYkTaZVxVOxvRWG9E/view",
		"download":"https://drive.google.com/uc?export=download&id=1WpgiLCKhfrYxUuADYkTaZVxVOxvRWG9E",
	},
	
	"PIMSFEB24":{
		"preview":"https://drive.google.com/file/d/11YspIwovgwMJ7SfUx0xlCZSzgpaj9sAl/preview",
		"view":"https://drive.google.com/file/d/11YspIwovgwMJ7SfUx0xlCZSzgpaj9sAl/view",
		"download":"https://drive.google.com/uc?export=download&id=11YspIwovgwMJ7SfUx0xlCZSzgpaj9sAl",
	},
	
	"PIMSMAR24":{
		"preview":"https://drive.google.com/file/d/1GGCNrtpvtDKed1mlEEDC-S7JHYgKCHtg/preview",
		"view":"https://drive.google.com/file/d/1GGCNrtpvtDKed1mlEEDC-S7JHYgKCHtg/view",
		"download":"https://drive.google.com/uc?export=download&id=1GGCNrtpvtDKed1mlEEDC-S7JHYgKCHtg",
	},
	
	"PIMSAPR24":{
		"preview":"https://drive.google.com/file/d/19_QMsdJ9cdHXo67KAqUnwHrCxE9gyhSV/preview",
		"view":"https://drive.google.com/file/d/19_QMsdJ9cdHXo67KAqUnwHrCxE9gyhSV/view",
		"download":"https://drive.google.com/uc?export=download&id=19_QMsdJ9cdHXo67KAqUnwHrCxE9gyhSV",
	},
	
	"PIMSMEI24":{
		"preview":"https://drive.google.com/file/d/1TwkRwCqvU6_oSxD8WYXWJeuuvnHAYIxK/preview",
		"view":"https://drive.google.com/file/d/1TwkRwCqvU6_oSxD8WYXWJeuuvnHAYIxK/view",
		"download":"https://drive.google.com/uc?export=download&id=1TwkRwCqvU6_oSxD8WYXWJeuuvnHAYIxK",
	},
	
	"PIMSJUNI24":{
		"preview":"https://drive.google.com/file/d/1tjum6D1js6hGjjIomA_erP_1Xym3_fzm/preview",
		"view":"https://drive.google.com/file/d/1tjum6D1js6hGjjIomA_erP_1Xym3_fzm/view",
		"download":"https://drive.google.com/uc?export=download&id=1tjum6D1js6hGjjIomA_erP_1Xym3_fzm",
	},
	
	"PIMSJULI24":{
		"preview":"https://drive.google.com/file/d/1KSLfDCPCX__q0az3AU8I-lSBY7n52jaR/preview",
		"view":"https://drive.google.com/file/d/1KSLfDCPCX__q0az3AU8I-lSBY7n52jaR/view",
		"download":"https://drive.google.com/uc?export=download&id=1KSLfDCPCX__q0az3AU8I-lSBY7n52jaR",
	},
	
	"PIMSAGU24":{
		"preview":"https://drive.google.com/file/d/1MsTHBbJ2Homa1_e-wt1qtaG2qX8a4Afk/preview",
		"view":"https://drive.google.com/file/d/1MsTHBbJ2Homa1_e-wt1qtaG2qX8a4Afk/view",
		"download":"https://drive.google.com/uc?export=download&id=1MsTHBbJ2Homa1_e-wt1qtaG2qX8a4Afk",
	},
	
	"PIMSSEPT24":{
		"preview":"https://drive.google.com/file/d/1MDk6iq8K4ow41ojdiJZL_xuYVXktQONK/preview",
		"view":"https://drive.google.com/file/d/1MDk6iq8K4ow41ojdiJZL_xuYVXktQONK/view",
		"download":"https://drive.google.com/uc?export=download&id=1MDk6iq8K4ow41ojdiJZL_xuYVXktQONK",
	},
	
	"PIMSOKT24":{
		"preview":"https://drive.google.com/file/d/19Tw6mFw5Hy_7qHw4gLmwDL8HjV4Fiqel/preview",
		"view":"https://drive.google.com/file/d/19Tw6mFw5Hy_7qHw4gLmwDL8HjV4Fiqel/view",
		"download":"https://drive.google.com/uc?export=download&id=19Tw6mFw5Hy_7qHw4gLmwDL8HjV4Fiqel",
	},
	
	"IHOSFHS":{
		"preview":"https://drive.google.com/file/d/1EwZz7m22XEX14OoanY5rZbw-HGTop6fa/preview",
		"view":"https://drive.google.com/file/d/1EwZz7m22XEX14OoanY5rZbw-HGTop6fa/view",
		"download":"https://drive.google.com/uc?export=download&id=1EwZz7m22XEX14OoanY5rZbw-HGTop6fa",
	},
	
	"BSISTDPBL":{
		"preview":"https://drive.google.com/file/d/1QRj5FuoempBjEnWuWKdHV4Cm323rjC1b/preview",
		"view":"https://drive.google.com/file/d/1QRj5FuoempBjEnWuWKdHV4Cm323rjC1b/view",
		"download":"https://drive.google.com/uc?export=download&id=1QRj5FuoempBjEnWuWKdHV4Cm323rjC1b",
	},
	
	"DNVSTF101":{
		"preview":"https://drive.google.com/file/d/1Vqu9Vw4se7ymePltZgdGcrJorw-QVUoh/preview",
		"view":"https://drive.google.com/file/d/1Vqu9Vw4se7ymePltZgdGcrJorw-QVUoh/view",
		"download":"https://drive.google.com/uc?export=download&id=1Vqu9Vw4se7ymePltZgdGcrJorw-QVUoh",
	},
	
	"DNVRPF113":{
		"preview":"https://drive.google.com/file/d/1-eMCcFnLqgeb_7bSqbecwgFW-P4jGDJN/preview",
		"view":"https://drive.google.com/file/d/1-eMCcFnLqgeb_7bSqbecwgFW-P4jGDJN/view",
		"download":"https://drive.google.com/uc?export=download&id=1-eMCcFnLqgeb_7bSqbecwgFW-P4jGDJN",
	},
	
	"DNVRPF105":{
		"preview":"https://drive.google.com/file/d/1d2WPCNHCo8f1YtmVMonigdUiFDvci1xM/preview",
		"view":"https://drive.google.com/file/d/1d2WPCNHCo8f1YtmVMonigdUiFDvci1xM/view",
		"download":"https://drive.google.com/uc?export=download&id=1d2WPCNHCo8f1YtmVMonigdUiFDvci1xM",
	},
	
	"DNVRPC203":{
		"preview":"https://drive.google.com/file/d/1IAT019anz29L8NWqIY8GA_3-R6A637TT/preview",
		"view":"https://drive.google.com/file/d/1IAT019anz29L8NWqIY8GA_3-R6A637TT/view",
		"download":"https://drive.google.com/uc?export=download&id=1IAT019anz29L8NWqIY8GA_3-R6A637TT",
	},
	
	"Jaringkontrolhorizontal":{
		"preview":"https://drive.google.com/file/d/15SeL55y7wSjQTIKkBoyOhL2kRh8K4Non/preview",
		"view":"https://drive.google.com/file/d/15SeL55y7wSjQTIKkBoyOhL2kRh8K4Non/view",
		"download":"https://drive.google.com/uc?export=download&id=15SeL55y7wSjQTIKkBoyOhL2kRh8K4Non",
	},
	
	"Jaringkontrolvertikal":{
		"preview":"https://drive.google.com/file/d/1aHQHmfTA0KhtNqbwiQ36cUrbKSwVqHOz/preview",
		"view":"https://drive.google.com/file/d/1aHQHmfTA0KhtNqbwiQ36cUrbKSwVqHOz/view",
		"download":"https://drive.google.com/uc?export=download&id=1aHQHmfTA0KhtNqbwiQ36cUrbKSwVqHOz",
	},
	
	"ReportILISPG_PGD_PGD":{
		"preview":"https://drive.google.com/file/d/1ujor3nwFXx7AQXeHE6ar3gYhSV4EsmKn/preview",
		"view":"https://drive.google.com/file/d/1ujor3nwFXx7AQXeHE6ar3gYhSV4EsmKn/view",
		"download":"https://drive.google.com/uc?export=download&id=1ujor3nwFXx7AQXeHE6ar3gYhSV4EsmKn",
	},
	
	"ReportILIGRS_PGD":{
		"preview":"https://drive.google.com/file/d/1CSXYKHsXoujd9tr0auQMY3re4JhYNGVG/preview",
		"view":"https://drive.google.com/file/d/1CSXYKHsXoujd9tr0auQMY3re4JhYNGVG/view",
		"download":"https://drive.google.com/uc?export=download&id=1CSXYKHsXoujd9tr0auQMY3re4JhYNGVG",
	},
	
	"ReportILIPGD_TBB":{
		"preview":"https://drive.google.com/file/d/1A-UjybAt9bY8SET3_hmhZ-MUV-adfAlc/preview",
		"view":"https://drive.google.com/file/d/1A-UjybAt9bY8SET3_hmhZ-MUV-adfAlc/view",
		"download":"https://drive.google.com/uc?export=download&id=1A-UjybAt9bY8SET3_hmhZ-MUV-adfAlc",
	},
	
	"ReportILITBB_LBM":{
		"preview":"https://drive.google.com/file/d/12MF3rewhO9q5P6xR4CSV48yGgJK4wuLH/preview",
		"view":"https://drive.google.com/file/d/12MF3rewhO9q5P6xR4CSV48yGgJK4wuLH/view",
		"download":"https://drive.google.com/uc?export=download&id=12MF3rewhO9q5P6xR4CSV48yGgJK4wuLH",
	},
	
	"ReportILILBM_BJN":{
		"preview":"https://drive.google.com/file/d/1YCWC7UvPJi3bB00GmLJW17dZ1x4Qba1S/preview",
		"view":"https://drive.google.com/file/d/1YCWC7UvPJi3bB00GmLJW17dZ1x4Qba1S/view",
		"download":"https://drive.google.com/uc?export=download&id=1YCWC7UvPJi3bB00GmLJW17dZ1x4Qba1S",
	},
	
	"Verifikasifreesepan2021":{
		"preview":"https://drive.google.com/file/d/1veqgtLZuPfxwTwbMA6R7eyFZ2cPyiIUs/preview",
		"view":"https://drive.google.com/file/d/1veqgtLZuPfxwTwbMA6R7eyFZ2cPyiIUs/view",
		"download":"https://drive.google.com/uc?export=download&id=1veqgtLZuPfxwTwbMA6R7eyFZ2cPyiIUs",
	},
	
	"NACESP01692007":{
		"preview":"https://drive.google.com/file/d/1V_dOEA6_GgLMYE8zE_HskrqIsdVKBlHb/preview",
		"view":"https://drive.google.com/file/d/1V_dOEA6_GgLMYE8zE_HskrqIsdVKBlHb/view",
		"download":"https://drive.google.com/uc?export=download&id=1V_dOEA6_GgLMYE8zE_HskrqIsdVKBlHb",
	},
	
	"Anomalies100depthlbmmbk":{
		"preview":"https://drive.google.com/file/d/1I_95mHv6ywQtHWfEshXPQQ1SjXsredwj/preview",
		"view":"https://drive.google.com/file/d/1I_95mHv6ywQtHWfEshXPQQ1SjXsredwj/view",
		"download":"https://drive.google.com/uc?export=download&id=1I_95mHv6ywQtHWfEshXPQQ1SjXsredwj",
	},
	
	
	
};

var [visualrovOptions, setVisualrovOptions] = useState([
	
	{label: '20211016 092822 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/9856RA0cut0?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=9856RA0cut0'},
	{label: '20211016 085802 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/0oNB0kSzL-w?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=0oNB0kSzL-w'},
	{label: '20211019 042834 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/iyjdDVpouZI?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=iyjdDVpouZI'},
	{label: '20211019 030655 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/TsH_A_OQ_Uo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=TsH_A_OQ_Uo'},
	{label: '20211020 061513 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hNvqJ8lrHHc?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hNvqJ8lrHHc'},
	{label: '20211020 101858 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rbgHoe7FdJA?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rbgHoe7FdJA'},
	{label: '20211020 141900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BqeHewFgPkA?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BqeHewFgPkA'},
	{label: '20211020 121859 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/wTAvGgIAcNQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=wTAvGgIAcNQ'},
	{label: '20211020 181900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/iPudQbUr3XM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=iPudQbUr3XM'},
	{label: '20211020 161900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/3NHbb5Z-UTg?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=3NHbb5Z-UTg'},
	{label: '20211020 201900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hiPn16Qh580?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hiPn16Qh580'},
	{label: '20211020 221900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/zaFgw9bi8Q8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=zaFgw9bi8Q8'},
	{label: '20211021 041900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BVbs1taItMw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BVbs1taItMw'},
	{label: '20211021 061900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/RksgdHHEIxg?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=RksgdHHEIxg'},
	{label: '20211021 094109 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/bPtBq5a3FPQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=bPtBq5a3FPQ'},
	{label: '20211021 001901 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/JJGH6F4O-Mw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=JJGH6F4O-Mw'},
	{ label: '20211021 021900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/-mI36gK-dzQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=-mI36gK-dzQ' },
    { label: '20211021 145900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/_wx9wD6vYyo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=_wx9wD6vYyo' },
    { label: '20211021 165900 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/LGiXfhzso9k?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=LGiXfhzso9k' },
    { label: '20211021 185901 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/PEviWe5JQsY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=PEviWe5JQsY' },
    { label: '20211021 114110 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ZKK5mcame3U?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ZKK5mcame3U' },
    { label: '20211021 125859 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/T7ViWbcpIQQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=T7ViWbcpIQQ' },
    { label: '20211021 193304 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/QuFuKSUsJf4?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=QuFuKSUsJf4' },
    { label: '20211022 114127 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/MBPB0w9IFLU?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=MBPB0w9IFLU' },
    { label: '20211021 213306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/zkB5SK8Oiko?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=zkB5SK8Oiko' },
    { label: '20211022 013306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/8Ywx2P6_3xc?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=8Ywx2P6_3xc' },
    { label: '20211022 033307 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/6XBygcfNN4E?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=6XBygcfNN4E' },
    { label: '20211021 233306 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ibiZgNMEWg8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ibiZgNMEWg8' },
    { label: '20211022 084359 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/WbZFavc-sSo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=WbZFavc-sSo' },
    { label: '20211022 100031 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/Ar-xr0ttkgQ?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=Ar-xr0ttkgQ' },
    { label: '20211022 053308 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/hBzgyelcYdM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=hBzgyelcYdM' },
    { label: '20211022 195841 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/_UIfk0mwYB8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=_UIfk0mwYB8' },
    { label: '20211022 134128 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/BkDJuukn_Ew?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=BkDJuukn_Ew' },
    { label: '20211023 051226 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/TOZEf0n-HBw?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=TOZEf0n-HBw' },
    { label: '20211023 071228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/A_GmayjTsBY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=A_GmayjTsBY' },
    { label: '20211023 091227 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/p5tsOWQlMmE?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=p5tsOWQlMmE' },
    { label: '20211023 111228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rbhBQG53M1k?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rbhBQG53M1k' },
    { label: '20211022 154128 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ahEEfdxEQKo?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ahEEfdxEQKo' },
    { label: '20211022 215843 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/UzTjuWlwq84?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=aUzTjuWlwq84' },
    { label: '20211023 011508 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/rGu4ZmcC5UI?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=rGu4ZmcC5UI' },
    { label: '20211023 031511 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/ry-um2nG_sY?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=ry-um2nG_sY' },
    { label: '20211023 171228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/4wd52nmiIqM?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=4wd52nmiIqM' },
    { label: '20211023 141228 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/vZTr1zyC0P8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=vZTr1zyC0P8' },
    { label: '20211023 121227 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/y24N6fCzZ5U?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=y24N6fCzZ5U' },
    { label: '20211023 091226 CH1 MERLINWR200P309', value: 'https://www.youtube.com/embed/nrOpd11ibY8?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=nrOpd11ibY8' }

]);

var stasiunData = {

	PGNSSWJ: {
        "AOL": "",
        "AOSS": "",
        "AOJBB": "",
    },
    
    PGNSOR2:{
        "Bekasi":"https://public.tableau.com/views/Sor2_Bekasi_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Bogor":"https://public.tableau.com/views/Sor2_Bogor_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Cilegon":"https://public.tableau.com/views/Sor2_Cilegon_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Cirebon":"https://public.tableau.com/views/Sor2_Cirebon_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Jakarta":"https://public.tableau.com/views/Sor2_Jakarta_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Karawang":"https://public.tableau.com/views/Sor2_Karawang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Tangerang":"https://public.tableau.com/views/Sor2_Tangerang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
    
    PGNSOR1:{
        "Batam":"https://public.tableau.com/views/Sor1_Batam_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Dumai":"https://public.tableau.com/views/Sor1_Dumai_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Lampung":"https://public.tableau.com/views/Sor1_Lampung_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Medan":"https://public.tableau.com/views/Sor1_Medan_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Palembang":"https://public.tableau.com/views/Sor1_Palembang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Pekanbaru":"https://public.tableau.com/views/Sor1_Pekanbaru_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
    
    PGNSOR3:{
        "Pasuruan":"https://public.tableau.com/views/Sor3_Pasuruan_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Semarang":"https://public.tableau.com/views/Sor3_Semarang_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Sidoarjo":"https://public.tableau.com/views/Sor3_Sidoarjo_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
        "Surabaya":"https://public.tableau.com/views/Sor3_Surabaya_Katodik/Dashboard1?:embed=yes&:showVizHome=no&:tabs=no&:toolbar=no",
    },
	
};

var jalurDataasg = {

	AOL: {
        "Belitang-LBM PH1": "",
        "Belitang-LBM PH2": "",
    },
	AOSS: {
        "GRS - PGD": "",
        "PGD - BELITANG (PH 1)": "",
        "PGD - BELITANG (PH 2)": "",
    },
	AOJBB: {
		"LBM - BJN":"https://ario93.github.io/asgpotentialpattern.html",
		"LBM - MBK":"https://alive.my.id/asgpotentialpattern_lbm_mbk.html",
    },
	
};

var data_kp_point = {
    
    AOL: {
       "": "",
    },
    AOSS: {
        "": "",
    },
    AOJBB: {
        "Pig Launcher - KP 0": "https://a360.co/4bcisxW",
		"KP 0 - KP 2.5": "https://a360.co/3Wzpzfn",
		"KP 2.5 - KP 5.0": "https://a360.co/3wnNkfN",
		"KP 5.0 - KP 7.5": "https://a360.co/4amzGHs",
		"KP 7.5 - KP 10.0": "https://a360.co/3WEPq5w",
		"KP 10.0 - KP 12.5": "https://a360.co/3JWgSEn",
		"KP 12.5 - KP 15.0": "https://a360.co/3WWh6Dh",
		"KP 15.0 - KP 17.5": "https://a360.co/3K1xxGB",
		"KP 17.5 - KP 20.0": "https://a360.co/3Wx0uBI",
		"KP 20.0 - KP 22.5": "https://a360.co/4bAOuU4",
		"KP 22.5 - KP 25.0": "https://a360.co/4bqx10u",
		"KP 25.0 - KP 27.5": "https://a360.co/3yiEBMr",
		"KP 27.5 - KP 30.0": "https://a360.co/4adxge9",
		"KP 30.0 - KP 32.5": "https://a360.co/3yf0mMZ",
		"KP 32.5 - KP 35.0": "https://a360.co/4ahbZ3i",
		"KP 35.0 - KP 37.5": "https://a360.co/4dEfzYo",
		"KP 37.5 - KP 40.0": "https://a360.co/3QCOlqT",
		"KP 40.0 - KP 42.5": "https://a360.co/3QILeOf",
		"KP 42.5 - KP 45.0": "https://a360.co/4ajkAm0",
		"KP 45.0 - KP 47.5": "https://a360.co/3QJgQn7",
		"KP 47.5 - KP 50.0": "https://a360.co/4bAOS4Y",
		"KP 50.0 - KP 52.5": "https://a360.co/4dAp5f0",
		"KP 52.5 - KP 55.0": "https://a360.co/3wwS96p",
		"KP 55.0 - KP 57.5": "https://a360.co/3QCOx9B",
		"KP 57.5 - KP 60.0": "https://a360.co/3K1xU3X",
		"KP 60.0 - KP 62.5": "https://a360.co/4bzhVpv",
		"KP 62.5 - KP 65.0": "https://a360.co/4bAavlP",
		"KP 65.0 - KP 67.5": "https://a360.co/3JZzeUO",
		"KP 67.5 - KP 70.0": "https://a360.co/4bwYxJI",
		"KP 70.0 - KP 72.5": "https://a360.co/3K05Pdq",
		"KP 72.5 - KP 75.0": "https://a360.co/3yiF29x",
		"KP 75.0 - KP 77.5": "https://a360.co/3JYjWQf",
		"KP 77.5 - KP 80.0": "https://a360.co/4bwYzRQ",
		"KP 80.0 - KP 82.5": "https://a360.co/4bwYzRQ",
		"KP 82.5 - KP 85.0": "https://a360.co/44BBawr",
		"KP 85.0 - KP 87.5": "https://a360.co/3K3kSmg",
		"KP 87.5 - KP 90.0": "https://a360.co/44HdF5e",
		"KP 90.0 - KP 92.5": "https://a360.co/3QFwP5m",
		"KP 92.5 - KP 95.0": "https://a360.co/3wHGNMR",
		"KP 95.0 - KP 97.5": "https://a360.co/3QFwX4Q",
		"KP 97.5 - KP 99.907": "https://a360.co/44GQHeb",
		"KP 99.907 - Pig Receiver": "https://a360.co/3UGxhBO"
    }
	
};

const handleCompanyChange = (e) => {
    const company = e.target.value;
    setSelectedCompany(company);
    setSelectedArea('');
    setJalur([]);
	
	if(company == "PGNSOR1"){
					document.getElementById('div_jalur').style.display="none";
				}else if(company == "PGNSOR2"){
					document.getElementById('div_jalur').style.display="none";
				}else if(company == "PGNSOR3"){
					document.getElementById('div_jalur').style.display="none";
				}else{
					document.getElementById('div_jalur').style.display="block";
				}
	
    if (company && stasiunData[company]) {
      setAreas(Object.keys(stasiunData[company]));
    } else {
      setAreas([]);
	  
    }
	
	
	
  };

  const handleAreaChange = (e) => {
	  
    const area = e.target.value;
    setSelectedArea(area);
	const ambildata = document.getElementById('prm_ifr_nipoc').innerHTML;
		if(ambildata == "asgcpmonitoring"){
			
			if (area && jalurDataasg[area]) {
			  setJalur(Object.keys(jalurDataasg[area]));
			} else {
			  setJalur([]);
			}
			
		}else if(ambildata == "cpmonitoring"){
			
			if(selectedCompany === "PGNSOR1" || selectedCompany === "PGNSOR2" || selectedCompany === "PGNSOR3"){
				
				const height1 = (jalurDataheight[selectedCompany][area]-40)+"px";
				const height2 = jalurDataheight[selectedCompany][area]+"px";
				
				document.getElementById('placeholder_tbl3').style.height = height1;
				document.getElementById('iframetbl3').style.height = height2;
				
				document.getElementById('iframetbl3').src = jalurData[selectedCompany][area];
				document.getElementById('ifr3').style.display="block";
				
			}else{
				if (area && jalurData[area]) {
				  setJalur(Object.keys(jalurData[area]));
				} else {
				  setJalur([]);
				}
			}
			
		}else if(ambildata == "aligmentsheet"){
			if (area && data_kp_point[area]) {
			  setJalur(Object.keys(data_kp_point[area]));
			} else {
			  setJalur([]);
			}
		}
		
  };
  
  const handlerovChange = (selectedOption) => {
	  if (selectedOption) {
		setSelectedVisualrov(selectedOption);
		document.getElementById('iframetbl4').src = selectedOption.value;
		document.getElementById('ifr4').style.display="block";
	  } else {
		setSelectedVisualrov(null);
		document.getElementById('ifr3').style.display="none";
	  }
	};

  
  const handleJalurChange = (e) => {
    const jalur = e.target.value;
    setSelectedJalur(jalur);
	const selectedArea = document.getElementById('stasiun').value;
	const ambildata = document.getElementById('prm_ifr_nipoc').innerHTML;
	if(ambildata == "asgcpmonitoring"){
		document.getElementById('iframetbl1').src = jalurDataasg[selectedArea][jalur];
		document.getElementById('ifr1').style.display="block";
	}else if(ambildata == "cpmonitoring"){
		
		const height1 = (jalurDataheight[selectedArea][jalur]-40)+"px";
		const height2 = jalurDataheight[selectedArea][jalur]+"px";
		
		document.getElementById('placeholder_tbl3').style.height = height1;
		document.getElementById('iframetbl3').style.height = height2;
		
		document.getElementById('iframetbl3').src = jalurData[selectedArea][jalur];
		document.getElementById('ifr3').style.display="block";
		
	}else if(ambildata == "aligmentsheet"){
		document.getElementById('iframetbl3').src = data_kp_point[selectedArea][jalur];
		document.getElementById('ifr3').style.display="block";
	}
	
  };
	
	const handleLinkClick = () => {
		
    setMuhlbauer_onshoreKey(prevKey => prevKey + 1);
    setMuhlbauer_offshoreKey(prevKey => prevKey + 1);
    setMuhlbauer_peKey(prevKey => prevKey + 1);
    tampilIfr('muhlbaueronshore','muhlbaueronshoreweb','bk_muhlbaueronshoreweb','ttp_muhlbaueronshoreweb');
    tampilIfr('muhlbaueroffshore','muhlbaueroffshoreweb','bk_muhlbaueroffshoreweb','ttp_muhlbaueroffshoreweb');
    tampilIfr('muhlbaueronshorepeweb','muhlbaueronshorepeweb','bk_muhlbaueronshorepeweb','ttp_muhlbaueronshorepeweb');
  
  };
	
	const tampilIfr = (ifr,idfile,ida,ida2) => {
		
		//document.getElementById('logoalive').style.height="500px";
		
		allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
		setAreas([]);
		setJalur([]);
		
		if(ifr == "muhlbaueroffshore"){
			
			showOffshore();
			
			var prmida = "bk_"+idfile;
			
			if(ida == prmida){
				
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				
				document.getElementById('muhlbaueroffshoreweb').style.display="block";
				
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				
				
			}else{
				
				document.getElementById('muhlbaueroffshoreweb').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				
			}
		
		}else if(ifr == "muhlbaueronshore"){
			showOnshore();
			var prmida = "bk_"+idfile;
			
			if(ida == prmida){
				
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				
				document.getElementById('muhlbaueronshoreweb').style.display="block";
				
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				
				
			}else{
				
				document.getElementById('muhlbaueronshoreweb').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				
			}
		
		}else if(ifr == "muhlbaueronshorepeweb"){
			showpe();
			var prmida = "bk_"+idfile;
			
			if(ida == prmida){
				
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				
				document.getElementById('muhlbaueronshorepeweb').style.display="block";
				
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				
				
			}else{
				
				document.getElementById('muhlbaueronshorepeweb').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				
			}
		
		}else if(ifr == 1){
			
			if(idfile =="asgcpmonitoring"){
				document.getElementById('prm_ifr_nipoc').innerHTML="asgcpmonitoring";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
			}
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('ifr'+ifr).style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl'+ifr).src = ifr1[idfile].preview;
			}else{
				document.getElementById('ifr'+ifr).style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('prm_ifr_nipoc').innerHTML="";
			}
			
		}else if(ifr == 2){
			
			if(idfile == "muhlbaueronshoresheet" || idfile == "muhlbaueroffshoresheet"){
				document.getElementById('iframetbl1').src = ifr2[idfile].preview;
			}else{
				var prmida = "bk_"+idfile;
				if(ida == prmida){
					document.getElementById('ifr1').style.display="block";
					document.getElementById(ida).style.display="none";
					document.getElementById(ida2).style.display="block";
					document.getElementById('homealive_samping_sidemenu').style.display="none";
					if(idfile == "pipetally"){
						document.getElementById('iframetbl1').src = ifr1[idfile].preview;
					}else{
						document.getElementById('iframetbl1').src = ifr2[idfile].preview;
					}
				}else{
					document.getElementById('ifr1').style.display="none";
					document.getElementById(ida).style.display="none";
					document.getElementById(ida2).style.display="block";
					document.getElementById('homealive_samping_sidemenu').style.display="block";
				}
			}
			
		}else if(ifr == 3){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('prm_ifr_nipoc').innerHTML="cpmonitoring";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
				document.getElementById('ifr3').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				//document.getElementById('iframetbl3').src = ifr1[idfile].preview;
				
				const firstKey1 = Object.keys(jalurDataheight)[0];

				const firstSubKey1 = Object.keys(jalurDataheight[firstKey1])[0];

				const firstValue1 = jalurDataheight[firstKey1][firstSubKey1];
				
				const firstKey2 = Object.keys(jalurData)[0];

				const firstSubKey2 = Object.keys(jalurData[firstKey2])[0];

				const firstValue2 = jalurData[firstKey2][firstSubKey2];
				
				const height1 = (firstValue1-40)+"px";
				const height2 = firstValue1+"px";
				
				document.getElementById('placeholder_tbl3').style.height = height1;
				document.getElementById('iframetbl3').style.height = height2;
				
				document.getElementById('iframetbl3').src = firstValue2;
				
			}else{
				document.getElementById('prm_ifr_nipoc').innerHTML="";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('ifr3').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 4){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('prm_ifr_nipoc').innerHTML="aligmentsheet";
				document.getElementById('div_subsidiary').style.display="block";
				document.getElementById('div_stasiun').style.display="block";
				document.getElementById('div_jalur').style.display="block";
				document.getElementById('ifr3').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
				document.getElementById('iframetbl3').src = ifr1[idfile].preview;
			}else{
				document.getElementById('prm_ifr_nipoc').innerHTML="";
				document.getElementById('div_subsidiary').style.display="none";
				document.getElementById('div_stasiun').style.display="none";
				document.getElementById('div_jalur').style.display="none";
				document.getElementById('ifr3').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}else if(ifr == 5){
			
			var prmida = "bk_"+idfile;
			if(ida == prmida){
				document.getElementById('iframetbl4').src = 'https://www.youtube.com/embed/9856RA0cut0?autoplay=1&enablejsapi=1&rel=0&modestbranding=1&showsearch=0&loop=1&playlist=9856RA0cut0';
				document.getElementById('ifr4').style.display="block";
				document.getElementById('div_rov').style.display="block";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="none";
			}else{
				document.getElementById('div_rov').style.display="none";
				document.getElementById('ifr4').style.display="none";
				document.getElementById(ida).style.display="none";
				document.getElementById(ida2).style.display="block";
				document.getElementById('homealive_samping_sidemenu').style.display="block";
			}
			
		}
		
	};
	
	const [openSections, setOpenSections] = useState({});

	const toggleCollapse = (section) => {
		setOpenSections((prevState) => ({
		  ...prevState,
		  [section]: !prevState[section],
		}));
	};

	const handlePofChange = async (selectedOption) => {

	

	};
	
  const [pointOfConcernOptions, setPointOfConcernOptions] = useState([
    { value: '', label: 'Point of Concern' },
    { value: 'sfpoc', label: 'Station Facilities' },
    { value: 'nipoc', label: 'Network Infrastructure' },
    { value: 'repoc', label: 'Rotating Equipment' },
    { value: 'lngrpoc', label: 'LNG Regasification' },
    { value: 'lpgppoc', label: 'LPG Plant' },
    { value: 'cngrpoc', label: 'CNG Refueling' },
    { value: 'pgpoc', label: 'Power Generation' }
  ]);
  
const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		ifr2css: {
			margin: '50px',
			width: 'calc(100vw - 270px)',
			height: '700px'
		},

		placeholder_tbl2css: {
			marginTop: '0px',
			marginLeft: '-0px',
			maxHeight: '700px',
			overflow: 'hidden',
		},

		iframetbl2css:{
			float: 'left',
			width: '1610px',
			height: '820px',
			left: 0,
			transform: 'scale(0.78)',
			overflow: 'hidden',
			top: '10%',
			transformOrigin: '0 0',
			msTransformOrigin: '0 0',
			MozTransformOrigin: '0 0',
			OTransformOrigin: '0 0',
			WebkitTransformOrigin: '0 0',
		},
		
		selectcompany:{
			margin: '0px 10px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		selectstasiun:{
			margin: '0px 0px 0px 0px',
			width: '250px',
			float: 'left',
		},
		
		iframetbl3css: {
			width: 'calc(100vw - 200px)',
			height: '590px',
		  },

		  ifr3css: {
			marginTop: '10px',
			width: 'calc(100vw - 200px)',
			height: '550px',
			overflowX: 'hidden',
			overflowY: 'auto',
			scrollbarWidth: 'none',
		  },
		  
		  placeholdertbl3css: {
			marginTop: '-0px',
			marginLeft: '-1px',
			height: '550px',
			overflow: 'hidden',
		  },
		  
		divsides:{
			//position: 'relative', 
			overflow: 'visible',
		},
		
		divsides2:{
			float: 'left',
			marginLeft: '160px',
			position : 'absolute',
			width : '100px',
			background: 'transparance',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		divsides3:{
			float: 'left',
			marginLeft: '250px',
			position : 'absolute',
			maxWidth : '400px',
			background: 'grey',
			borderRadius: '5px',
			textAlign: 'justify',
			padding: '5px',
			height: '100px',
			overflow: 'auto', 
			scrollbarWidth: 'none',
			color: 'white',
		},
		
		dividesdalam:{
			fontSize: '0px', 
			height: '50px', 
			width:'50px',
		},
		
	};

const getCurrentDateTime = () => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // bulan dalam format 2 digit
    const day = String(now.getDate()).padStart(2, '0'); // hari dalam format 2 digit
    const hours = String(now.getHours()).padStart(2, '0'); // jam dalam format 2 digit
    const minutes = String(now.getMinutes()).padStart(2, '0'); // menit dalam format 2 digit

    return `${year}-${month}-${day} ${hours}:${minutes}`;
  };

	useEffect(() => {
    
    const url = 'https://script.google.com/macros/s/AKfycbyiB_FX-cJNo8frFs-vVWc5W2QxqG8LLzJ2bCSxs4V8qO5m4PsSVk1_3VJCJwL__fF1/exec?prm_potential=link_potential';

    fetch(url)
      .then(response => response.json())
      .then(data => {
        setJalurData(data);
      })
      .catch(error => console.error('Error fetching data:', error));
	  
	const url2 = 'https://script.google.com/macros/s/AKfycbyiB_FX-cJNo8frFs-vVWc5W2QxqG8LLzJ2bCSxs4V8qO5m4PsSVk1_3VJCJwL__fF1/exec?prm_potential=height_potential';

    fetch(url2)
      .then(response => response.json())
      .then(data => {
        setJalurDataheight(data);
      })
      .catch(error => console.error('Error fetching data:', error));
	  
  }, []);
	
  useEffect(() => {
    const fetchUserData = async () => {
      // Cek token di localStorage
      const storedToken = localStorage.getItem('token');
      if (!storedToken) {
        // Jika tidak ada token, arahkan ke login
        navigate('/login');
      } else {
        setToken(storedToken);
        
        try {
          // Panggil API untuk verifikasi token
          const response = await axios.get('https://alive.my.id/restapialive3/api/useratr', {
            headers: {
              Authorization: `Bearer ${storedToken}`,
            },
          });

          if (response.data) {
			
			setUserData(response.data);			
			 
			try {
				const response = await axios.post('https://alive.my.id/restapialive3/api/data', {
				  tgl: getCurrentDateTime()
				}, {
				  headers: {
					Authorization: `Bearer ${storedToken}`,
					'Content-Type': 'application/json'
				  }
				});

				console.log('Data successfully sent:', response.data);
			} catch (error) {
				console.error('Error sending data:', error);
			}
            
          } else {
            //navigate('/login');
          }
        } catch (error) {
          console.error('Error verifying token:', error);
          //navigate('/login');
        }
      }
    };

    // Panggil fungsi fetchUserData
    fetchUserData();
  }, [navigate]);
  
  useEffect(() => {
    if (selectedPointOfConcern) {
      navigate("/"+selectedPointOfConcern.value);
	  
    }
  }, [selectedPointOfConcern, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
	
		<div id="prm_ifr_nipoc" style={{display: 'none'}}></div>
		<div id="prm_entitas" style={{display: 'none'}}>PGN</div>
		<div id="prm_rimox" style={{display: 'none'}}></div>
		
		<a href="./">
			<img 
				id="logoalive"
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`}
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', marginTop: '0px', marginRight: '15px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{ clear: 'both' }}></div>
		
	<div style={{ float: 'left', margin: '0px 10px 10px 0px' }}>
			<Select
			  styles={customStyles}
			  options={pointOfConcernOptions}
			  onChange={setSelectedPointOfConcern}
			  placeholder="Point of Concern"
			  value={selectedPointOfConcern}
			/>
		</div>
		
		<div ref={(el) => (allIframesRef.current[4] = el)} id="div_subsidiary" style={{ display: 'none', margin: '0px 10px -20px 0px', width: '250px', float: 'left' }}>
		  <select 
			style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none',
			}}
			id="subsidiary" 
			className="form-control"
			value={selectedCompany}
			onChange={handleCompanyChange}
		>
			<option value="">Company</option>
			<option value="PGNSSWJ">1.PGN-SSWJ</option>
			<option value="PTPertaminaGas">2.Pertamina Gas</option>
			<option value="PTNusantaraRegas">3.Nusantara Regas</option>
			<option value="PTTransportasiGasIndonesia">4.Transportasi Gas Indonesia</option>
			<option value="PTKalimantanJawaGas">5.Kalimantan Jawa Gas</option>
			<option value="PTPertaSamtanGas">6.Perta Samtan Gas</option>
			<option value="PTPGNLNGIndonesia">7.PGN LNG Indonesia</option>
			<option value="PTWidarMandripaNusantara">8.Widar Mandripa Nusantara</option>
			<option value="PTSakaEnergiIndonesia">9.Saka Energi Indonesia</option>
			<option value="PTGagasEnergiIndonesia">10.Gagas Energi Indonesia</option>
			<option value="PTPertagasNiaga">11.Pertagas Niaga</option>
			<option value="PTPertaArunGas">12.Perta Arun Gas</option>
			<option value="PTPertaDayaGas">13.Perta Daya Gas</option>
			<option value="PGNSOR1">14.PGN-SOR1</option>
			<option value="PGNSOR2">15.PGN-SOR2</option>
			<option value="PGNSOR3">16.PGN-SOR3</option>
		</select>

		</div>


      <div ref={(el) => (allIframesRef.current[5] = el)} id="div_stasiun" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
        <select 
          style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none',
			}}
          id="stasiun" 
          className="form-control"
          value={selectedArea}
          onChange={handleAreaChange}
          disabled={!areas.length}
        >
          <option value="">Area</option>
          {areas.map(area => (
            <option key={area} value={area}>{area}</option>
          ))}
        </select>
      </div>

      <div ref={(el) => (allIframesRef.current[6] = el)} id="div_jalur" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
        <select 
          style={{
			  height: '25px', 
			  width: '100%', 
			  backgroundColor: '#fff', 
			  border: '1px solid #ccc', 
			  borderRadius: '4px', 
			  padding: '0 10px',
			  fontSize: '14px',
			  color: '#333',
			  boxShadow: '0px 2px 5px rgba(0, 0, 0, 0.1)',
			  appearance: 'none', // Remove default dropdown arrow for custom styling
			}}
          id="jalur" 
          className="form-control all_ifr"
		  onChange={handleJalurChange}
          disabled={!jalur.length}
        >
          <option value="">Jalur</option>
          {jalur.map(j => (
            <option key={j} value={j}>{j}</option>
          ))}
        </select>
      </div>
	  
	  <div ref={(el) => (allIframesRef.current[26] = el)} id="div_rov" style={{ display: 'none', margin: '0px 10px 0px 0px', width: '250px', float: 'left' }}>
			<Select
			  styles={customStyles}
			  options={visualrovOptions}
			  onChange={handlerovChange}
			  placeholder="ROV"
			  value={selectedVisualrov}
			/>
		</div>
		
		
		<div style={{clear: 'both'}}></div>
	  
		<table style={{marginRight: '0px'}}>
		
			<tbody>
			<tr>
				<td align="left" valign="top" style={{width: '200px', fontWeight: '500'}}>
					
					<div
					ref={scrollRef}
					style={{
					  width: '100%',
					  maxHeight: '560px',
					  overflowY: 'auto',
					  scrollbarWidth: 'none',
					}}
					onScroll={handleScroll}
					className={`custom-scroll ${isHovered ? 'hover' : ''}`}
					onMouseEnter={() => setIsHovered(true)}
					onMouseLeave={() => setIsHovered(false)}
				  >
				  {/*Risk Profile Update*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('risk_profile_update')}
						  onMouseEnter={() => handleMouseEnter('risk_profile_updatehover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'risk_profile_updatehover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Integrity Management System (PIMS) adalah suatu sistem yang dirancang untuk memastikan bahwa jaringan pipa gas tetap beroperasi dengan aman, handal, dan efisien sepanjang masa pakainya. PIMS adalah bagian dari manajemen keselamatan pipa yang komprehensif yang mencakup berbagai prosedur, teknologi, dan metode untuk mengelola risiko yang terkait dengan operasi pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											PIMS Pipeline Integrity Management System; Risk Profile Update
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['risk_profile_update'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[13] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
														<a ref={(el) => (allIframesRef.current[13] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[16] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refmuhlbauer" onClick={() => tampilIfr(2,'refmuhlbauer','bk_refmuhlbauer','ttp_refmuhlbauer')}>Pipeline Risk Assessment - The Defenitive Approach and its Role in Risk Management (W. Kent Muhlbauer)</a>
														<a ref={(el) => (allIframesRef.current[16] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refmuhlbauer" onClick={() => tampilIfr(2,'refmuhlbauer','ttp_refmuhlbauer','bk_refmuhlbauer')}><b>Pipeline Risk Assessment - The Defenitive Approach and its Role in Risk Management (W. Kent Muhlbauer)</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_calc']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[14] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueronshoreweb" onClick={() => tampilIfr('muhlbaueronshore','muhlbaueronshoreweb','bk_muhlbaueronshoreweb','ttp_muhlbaueronshoreweb')}>Muhlbauer for Steel (Onshore) Web</a>
															<a ref={(el) => (allIframesRef.current[14] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueronshoreweb" onClick={() => tampilIfr('muhlbaueronshore','muhlbaueronshoreweb','ttp_muhlbaueronshoreweb','bk_muhlbaueronshoreweb')}><b style={{color: 'black'}}>Muhlbauer for Steel (Onshore) Web</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[142] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueronshoresheet" onClick={() => tampilIfr('2','muhlbaueronshoresheet','bk_muhlbaueronshoresheet','ttp_muhlbaueronshoresheet')}>Muhlbauer for Steel (Onshore) Sheet</a>
															<a ref={(el) => (allIframesRef.current[142] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueronshoresheet" onClick={() => tampilIfr('2','muhlbaueronshoresheet','ttp_muhlbaueronshoresheet','bk_muhlbaueronshoresheet')}><b style={{color: 'black'}}>Muhlbauer for Non Steel (Onshore) Sheet</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[145] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueronshorepeweb" onClick={() => tampilIfr('muhlbaueronshorepeweb','muhlbaueronshorepeweb','bk_muhlbaueronshorepeweb','ttp_muhlbaueronshorepeweb')}>Muhlbauer for Non Steel (Onshore) Web</a>
															<a ref={(el) => (allIframesRef.current[145] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueronshorepeweb" onClick={() => tampilIfr('muhlbaueronshorepeweb','muhlbaueronshorepeweb','ttp_muhlbaueronshorepeweb','bk_muhlbaueronshorepeweb')}><b style={{color: 'black'}}>Muhlbauer for Non Steel (Onshore) Web</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[134] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueronshorepe" onClick={() => tampilIfr('2','muhlbaueronshorepe','bk_muhlbaueronshorepe','ttp_muhlbaueronshorepe')}>Muhlbauer for Non Steel (Onshore) sheet</a>
															<a ref={(el) => (allIframesRef.current[134] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueronshorepe" onClick={() => tampilIfr('2','muhlbaueronshorepe','ttp_muhlbaueronshorepe','bk_muhlbaueronshorepe')}><b style={{color: 'black'}}>Muhlbauer for Non Steel (Onshore) sheet</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[141] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueroffshoreweb" onClick={() => tampilIfr('muhlbaueroffshore','muhlbaueroffshoreweb','bk_muhlbaueroffshoreweb','ttp_muhlbaueroffshoreweb')}>Muhlbauer for Steel (Offshore) Web</a>
															<a ref={(el) => (allIframesRef.current[141] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueroffshoreweb" onClick={() => tampilIfr('muhlbaueroffshore','muhlbaueroffshoreweb','ttp_muhlbaueroffshoreweb','bk_muhlbaueroffshoreweb')}><b style={{color: 'black'}}>Muhlbauer for Steel (Offshore) Web</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{ marginTop: '-15px' }}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[143] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_muhlbaueroffshoresheet" onClick={() => tampilIfr('2','muhlbaueroffshoresheet','bk_muhlbaueroffshoresheet','ttp_muhlbaueroffshoresheet')}>Muhlbauer for Steel (Offshore) Sheet</a>
															<a ref={(el) => (allIframesRef.current[143] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_muhlbaueroffshoresheet" onClick={() => tampilIfr('2','muhlbaueroffshoresheet','ttp_muhlbaueroffshoresheet','bk_muhlbaueroffshoresheet')}><b style={{color: 'black'}}>Muhlbauer for Non Steel (Offshore) Sheet</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('risk_profile_update_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['risk_profile_update_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('risk_profile_update_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['risk_profile_update_SSWJ'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[54] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','bk_PIMSJAN24','ttp_PIMSJAN24')}>PIMS SSWJ Januari 2024</a>
																	<a ref={(el) => (allIframesRef.current[54] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','ttp_PIMSJAN24','bk_PIMSJAN24')}><b>PIMS SSWJ Januari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[55] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','bk_PIMSFEB24','ttp_PIMSFEB24')}>PIMS SSWJ Februari 2024</a>
																	<a ref={(el) => (allIframesRef.current[55] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','ttp_PIMSFEB24','bk_PIMSFEB24')}><b>PIMS SSWJ Februari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[56] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','bk_PIMSMAR24','ttp_PIMSMAR24')}>PIMS SSWJ Maret 2024</a>
																	<a ref={(el) => (allIframesRef.current[56] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','ttp_PIMSMAR24','bk_PIMSMAR24')}><b>PIMS SSWJ Maret 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[57] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','bk_PIMSAPR24','ttp_PIMSAPR24')}>PIMS SSWJ April 2024</a>
																	<a ref={(el) => (allIframesRef.current[57] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','ttp_PIMSAPR24','bk_PIMSAPR24')}><b>PIMS SSWJ April 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[58] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','bk_PIMSMEI24','ttp_PIMSMEI24')}>PIMS SSWJ Mei 2024</a>
																	<a ref={(el) => (allIframesRef.current[58] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','ttp_PIMSMEI24','bk_PIMSMEI24')}><b>PIMS SSWJ Mei 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[59] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','bk_PIMSJUNI24','ttp_PIMSJUNI24')}>PIMS SSWJ Juni 2024</a>
																	<a ref={(el) => (allIframesRef.current[59] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','ttp_PIMSJUNI24','bk_PIMSJUNI24')}><b>PIMS SSWJ Juni 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[60] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','bk_PIMSJULI24','ttp_PIMSJULI24')}>PIMS SSWJ Juli 2024</a>
																	<a ref={(el) => (allIframesRef.current[60] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','ttp_PIMSJULI24','bk_PIMSJULI24')}><b>PIMS SSWJ Juli 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[61] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','bk_PIMSAGU24','ttp_PIMSAGU24')}>PIMS SSWJ Agustus 2024</a>
																	<a ref={(el) => (allIframesRef.current[61] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','ttp_PIMSAGU24','bk_PIMSAGU24')}><b>PIMS SSWJ Agustus 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[62] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','bk_PIMSSEPT24','ttp_PIMSSEPT24')}>PIMS SSWJ September 2024</a>
																	<a ref={(el) => (allIframesRef.current[62] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','ttp_PIMSSEPT24','bk_PIMSSEPT24')}><b>PIMS SSWJ September 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[63] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','bk_PIMSOKT24','ttp_PIMSOKT24')}>PIMS SSWJ Oktober 2024</a>
																	<a ref={(el) => (allIframesRef.current[63] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','ttp_PIMSOKT24','bk_PIMSOKT24')}><b>PIMS SSWJ Oktober 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											</div>
										)}
										
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('risk_profile_update_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['risk_profile_update_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('risk_profile_update_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['risk_profile_update_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('risk_profile_update_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['risk_profile_update_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
									</div>
								)}
							</div>
						 
						 </div>
						)}
					</div>
					{/*Risk Profile Update*/}
				  
					{/*Intelligent Pigging*/}
					<div style={{  }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('intelligent_pigging')}
						  onMouseEnter={() => handleMouseEnter('intelligent_pigginghover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'intelligent_pigginghover' && (
							 <>
							  <div 
							  style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
								
							  }}
							  >
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div 
								style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
									}}
								>

									“Intelligent Pigging” yang juga disebut sebagai “Smart Pigging” adalah teknik robotik canggih yang menggerakkan unit pengukur integritas pipa (PIG) ​​yang dibuat khusus yang dilengkapi dengan berbagai probe dan sensor melalui pipa untuk mendeteksi dan mengukur korosi, kehilangan logam, retakan, penyok, deformasi, dll. Laporan ILI akan merinci semua masalah utama terkait kondisi dan integritas pipa dengan penanda lokasi yang tepat.
									
								</div>
								
								<div style={{clear: 'both'}}></div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Intelligent Pigging
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['intelligent_pigging'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[17] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refip" onClick={() => tampilIfr(2,'refip','bk_refip','ttp_refip')}>ASME B31G-2012</a>
														<a ref={(el) => (allIframesRef.current[17] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refip" onClick={() => tampilIfr(2,'refip','ttp_refip','bk_refip')}><b>ASME B31G-2012</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_calc'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ffs" onClick={() => tampilIfr(1,'ffs','bk_ffs','ttp_ffs')}>Fitness For Service</a>
														<a ref={(el) => (allIframesRef.current[0] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ffs" onClick={() => tampilIfr(1,'ffs','ttp_ffs','bk_ffs')}><b>Fitness For Service</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_assessment12')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('intelligent_pigging_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['intelligent_pigging_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<div
										style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										onClick={() => toggleCollapse('intelligent_pigging_SSWJ')}
									>
										<ul>
											<li>
												<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
												SSWJ
											</li>
										</ul>
									</div>
									{openSections['intelligent_pigging_SSWJ'] && (
									  
										<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_2012')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													2012
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_2012'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>

												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[32] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','bk_IP2012_GRS_PGD','ttp_IP2012_GRS_PGD')}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</a>
																	<a ref={(el) => (allIframesRef.current[32] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','ttp_IP2012_GRS_PGD','bk_IP2012_GRS_PGD')}><b style={{color: 'black'}}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[33] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','bk_IP2012_PGD_TBB','ttp_IP2012_PGD_TBB')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</a>
																	<a ref={(el) => (allIframesRef.current[33] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','ttp_IP2012_PGD_TBB','bk_IP2012_PGD_TBB')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[34] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','bk_IP2012_TBB_LBM','ttp_IP2012_TBB_LBM')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</a>
																	<a ref={(el) => (allIframesRef.current[34] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','ttp_IP2012_TBB_LBM','bk_IP2012_TBB_LBM')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
										  
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_2016')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													2016
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_2016'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[34] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1')}>Final Report Section 1 ( 36" Grissik-Pagardewa )</a>
																	<a ref={(el) => (allIframesRef.current[34] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1')}><b style={{color: 'black'}}>Final Report Section 1 ( 36" Grissik-Pagardewa )</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[35] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5')}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</a>
																	<a ref={(el) => (allIframesRef.current[35] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5')}><b style={{color: 'black'}}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[36] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3')}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</a>
																	<a ref={(el) => (allIframesRef.current[36] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3')}><b style={{color: 'black'}}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[37] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2')}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</a>
																	<a ref={(el) => (allIframesRef.current[37] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2')}><b style={{color: 'black'}}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[38] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_TBB_LBM_SECTION4" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4')}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</a>
																	<a ref={(el) => (allIframesRef.current[38] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4')}><b style={{color: 'black'}}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_2017')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													2017
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_2017'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[39] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3')}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</a>
																	<a ref={(el) => (allIframesRef.current[39] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[40] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3')}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</a>
																	<a ref={(el) => (allIframesRef.current[40] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3','bk_IP2017_LBM_MBK_PHASE2_Section3')}><b style={{color: 'black'}}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[41] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1')}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</a>
																	<a ref={(el) => (allIframesRef.current[41] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[42] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1')}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</a>
																	<a ref={(el) => (allIframesRef.current[42] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1')}><b style={{color: 'black'}}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[43] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2')}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</a>
																	<a ref={(el) => (allIframesRef.current[43] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[44] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2')}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</a>
																	<a ref={(el) => (allIframesRef.current[44] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2')}><b style={{color: 'black'}}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_pipetally" onClick={() => tampilIfr(1,'pipetally','bk_pipetally','ttp_pipetally')}>Pipetally</a>
																	<a ref={(el) => (allIframesRef.current[1] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_pipetally" onClick={() => tampilIfr(1,'pipetally','ttp_pipetally','bk_pipetally')}><b>Pipetally</b></a>
																</td>
															</tr>
														</table>
													</li>
													
												</ul>
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_2023')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													2023
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_2023'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','bk_ReportILIGRS_PGD','ttp_ReportILIGRS_PGD')}>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</a>
																	<a ref={(el) => (allIframesRef.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','ttp_ReportILIGRS_PGD','bk_ReportILIGRS_PGD')}><b>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD')}>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</a>
																	<a ref={(el) => (allIframesRef.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD')}><b>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','bk_ReportILIPGD_TBB','ttp_ReportILIPGD_TBB')}>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</a>
																	<a ref={(el) => (allIframesRef.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','ttp_ReportILIPGD_TBB','bk_ReportILIPGD_TBB')}><b>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','bk_ReportILITBB_LBM','ttp_ReportILITBB_LBM')}>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</a>
																	<a ref={(el) => (allIframesRef.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','ttp_ReportILITBB_LBM','bk_ReportILITBB_LBM')}><b>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','bk_ReportILILBM_BJN','ttp_ReportILILBM_BJN')}>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</a>
																	<a ref={(el) => (allIframesRef.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','ttp_ReportILILBM_BJN','bk_ReportILILBM_BJN')}><b>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_2024')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													2024
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_2024'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','bk_repot_1p_2024','ttp_repot_1p_2024')}>Repot IP 2024</a>
																	<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','ttp_repot_1p_2024','bk_repot_1p_2024')}><b>Repot IP 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
										)}
									 
									 </div>
									)}
									
									<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('intelligent_pigging_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['intelligent_pigging_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
									
									</div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Intelligent Pigging*/}
					
					{/*CP Monitoring*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('cp_monitoring')}
						  onMouseEnter={() => handleMouseEnter('cp_monitoringhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'cp_monitoringhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									CP Monitoring (Cathodic Protection Monitoring) pada pipa gas adalah proses pengawasan dan pengukuran perlindungan katodik (Cathodic Protection/CP) untuk mencegah korosi eksternal pada pipa bawah tanah atau pipa yang terpapar lingkungan korosif. CP merupakan metode yang paling umum digunakan untuk melindungi pipa baja dari korosi dengan mengubahnya menjadi katode dalam suatu sel elektrokimia.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											CP Monitoring
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['cp_monitoring'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['cp_monitoring_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refcpmonitoring" onClick={() => tampilIfr(2,'refcpmonitoring','bk_refcpmonitoring','ttp_refcpmonitoring')}>NACE RP0102</a>
														<a ref={(el) => (allIframesRef.current[2] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refcpmonitoring" onClick={() => tampilIfr(2,'refcpmonitoring','ttp_refcpmonitoring','bk_refcpmonitoring')}><b>NACE RP0102</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['quantitative_risk_assesment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['lightning_protection_system_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cp_monitoring_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['cp_monitoring_lastreport'] && (
								
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
											<ul>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_cpmonitoring" onClick={() => tampilIfr(3,'cpmonitoring','bk_cpmonitoring','ttp_cpmonitoring')}>Potential Pattern</a>
																<a ref={(el) => (allIframesRef.current[3] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_cpmonitoring" onClick={() => tampilIfr(3,'cpmonitoring','ttp_cpmonitoring','bk_cpmonitoring')}><b>Potential Pattern</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
											<ul style={{margin: '-15px 0px 0px 0px'}}>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[7] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asgcpmonitoring" onClick={() => tampilIfr(1,'asgcpmonitoring','bk_asgcpmonitoring','ttp_asgcpmonitoring')}>ASG Potential Pattern</a>
																<a ref={(el) => (allIframesRef.current[7] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asgcpmonitoring" onClick={() => tampilIfr(1,'asgcpmonitoring','ttp_asgcpmonitoring','bk_asgcpmonitoring')}><b>ASG Potential Pattern</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
										</div>
								 
								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end CP Monitoring*/}
					
					{/*ROW Management*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('row_management')}
						  onMouseEnter={() => handleMouseEnter('row_managementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'row_managementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									ROW Management (Right-of-Way Management) adalah proses pengelolaan area yang diperlukan untuk instalasi, pemeliharaan, dan operasi pipa. Right-of-Way (ROW) adalah hak atau izin untuk menggunakan sepotong tanah untuk menempatkan pipa dan infrastruktur terkait lainnya. ROW Management mencakup berbagai aktivitas untuk memastikan bahwa penggunaan area ini aman, efisien, dan sesuai dengan regulasi.
									
								</div>
							 </>
						)}

							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											ROW Management
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['row_management'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['row_management_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[53] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_APISPEC5L" onClick={() => tampilIfr(2,'APISPEC5L','bk_APISPEC5L','ttp_APISPEC5L')}>API Specification 5L</a>
														<a ref={(el) => (allIframesRef.current[53] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_APISPEC5L" onClick={() => tampilIfr(2,'APISPEC5L','ttp_APISPEC5L','bk_APISPEC5L')}><b>API Specification 5L</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: "-20px 0px 10px 0px",}}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[8] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_aligmentsheet" onClick={() => tampilIfr(4,'aligmentsheet','bk_aligmentsheet','ttp_aligmentsheet')}>Aligment Sheet</a>
														<a ref={(el) => (allIframesRef.current[8] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_aligmentsheet" onClick={() => tampilIfr(4,'aligmentsheet','ttp_aligmentsheet','bk_aligmentsheet')}><b>Aligment Sheet</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['row_management_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['row_management_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('row_management_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['row_management_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<div
										 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										 onClick={() => toggleCollapse('row_management_SSWJ')}
									>
										<ul>
											<li>
												<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
												SSWJ
											</li>
										</ul>
									</div>
									{openSections['row_management_SSWJ'] && (
									  
										<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<ul>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[22] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_RIGHTOFWAYMANAGEMET" onClick={() => tampilIfr(2,'RIGHTOFWAYMANAGEMET','bk_RIGHTOFWAYMANAGEMET','ttp_RIGHTOFWAYMANAGEMET')}>Right Of Way Management</a>
																<a ref={(el) => (allIframesRef.current[22] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_RIGHTOFWAYMANAGEMET" onClick={() => tampilIfr(2,'RIGHTOFWAYMANAGEMET','ttp_RIGHTOFWAYMANAGEMET','bk_RIGHTOFWAYMANAGEMET')}><b>Right Of Way Management</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
										
										</div>
										
									)}
									
									<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('row_management_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['row_management_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('row_management_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['row_management_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('row_management_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['row_management_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
								 
								 </div>
								)}
								
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end ROW Management*/}
					
					{/*SideScan Sonar*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('sidescan_sonar')}
						  onMouseEnter={() => handleMouseEnter('sidescan_sonarhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'sidescan_sonarhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									SideScan Sonar adalah teknologi penginderaan bawah air yang digunakan untuk survei dan pemantauan pipa gas yang terendam atau berada di bawah permukaan air. Teknologi ini memberikan gambaran dua dimensi dari dasar perairan, termasuk pipa, struktur bawah air, dan fitur lainnya, dengan memanfaatkan gelombang suara untuk mendeteksi objek dan menganalisis kondisi sekitarnya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											SideScan Sonar
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['sidescan_sonar'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_ref']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('sidescan_sonar_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['sidescan_sonar_lastreport']}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end sidescan_sonar*/}
					
					{/*Survey Accoustic Survey*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('survey_accoustic_survey')}
						  onMouseEnter={() => handleMouseEnter('survey_accoustichover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'survey_accoustichover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Acoustic Survey atau Survei Akustik pada pipa gas adalah metode non-destruktif yang digunakan untuk memeriksa dan menganalisis kondisi pipa, terutama pipa bawah tanah atau pipa yang terendam di dalam air. Teknik ini menggunakan gelombang suara untuk mendeteksi dan mengukur kondisi pipa dan lingkungan sekitarnya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Survey Accoustic Survey
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['survey_accoustic_survey'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_ref'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[64] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','bk_IHOSFHS','ttp_IHOSFHS')}>International Hydrographic Organization Standards for Hydrographic Surveys</a>
															<a ref={(el) => (allIframesRef.current[64] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','ttp_IHOSFHS','bk_IHOSFHS')}><b style={{color: 'black'}}>International Hydrographic Organization Standards for Hydrographic Surveys</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[65] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_BSISTDPBL" onClick={() => tampilIfr(2,'BSISTDPBL','bk_BSISTDPBL','ttp_BSISTDPBL')}>Weight control during engineering and construction (ISO 19901-5:2016)</a>
															<a ref={(el) => (allIframesRef.current[65] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_BSISTDPBL" onClick={() => tampilIfr(2,'BSISTDPBL','ttp_BSISTDPBL','bk_BSISTDPBL')}><b style={{color: 'black'}}>Weight control during engineering and construction (ISO 19901-5:2016)</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									  
									  
									  </div>
									  
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_accoustic_survey_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['survey_accoustic_survey_lastreport'] && (
								  
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul>
											<li>
												<table>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[21] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ACOUSTICSURVEYSOUTH" onClick={() => tampilIfr(2,'ACOUSTICSURVEYSOUTH','bk_ACOUSTICSURVEYSOUTH','ttp_ACOUSTICSURVEYSOUTH')}>Acoustic Surveysouth Sumatera - West Java Offshore Pipeline</a>
															<a ref={(el) => (allIframesRef.current[21] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ACOUSTICSURVEYSOUTH" onClick={() => tampilIfr(2,'ACOUSTICSURVEYSOUTH','ttp_ACOUSTICSURVEYSOUTH','bk_ACOUSTICSURVEYSOUTH')}><b>Acoustic Surveysouth Sumatera - West Java Offshore Pipeline</b></a>
														</td>
													</tr>
												</table>
											</li>
										</ul>
										
									</div>
									
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Survey Accoustic Survey*/}
					
					{/*CIPS DCVG*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('cipsdcvg')}
						  onMouseEnter={() => handleMouseEnter('cipsdcvghover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'cipsdcvghover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									CIPS (Current Injection Pipe Survey) dan DCVG (Direct Current Voltage Gradient) adalah metode pengujian yang digunakan untuk memantau dan mengevaluasi sistem perlindungan katodik (Cathodic Protection/CP) pada pipa gas untuk memastikan bahwa pipa dilindungi dari korosi.
								
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											CIPS DCVG
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['cipsdcvg'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_ref'] && (
										<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<ul>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[51] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_SPPECDAM" onClick={() => tampilIfr(2,'SPPECDAM','bk_SPPECDAM','ttp_SPPECDAM')}>Standard Practice Pipeline External Corrosion Direct Assessment Methodology</a>
																<a ref={(el) => (allIframesRef.current[51] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_SPPECDAM" onClick={() => tampilIfr(2,'SPPECDAM','ttp_SPPECDAM','bk_SPPECDAM')}><b style={{color: 'black'}}>Standard Practice Pipeline External Corrosion Direct Assessment Methodology</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
										
										</div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('cipsdcvg_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['cipsdcvg_lastreport'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<div
										 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										 onClick={() => toggleCollapse('cipsdcvg_SSWJ')}
									>
										<ul>
											<li>
												<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
												SSWJ
											</li>
										</ul>
									</div>
									{openSections['cipsdcvg_SSWJ'] && (
									  
										<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('cipsdcvg_report_2010')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2010
													</li>
												</ul>
											</div>
											{openSections['cipsdcvg_report_2010'] && (
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[27] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_LBM_PGD_PHASE2_2010','bk_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010','ttp_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010')}>Laporan Akhir CIPS DCVG Jalur LBM-PGD Phase 2 2010</a>
																		<a ref={(el) => (allIframesRef.current[27] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_LBM_PGD_PHASE2_2010','ttp_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010','bk_LA_CIPS_DCVG_LBM_PGD_PHASE2_2010')}><b style={{color: 'black'}}>Laporan Akhir CIPS DCVG Jalur LBM-PGD Phase 2 2010123</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-20px 0px 10px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[28] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104','bk_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104','ttp_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104')}>Laporan Akhir CIPS DCVG Jalur TBB-PGD Phase 2 KP4-KP104 2010</a>
																		<a ref={(el) => (allIframesRef.current[28] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104','ttp_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104','bk_LA_CIPS_DCVG_TBB_PGD_PHASE2_KP4_104')}><b style={{color: 'black'}}>Laporan Akhir CIPS DCVG Jalur TBB-PGD Phase 2 KP4-KP104 2010</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('cipsdcvg_report_2011')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2011
													</li>
												</ul>
											</div>
											{openSections['cipsdcvg_report_2011'] && (
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[29] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_LBM_PGD_PHASE1_2011','bk_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011','ttp_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011')}>Laporan Akhir CIPS DCVG Jalur LBM-PGD Phase 1 2011</a>
																		<a ref={(el) => (allIframesRef.current[29] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011" onClick={() => tampilIfr(2,'LA_CIPS_DCVG_LBM_PGD_PHASE1_2011','ttp_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011','bk_LA_CIPS_DCVG_LBM_PGD_PHASE1_2011')}><b style={{color: 'black'}}>Laporan Akhir CIPS DCVG Jalur LBM-PGD Phase 1 2011</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('cipsdcvg_report_2015')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2015
													</li>
												</ul>
											</div>
											{openSections['cipsdcvg_report_2015'] && (
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[30] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015" onClick={() => tampilIfr(2,'PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015','bk_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015','ttp_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015')}>Presentasi Laporan Akhir CIPS DCVG Jalur PGD-LBM Phase 1 2015</a>
																		<a ref={(el) => (allIframesRef.current[30] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015" onClick={() => tampilIfr(2,'PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015','ttp_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015','bk_PLA_CIPS_DCVG_PGD_LBM_PHASE1_2015')}><b style={{color: 'black'}}>Presentasi Laporan Akhir CIPS DCVG Jalur PGD-LBM Phase 1 2015</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('cipsdcvg_report_2018')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2018
													</li>
												</ul>
											</div>
											{openSections['cipsdcvg_report_2018'] && (
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[31] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018" onClick={() => tampilIfr(2,'LT_CIPS_DCVG_GRS_PGD_PHASE2_2018','bk_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018','ttp_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018')}>Laporan Tengah CIPS DCVG Jalur GRS - PGD Phase 2 2018</a>
																		<a ref={(el) => (allIframesRef.current[31] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018" onClick={() => tampilIfr(2,'LT_CIPS_DCVG_GRS_PGD_PHASE2_2018','ttp_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018','bk_LT_CIPS_DCVG_GRS_PGD_PHASE2_2018')}><b style={{color: 'black'}}>Laporan Tengah CIPS DCVG Jalur GRS - PGD Phase 2 2018</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
										
										</div>
										
									)}
									
									<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('cipsdcvg_report_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['cipsdcvg_report_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('cipsdcvg_report_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['cipsdcvg_report_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('cipsdcvg_report_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['cipsdcvg_report_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}

								 </div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end CIPS DCVG*/}
					
					{/*Freespan Rectification*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('freespan_rectification')}
						  onMouseEnter={() => handleMouseEnter('freespan_rectificationhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'freespan_rectificationhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
								    marginTop: `-${scrollPosition}px`,
								}}>

									Freespan Rectification adalah metode yang digunakan dalam pengelolaan dan pemeliharaan pipa gas untuk menangani dan memperbaiki masalah yang terkait dengan freespan — bagian pipa yang terjuntai tanpa dukungan dari struktur penopang. Freespan dapat menjadi area yang rawan terhadap stres dan kegagalan jika tidak ditangani dengan benar.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Freespan Rectification
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['freespan_rectification'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_ref'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[68] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF105" onClick={() => tampilIfr(2,'DNVRPF105','bk_DNVRPF105','ttp_DNVRPF105')}>DNV-RP-F105 FREE SPANNING PIPELINES</a>
															<a ref={(el) => (allIframesRef.current[68] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF105" onClick={() => tampilIfr(2,'DNVRPF105','ttp_DNVRPF105','bk_DNVRPF105')}><b style={{color: 'black'}}>DNV-RP-F105 FREE SPANNING PIPELINES</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[69] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPC203" onClick={() => tampilIfr(2,'DNVRPC203','bk_DNVRPC203','ttp_DNVRPC203')}>DNV-RP-C203 Fatigue Design Offshore Steel Structures</a>
															<a ref={(el) => (allIframesRef.current[69] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPC203" onClick={() => tampilIfr(2,'DNVRPC203','ttp_DNVRPC203','bk_DNVRPC203')}><b style={{color: 'black'}}>DNV-RP-C203 Fatigue Design Offshore Steel Structures</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									  
									  
									  </div>
									  
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('freespan_rectification_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['freespan_rectification_lastreport'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('freespan_rectification_lastreport_2021_SSWJ')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2021
														</li>
													</ul>
												</div>
												{openSections['freespan_rectification_lastreport_2021_SSWJ'] && (
												
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
													
														<ul style={{margin: '-15px 0px 10px 10px'}}>
															<li>
																<table>
																	<tbody>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[75] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_Verifikasifreesepan2021" onClick={() => tampilIfr(2,'Verifikasifreesepan2021','bk_Verifikasifreesepan2021','ttp_Verifikasifreesepan2021')}>Verifikasi Freesepan</a>
																			<a ref={(el) => (allIframesRef.current[75] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_Verifikasifreesepan2021" onClick={() => tampilIfr(2,'Verifikasifreesepan2021','ttp_Verifikasifreesepan2021','bk_Verifikasifreesepan2021')}><b style={{color: 'black'}}>Verifikasi Freesepan</b></a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</li>
														</ul>
														
														
													
													</div>
												
												)}
									  
									  </div>
									  
									)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Freespan Rectification*/}
					
					
					{/*Reinforcement*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('reinforcement')}
						  onMouseEnter={() => handleMouseEnter('reinforcementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'reinforcementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Reinforcement pada pipa gas merujuk pada proses atau teknik yang digunakan untuk memperkuat pipa agar dapat menahan tekanan, beban, dan kondisi lingkungan yang mungkin mengancam integritasnya. Proses ini penting untuk memastikan bahwa pipa gas tetap aman, efektif, dan berfungsi dengan baik sepanjang masa pakainya.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Reinforcement
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['reinforcement'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['reinforcement_ref'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[52] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ROPEAP" onClick={() => tampilIfr(2,'ROPEAP','bk_ROPEAP','ttp_ROPEAP')}>Repair of Pressure Equipment and Piping</a>
															<a ref={(el) => (allIframesRef.current[52] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ROPEAP" onClick={() => tampilIfr(2,'ROPEAP','ttp_ROPEAP','bk_ROPEAP')}><b style={{color: 'black'}}>Repair of Pressure Equipment and Piping</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									  
									  
									  </div>
									  
								)}
								
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['reinforcement_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['reinforcement_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('reinforcement_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['reinforcement_lastreport'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<ul>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[24] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ABDLPR72LDG001A13129REINFORCEMENT" onClick={() => tampilIfr(2,'ABDLPR72LDG001A13129REINFORCEMENT','bk_ABDLPR72LDG001A13129REINFORCEMENT','ttp_ABDLPR72LDG001A13129REINFORCEMENT')}>ABD.LPR-72-L-DG-001-A1-3-129 Reinforcement</a>
																<a ref={(el) => (allIframesRef.current[24] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ABDLPR72LDG001A13129REINFORCEMENT" onClick={() => tampilIfr(2,'ABDLPR72LDG001A13129REINFORCEMENT','ttp_ABDLPR72LDG001A13129REINFORCEMENT','bk_ABDLPR72LDG001A13129REINFORCEMENT')}><b style={{color: 'black'}}>ABD.LPR-72-L-DG-001-A1-3-129 Reinforcement</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
											<ul style={{ margin: '-15px 0px 0px 0px', fontSize: '13px' }}>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[25] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_KONSORSIUMDCNSGI" onClick={() => tampilIfr(2,'KONSORSIUMDCNSGI','bk_KONSORSIUMDCNSGI','ttp_KONSORSIUMDCNSGI')}>Konsorsium DCN-SGI Project Execution Plan</a>
																<a ref={(el) => (allIframesRef.current[25] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_KONSORSIUMDCNSGI" onClick={() => tampilIfr(2,'KONSORSIUMDCNSGI','ttp_KONSORSIUMDCNSGI','bk_KONSORSIUMDCNSGI')}><b style={{color: 'black'}}>Konsorsium DCN-SGI Project Execution Plan</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
											<ul style={{ margin: '0px 0px 0px 0px', fontSize: '13px' }}>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[26] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_KONSORSIUMDCNSGI2" onClick={() => tampilIfr(2,'KONSORSIUMDCNSGI2','bk_KONSORSIUMDCNSGI2','ttp_KONSORSIUMDCNSGI2')}>Konsorsium DCN-SGI Post Construction Survey After Final Repair Work</a>
																<a ref={(el) => (allIframesRef.current[26] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_KONSORSIUMDCNSGI2" onClick={() => tampilIfr(2,'KONSORSIUMDCNSGI2','ttp_KONSORSIUMDCNSGI2','bk_KONSORSIUMDCNSGI2')}><b style={{color: 'black'}}>Konsorsium DCN-SGI Post Construction Survey After Final Repair Work</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
										
										</div>
									
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Reinforcement*/}
					
					
					{/*Visual Inspection ROV*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('visual_inspection_rov')}
						  onMouseEnter={() => handleMouseEnter('visual_inspection_rovhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'visual_inspection_rovhover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Visual Inspection ROV (Remotely Operated Vehicle) pada pipa gas adalah metode inspeksi bawah air menggunakan kendaraan yang dikendalikan dari jarak jauh untuk memeriksa dan menilai kondisi pipa yang terendam atau berada di bawah permukaan air. Metode ini memungkinkan pemeriksaan visual secara langsung tanpa perlu melakukan penggalian atau pemindahan pipa, yang sangat berguna untuk pipa yang terletak di lokasi yang sulit dijangkau.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Visual Inspection Rov
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['visual_inspection_rov'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_ref'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[76] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','bk_IHOSFHS','ttp_IHOSFHS')}>International Hydrographic Organization Standards for Hydrographic Surveys</a>
															<a ref={(el) => (allIframesRef.current[76] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','ttp_IHOSFHS','bk_IHOSFHS')}><b style={{color: 'black'}}>International Hydrographic Organization Standards for Hydrographic Surveys</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[77] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','bk_DNVSTF101','ttp_DNVSTF101')}>DNV-ST-F101 Submarine pipeline systems</a>
															<a ref={(el) => (allIframesRef.current[77] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','ttp_DNVSTF101','bk_DNVSTF101')}><b style={{color: 'black'}}>DNV-ST-F101 Submarine pipeline systems</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[78] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','bk_DNVRPF113','ttp_DNVRPF113')}>DNV-RP-F113 Pipeline Subsea Repair</a>
															<a ref={(el) => (allIframesRef.current[78] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','ttp_DNVRPF113','bk_DNVRPF113')}><b style={{color: 'black'}}>DNV-RP-F113 Pipeline Subsea Repair</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									
									</div>
								)}
								
								
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('visual_inspection_rov_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['visual_inspection_rov_lastreport'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<ul>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[9] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_visual_rov_2021" onClick={() => tampilIfr(5,'visual_rov_2021','bk_visual_rov_2021','ttp_visual_rov_2021')}>2021</a>
																<a ref={(el) => (allIframesRef.current[9] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_visual_rov_2021" onClick={() => tampilIfr(5,'visual_rov_2021','ttp_visual_rov_2021','bk_visual_rov_2021')}><b>2021</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
											<ul style={{margin: '-15px 0px 15px 0px'}}>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[18] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_finalreportrov" onClick={() => tampilIfr(2,'finalreportrov','bk_finalreportrov','ttp_finalreportrov')}>Final Report - ROV Pipeline Inspection Report Of 32" Offshore Pipeline Labuhan Maringgai to Bojonegara</a>
																<a ref={(el) => (allIframesRef.current[18] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_finalreportrov" onClick={() => tampilIfr(2,'finalreportrov','ttp_finalreportrov','bk_finalreportrov')}><b>Final Report - ROV Pipeline Inspection Report Of 32" Offshore Pipeline Labuhan Maringgai to Bojonegara</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
											
											<ul style={{margin: '-15px 0px 15px 0px'}}>
												<li>
													<table>
														<tr>
															<td valign="top">
																<span className="lbn" style={{ fontSize: '12px'}}></span>
															</td>
															<td>
																<a ref={(el) => (allIframesRefbk.current[19] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_finalreportrovlbmmbk" onClick={() => tampilIfr(2,'finalreportrovlbmmbk','bk_finalreportrovlbmmbk','ttp_finalreportrovlbmmbk')}>Final Report - ROV Pipeline Inspection Report Of 32" Offshore Pipeline Labuhan Maringgai to Muara Bekasi</a>
																<a ref={(el) => (allIframesRef.current[19] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_finalreportrovlbmmbk" onClick={() => tampilIfr(2,'finalreportrovlbmmbk','ttp_finalreportrovlbmmbk','bk_finalreportrovlbmmbk')}><b>Final Report - ROV Pipeline Inspection Report Of 32" Offshore Pipeline Labuhan Maringgai to Muara Bekasi</b></a>
															</td>
														</tr>
													</table>
												</li>
											</ul>
										
										</div>
									
								)}
							</div>
						 
						 </div>
						)}
					</div>
					{/*end Visual Inspection ROV*/}
					
					{/*Inline Inspection*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('inline_inspection')}
						  onMouseEnter={() => handleMouseEnter('inline_inspectionhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'inline_inspectionhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Inline Inspection (ILI) pada pipa gas adalah metode pengujian yang dilakukan untuk mengevaluasi kondisi internal pipa menggunakan perangkat yang dimasukkan langsung ke dalam pipa. Teknik ini penting untuk mendeteksi potensi masalah seperti korosi, retakan, dan penumpukan material yang dapat mempengaruhi integritas dan keamanan pipa.
									
								</div>
							 </>
						)}
						
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Inline Inspection
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['inline_inspection'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[93] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refip" onClick={() => tampilIfr(2,'refip','bk_refip','ttp_refip')}>ASME B31G-2012</a>
														<a ref={(el) => (allIframesRef.current[93] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refip" onClick={() => tampilIfr(2,'refip','ttp_refip','bk_refip')}><b>ASME B31G-2012</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['inline_inspection_lastreport'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('inline_inspection_lastreport_SSWJ_2012')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2012
													</li>
												</ul>
											</div>
											{openSections['inline_inspection_lastreport_SSWJ_2012'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>

													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[79] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','bk_IP2012_GRS_PGD','ttp_IP2012_GRS_PGD')}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</a>
																		<a ref={(el) => (allIframesRef.current[79] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','ttp_IP2012_GRS_PGD','bk_IP2012_GRS_PGD')}><b style={{color: 'black'}}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[80] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','bk_IP2012_PGD_TBB','ttp_IP2012_PGD_TBB')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</a>
																		<a ref={(el) => (allIframesRef.current[80] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','ttp_IP2012_PGD_TBB','bk_IP2012_PGD_TBB')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[81] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','bk_IP2012_TBB_LBM','ttp_IP2012_TBB_LBM')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</a>
																		<a ref={(el) => (allIframesRef.current[81] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','ttp_IP2012_TBB_LBM','bk_IP2012_TBB_LBM')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											  
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('inline_inspection_lastreport_SSWJ_2016')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2016
													</li>
												</ul>
											</div>
											{openSections['inline_inspection_lastreport_SSWJ_2016'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[82] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1')}>Final Report Section 1 ( 36" Grissik-Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[82] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1')}><b style={{color: 'black'}}>Final Report Section 1 ( 36" Grissik-Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[83] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5')}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</a>
																		<a ref={(el) => (allIframesRef.current[83] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5')}><b style={{color: 'black'}}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[84] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3')}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</a>
																		<a ref={(el) => (allIframesRef.current[84] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3')}><b style={{color: 'black'}}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[85] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2')}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[85] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2')}><b style={{color: 'black'}}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[86] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_TBB_LBM_SECTION4" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4')}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</a>
																		<a ref={(el) => (allIframesRef.current[86] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4')}><b style={{color: 'black'}}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('inline_inspection_lastreport_SSWJ_2017')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2017
													</li>
												</ul>
											</div>
											{openSections['inline_inspection_lastreport_SSWJ_2017'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[87] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3')}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</a>
																		<a ref={(el) => (allIframesRef.current[87] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[88] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3')}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</a>
																		<a ref={(el) => (allIframesRef.current[88] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3','bk_IP2017_LBM_MBK_PHASE2_Section3')}><b style={{color: 'black'}}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[89] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1')}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</a>
																		<a ref={(el) => (allIframesRef.current[89] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[90] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1')}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</a>
																		<a ref={(el) => (allIframesRef.current[90] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1')}><b style={{color: 'black'}}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[91] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2')}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</a>
																		<a ref={(el) => (allIframesRef.current[91] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[92] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2')}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</a>
																		<a ref={(el) => (allIframesRef.current[92] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2')}><b style={{color: 'black'}}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('inline_inspection_lastreport_SSWJ_2023')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2023
													</li>
												</ul>
											</div>
											{openSections['inline_inspection_lastreport_SSWJ_2023'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','bk_ReportILIGRS_PGD','ttp_ReportILIGRS_PGD')}>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','ttp_ReportILIGRS_PGD','bk_ReportILIGRS_PGD')}><b>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD')}>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD')}><b>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','bk_ReportILIPGD_TBB','ttp_ReportILIPGD_TBB')}>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</a>
																		<a ref={(el) => (allIframesRef.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','ttp_ReportILIPGD_TBB','bk_ReportILIPGD_TBB')}><b>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','bk_ReportILITBB_LBM','ttp_ReportILITBB_LBM')}>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</a>
																		<a ref={(el) => (allIframesRef.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','ttp_ReportILITBB_LBM','bk_ReportILITBB_LBM')}><b>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','bk_ReportILILBM_BJN','ttp_ReportILILBM_BJN')}>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</a>
																		<a ref={(el) => (allIframesRef.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','ttp_ReportILILBM_BJN','bk_ReportILILBM_BJN')}><b>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('inline_inspection_lastreport_SSWJ_2024')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2024
													</li>
												</ul>
											</div>
											{openSections['inline_inspection_lastreport_SSWJ_2024'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','bk_repot_1p_2024','ttp_repot_1p_2024')}>Repot IP 2024</a>
																		<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','ttp_repot_1p_2024','bk_repot_1p_2024')}><b>Repot IP 2024</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
										
										</div>
								
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Inline Inspection*/}
					
					{/*Long Range UT Inspection*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('long_range_ut_inspection')}
						  onMouseEnter={() => handleMouseEnter('long_range_ut_inspectionhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'long_range_ut_inspectionhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} 
								class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Long Range Ultrasonic Testing (LRUT) adalah metode inspeksi non-destruktif yang digunakan untuk memeriksa pipa gas dan struktur logam lainnya dengan menggunakan gelombang ultrasonik untuk mendeteksi kerusakan, korosi, atau degradasi material dari jarak jauh. Metode ini sangat berguna untuk melakukan survei pipa panjang tanpa perlu membuka atau memindahkan pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Long Range Ultrasonic Testing (LRUT)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['long_range_ut_inspection'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[93] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refip" onClick={() => tampilIfr(2,'refip','bk_refip','ttp_refip')}>ASME B31G-2012</a>
														<a ref={(el) => (allIframesRef.current[93] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refip" onClick={() => tampilIfr(2,'refip','ttp_refip','bk_refip')}><b>ASME B31G-2012</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['long_range_ut_inspection_lastreport'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport_SSWJ_2012')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2012
													</li>
												</ul>
											</div>
											{openSections['long_range_ut_inspection_lastreport_SSWJ_2012'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>

													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[79] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','bk_IP2012_GRS_PGD','ttp_IP2012_GRS_PGD')}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</a>
																		<a ref={(el) => (allIframesRef.current[79] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','ttp_IP2012_GRS_PGD','bk_IP2012_GRS_PGD')}><b style={{color: 'black'}}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[80] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','bk_IP2012_PGD_TBB','ttp_IP2012_PGD_TBB')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</a>
																		<a ref={(el) => (allIframesRef.current[80] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','ttp_IP2012_PGD_TBB','bk_IP2012_PGD_TBB')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[81] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','bk_IP2012_TBB_LBM','ttp_IP2012_TBB_LBM')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</a>
																		<a ref={(el) => (allIframesRef.current[81] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','ttp_IP2012_TBB_LBM','bk_IP2012_TBB_LBM')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											  
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport_SSWJ_2016')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2016
													</li>
												</ul>
											</div>
											{openSections['long_range_ut_inspection_lastreport_SSWJ_2016'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[82] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1')}>Final Report Section 1 ( 36" Grissik-Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[82] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1')}><b style={{color: 'black'}}>Final Report Section 1 ( 36" Grissik-Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[83] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5')}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</a>
																		<a ref={(el) => (allIframesRef.current[83] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5')}><b style={{color: 'black'}}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[84] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3')}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</a>
																		<a ref={(el) => (allIframesRef.current[84] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3')}><b style={{color: 'black'}}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[85] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2')}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[85] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2')}><b style={{color: 'black'}}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[86] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_TBB_LBM_SECTION4" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4')}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</a>
																		<a ref={(el) => (allIframesRef.current[86] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4')}><b style={{color: 'black'}}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport_SSWJ_2017')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2017
													</li>
												</ul>
											</div>
											{openSections['long_range_ut_inspection_lastreport_SSWJ_2017'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[87] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3')}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</a>
																		<a ref={(el) => (allIframesRef.current[87] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[88] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3')}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</a>
																		<a ref={(el) => (allIframesRef.current[88] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3','bk_IP2017_LBM_MBK_PHASE2_Section3')}><b style={{color: 'black'}}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[89] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1')}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</a>
																		<a ref={(el) => (allIframesRef.current[89] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[90] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1')}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</a>
																		<a ref={(el) => (allIframesRef.current[90] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1')}><b style={{color: 'black'}}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[91] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2')}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</a>
																		<a ref={(el) => (allIframesRef.current[91] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[92] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2')}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</a>
																		<a ref={(el) => (allIframesRef.current[92] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2')}><b style={{color: 'black'}}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport_SSWJ_2023')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2023
													</li>
												</ul>
											</div>
											{openSections['long_range_ut_inspection_lastreport_SSWJ_2023'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','bk_ReportILIGRS_PGD','ttp_ReportILIGRS_PGD')}>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[71] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','ttp_ReportILIGRS_PGD','bk_ReportILIGRS_PGD')}><b>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD')}>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[70] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD')}><b>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','bk_ReportILIPGD_TBB','ttp_ReportILIPGD_TBB')}>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</a>
																		<a ref={(el) => (allIframesRef.current[72] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','ttp_ReportILIPGD_TBB','bk_ReportILIPGD_TBB')}><b>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','bk_ReportILITBB_LBM','ttp_ReportILITBB_LBM')}>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</a>
																		<a ref={(el) => (allIframesRef.current[73] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','ttp_ReportILITBB_LBM','bk_ReportILITBB_LBM')}><b>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','bk_ReportILILBM_BJN','ttp_ReportILILBM_BJN')}>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</a>
																		<a ref={(el) => (allIframesRef.current[74] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','ttp_ReportILILBM_BJN','bk_ReportILILBM_BJN')}><b>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('long_range_ut_inspection_lastreport_SSWJ_2024')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2024
													</li>
												</ul>
											</div>
											{openSections['long_range_ut_inspection_lastreport_SSWJ_2024'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','bk_repot_1p_2024','ttp_repot_1p_2024')}>Repot IP 2024</a>
																		<a ref={(el) => (allIframesRef.current[15] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','ttp_repot_1p_2024','bk_repot_1p_2024')}><b>Repot IP 2024</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
										
										</div>
									
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Long Range UT Inspection*/}
					
					{/*Pipeline Patrol & Leak Survey*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_patrol_leak_survey')}
						  onMouseEnter={() => handleMouseEnter('pipeline_patrol_leak_surveyhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_patrol_leak_surveyhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Patrol & Leak Survey adalah metode pengawasan dan pemeriksaan yang digunakan untuk memantau pipa gas dan mendeteksi kebocoran atau masalah lain yang mungkin mempengaruhi operasional dan keselamatan sistem pipa. Metode ini penting untuk memastikan bahwa pipa gas berfungsi dengan aman dan efisien serta untuk mengidentifikasi masalah sebelum menjadi lebih serius.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Patrol & Leak Survey
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_patrol_leak_survey'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_ref'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 15px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[135] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','bk_DNVSTF101','ttp_DNVSTF101')}>DNV-ST-F101 Submarine pipeline systems</a>
															<a ref={(el) => (allIframesRef.current[135] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','ttp_DNVSTF101','bk_DNVSTF101')}><b style={{color: 'black'}}>DNV-ST-F101 Submarine pipeline systems</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[136] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
															<a ref={(el) => (allIframesRef.current[136] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
														</td>
													</tr>
												</table>
											</li>
										</ul>
										
									</div>
								)}
								
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('inline_inspection_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_patrol_leak_survey_lastreport'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['pipeline_patrol_leak_survey_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[147] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_Anomalies100depthlbmmbk" onClick={() => tampilIfr(2,'Anomalies100depthlbmmbk','bk_Anomalies100depthlbmmbk','ttp_Anomalies100depthlbmmbk')}>List of Anomalies 100 Depth LBM - MBK</a>
																	<a ref={(el) => (allIframesRef.current[147] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_Anomalies100depthlbmmbk" onClick={() => tampilIfr(2,'Anomalies100depthlbmmbk','ttp_Anomalies100depthlbmmbk','bk_Anomalies100depthlbmmbk')}><b>List of Anomalies 100 Depth LBM - MBK</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[137] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','bk_PIMSJAN24','ttp_PIMSJAN24')}>PIMS SSWJ Januari 2024</a>
																	<a ref={(el) => (allIframesRef.current[137] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','ttp_PIMSJAN24','bk_PIMSJAN24')}><b>PIMS SSWJ Januari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[138] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','bk_PIMSFEB24','ttp_PIMSFEB24')}>PIMS SSWJ Februari 2024</a>
																	<a ref={(el) => (allIframesRef.current[138] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','ttp_PIMSFEB24','bk_PIMSFEB24')}><b>PIMS SSWJ Februari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[139] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','bk_PIMSMAR24','ttp_PIMSMAR24')}>PIMS SSWJ Maret 2024</a>
																	<a ref={(el) => (allIframesRef.current[139] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','ttp_PIMSMAR24','bk_PIMSMAR24')}><b>PIMS SSWJ Maret 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[140] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','bk_PIMSAPR24','ttp_PIMSAPR24')}>PIMS SSWJ April 2024</a>
																	<a ref={(el) => (allIframesRef.current[140] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','ttp_PIMSAPR24','bk_PIMSAPR24')}><b>PIMS SSWJ April 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[141] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','bk_PIMSMEI24','ttp_PIMSMEI24')}>PIMS SSWJ Mei 2024</a>
																	<a ref={(el) => (allIframesRef.current[141] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','ttp_PIMSMEI24','bk_PIMSMEI24')}><b>PIMS SSWJ Mei 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[142] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','bk_PIMSJUNI24','ttp_PIMSJUNI24')}>PIMS SSWJ Juni 2024</a>
																	<a ref={(el) => (allIframesRef.current[142] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','ttp_PIMSJUNI24','bk_PIMSJUNI24')}><b>PIMS SSWJ Juni 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[143] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','bk_PIMSJULI24','ttp_PIMSJULI24')}>PIMS SSWJ Juli 2024</a>
																	<a ref={(el) => (allIframesRef.current[143] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','ttp_PIMSJULI24','bk_PIMSJULI24')}><b>PIMS SSWJ Juli 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[144] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','bk_PIMSAGU24','ttp_PIMSAGU24')}>PIMS SSWJ Agustus 2024</a>
																	<a ref={(el) => (allIframesRef.current[144] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','ttp_PIMSAGU24','bk_PIMSAGU24')}><b>PIMS SSWJ Agustus 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[145] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','bk_PIMSSEPT24','ttp_PIMSSEPT24')}>PIMS SSWJ September 2024</a>
																	<a ref={(el) => (allIframesRef.current[145] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','ttp_PIMSSEPT24','bk_PIMSSEPT24')}><b>PIMS SSWJ September 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[146] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','bk_PIMSOKT24','ttp_PIMSOKT24')}>PIMS SSWJ Oktober 2024</a>
																	<a ref={(el) => (allIframesRef.current[146] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','ttp_PIMSOKT24','bk_PIMSOKT24')}><b>PIMS SSWJ Oktober 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['pipeline_patrol_leak_survey_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['pipeline_patrol_leak_survey_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_patrol_leak_survey_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['pipeline_patrol_leak_survey_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
									</div>
								
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Patrol & Leak Survey*/}
					
					{/*Barrier Activities*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('barrier_activities')}
						  onMouseEnter={() => handleMouseEnter('barrier_activitieshover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'barrier_activitieshover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
								  ...styles.divsides3,
								  marginTop: `-${scrollPosition}px`,
								}}>

									Barrier Activities pada pipa gas adalah tindakan yang dilakukan untuk menetapkan dan menjaga batasan atau penghalang yang melindungi pipa dari potensi risiko dan bahaya yang dapat mempengaruhi integritas dan keamanan operasionalnya. Konsep ini mencakup berbagai strategi dan tindakan yang bertujuan untuk mencegah atau memitigasi masalah yang dapat menyebabkan kerusakan atau kecelakaan pada sistem pipa gas.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Barrier Activities
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['barrier_activities'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_ref'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[148] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
															<a ref={(el) => (allIframesRef.current[148] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
														</td>
													</tr>
												</table>
											</li>
										</ul>
										
									</div>
								
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['barrier_activities_lastreport'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('barrier_activities_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['barrier_activities_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[149] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','bk_PIMSJAN24','ttp_PIMSJAN24')}>PIMS SSWJ Januari 2024</a>
																	<a ref={(el) => (allIframesRef.current[149] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','ttp_PIMSJAN24','bk_PIMSJAN24')}><b>PIMS SSWJ Januari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[150] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','bk_PIMSFEB24','ttp_PIMSFEB24')}>PIMS SSWJ Februari 2024</a>
																	<a ref={(el) => (allIframesRef.current[150] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','ttp_PIMSFEB24','bk_PIMSFEB24')}><b>PIMS SSWJ Februari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[151] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','bk_PIMSMAR24','ttp_PIMSMAR24')}>PIMS SSWJ Maret 2024</a>
																	<a ref={(el) => (allIframesRef.current[151] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','ttp_PIMSMAR24','bk_PIMSMAR24')}><b>PIMS SSWJ Maret 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[152] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','bk_PIMSAPR24','ttp_PIMSAPR24')}>PIMS SSWJ April 2024</a>
																	<a ref={(el) => (allIframesRef.current[152] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','ttp_PIMSAPR24','bk_PIMSAPR24')}><b>PIMS SSWJ April 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[153] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','bk_PIMSMEI24','ttp_PIMSMEI24')}>PIMS SSWJ Mei 2024</a>
																	<a ref={(el) => (allIframesRef.current[153] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','ttp_PIMSMEI24','bk_PIMSMEI24')}><b>PIMS SSWJ Mei 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[154] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','bk_PIMSJUNI24','ttp_PIMSJUNI24')}>PIMS SSWJ Juni 2024</a>
																	<a ref={(el) => (allIframesRef.current[154] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','ttp_PIMSJUNI24','bk_PIMSJUNI24')}><b>PIMS SSWJ Juni 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[155] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','bk_PIMSJULI24','ttp_PIMSJULI24')}>PIMS SSWJ Juli 2024</a>
																	<a ref={(el) => (allIframesRef.current[155] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','ttp_PIMSJULI24','bk_PIMSJULI24')}><b>PIMS SSWJ Juli 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[156] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','bk_PIMSAGU24','ttp_PIMSAGU24')}>PIMS SSWJ Agustus 2024</a>
																	<a ref={(el) => (allIframesRef.current[156] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','ttp_PIMSAGU24','bk_PIMSAGU24')}><b>PIMS SSWJ Agustus 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[157] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','bk_PIMSSEPT24','ttp_PIMSSEPT24')}>PIMS SSWJ September 2024</a>
																	<a ref={(el) => (allIframesRef.current[157] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','ttp_PIMSSEPT24','bk_PIMSSEPT24')}><b>PIMS SSWJ September 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[158] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','bk_PIMSOKT24','ttp_PIMSOKT24')}>PIMS SSWJ Oktober 2024</a>
																	<a ref={(el) => (allIframesRef.current[158] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','ttp_PIMSOKT24','bk_PIMSOKT24')}><b>PIMS SSWJ Oktober 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('barrier_activities_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['barrier_activities_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('barrier_activities_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['barrier_activities_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('barrier_activities_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['barrier_activities_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
										
									</div>
								
								)}
							</div>
						 
						 </div>
						)}
					</div>
					{/*Barrier Activities*/}
					
					{/*Permanen Repair*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('permanen_repair')}
						  onMouseEnter={() => handleMouseEnter('permanen_repairhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'permanen_repairhover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Permanen Repair pada pipa gas adalah tindakan perbaikan jangka panjang yang dilakukan untuk memperbaiki kerusakan atau masalah pada pipa secara permanen, memastikan bahwa pipa berfungsi kembali dengan aman dan dapat diandalkan. Tindakan ini berbeda dari perbaikan sementara yang hanya mengatasi masalah untuk jangka waktu pendek. Repair permanen dirancang untuk memulihkan integritas struktural pipa dan memastikan bahwa pipa dapat beroperasi tanpa risiko kegagalan di masa depan.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Permanen Repair
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['permanen_repair'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_ref'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[66] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','bk_DNVSTF101','ttp_DNVSTF101')}>DNV-ST-F101 Submarine pipeline systems</a>
															<a ref={(el) => (allIframesRef.current[66] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','ttp_DNVSTF101','bk_DNVSTF101')}><b style={{color: 'black'}}>DNV-ST-F101 Submarine pipeline systems</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[67] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','bk_DNVRPF113','ttp_DNVRPF113')}>DNV-RP-F113 Pipeline Subsea Repair</a>
															<a ref={(el) => (allIframesRef.current[67] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','ttp_DNVRPF113','bk_DNVRPF113')}><b style={{color: 'black'}}>DNV-RP-F113 Pipeline Subsea Repair</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									  
									  
									  </div>
									  
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('barrier_activities_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('permanen_repair_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['permanen_repair_lastreport'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('permanen_repair_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['permanen_repair_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														Aligment Sheet GC
													</li>
												</ul>
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('ermanen_repair_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['ermanen_repair_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('ermanen_repair_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['ermanen_repair_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('ermanen_repair_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['ermanen_repair_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
										
									</div>
								
								)}
								
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Permanen Repair*/}
					
					{/*Geohazard Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('geohazard_assessment')}
						  onMouseEnter={() => handleMouseEnter('geohazard_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'geohazard_assessmenthover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Geohazard Assessment pada pipa gas adalah proses evaluasi dan identifikasi potensi bahaya geologi yang dapat mempengaruhi integritas pipa. Tujuan dari penilaian ini adalah untuk memahami risiko yang terkait dengan kondisi geologis di sekitar jalur pipa dan mengambil langkah-langkah pencegahan untuk melindungi pipa dari kerusakan atau kegagalan yang disebabkan oleh faktor-faktor geologi.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Geohazard Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['geohazard_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_ref'] && (
									  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[68] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_Jaringkontrolhorizontal" onClick={() => tampilIfr(2,'Jaringkontrolhorizontal','bk_Jaringkontrolhorizontal','ttp_Jaringkontrolhorizontal')}>Jaring Kontrol Horizontal</a>
															<a ref={(el) => (allIframesRef.current[68] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_Jaringkontrolhorizontal" onClick={() => tampilIfr(2,'Jaringkontrolhorizontal','ttp_Jaringkontrolhorizontal','bk_Jaringkontrolhorizontal')}><b style={{color: 'black'}}>Jaring Kontrol Horizontal</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[69] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_Jaringkontrolvertikal" onClick={() => tampilIfr(2,'Jaringkontrolvertikal','bk_Jaringkontrolvertikal','ttp_Jaringkontrolvertikal')}>Jaring kontrol Vertikal dengan Metode Sipatdatar</a>
															<a ref={(el) => (allIframesRef.current[69] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_Jaringkontrolvertikal" onClick={() => tampilIfr(2,'Jaringkontrolvertikal','ttp_Jaringkontrolvertikal','bk_Jaringkontrolvertikal')}><b style={{color: 'black'}}>Jaring kontrol Vertikal dengan Metode Sipatdatar</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									  
									  
									  </div>
									  
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('geohazard_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['geohazard_assessment_lastreport'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('geohazard_assessment_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['geohazard_assessment_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[20] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_reportgehazard" onClick={() => tampilIfr(2,'reportgehazard','bk_reportgehazard','ttp_reportgehazard')}>Kajian Teknis - Analisis Hasil Geohazard Assessment pada Stasiun Bojonegara & Muara Bekasi Tahun 2023</a>
																	<a ref={(el) => (allIframesRef.current[20] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_reportgehazard" onClick={() => tampilIfr(2,'reportgehazard','ttp_reportgehazard','bk_reportgehazard')}><b>Kajian Teknis - Analisis Hasil Geohazard Assessment pada Stasiun Bojonegara & Muara Bekasi Tahun 2023</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('geohazard_assessment_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['geohazard_assessment_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('geohazard_assessment_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['geohazard_assessment_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('geohazard_assessment_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['geohazard_assessment_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


									
										
									</div>
								
								) }
							</div>
						 
						 </div>
						)}
					</div>
					{/*Geohazard Assessment*/}
					
					{/*Field Joint Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('field_joint_assessment')}
						  onMouseEnter={() => handleMouseEnter('field_joint_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'field_joint_assessmenthover' && (
							 <>
							  <div style={{
								  ...styles.divsides2,
								  marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Field Joint Assessment pada pipa gas adalah proses evaluasi dan pemeriksaan sambungan pipa yang dilakukan di lapangan. Field joints adalah sambungan atau penyambungan antara potongan-potongan pipa yang dilakukan di lokasi pemasangan. Proses ini penting untuk memastikan bahwa sambungan pipa di lapangan memenuhi standar kualitas dan keselamatan yang diperlukan.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Field Joint Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['field_joint_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_ref'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[94] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','bk_IHOSFHS','ttp_IHOSFHS')}>International Hydrographic Organization Standards for Hydrographic Surveys</a>
															<a ref={(el) => (allIframesRef.current[94] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','ttp_IHOSFHS','bk_IHOSFHS')}><b style={{color: 'black'}}>International Hydrographic Organization Standards for Hydrographic Surveys</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[95] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','bk_DNVSTF101','ttp_DNVSTF101')}>DNV-ST-F101 Submarine pipeline systems</a>
															<a ref={(el) => (allIframesRef.current[95] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','ttp_DNVSTF101','bk_DNVSTF101')}><b style={{color: 'black'}}>DNV-ST-F101 Submarine pipeline systems</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[96] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','bk_DNVRPF113','ttp_DNVRPF113')}>DNV-RP-F113 Pipeline Subsea Repair</a>
															<a ref={(el) => (allIframesRef.current[96] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','ttp_DNVRPF113','bk_DNVRPF113')}><b style={{color: 'black'}}>DNV-RP-F113 Pipeline Subsea Repair</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									
									</div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('field_joint_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['field_joint_assessment_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('field_joint_assessment_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['field_joint_assessment_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('field_joint_assessment_lastreport_SSWJ_2013')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2013
														</li>
													</ul>
												</div>
												{openSections['field_joint_assessment_lastreport_SSWJ_2013'] && (
												  
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
														<ul>
															<li>
																<table>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[45] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PSDA_KFJPO_2013" onClick={() => tampilIfr(2,'PSDA_KFJPO_2013','bk_PSDA_KFJPO_2013','ttp_PSDA_KFJPO_2013')}>Pekerjaan Studi dan Analisa Kondisi Field Joint Pipa Onshore</a>
																			<a ref={(el) => (allIframesRef.current[45] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PSDA_KFJPO_2013" onClick={() => tampilIfr(2,'PSDA_KFJPO_2013','ttp_PSDA_KFJPO_2013','bk_PSDA_KFJPO_2013')}><b style={{color: 'black'}}>Pekerjaan Studi dan Analisa Kondisi Field Joint Pipa Onshore</b></a>
																		</td>
																	</tr>
																</table>
															</li>
														</ul>
													
													</div>
												)}
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('field_joint_assessment_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['field_joint_assessment_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('field_joint_assessment_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['field_joint_assessment_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('field_joint_assessment_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['field_joint_assessment_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
									</div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Field Joint Assessment*/}
					
					{/*Survey Foto Udara (Fotogrametri) & LIDAR*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar')}
						  onMouseEnter={() => handleMouseEnter('survey_foto_udara_fotogrametri_lidarhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'survey_foto_udara_fotogrametri_lidarhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Survey Foto Udara (Fotogrametri) dan LIDAR adalah teknik pemantauan dan pemetaan yang digunakan dalam industri pipa gas untuk mengumpulkan data akurat mengenai jalur pipa dan area sekitarnya dari udara. Kedua metode ini memberikan informasi yang sangat berguna untuk perencanaan, pemeliharaan, dan manajemen pipa gas.

								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Survey Foto Udara (Fotogrametri) & LIDAR
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['survey_foto_udara_fotogrametri_lidar'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_ref'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<ul>
											<li>
												<table>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[133] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
															<a ref={(el) => (allIframesRef.current[133] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
														</td>
													</tr>
												</table>
											</li>
										</ul>
									</div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['survey_foto_udara_fotogrametri_lidar_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['survey_foto_udara_fotogrametri_lidar_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport_SSWJ_2014')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2014
														</li>
													</ul>
												</div>
												{openSections['survey_foto_udara_fotogrametri_lidar_lastreport_SSWJ_2014'] && (
												  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												  
													<ul style={{margin: '-15px 0px 10px 10px'}}>
														<li>
															<table>
																<tbody>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[48] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_Fotogrametri2014" onClick={() => tampilIfr(2,'Fotogrametri2014','bk_Fotogrametri2014','ttp_Fotogrametri2014')}>Survei dan Pemetaan Udara (Fotogrametri)</a>
																		<a ref={(el) => (allIframesRef.current[48] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_Fotogrametri2014" onClick={() => tampilIfr(2,'Fotogrametri2014','ttp_Fotogrametri2014','bk_Fotogrametri2014')}><b style={{color: 'black'}}>Survei dan Pemetaan Udara (Fotogrametri)</b></a>
																	</td>
																</tr>
																</tbody>
															</table>
														</li>
													</ul>
												  
												  </div>
												)}
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['survey_foto_udara_fotogrametri_lidar_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['survey_foto_udara_fotogrametri_lidar_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['survey_foto_udara_fotogrametri_lidar_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
										
									</div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Survey Foto Udara (Fotogrametri) & LIDAR*/}
					
					{/*Coating Defect Assessment and Repair*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('coating_defect_sssessment_repair')}
						  onMouseEnter={() => handleMouseEnter('coating_defect_sssessment_repairhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'coating_defect_sssessment_repairhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Coating Defect Assessment and Repair pada pipa gas adalah proses untuk menilai dan memperbaiki kerusakan pada lapisan pelindung (coating) yang melindungi pipa dari korosi dan faktor lingkungan lainnya. Coating pada pipa gas memainkan peran penting dalam melindungi pipa dari kerusakan eksternal yang dapat mempengaruhi integritas dan umur pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Coating Defect Assessment and Repair
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['coating_defect_sssessment_repair'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coating_defect_sssessment_repair_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_ref'] && (
									
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<ul>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[134] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_NACESP01692007" onClick={() => tampilIfr(2,'NACESP01692007','bk_NACESP01692007','ttp_NACESP01692007')}>NACE-SP0169-2007</a>
															<a ref={(el) => (allIframesRef.current[134] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_NACESP01692007" onClick={() => tampilIfr(2,'NACESP01692007','ttp_NACESP01692007','bk_NACESP01692007')}><b style={{color: 'black'}}>NACE-SP0169-2007</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									
									</div>
								
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('scoating_defect_sssessment_repair_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('survey_foto_udara_fotogrametri_lidar_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coating_defect_sssessment_repair_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['coating_defect_sssessment_repair_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('coating_defect_sssessment_repair_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['coating_defect_sssessment_repair_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('coating_defect_sssessment_repair_SSWJ_2012')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2012
														</li>
													</ul>
												</div>
												{openSections['coating_defect_sssessment_repair_SSWJ_2012'] && (
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
													
														<ul style={{margin: '-15px 0px 10px 10px'}}>
															<li>
																<table>
																	<tbody>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[46] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LAP_AKH_CDA_2012" onClick={() => tampilIfr(2,'LAP_AKH_CDA_2012','bk_LAP_AKH_CDA_2012','ttp_LAP_AKH_CDA_2012')}>Pekerjaan Jasa Coating Deffect Assement And Repair</a>
																			<a ref={(el) => (allIframesRef.current[46] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LAP_AKH_CDA_2012" onClick={() => tampilIfr(2,'LAP_AKH_CDA_2012','ttp_LAP_AKH_CDA_2012','bk_LAP_AKH_CDA_2012')}><b style={{color: 'black'}}>Pekerjaan Jasa Coating Deffect Assement And Repair</b></a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</li>
														</ul>
													
													</div>
												)}
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coating_defect_sssessment_repair_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['coating_defect_sssessment_repair_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coating_defect_sssessment_repair_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['coating_defect_sssessment_repair_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coating_defect_sssessment_repair_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['coating_defect_sssessment_repair_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}


										
								  
									</div>
								)}	
								  
							</div>
						 
						 </div>
						)}
					</div>
					{/*Coating Defect Assessment and Repair*/}
					
					{/*Pipeline Defect Assessment and Reinforcement*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement')}
						  onMouseEnter={() => handleMouseEnter('pipeline_defect_assessment_reinforcementhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_defect_assessment_reinforcementhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Defect Assessment and Reinforcement pada pipa gas adalah proses yang melibatkan identifikasi, evaluasi, dan perbaikan kerusakan atau kekurangan pada pipa untuk memastikan integritas dan keamanan sistem pipa gas. Proses ini meliputi penilaian terhadap defect atau cacat yang ada, serta penerapan langkah-langkah penguatan (reinforcement) untuk memperbaiki dan memperkuat pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Defect Assessment and Reinforcement
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_defect_assessment_reinforcement'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_ref'] && (
								
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[97] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','bk_IHOSFHS','ttp_IHOSFHS')}>International Hydrographic Organization Standards for Hydrographic Surveys</a>
															<a ref={(el) => (allIframesRef.current[97] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IHOSFHS" onClick={() => tampilIfr(2,'IHOSFHS','ttp_IHOSFHS','bk_IHOSFHS')}><b style={{color: 'black'}}>International Hydrographic Organization Standards for Hydrographic Surveys</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[98] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','bk_DNVSTF101','ttp_DNVSTF101')}>DNV-ST-F101 Submarine pipeline systems</a>
															<a ref={(el) => (allIframesRef.current[98] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVSTF101" onClick={() => tampilIfr(2,'DNVSTF101','ttp_DNVSTF101','bk_DNVSTF101')}><b style={{color: 'black'}}>DNV-ST-F101 Submarine pipeline systems</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
										
										<ul style={{margin: '-15px 0px 10px 10px'}}>
											<li>
												<table>
													<tbody>
													<tr>
														<td valign="top">
															<span className="lbn" style={{ fontSize: '12px'}}></span>
														</td>
														<td>
															<a ref={(el) => (allIframesRefbk.current[99] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','bk_DNVRPF113','ttp_DNVRPF113')}>DNV-RP-F113 Pipeline Subsea Repair</a>
															<a ref={(el) => (allIframesRef.current[99] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF113" onClick={() => tampilIfr(2,'DNVRPF113','ttp_DNVRPF113','bk_DNVRPF113')}><b style={{color: 'black'}}>DNV-RP-F113 Pipeline Subsea Repair</b></a>
														</td>
													</tr>
													</tbody>
												</table>
											</li>
										</ul>
									
									</div>
								)}
								
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_defect_assessment_reinforcement_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['pipeline_defect_assessment_reinforcement_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_SSWJ_2010')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2010
														</li>
													</ul>
												</div>
												{openSections['pipeline_defect_assessment_reinforcement_SSWJ_2010'] && (
													
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
													
														<ul style={{margin: '-15px 0px 10px 10px'}}>
															<li>
																<table>
																	<tbody>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[47] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_LP_PDA_KP_68_5_PGD_TBB" onClick={() => tampilIfr(2,'LP_PDA_KP_68_5_PGD_TBB','bk_LP_PDA_KP_68_5_PGD_TBB','ttp_LP_PDA_KP_68_5_PGD_TBB')}>Laporan Pipeline Defect Assessment KP 68,5 PGD -TBB Phase 2</a>
																			<a ref={(el) => (allIframesRef.current[47] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_LP_PDA_KP_68_5_PGD_TBB" onClick={() => tampilIfr(2,'LP_PDA_KP_68_5_PGD_TBB','ttp_LP_PDA_KP_68_5_PGD_TBB','bk_LP_PDA_KP_68_5_PGD_TBB')}><b style={{color: 'black'}}>Laporan Pipeline Defect Assessment KP 68,5 PGD -TBB Phase 2</b></a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</li>
														</ul>
													
													</div>
												
												)}
											
											</div>
											
										)}
									
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['pipeline_defect_assessment_reinforcement_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['pipeline_defect_assessment_reinforcement_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_defect_assessment_reinforcement_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['pipeline_defect_assessment_reinforcement_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
									
									</div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Defect Assessment and Reinforcement*/}
					
					{/*Pipeline Risk Assessment*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('pipeline_risk_assessment')}
						  onMouseEnter={() => handleMouseEnter('pipeline_risk_assessmenthover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'pipeline_risk_assessmenthover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Pipeline Risk Assessment pada pipa gas adalah proses sistematis untuk mengidentifikasi, menganalisis, dan menilai risiko yang dapat mempengaruhi keselamatan, keandalan, dan operasional sistem pipa gas. Tujuan dari penilaian risiko ini adalah untuk memahami potensi ancaman dan dampaknya serta mengembangkan strategi mitigasi untuk mengurangi risiko tersebut.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Pipeline Risk Assessment
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['pipeline_risk_assessment'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_ref'] && (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[100] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_asmeb318" onClick={() => tampilIfr(2,'asmeb318','bk_asmeb318','ttp_asmeb318')}>ASME B-318</a>
														<a ref={(el) => (allIframesRef.current[100] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_asmeb318" onClick={() => tampilIfr(2,'asmeb318','ttp_asmeb318','bk_asmeb318')}><b>ASME B-318</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{margin: '-15px 0px 15px 0px'}}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[101] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_refmuhlbauer" onClick={() => tampilIfr(2,'refmuhlbauer','bk_refmuhlbauer','ttp_refmuhlbauer')}>Pipeline Risk Assessment - The Defenitive Approach and its Role in Risk Management (W. Kent Muhlbauer)</a>
														<a ref={(el) => (allIframesRef.current[101] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_refmuhlbauer" onClick={() => tampilIfr(2,'refmuhlbauer','ttp_refmuhlbauer','bk_refmuhlbauer')}><b>Pipeline Risk Assessment - The Defenitive Approach and its Role in Risk Management (W. Kent Muhlbauer)</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['pipeline_risk_assessment_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['pipeline_risk_assessment_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<ul>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[102] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','bk_PIMSJAN24','ttp_PIMSJAN24')}>PIMS SSWJ Januari 2024</a>
																	<a ref={(el) => (allIframesRef.current[102] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJAN24" onClick={() => tampilIfr(2,'PIMSJAN24','ttp_PIMSJAN24','bk_PIMSJAN24')}><b>PIMS SSWJ Januari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[103] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','bk_PIMSFEB24','ttp_PIMSFEB24')}>PIMS SSWJ Februari 2024</a>
																	<a ref={(el) => (allIframesRef.current[103] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSFEB24" onClick={() => tampilIfr(2,'PIMSFEB24','ttp_PIMSFEB24','bk_PIMSFEB24')}><b>PIMS SSWJ Februari 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[104] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','bk_PIMSMAR24','ttp_PIMSMAR24')}>PIMS SSWJ Maret 2024</a>
																	<a ref={(el) => (allIframesRef.current[104] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMAR24" onClick={() => tampilIfr(2,'PIMSMAR24','ttp_PIMSMAR24','bk_PIMSMAR24')}><b>PIMS SSWJ Maret 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[105] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','bk_PIMSAPR24','ttp_PIMSAPR24')}>PIMS SSWJ April 2024</a>
																	<a ref={(el) => (allIframesRef.current[105] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAPR24" onClick={() => tampilIfr(2,'PIMSAPR24','ttp_PIMSAPR24','bk_PIMSAPR24')}><b>PIMS SSWJ April 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[106] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','bk_PIMSMEI24','ttp_PIMSMEI24')}>PIMS SSWJ Mei 2024</a>
																	<a ref={(el) => (allIframesRef.current[106] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSMEI24" onClick={() => tampilIfr(2,'PIMSMEI24','ttp_PIMSMEI24','bk_PIMSMEI24')}><b>PIMS SSWJ Mei 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[107] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','bk_PIMSJUNI24','ttp_PIMSJUNI24')}>PIMS SSWJ Juni 2024</a>
																	<a ref={(el) => (allIframesRef.current[107] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJUNI24" onClick={() => tampilIfr(2,'PIMSJUNI24','ttp_PIMSJUNI24','bk_PIMSJUNI24')}><b>PIMS SSWJ Juni 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[108] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','bk_PIMSJULI24','ttp_PIMSJULI24')}>PIMS SSWJ Juli 2024</a>
																	<a ref={(el) => (allIframesRef.current[108] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSJULI24" onClick={() => tampilIfr(2,'PIMSJULI24','ttp_PIMSJULI24','bk_PIMSJULI24')}><b>PIMS SSWJ Juli 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[109] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','bk_PIMSAGU24','ttp_PIMSAGU24')}>PIMS SSWJ Agustus 2024</a>
																	<a ref={(el) => (allIframesRef.current[109] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSAGU24" onClick={() => tampilIfr(2,'PIMSAGU24','ttp_PIMSAGU24','bk_PIMSAGU24')}><b>PIMS SSWJ Agustus 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[110] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','bk_PIMSSEPT24','ttp_PIMSSEPT24')}>PIMS SSWJ September 2024</a>
																	<a ref={(el) => (allIframesRef.current[110] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSSEPT24" onClick={() => tampilIfr(2,'PIMSSEPT24','ttp_PIMSSEPT24','bk_PIMSSEPT24')}><b>PIMS SSWJ September 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
												
												<ul style={{margin: '-15px 0px 15px 0px'}}>
													<li>
														<table>
															<tr>
																<td valign="top">
																	<span className="lbn" style={{ fontSize: '12px'}}></span>
																</td>
																<td>
																	<a ref={(el) => (allIframesRefbk.current[112] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','bk_PIMSOKT24','ttp_PIMSOKT24')}>PIMS SSWJ Oktober 2024</a>
																	<a ref={(el) => (allIframesRef.current[112] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PIMSOKT24" onClick={() => tampilIfr(2,'PIMSOKT24','ttp_PIMSOKT24','bk_PIMSOKT24')}><b>PIMS SSWJ Oktober 2024</b></a>
																</td>
															</tr>
														</table>
													</li>
												</ul>
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['pipeline_risk_assessment_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['pipeline_risk_assessment_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('pipeline_risk_assessment_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['pipeline_risk_assessment_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
									</div>
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Pipeline Risk Assessment*/}
					
					{/*Corrosion Underwater*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('corrosion_underwater')}
						  onMouseEnter={() => handleMouseEnter('corrosion_underwaterhover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'corrosion_underwaterhover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Corrosion Underwater pada pipa gas adalah proses korosi yang terjadi pada bagian pipa yang terendam di bawah air, seperti pipa yang melewati sungai, danau, atau laut. Korosi bawah air ini dapat menjadi masalah serius karena dapat mengakibatkan penurunan integritas struktural pipa, yang berpotensi menyebabkan kebocoran atau kegagalan pipa.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Corrosion Underwater
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['corrosion_underwater'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[10] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_api579" onClick={() => tampilIfr(2,'api579','bk_api579','ttp_api579')}>API 579-1-2021</a>
														<a ref={(el) => (allIframesRef.current[10] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_api579" onClick={() => tampilIfr(2,'api579','ttp_api579','bk_api579')}><b>API 579-1-2021</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
									
									<ul style={{ margin: '-15px 0px 10px 0px', fontSize: '13px' }}>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[11] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_DNVRPF101" onClick={() => tampilIfr(2,'DNVRPF101','bk_DNVRPF101','ttp_DNVRPF101')}>DNV-RP-F101</a>
														<a ref={(el) => (allIframesRef.current[11] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_DNVRPF101" onClick={() => tampilIfr(2,'DNVRPF101','ttp_DNVRPF101','bk_DNVRPF101')}><b>DNV-RP-F101</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('corrosion_underwater_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['corrosion_underwater_lastreport'] && (
								  
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('corrosion_underwater_lastreport_SSWJ_2012')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2012
													</li>
												</ul>
											</div>
											{openSections['corrosion_underwater_lastreport_SSWJ_2012'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>

													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[113] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','bk_IP2012_GRS_PGD','ttp_IP2012_GRS_PGD')}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</a>
																		<a ref={(el) => (allIframesRef.current[113] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_GRS_PGD" onClick={() => tampilIfr(2,'IP2012_GRS_PGD','ttp_IP2012_GRS_PGD','bk_IP2012_GRS_PGD')}><b style={{color: 'black'}}>Final Inline Inspection Report SBU Transmisi Sumatera Jawa 36” Gas Pipeline Grissik Gathering Station to Pagardewa Compressor Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[114] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','bk_IP2012_PGD_TBB','ttp_IP2012_PGD_TBB')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</a>
																		<a ref={(el) => (allIframesRef.current[114] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_PGD_TBB" onClick={() => tampilIfr(2,'IP2012_PGD_TBB','ttp_IP2012_PGD_TBB','bk_IP2012_PGD_TBB')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Pagardewa Station to Terbanggi Besar Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[115] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','bk_IP2012_TBB_LBM','ttp_IP2012_TBB_LBM')}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</a>
																		<a ref={(el) => (allIframesRef.current[115] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2012_TBB_LBM" onClick={() => tampilIfr(2,'IP2012_TBB_LBM','ttp_IP2012_TBB_LBM','bk_IP2012_TBB_LBM')}><b style={{color: 'black'}}>SBU Transmisi Sumatera Jawa 32” Gas Pipeline Terbanggi Besar Station to Labuhan Meringgai Station</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											  
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('corrosion_underwater_lastreport_SSWJ_2016')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2016
													</li>
												</ul>
											</div>
											{openSections['corrosion_underwater_lastreport_SSWJ_2016'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[116] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1')}>Final Report Section 1 ( 36" Grissik-Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[116] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_GRS_PGD_SECTION1" onClick={() => tampilIfr(2,'IP2016_GRS_PGD_SECTION1','ttp_IP2016_GRS_PGD_SECTION1','bk_IP2016_GRS_PGD_SECTION1')}><b style={{color: 'black'}}>Final Report Section 1 ( 36" Grissik-Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[117] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5')}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</a>
																		<a ref={(el) => (allIframesRef.current[117] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_LBM_BJN_SECTION5" onClick={() => tampilIfr(2,'IP2016_LBM_BJN_SECTION5','ttp_IP2016_LBM_BJN_SECTION5','bk_IP2016_LBM_BJN_SECTION5')}><b style={{color: 'black'}}>Final Report Section 5 ( 32" Labuhan Maringgai - Bojonegara )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[118] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3')}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</a>
																		<a ref={(el) => (allIframesRef.current[118] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_PGD_TBB_SECTION3" onClick={() => tampilIfr(2,'IP2016_PGD_TBB_SECTION3','ttp_IP2016_PGD_TBB_SECTION3','bk_IP2016_PGD_TBB_SECTION3')}><b style={{color: 'black'}}>Final Report Section 3 ( 32" Pagardewa - Terbanggi Besar )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[119] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2')}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</a>
																		<a ref={(el) => (allIframesRef.current[119] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_SPGPGD_PGD_SECTION2','ttp_IP2016_SPGPGD_PGD_SECTION2','bk_IP2016_SPGPGD_PGD_SECTION2')}><b style={{color: 'black'}}>Final Report Section 2 ( 28" SPG Pagardewa - Pagardewa )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[120] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2016_TBB_LBM_SECTION4" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4')}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</a>
																		<a ref={(el) => (allIframesRef.current[120] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2016_SPGPGD_PGD_SECTION2" onClick={() => tampilIfr(2,'IP2016_TBB_LBM_SECTION4','ttp_IP2016_TBB_LBM_SECTION4','bk_IP2016_TBB_LBM_SECTION4')}><b style={{color: 'black'}}>Final Report Section 4 ( 32" Terbanggi Besar - Labuhan Maringgai )</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('corrosion_underwater_lastreport_SSWJ_2017')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2017
													</li>
												</ul>
											</div>
											{openSections['corrosion_underwater_lastreport_SSWJ_2017'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[121] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3')}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</a>
																		<a ref={(el) => (allIframesRef.current[121] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_SECTION3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','ttp_IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_SECTION3')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 3 Labuhan Maringgai - Muara Bekasi</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[122] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_SECTION3','bk_IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3')}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</a>
																		<a ref={(el) => (allIframesRef.current[122] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_LBM_MBK_PHASE2_Section3" onClick={() => tampilIfr(2,'IP2017_LBM_MBK_PHASE2_Section3','ttp_IP2017_LBM_MBK_PHASE2_Section3','bk_IP2017_LBM_MBK_PHASE2_Section3')}><b style={{color: 'black'}}>Final Report LBM - MBK Phase 2 Section 3 2017 125-249</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[123] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1')}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</a>
																		<a ref={(el) => (allIframesRef.current[123] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_SECTION1','ttp_IP2017_PGD_TBB_SECTION1','bk_IP2017_PGD_TBB_SECTION1')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 1 Pagardewa - Terbanggi Besar</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[124] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1')}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</a>
																		<a ref={(el) => (allIframesRef.current[124] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_PGD_TBB_PHASE2_SECTION1" onClick={() => tampilIfr(2,'IP2017_PGD_TBB_PHASE2_SECTION1','ttp_IP2017_PGD_TBB_PHASE2_SECTION1','bk_IP2017_PGD_TBB_PHASE2_SECTION1')}><b style={{color: 'black'}}>Final Report PGD - TBB Phase 2 Section 1 2017 112-211</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[125] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2')}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</a>
																		<a ref={(el) => (allIframesRef.current[125] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_SECTION2','ttp_IP2017_TBB_LBM_SECTION2','bk_IP2017_TBB_LBM_SECTION2')}><b style={{color: 'black'}}>Final Report Intelligent Pigging Section 2 Terbanggi - Labuhan Maringgai</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px'}}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[126] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2')}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</a>
																		<a ref={(el) => (allIframesRef.current[126] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_IP2017_TBB_LBM_PHASE2_SECTION2" onClick={() => tampilIfr(2,'IP2017_TBB_LBM_PHASE2_SECTION2','ttp_IP2017_TBB_LBM_PHASE2_SECTION2','bk_IP2017_TBB_LBM_PHASE2_SECTION2')}><b style={{color: 'black'}}>Final Report TBB - LBM Phase 2 Section 2 2017 121-240</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('corrosion_underwater_lastreport_SSWJ_2023')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2023
													</li>
												</ul>
											</div>
											{openSections['corrosion_underwater_lastreport_SSWJ_2023'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[127] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','bk_ReportILIGRS_PGD','ttp_ReportILIGRS_PGD')}>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[127] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIGRS_PGD" onClick={() => tampilIfr(2,'ReportILIGRS_PGD','ttp_ReportILIGRS_PGD','bk_ReportILIGRS_PGD')}><b>Final Report ILI Section #1 (Grissik Station - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[128] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD')}>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</a>
																		<a ref={(el) => (allIframesRef.current[128] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILISPG_PGD_PGD" onClick={() => tampilIfr(2,'ReportILISPG_PGD_PGD','ttp_ReportILISPG_PGD_PGD','bk_ReportILISPG_PGD_PGD')}><b>Final Report ILI Section #2 (28" SPG Pagardewa - Pagardewa Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[129] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','bk_ReportILIPGD_TBB','ttp_ReportILIPGD_TBB')}>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</a>
																		<a ref={(el) => (allIframesRef.current[129] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILIPGD_TBB" onClick={() => tampilIfr(2,'ReportILIPGD_TBB','ttp_ReportILIPGD_TBB','bk_ReportILIPGD_TBB')}><b>Final Report ILI Section #3 (32" Pagardewa Station - Terbanggi Besar Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[130] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','bk_ReportILITBB_LBM','ttp_ReportILITBB_LBM')}>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</a>
																		<a ref={(el) => (allIframesRef.current[130] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILITBB_LBM" onClick={() => tampilIfr(2,'ReportILITBB_LBM','ttp_ReportILITBB_LBM','bk_ReportILITBB_LBM')}><b>Final Report ILI Section #4 (32" Terbanggi Besar Station - Labuhan Maringgai Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
													
													<ul style={{margin: '-15px 0px 15px 0px'}}>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[131] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','bk_ReportILILBM_BJN','ttp_ReportILILBM_BJN')}>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</a>
																		<a ref={(el) => (allIframesRef.current[131] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_ReportILILBM_BJN" onClick={() => tampilIfr(2,'ReportILILBM_BJN','ttp_ReportILILBM_BJN','bk_ReportILILBM_BJN')}><b>Final Report ILI Section #5 (32" Labuhan Maringgai Station - Bojonegara Station)</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
											<div
											  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											  onClick={() => toggleCollapse('corrosion_underwater_lastreport_SSWJ_2024')}
											>
												<ul>
													<li>
														<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
														2024
													</li>
												</ul>
											</div>
											{openSections['corrosion_underwater_lastreport_SSWJ_2024'] && (
											  
												<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
												
													<ul>
														<li>
															<table>
																<tr>
																	<td valign="top">
																		<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
																	</td>
																	<td>
																		<a ref={(el) => (allIframesRefbk.current[132] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','bk_repot_1p_2024','ttp_repot_1p_2024')}>Repot IP 2024</a>
																		<a ref={(el) => (allIframesRef.current[132] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_repot_1p_2024" onClick={() => tampilIfr(2,'repot_1p_2024','ttp_repot_1p_2024','bk_repot_1p_2024')}><b>Repot IP 2024</b></a>
																	</td>
																</tr>
															</table>
														</li>
													</ul>
												
												</div>
											)}
											
										
										</div>
								  
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Corrosion Underwater*/}
					
					{/*Certification of Inspection(COI/PLO)*/}
					<div style={{ }}>
						<div
						  style={{margin: '10px 0px 0px -30px' ,cursor: 'pointer', fontSize: '13px'}}
						  onClick={() => toggleCollapse('coiplo')}
						  onMouseEnter={() => handleMouseEnter('coiplohover')}
						  onMouseLeave={handleMouseLeave}
						>
						
						{hoveredItem === 'coiplohover' && (
							 <>
							  <div style={{
								...styles.divsides2,
								marginTop: `-${scrollPosition}px`,
							  }}>
								<i style={styles.dividesdalam} class="fa fa-arrow-right" aria-hidden="true"></i>
							  </div>
							  
								<div style={{
									...styles.divsides3,
									marginTop: `-${scrollPosition}px`,
								}}>

									Certification of Inspection (COI) / Pipeline Location and Orientation (PLO) adalah dokumen penting dalam industri pipa gas yang mengonfirmasi bahwa pipa telah diperiksa dan memenuhi standar kualitas serta regulasi yang berlaku.
									
								</div>
							 </>
						)}
						
							<ul>
								<li>
									<table>
									  <tbody>
										<tr>
										  <td valign="top">
											<span className="lbn" style={{ fontSize: '12px'}}></span>
										  </td>
										  <td>
											Certification of Inspection(COI/PLO)
										  </td>
										</tr>
									  </tbody>
									</table>
								</li>
							</ul>
						</div>
						{openSections['coiplo'] && (
						  <div style={{fontSize: '13px' }}>
							
							<div style={{ }}>
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_ref')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px'}}></span>
											Reference
										</li>
									</ul>
								</div>
								{openSections['coiplo_ref']&& (
								  <div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
									
									<ul>
										<li>
											<table>
												<tr>
													<td valign="top">
														<span className="lbn" style={{ fontSize: '12px'}}></span>
													</td>
													<td>
														<a ref={(el) => (allIframesRefbk.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_KEPDIRTENGLING_MIGAS" onClick={() => tampilIfr(2,'KEPDIRTENGLING_MIGAS','bk_KEPDIRTENGLING_MIGAS','ttp_KEPDIRTENGLING_MIGAS')}>KEPDIRTENGLING MIGAS</a>
														<a ref={(el) => (allIframesRef.current[12] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_KEPDIRTENGLING_MIGAS" onClick={() => tampilIfr(2,'KEPDIRTENGLING_MIGAS','ttp_KEPDIRTENGLING_MIGAS','bk_KEPDIRTENGLING_MIGAS')}><b>KEPDIRTENGLING MIGAS</b></a>
													</td>
												</tr>
											</table>
										</li>
									</ul>
								 
								 </div>
								)}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_calc')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Calculators
										</li>
									</ul>
								</div>
								{openSections['coiplo_calc']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_assessment')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Assessment
										</li>
									</ul>
								</div>
								{openSections['coiplo_assessment']}
								
								<div
								  style={{ margin: '-10px 0px 0px -10px', cursor: 'pointer', fontSize: '13px'}}
								  onClick={() => toggleCollapse('coiplo_lastreport')}
								>
									<ul>
										<li>
											<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
											Last Report
										</li>
									</ul>
								</div>
								{openSections['coiplo_lastreport'] && (
									<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
										
										<div
											 style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
											 onClick={() => toggleCollapse('coiplo_lastreport_SSWJ')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SSWJ
												</li>
											</ul>
										</div>
										{openSections['coiplo_lastreport_SSWJ'] && (
										  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('coiplo_lastreport_SSWJ_2009')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2009
														</li>
													</ul>
												</div>
												{openSections['coiplo_lastreport_SSWJ_2009'] && (
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
													  
														<ul style={{margin: '-15px 0px 10px 10px'}}>
															<li>
																<table>
																	<tbody>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[49] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_izinusaha1110" onClick={() => tampilIfr(2,'izinusaha1110','bk_izinusaha1110','ttp_izinusaha1110')}>Izin Usaha Pengangkutan No 1110</a>
																			<a ref={(el) => (allIframesRef.current[49] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_izinusaha1110" onClick={() => tampilIfr(2,'izinusaha1110','ttp_izinusaha1110','bk_izinusaha1110')}><b style={{color: 'black'}}>Izin Usaha Pengangkutan No 1110</b></a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</li>
														</ul>
													  
													</div>
												)}
												
												<div
												  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
												  onClick={() => toggleCollapse('coiplo_lastreport_SSWJ_2023')}
												>
													<ul>
														<li>
															<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
															2023
														</li>
													</ul>
												</div>
												{openSections['coiplo_lastreport_SSWJ_2023'] && (
													<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
													  
														<ul style={{margin: '-15px 0px 10px 10px'}}>
															<li>
																<table>
																	<tbody>
																	<tr>
																		<td valign="top">
																			<span className="lbn" style={{ fontSize: '12px'}}></span>
																		</td>
																		<td>
																			<a ref={(el) => (allIframesRefbk.current[50] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" id="bk_PLOSSWJ2023" onClick={() => tampilIfr(2,'PLOSSWJ2023','bk_PLOSSWJ2023','ttp_PLOSSWJ2023')}>PLO SSWJ 2023</a>
																			<a ref={(el) => (allIframesRef.current[50] = el)} style={{textDecoration: 'none', color: 'black'}} className="all_ifr" style={{display: 'none'}} id="ttp_PLOSSWJ2023" onClick={() => tampilIfr(2,'PLOSSWJ2023','ttp_PLOSSWJ2023','bk_PLOSSWJ2023')}><b style={{color: 'black'}}>PLO SSWJ 2023</b></a>
																		</td>
																	</tr>
																	</tbody>
																</table>
															</li>
														</ul>
													  
													</div>
												)}
											
											</div>
											
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coiplo_lastreport_SOR1')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORI
												</li>
											</ul>
										</div>
										{openSections['coiplo_lastreport_SOR1'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coiplo_lastreport_SOR2')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORII
												</li>
											</ul>
										</div>
										{openSections['coiplo_lastreport_SOR2'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
										<div
										  style={{ margin: '-10px 0px 0px 0px', cursor: 'pointer', fontSize: '13px'}}
										  onClick={() => toggleCollapse('coiplo_lastreport_SOR3')}
										>
											<ul>
												<li>
													<span className="lbn" style={{ fontSize: '12px', verticalAlign: 'top' }}></span>
													SORIII
												</li>
											</ul>
										</div>
										{openSections['coiplo_lastreport_SOR3'] && (
									  
											<div style={{ margin: '-10px 0px 0px 5px', fontSize: '13px' }}>
											
												
											
											</div>
										)}
										
									</div>
								
								)}
								
							</div>
						 
						 </div>
						)}
					</div>
					{/*Certification of Inspection(COI/PLO)*/}
				</div>
				</td>
				<td valign="top" style={{width:'calc(100vw - 200px)'}}>
					<div style={{width: '100%', height: '100%', overflow: 'auto'}}>
						<img 
							id="homealive_samping_sidemenu"
							src={`${process.env.PUBLIC_URL}/gambar/alive_poc.jpeg`} 
							alt="Alive home" 
							style={{width: '98%', height: '73vh', margin: '10px'}}
						/>
						
						<div ref={(el) => (allIframesRef.current[20] = el)} id="ifr1" style={{ width: '100%', height: '75vh', margin: '10px', display: 'none'}}>
							<iframe id="iframetbl1" src="" style={{width: '100%', height: '100%'}}></iframe>
						</div>
						
						<div ref={(el) => (allIframesRef.current[21] = el)} id="ifr2" style={{display: 'none', maxWidth: 'calc(100vw - 270px)'}} >
							<iframe id="iframetbl2" style={styles.iframetbl2css} src="" frameBorder="0" scrolling="no"></iframe>
						</div>
						
						<div ref={(el) => (allIframesRef.current[22] = el)} id="ifr3" style={{ display: 'none', ...styles.ifr3css }}>
							<div id="placeholder_tbl3" style={styles.placeholdertbl3css}>
								<iframe id="iframetbl3" src="" style={styles.iframetbl3css}></iframe>
							</div>
						</div>
				
						<div ref={(el) => (allIframesRef.current[23] = el)} id="ifr4" style={{display: 'none', marginTop: '80px', width: 'calc(100vw - 270px)', height: '700px'}}>
							<div id="placeholder_tbl4" style={{marginTop: '-65px', marginLeft: '-0px', maxHeight: '610px', overflow: 'hidden'}}>
								<iframe id="iframetbl4"style={{border: '0px none', height: '700px', marginTop: '-60px', width: 'calc(100vw - 270px)'}}  src="" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
							</div>
						</div>
						
						<div ref={(el) => (allIframesRef.current[135] = el)} id="muhlbaueronshoreweb" style={{display: 'none', marginTop: '-50px', position: 'absolute'}}>
							
							{activeView === 'onshore' && (
							<Muhlbauer_onshore key={muhlbauer_onshoreKey} />
							 )}
						
						</div>
						
						<div ref={(el) => (allIframesRef.current[140] = el)} id="muhlbaueroffshoreweb" style={{display: 'none', marginTop: '-50px', position: 'absolute'}}>
							 {activeView === 'offshore' && (
							<Muhlbauer_offshore key={muhlbauer_offshoreKey} />
							)}
						</div>
						
						<div ref={(el) => (allIframesRef.current[146] = el)} id="muhlbaueronshorepeweb" style={{display: 'none', marginTop: '-50px', position: 'absolute'}}>
							 {activeView === 'nonsteel' && (
							<Muhlbauer_pe key={Muhlbauer_peKey} />
							)}
						</div>
						
					</div>
				</td>
			</tr>
			</tbody>
			
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Nipoc;

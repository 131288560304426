import React, { useRef,useEffect,useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import Select from 'react-select';
import Logout from './Logout';
import { fetchUserData, logoutUser } from '../api';
import { auth, db } from '../firebase';
import { GoogleAuthProvider, signInWithPopup, onAuthStateChanged, signOut } from 'firebase/auth';
import { doc, getDoc } from 'firebase/firestore';
import $ from 'jquery';

const Photostock_pdg = () => {
	const [isHovered, setIsHovered] = useState(false);
	
	var photostocklist = {
        
        TAMBAK_LOROK:{
            "1":"1COmnP_PSUYFVyw71uhQEV3uTfKNM8ivd",
			"2":"151BIK5gvkgpOaz9Lewv-23Iz0xl6cboB",
			"3":"1p3hSTS2ISq_RublEtBdH_kUb577sqKj9",
			"4":"16rnW6QVQmJFj2Me-53Yl0RI9xKENfFWG",
			"5":"1wAzWslb0NMYsgn7797COrgSdUaEAf8VI",
			"6":"1aUphA8Zo0Z94Wr3pPwJ0mDDPLDg_c3f_",
			"7":"1tPdkB_9z4C-kXYe8fgdy7b7d4lqNyV7D",
			"8":"1B5J2iGyFjNn-UeibX7CH4t_oq1B-CjUF",
			"9":"1bvLiCikjqJchR5j7xrp5d0eYPhvamOYm",
			"10":"1-zFO747MWzzvOqzp78MFzOeTFLFPrKV0",
			"11":"1QNEqljmoJVwz3uIBzPio5rfiEtItoIfb",
			"12":"1rnscn-9UJWFdUtytBuxoo5_oPbqZjYWL",
			"13":"1emxYS5VQp2-UW2rh6BorNYrAQQaLrLUm",
			"14":"1HqgqO6uaeYepA0FS_73W7pLVu1QOjsm5",
			"15":"1RkNOrkNqJh_X_ps8sGYoG34oXeeffOM6",
			"16":"1HyyJyb3lA2qb0vDSas5-48sIlkNcLnZW",
			"17":"12kwYbLinbG6FQTwYHmY7r3DMVa8Bm_c9",
			"18":"1VjLs1d6rFlcjI0NmgNIdmlHSKl7chEk8",
			"19":"1MtSFjYwsyR0pghcLE-2QOLBwWIwwgOw9",
			"20":"1biCN1eTIqqTDi_Q0iQsd8epx8KQTOIMZ",
			"21":"1LpswMOtiP0xihAqUd_4mwr5HHWy0lhL2",
			"22":"1VBdCJk_v2VWFnLWd10P-9SxFT28_q2hR",
			"23":"1w2bVp0nvUQjpshzwI-xV9ZmMnR_cZI5Y",
			"24":"183_cjtw9Ki69MpAQAQzjx9BKA3q9aRn5",
			"25":"1Dh9Ky0SXTFCee1HEmcam3AP4grDt8NkW",
			"26":"1f28YhhLVveLAZNabM9w9UCDD2zxOpuJe",
			"27":"13GTAiSZoz-SCQauIFf31FAJbFh45fx3Z",
			"28":"1hIL2tQvSrNubse1zfSWyR4s5HMYpgZvU",
			"29":"14Yb2vK3882VDRe-pm1GFCu5gedXDvvaV",
			"30":"1KOASLPqypUMREEpNqiFkc-F3G7fh0eiv",
			"31":"1GWILgJYAmmmqx4b-Jks_6Wl94m8EeW5c",
			"32":"1ir864TAkpTmxsnjlSqkvl6bxMMKqh7R9",
			"33":"1LZEEENCI4p431NxjHq9pVkaxOc7aqqi1",
			"34":"1E72o_72jZGpBA7EQBpDe7sgHzSlzVVvn",
			"35":"1anr6sEphgFZaNMt5WNOUe7bYmfX8fP_P",
			"36":"1ZEkn1ntWGloJFApc-Cj6crANwCvLrkkC",
			"37":"1edSgf8AO9rN4d7Q8X3pYvSJ_RSrNl03R",
			"38":"1FYyVtXWG_1whqZoVfYLgTy4RvFh4bTwK",
			"39":"1kqUvkpD_XEnv8AjMyiz6tME1aILuh3nT",
			"40":"1q2DRLsp6HEAYVwLp6k1hVdcXK1RZT6Iw",
			"41":"1mjGbyMcpfwg6wvijTF4aHwo7gOlO5TA2",
			"42":"1s6zsO0RdbXgbnVPobt09zrlVC3WrI2OO",
			"43":"10ZmynsbenSVNQ5q_OjWdLx-G0V1kfghy",
			"44":"1lf_4FmV9XaoJabxtcygZJxfpUsoSA_v2",
			"45":"1kSfJiM4e0aXbqPrbNhZw0o9yN5O0-fRU",
			"46":"1Pih7Gj5ByxonM9lrQUknPEkSWzPEcnex",
			"47":"1EjG0LwpYLijlQO6KoPpGpg47Pb7PpPWx",
			"48":"1aQaB8VmDRSHhBVOMd-uSm7jp6H-J2xBw",
			"49":"1sLm1rypkdIy1dWLT5_vEx6iiXyulSDfL",
			"50":"1sI_XcIpg0FJPjPx7y28fW676NnMZIaPm",
			"51":"1ir3FVWYFONKjSgXzDDtqmumzEuZW1HBr",
			"52":"1S_-mqyHfT6I0hej8CFEQPQowtIhBJBeG",
			"53":"1MGVdhSND6iiSE_3uIgoCWIJRUDQ_H2Bp",
			"54":"1Y2FJFHgdl93wWjXfYwTz0RHOah-TZKYd",
			"55":"1Kyyge0TPtjdRby0RGS-pCLiEiMa00tnp",
			"56":"1XoZywHYXtQqp-zejOjSbzxXzMfQ6zdGb",
			"57":"1tcXdj1-xr3HX17ltCSbuLishYo7gGdfF",
			"58":"14TE37ZHUpPyMDbGQcbmlAPhEG5ZvBqoy",
			"59":"1_goVareRCAX7VS0lPggMUHwu_omOqfOS",
			"60":"1H80nlsKA8J7WLXxYarOoGWFO9HZK7VoZ",
			"61":"1HGmkwmyP8uxB5Rt1G55aR73u2fOKIQHt",
			"62":"1pvxwih1QN7mZxS1EHx2riNulJ6I_DgXY",
			"63":"1QQEtK1OhFQoDB5Oz8FWWGvClsCYXxC-l",
			"64":"1s_TuLNBGvy4b736pHXPr7st2cbrJICHS",
			"65":"1sE8iJUsLEGD_C7-LBbmJEy6h95Sl0EFA",
			"66":"1k8uQ8EZgTLskGCDXB3-VJ75FqXhtdf_b",
			"67":"1qXnhsyxFG8s-bhs_O6swYvvcA_9Cr-0q",
			"68":"1nM7m0uDsIphK6OmHOuGIBF076W9iS2VX",
			"69":"1nLrhHvjyucER2gmpT6QHDtG9GZ8dX_9B",
			"70":"1DI5BeA7Yu91CWD5AvBnILI5x2JdVSbu_",
			"71":"1wDLS5ylVGMKsHLving4AbZG-MFegOyY_",
			"72":"11XFugXKKCqBI_4xdXMYEIsibrvn32mX2",
			"73":"1har3me2oEt40X47vRj1SjLzKtcsi4d-V",
			"74":"1r97Tyy9Xnwo5sXBH9_l6PiJ_8UH_cR33",
			"75":"1OMQptXNJ-ZyrxXHMPZgePDkvZqxgc0Xl",
			"76":"148f44ePtG6SjM6zP2KJiNiflsruJry-l",
			"77":"11Sqw9AbQiwb-G9gU5rOY2gqDDPgGGjUV",
			"78":"19tUUTGXg9lf420o-kqxTcWDGTiymAv3L",
			"79":"1YVffXkKGiCrTDlVwrFbvaCbuS0woi2ON",
			"80":"1TE-55w80_7w5DmUtjLsvknqIhk8Kawp4",
			"81":"14MWfRr1MRN0qs24Vpd3R7Ny7qD3xgzTD",
			"82":"1W2fktG5i2H3TdKHg2b3gkPZw4nW6XpNU",
			"83":"183nQ3TNowXzxUXSfSGqLhweXLeeipn7p",
			"84":"1jlQt7bwT-zIk6D_at7tpWXnFskCUWSK7",
			"85":"14X6snaLN7EQ-nVJZFneYBWuNNK6S-54Z",
			"86":"1FYYma8pcLCJvS4XAHtTh_G_b7BFgCgN8",
			"87":"1gqEocvdpl96SxKGIZZKVtvOOBShCkicz",
			"88":"1-Ge80l0SqZn86JPPvYnNlfRerLg06zjY",
			"89":"1EurbxG0REzUuxQgIN4ZKFuGUA93pNZic",
			"90":"19ER6nCmbc5QOEbQ_YpGl2eDXe4Ufa772",
			"91":"1REcAUmaIktKIRu5vLuYQzCStVR_vU-TB",
			"92":"1fe6LOG0o5qKgj2ML70aZUzJY3_jTjLqh",
			"93":"1Mzfr_ZFT1vTJHKdX6mAYAzYsSTv3XWYO",
			"94":"19zz2xlsRzFSn-MM-Zo-5hHO-R5EtKkMt",
			"95":"11F5cyKTxRJQs2I2oVW0SPcErO3U6OY_N",
			"96":"1Rr3bxXWzGBnoOIKnGMS5uPqa0m68OGlo",
			"97":"1YFe0SLu7IPoSXA3rFPriGcJIosZbu7Xv",
			"98":"1McvysLO3o2W5MTUELQHFdi_c98tJzuDI",
			"99":"1v41yasKQ6XnE1-SrDOFGvkbb81eH7CDM",
			"100":"1D-pKDR9hHQFRUQ8ByKetRk0_IRptebG5",
			"101":"192EPzQx6lB7yV0sSg-v0doba9HLy_wWR",
			"102":"1fndoq43j6_Z2MXPcGRO1fvnbx0XymfpA",
			"103":"10AqrovCu1jzLDPpxOJ235om0qPJkzrBo",
			"104":"1xwtOmKIvtlMrrqhzXTUPnzbumCtQSh9h",
			"105":"1G0k8gooLz3KdDybAmEVpBKJVaPpKTFTd",
			"106":"12XVxzp8YRBjHpX1RvSyvOQ2CY6EenWmm",
			"107":"1JI5O6mkoQGOKReXL8hbp_BJgG-XUkN-E",
			"108":"1RsYhRV7i9tyMEENhIbC5N_bB8RoQ0TA7",
			"109":"1aCqkkELSLsjhT18lVZ5t-ZGvnbO9_QMQ",
			"110":"1K0_5PPKj1P0JjB3RxqpXm-7-iuPF1vgN",
			"111":"1HO7bWqZ1tyMocByC5BKl_qfBERA0aU64",
			"112":"1Zi6ls7DkdLwC6fDGB5MlZM3IZJ_rJrxU",
			"113":"1Xah42le_aIG3TDv4PseSYCE6iqd3lW6e",
			"114":"1eZS2-zdlYkKp3bK68tv12Gm4ZbypOaPC",
			"115":"1YpHTdOUlUeHb4CtZs3bKNw4XRt4o-bae",
			"116":"1DEJYsTXdkRz6iFnIcXjhLRcxItAaejfh",
			"117":"1rvHO3vq8rpZICU7DHuO-AILwES_VVC4b",
			"118":"1VSvgchjfds4QmykhmSGeAgv3qSn9jqce",
			"119":"1fr3MgIQA9Bj4T5LXN5pBgGGGwwko3cKs",
			"120":"1--2Qv0OH4bMJnBs0BEuYS92UsrY15tEQ",
			"121":"1KZIMqFahpZrjhFdNox5LXYLG41wGJpjR",
			"122":"1Y71U8hytpFVX6LZlfCAXyN_TTUe8rGRA",
			"123":"1n3qVxMZZSk56DZnUroRPQDk4TPI13oec",
			"124":"1CfWihJr3OewRHS3dFd4gmdf3vysS_aO6",
			"125":"1tqbuSSIU0e73AdHyt7lVA6lQKx8zu8Mb",
			"126":"1u34aSMoztYeDDtagLl_ZOt3b86ULGG4r",
			"127":"1heZT-2pmeASPKZB5oNyfcfemtVeYWA9y",
			"128":"12a-P93QzTKQZVcGRbUMREFSwwd5FGyFq",
			"129":"1gDkKEBdZnDj8DLRlFwRNFC9LBg_oF4RH",
			"130":"1O2hgLHuESa352N2r7UYag4e5vQJO8gPo",
			"131":"1cjp8b8qu9h4KFl4fjlH2MYsRcQw5Lsc6",
			"132":"1sIU99jKcU28NXkwqnk4l4-raUPJDzFua",
			"133":"1K3lFklfeYbbMGVF2PIoPtiOWJra7pvUX",
			"134":"1K1F0kFMKLv7ApGun9rMAxlnsz7nvHmDI",
			"135":"1eMMu_z2bR2F7n7zBqoqNbJ2SH6udZ0Pd",
			"136":"1OIdWvhFsm896W07PMXf4ZE3HCbJGUKW5",
			"137":"1KiaPThMpIGXK5PYagp6bjYOTwHFbh4lh",
			"138":"1G8mgrLAd7U2sPHgysytWnmZY0_ZhW9Mi",
			"139":"1S8MpAKZjS5FhoQfxFHtkHcvaMGOEvo_1",
			"140":"1U07AA4VLja0wqzeUu-R_OR9BYAXg3UnR",
			"141":"1hI0BSNTyyUo79vhbFDB6rUOoF_5IDEby",
			"142":"14lSOPWgJYvAun12eP39PE9hOl4PWV_ec",
			"143":"1qIaVV-eAUUpavTxL2kNJpBbL_W5miv6S",
			"144":"1kC9BcO9PYR1Rcpju_8AH2aJj_6P36R15",
			"145":"1Er6_UyGkBtJcfNbszL6FAxEXiBY_GETl",
			"146":"1plsSiGFDykUHTlzFDMTXwpOI-o3SihAp",
			"147":"1Izgt_tiVhTB6WTSlMEG8eS0DWSKJOCuk",
			"148":"1wTdLrZrI1La-dAB-HD1Pifc-nZWnf0Gi",
			"149":"1FXcn-E3u3F92FPH-93ZEiOSOrgixWvfH",
			"150":"1zopCAPAqmUkiE4JiONQqRagoLh36Fc40",
			"151":"1NgGIsfjIbl_5hQ2WHLXjKVuNWa-dc9dW",
			"152":"13fPWMeCWhllpasjz81cY5rLfRSE0Ds0u",
			"153":"1YjMGb2d0puEn6MHL9Zf4DoZIEbLA2mmj",
			"154":"11i4-W3xFD0yNzmXjUTBNbQacGLX2qfoE",
			"155":"1gf7ho7prkxV9RBPwpXLS7X_8wXLRCfmE",
			"156":"1PhSidD0xXSuUq3vGhYn8IcPoDBO3uAgO",
			"157":"1atc_lDhcRoHoih1EwBTbYFXunEW0aW_O",
			"158":"1-oZWA-gnTu8iJLB-KO4pA2ZrAPnRwcQG",
			"159":"1VmaBCsouWEQIUbd3yKSwkIqC1usRFdAc",
			"160":"1PVe96UMPZlSYvJkc1bMV8UKoLuPsZiVs",
			"161":"1KV_hipJPzrOgnW_dbDmygrNuconRWfEI",
			"162":"1FQJCFf0sxFReWnyj7qwaaWk-FInZx0jx",
			"163":"18LOruoG1D1UAJvmr4TMOg4pM_QX55_wd",
			"164":"1Ahpg0dcodknWw4H09mxApBh7UxEP7-b-",
			"165":"1FOYop8TJQYYLudUu0umE-rsFysKU2pXa",
			"166":"1LTeVZ972qhUUwj8o6o1kp35Wwu5hkXK_",
			"167":"10zNwrm7lc_SQU16koJyIqOvKzyDMejmF",
			"168":"1qK3L139rnVA-4J1Pp_oUuBo3nh3dsl1a",
			"169":"16qEwIULbkBN2_PHpUzNzOhphcOXv7MAE",
			"170":"144T0hp-2OjGqQTz5OO78otGTTnWJVoeS",
			"171":"1O2EixzyQ2i53Ub_bEPKVDVI7TtKZAfo8",
			"172":"19E8XunR4rvWNvsJE0Qj-ieH5IheQLMTC",
			"173":"1JaSoDbSnf7kOe9fRVtCk4H2-b3Xufwhn",
			"174":"10GeKAtsDoYapPO3dbq4F8jiRoMNiHrKN",
			"175":"1VF6YpNvNbGU8A3Kb_6LHQhnuR3JT8ksP",
			"176":"17J8Ot-alE31kUS13PeSJpT_cgMuw1TOk",
			"177":"1ss4nveAEsVDpztkObgp8IjGIx-rvr5eV",
			"178":"1Pck_fcQZWKATiX1g-yYJalAnMiYyK2cp",
			"179":"1oqjWR8G7q8xDs5j6W0v68ANS9wY-kHNW",
			"180":"1osR7eck0yxyqbnMbk7RaIvPsaKwrMh5G",
			"181":"1au4tL7DMk1Tg1DJA6wgJm4WdlZ7S26gA",
			"182":"1GhMFlCh7PdJXIW1OmMr9VmY6jYqwIbSQ",
			"183":"1w-E1fcxS7S2XUfh4Vr-QE0Kikrer0IBv",
			"184":"1ncoM0qo6AnGYZ_NL3rAGbbczuXQHgU0l",
			"185":"1fqAjY2kj_R8HTquuN4UvAcinDBX7CB_0",
			"186":"1yGDdb2NUULaZ5YYQg5Fp5Lm2OmQ21rGc",
			"187":"1rkxa2KY7FAL9mAq2KHKIbFhath-KqEzw",
			"188":"1WhW5QWnGy4Yitc2ZniDvtAnRR1TGObY-",
			"189":"1RZaNZcUYWFIzqxg98J8YygSwIuZgh1Oq",
			"190":"10zo1kOuxqBpsAnUPyUTIGU6st8L59Sb6",
			"191":"1ZyqJycylvCxqlc9Y3Yl6myL5YPWToxny",
			"192":"103-kytZP7kEGG7a9t282JxHeHjgqKLZv",
			"193":"1e0yzASq5qUUG5tebdTKPvXb0pst2Q1qV",
			"194":"13LjuiLM7b7becEQdUvHYve3iTFnrA-FJ",
			"195":"1mOYKuQxJ21WeXELz1wA5qZfyqKPQLZrw",
			"196":"1llVejuVMJF9pinRAhS9k92EzLQqXgoU_",
			"197":"1x6zPkiT2aKZaes9dnqVgRtYHekAUqOGD",
			"198":"1Vfi8G3hy4P3x9YKPIVxTknmFHhwB9B7o",
			"199":"1TngT6Yu_Wl2UrfdsW9SBfuojITpY1Dt9",
			"200":"11kuYYcKdY_J4C9KE9ngh3GdKOcADYDIx",
			"201":"1XYnyjK7NcLbbHf9iJFQZhG7uUTtaPyI4",
			"202":"1qDWu0q8o9iCPNQ7xyyWce3ZgbKIBy478",
			"203":"1OqQhfuJ2QhOZ8bHyganLVqBn-P65Dq2c",
			"204":"1MOGpIA02_iTv1T8Nwl5saghRAGycrHTW",
			"205":"1BKn9rFH_4bRSW0T3tNhIUqGpdRjU-EVK",
			"206":"1cu0ylYM64d49QL2aYClOrY5c4_gB_xq6",
			"207":"1zH0zhataYRkLg097WPaEZA6hMpMBRqTC",
			"208":"1LjNrGRdTBiuI3-IJ6zbx8xZJAnDt-AOv",
			"209":"16P7zEHRQ3vbkXUhSLu_mdW-eQ51H8TvN",
			"210":"1PaTF26FOqZh_32Q4jmaMBjqUdbXWWKHn",
			"211":"1RJBl3KUCELN51BUOYB_lZYYy-JJ7XMpZ",
			"212":"1PzEgzDnjrxrMa6yklSxuS6vmSrZZDSdz",
			"213":"1xC5ET1A4hcIamxF6j035WnzNTx2K-RIZ",
			"214":"1MEEjx7XteI0dDWM6lybuwjJkHI7CCKg9",
			"215":"1fwVz8KLOEGE-FoGLBjCFC_wJiu6Ico64",
			"216":"1y4BGoqrkaDy0oXdmuW4cLFdWz96U1E3E",
			"217":"1F714ZkbNLIf31ReLR3NNsFQXV926uDva",
			"218":"1MmzAhggAjp6KspXwp8xHSNcVWrS0SAti",
			"219":"1vONF2LN31RdHchXVOgiokllOn7-q9JnY",
			"220":"1gbmBfGbV621jsrsGEelTev14lZmOz_9s",
			"221":"1olrzQuvsvTwL8cY4TWU2r08-zLrq6XiC",
			"222":"19-Hu_tQMzKbI4SIemTd95_NsvEVfkdvp",
			"223":"1DcQxOX61Xib_z1YSX3VR7PPZZoBPhKWZ",
			"224":"1xRsssZSUYY5vsdz3S5YVN0pZXKAN3qsz",
			"225":"1XOvx0u7MHx5oMc63dpdm2hl2jGiposrg",
			"226":"1y3yzmwLU8GkfrYKao9AYeqenxsb5oH-7",
			"227":"17xEtvUu7fMjlnprhbNm_PUA83OvX4GSv",
			"228":"1DVcrKFQeG8AkQL7JDE_3rRevodJ5rMc1",
			"229":"1JRUWvccGnSGVsWUkE7SAQHhY4lMC5DHP",
			"230":"1NFMfWrtvkTDF31_QMCZ0R5tqpTRrYlMR",
			"231":"11xb6jmK9NRfr3n21Ti6oyjPqquWbqARl",
			"232":"1FjS2F1cAdTbfR7AHOyb3S-w-wZ0ijG-o",
			"233":"1GUMbJXqAJGTxSShLixaSU_88bKS59Bap",
			"234":"1qLpLYnIvw5HJoLgeRE_hI3fympkP18fq",
			"235":"19clWLgA8CTNu1aI6S8on8_m1_X0CfVTd",
			"236":"1vm1pBc6mKI8R3OwMK31P94E_c70wRS3y",
			"237":"138GuLSjgEu2CC5lNp49cilR7TL5KeHl-",
			"238":"1RHIvrfFNzf8PmkCms1AmaWHvzJoEpNa8",
			"239":"16_QgvrSWwq5EAa-CqxwpuC1H4zuRPJTL",
			"240":"1E7cMXU22fQf6AEyWR2Rk6FnD31BR5sLL",
			"241":"1tIco-PLfbmD9Xp0WrHmH4fv-bXX1cgnF",
			"242":"1BA7BdB1sn1QBNn_6rEJDG5O8QW-7SYfX",
			"243":"1e015GSS9baoOYTP4IQZo_YFaUwHv-iOI",
			"244":"108V2w62Umt_3zuc-Q_MaiiyJ0R5b3fGL",
			"245":"1UvwoqC07zv2X3-Orc9eQL3yVhbZwxmnz",
			"246":"1o3j5oHh31VMUS8pg07Q_oTnhtknD8DBR",
			"247":"16D_z3G72kK89bo4OldAsPU1_fQvZ3xUP",
			"248":"1fVZOU8wJ0G-XVw1615qBaR3ynCoQQEPl",
			"249":"1Cy-Zd7Co9v2EJXN0kRXRXx1YORHaPH9X",
			"250":"1Hr2m_hCxf3bG_tgU-A9ieoa8QjSGrfqp",
			"251":"1S7q3W0VbFsN9OeQsBJHG3tKlNmI_ewJ3",
			"252":"1Nl5wwD50jiustet8TqTJkr5_a_Ngx0dY",
			"253":"1Tkp0lxipN7j4XARM-Gs-rHbsuO8yX1st",
			"254":"158kwkgxGYUNzrqXPciUhpE29yBISXAOW",
			"255":"1lkk7UmSfhHiwM4twlt4JdMymOuApO4M1",
			"256":"1nFu4vQOO2GY5DuwKvwzhEyWaBJTFeUne",
			"257":"1xE7odADPI5qfy40FZye5iWqB4yrcO1pJ",
			"258":"187bzCe29YQZ21YitnY3mfVymubAWkHzb",
			"259":"1-w74xqda_SvVAp9efJNbP8pKV_yEiXLL",
			"260":"1lZqCKESE0VYn2UaARvTkSSL_wzOqcsUo",
			"261":"1UkNyY69mCS9O4r1cC8CHe4jQnbQ2cJrr",
			"262":"1jm01QLB9aGFfPFuKqadSoK6ByBzG_Gus",
			"263":"1h9XMqo8d_gr1ezDaxuvDLPMD7YMukvl3",
			"264":"12wGQje4pZfD0NhwU5dgggf18n8KMi6sm",
			"265":"1yyI5dG8f2iGNJumIO736HJ4mlLSttGkm",
        },
        
        
    };
	
	
 const photostockData = [
   { id: 'TAMBAK_LOROK', label: 'TAMBAK LOROK' },
];



   const navigate = useNavigate();
	
	const allIframesRef = useRef([]);
	const allIframesRefbk = useRef([]);
	const [currentId, setCurrentId] = useState(1);
  const [prmPhotostock, setPrmPhotostock] = useState('');
  const maxKey = photostocklist[prmPhotostock] ? Math.max(...Object.keys(photostocklist[prmPhotostock]).map(Number)) : 1;
  
  const [selectedTablesPhotoStock, setSelectedTablesPhotoStock] = useState(null);

const customStyles = {
  control: (provided) => ({
    ...provided,
    borderRadius: '0',
    background: 'white',
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'nowrap',
    width: '200px',
    height: '25px',
    minHeight: '25px',
    whiteSpace: 'nowrap',
    borderColor: '#ced4da',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#ced4da',
    },
  }),
  menu: (provided) => ({
    ...provided,
    background: 'white',
    maxHeight: '300px',
    overflow: 'hidden',
    marginTop: '0px', 
    padding: '0',
  }),
  menuList: (provided) => ({
    ...provided,
    maxHeight: '300px',
    overflowY: 'auto',
    padding: '0',
    '::-webkit-scrollbar': {
      width: '0px',
      height: '0px', 
    },
  }),
  option: (provided, state) => ({
    ...provided,
    padding: '0px 10px',
    background: state.isFocused ? '#007bff' : 'white',
    color: state.isFocused ? 'white' : 'black',
    '&:hover': {
      backgroundColor: '#007bff',
      color: 'white',
    },
  }),
  singleValue: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
  placeholder: (provided) => ({
    ...provided,
    display: 'flex',
    alignItems: 'center',
    color: '#333',
  }),
};

	const styles = {
		
		
		
	};
	
	const handleClick = (prm_photostock) => {
		
	allIframesRef.current.forEach((div) => {
		  if (div) {
			div.style.display = 'none';
		  }
		});
		
		allIframesRefbk.current.forEach((div) => {
		  if (div) {
			div.style.display = 'block';
		  }
		});
    
    const maxKey = Math.max(...Object.keys(photostocklist[prm_photostock]).map(Number));
    
    document.getElementById('iframe_ps').src = 
    `https://drive.google.com/file/d/${photostocklist[prm_photostock][1]}/preview`;
	setPrmPhotostock(prm_photostock);  
    
    document.getElementById('homealive_photostock').style.display = "none";
    document.getElementById('table_photostock').style.display = "block";
    
    document.getElementById(`bk_${prm_photostock}`).style.display = "none";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "block";
    
    
  };

  const handleClose = (prm_photostock) => {
    document.getElementById(`bk_${prm_photostock}`).style.display = "block";
    document.getElementById(`ttp_${prm_photostock}`).style.display = "none";
    document.getElementById('homealive_photostock').style.display = "block";
    document.getElementById('table_photostock').style.display = "none";
    
  };
	
  const handleNavRight = () => {
  console.log('currentId (before increment):', currentId);
  let idNav = currentId + 1;
  if (idNav > maxKey) {
    idNav = 1;
  }
  setCurrentId(idNav);
  console.log('currentId (after increment):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};

const handleNavLeft = () => {
  console.log('currentId (before decrement):', currentId);
  let idNav = currentId - 1;
  if (idNav < 1) {
    idNav = maxKey;
  }
  setCurrentId(idNav);
  console.log('currentId (after decrement):', idNav);
  updateIframeSrc(prmPhotostock, idNav);
};


  const updateIframeSrc = (prmPhotostock, idNav) => {
  console.log('prmPhotostock:', prmPhotostock);
  console.log('idNav:', idNav);
  if (!photostocklist[prmPhotostock] || !photostocklist[prmPhotostock][idNav]) {
    console.log('Photostock data not found or undefined');
    return;
  }
  document.getElementById('iframe_ps').src = `https://drive.google.com/file/d/${photostocklist[prmPhotostock][idNav]}/preview`;
};


  useEffect(() => {
   
    window.$ = $;
    window.jQuery = $;
	
  }, []);
  
const [tablesPhotoStockOptions, setTablesPhotoStockOptions] = useState([
    { value: '', label: 'PhotoStock' },
	
	{ value: 'kosong', label: '1. Perusahaan Gas Negara' },
	{ value: 'photostock_pertagas', label: '2. Pertamina Gas' },
	{ value: 'photostock_nr', label: '3. Nusantara Regas' },
	{ value: 'kosong', label: '4. PLI' },
	{ value: 'photostock_tgi', label: '5. Transportasi Gas Indonesia' },
	{ value: 'photostock_kjg', label: '6. Kalimantan Jawa Gas' },
	{ value: 'kosong', label: '7. Perta Samtan Gas' },
	{ value: 'kosong', label: '8. PGN LNG Indonesia' },
	{ value: 'kosong', label: '9. Widar Mandripa Nusantara' },
	{ value: 'kosong', label: '10. Saka Energi Indonesia' },
	{ value: 'PhotoStock_gagas', label: '11. Gagas Energi Indonesia' },
	{ value: 'kosong', label: '12. Pertagas Niaga' },
	{ value: 'kosong', label: '13. Perta Arun Gas' },
	{ value: 'photostock_pdg', label: '14. Perta Daya Gas' },
	{ value: 'photostock_sswj', label: '15. PGN-SSWJ' },
	{ value: 'photostock_sor1', label: '16. PGN-SOR1' },
	{ value: 'photostock_sor2', label: '17. PGN-SOR2' },
	{ value: 'photostock_sor3', label: '18. PGN-SOR3' },
	{ value: 'photostock_sor4', label: '19. PGN-SOR4' },
	
  ]);
  
 const location = useLocation();

useEffect(() => {
    if (selectedTablesPhotoStock) {
      if(selectedTablesPhotoStock.value === "kosong"){
		 alert('Coming Soon!!!');
		 setSelectedTablesPhotoStock(null);
	  }else{
		navigate("/"+selectedTablesPhotoStock.value);
	  }
    }
  }, [selectedTablesPhotoStock, navigate]);

  return (
    <div style={{marginTop: '30px',padding: '10px'}}>
		
		<a href="./">
			<img 
				src={`${process.env.PUBLIC_URL}/gambar/Alive-red.png`} 
				alt="Alive Logo" 
				style={{ width: '150px', marginTop: '-30px', marginLeft: '-10px' }} 
			/>
		</a>
		
		<img style={{float: 'right', margin: '10px 30px'}} src={`${process.env.PUBLIC_URL}/gambar/Logo_Pertamina_Gas_Negara.png`} width="200" />
		
		<div style={{clear: 'both'}}></div>
		
		<div id="tph" style={{ float: 'left', marginRight: '10px' }}>
			<Select
			  styles={customStyles}
			  options={tablesPhotoStockOptions}
			  onChange={setSelectedTablesPhotoStock}
			  placeholder="PhotoStock"
			  value={selectedTablesPhotoStock}
			/>
		  </div>
		
		<div style={{clear: 'both'}}></div>
		
		<table>
		
			<tr>
				<td align="left" valign="top" style={{width: '200px'}}>
					
					<div
						style={{
						  marginTop: '10px', 
						  marginLeft: '-30px', 
						  width: '200px', 
						  maxHeight: '600px',
						  overflowY: 'auto',
						  scrollbarWidth: 'none',
						}}
						className={`custom-scroll ${isHovered ? 'hover' : ''}`}
						onMouseEnter={() => setIsHovered(true)}
						onMouseLeave={() => setIsHovered(false)}
					>
						<ul>
							{photostockData.map(({ id, label }, index) => (
							  <li key={id}>
								<table>
								  <tbody>
									<tr>
									  <td valign="top">
										<span className="lbn"></span>
									  </td>
									  <td>
										<a
										  ref={(el) => (allIframesRefbk.current[index] = el)}
										  style={{ fontSize: '13px', color: 'black' }}
										  id={`bk_${id}`}
										  onClick={() => handleClick(id)}
										>
										  {label}
										</a>
										<a
										  ref={(el) => (allIframesRef.current[index] = el)}
										  style={{ display: 'none', fontSize: '13px', color: 'black' }}
										  id={`ttp_${id}`}
										  onClick={() => handleClose(id)}
										>
										  <b>{label}</b>
										</a>
									  </td>
									</tr>
								  </tbody>
								</table>
							  </li>
							))}

						  </ul>
						
					</div>
					
				</td>
				<td valign="top" style={{width:'calc(100vw - 250px)'}}>
					
					<div id="table_photostock" style={{ display: 'none' }}>
						<table style={{ width: '100%' }}>
						  <tbody>
							<tr>
							  <td align="right"></td>
							  <td style={{ position: 'relative' }}>
								<div id="leftrighthover">
								  {/* Tombol navigasi kiri */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavLeft} 
									style={{ position: 'absolute', left: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon left.png" 
									  alt="Navigate Left" 
									/>
								  </i>
								  {/* Iframe */}
								  <iframe 
									style={{ width: '100%', height: '600px', border: 'none' }} 
									id="iframe_ps" 
									src="" 
									title="Photostock Frame"
								  />
								  {/* Tombol navigasi kanan */}
								  <i 
									className="left_nav_right_ps" 
									onClick={handleNavRight} 
									style={{ position: 'absolute', right: '20px', top: '45%' }} 
									aria-hidden="true"
								  >
									<img 
									  style={{ width: '40px', height: '40px' }} 
									  src="gambar/icon right.png" 
									  alt="Navigate Right" 
									/>
								  </i>
								</div>
							  </td>
							  <td></td>
							</tr>
						  </tbody>
						</table>
					  </div>
					  <img 
						id="homealive_photostock" 
						style={{ width: '100%', height: '600px' }} 
						src={`${process.env.PUBLIC_URL}/gambar/photostock background new2.jpeg`} 
						 
					  />
					
				</td>
			</tr>
		
		</table>
		
		
		<div style={{
		  position: 'fixed', 
		  bottom: '0', 
		  width: '100%', 
		  backgroundColor: '#ffff', 
		  padding: '5px', 
		  fontSize: '12px', 
		  textAlign: 'center'
		}}>
		ALIVE - Assessment Status and Virtual Modelling for Infrastructure Operation Assets
		  <div style={{
			position: 'absolute', 
			bottom: '5px', 
			right: '50px'
		  }}>
			<Logout />
		  </div>
		</div>
	  
	</div>
  );
};

export default Photostock_pdg;
